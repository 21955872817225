import { FieldInputProps } from 'formik'
import React, { useRef } from 'react'
import { toAbsoluteUrl } from 'src/util/url'
import SVG from 'react-inlinesvg'
import './styles.css'

type ListSearchInputProps = {
    field: FieldInputProps<string>
    changeHandler: (value: string) => void,
    submitHandler?: () => void,
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

function ListSearchInput(props: ListSearchInputProps): React.ReactElement {
    const timer = useRef<NodeJS.Timeout>( setTimeout(() => {}) );
    const value = props.value;
    const noInput = React.useMemo(() => {
        return value === ''
    }, [value]); 

    const inputHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.persist();

        const { changeHandler, submitHandler } = props;
        const value = e.target.value;

        changeHandler(value);

        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            submitHandler && submitHandler()            
        }, 800);
    }

    const clearInputField = () => {
        const { changeHandler, submitHandler } = props;

        changeHandler('');
        submitHandler && submitHandler();
    }

    const { className, placeholder, field } = props;

    return (
        <div className='list-search-input'>
            <input
                type='text'
                className={className}
                placeholder={placeholder}
                {...field}
                onChange = {inputHandler}
            />
            <button disabled={noInput} onClick={clearInputField} className={noInput ? 'd-none' : ''}>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Times.svg')}/>
            </button>
        </div>
    );
}

export default  ListSearchInput