import React from 'react'
import { useHistory } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { comment } from 'src/util/comment'
import { createFilmsRouteLink } from '../utils/createFilmsRouteLink'
import { defaultSearchParams } from '../utils/defaultParams'

export function useSetInitialQueryParams() {
    const { 
        navigationLinks: {filmsLink} 
    } = useNavigationLinksContext();

    const history = useHistory();

    React.useEffect(() => {
        const constraition = filmsLink === '';

        if(constraition) {
            comment('useSetInitialParams on FilmsList','Setted initial params');
            history.push(
                createFilmsRouteLink(defaultSearchParams)
            )
        }
    }, [filmsLink, history]);    
}