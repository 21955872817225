import CustomContainer from 'src/app/components/CustomContainer'
import AddBasicInfo from './AddBasicInfo'

function AddContent() {

    return (
      <CustomContainer className='content-add-container'>
        <AddBasicInfo/>
      </CustomContainer>
    )
}

export default AddContent