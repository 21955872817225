import React from 'react'
import { useListUIContext } from 'src/app/contexts/ListUIContext'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'
import ErrorBoundary from '../../../ErrorBoundary'
import { AbonentsFilter } from './AbonentsFilter'
import AbonentsTable from './AbonentsTable'
import AbonentsCardFilter from './AbonentsCardHeader';

function AbonentsCard(): React.ReactElement {
    const abonentUIContextValue = useListUIContext();
    const abonentsUIProps = React.useMemo(() => {
        return {
            loading: abonentUIContextValue.loading,
            queryParams: abonentUIContextValue.queryParams
        }
    }, [abonentUIContextValue]);

    return (
        <Card>
            <CardHeader title={
                <CardHeaderTitle>
                    <AbonentsCardFilter/>
                </CardHeaderTitle>
            }/>
            <CardBody>
                <AbonentsFilter listLoading={abonentsUIProps.loading}/>
                {/* Unused, errors occur for reason of empty pagination */}
                <ErrorBoundary> 
                    <AbonentsTable/>
                </ErrorBoundary>
            </CardBody>
        </Card>
    )

}

export default AbonentsCard