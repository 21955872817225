import { gql } from '@apollo/client'
import { Tag, TagInput } from '../types'

export interface AddTagMutationArgs {
    input: TagInput
} 
export interface AddTagMutationData {
    AddTag: Tag
}

export const ADD_TAG_MUTATION = gql`
    mutation AddTagMutation( $input: TagInput! ) {
        AddTag(input: $input) {
            id
            name
        }
    }
`