import React from 'react'
import { ApolloError, ApolloQueryResult, QueryLazyOptions } from '@apollo/client'
import { GetAllEpisodesArgs, GetAllEpisodesData } from 'src/api/graphql/queries/GetAllEpisodes'
import { GetAllSeasonsArgs, GetAllSeasonsData } from 'src/api/graphql/queries/GetAllSeasons'
import { useGetAllEpisodesLazyQuery } from '../hooks/useGetAllEpisodesLazyQuery'
import { useGetAllSeasonsQuery } from '../hooks/useGetAllSeasonsQuery'
import { Episode, Season, SeriesFileInfo } from 'src/api/graphql/types'
import { useGetSeriesFileInfoLazyQuery } from '../hooks/useGetSeriesFileInfoLazyQuery'
import { GetSeriesFileInfoArgs, GetSeriesFileInfoData } from 'src/api/graphql/queries/GetSeriesFileInfo'

interface SerialFilesContextValue {
    getAllSeasonsResult: {
        loading: boolean;
        data: Season[];
        error: ApolloError | undefined;
        refetch: (variables?: Partial<GetAllSeasonsArgs> | undefined) => Promise<ApolloQueryResult<GetAllSeasonsData>>;
    },
    getAllEpisodesResult: {
        loading: boolean, 
        data: Episode[], 
        error: ApolloError| undefined,
        refetch?: ((variables?: Partial<GetAllEpisodesArgs> | undefined) => Promise<ApolloQueryResult<GetAllEpisodesData>>)
    },
    getSeriesFileInfoResult: {
        loading: boolean, 
        data: SeriesFileInfo[], 
        error: ApolloError|undefined, 
        refetch?: ((variables?: Partial<GetSeriesFileInfoArgs> | undefined) => Promise<ApolloQueryResult<GetSeriesFileInfoData>>)
    }
    activeSeason: number,
    activeEpisode: number,
    getAllEpisodesCallback: (options?: QueryLazyOptions<GetAllEpisodesArgs> | undefined) => void,
    activateSeasonCallback: (id: number) => void,
    activateEpisodeCallback: (id: number) => void
}

interface SerialFilesProviderProps extends ReactMultipleContainer {
    serialId: number
}

const SerialFilesContext = React.createContext<SerialFilesContextValue|null>(null);

function SerialFilesProvider(props: SerialFilesProviderProps) {
    const { serialId, children } = props;
    const [activeSeason, setActiveSeason] = React.useState(0);
    const [activeEpisode, setActiveEpisode] = React.useState(0);

    const getAllSeasonsResult = useGetAllSeasonsQuery(
        serialId,
        data => {
            const [latest] = data;
            if(latest !== undefined) {
                setActiveSeason(latest.id); 
            }
        }
    );

    const [getSeriesFileInfoCallback, getSeriesFileInfoResult] = useGetSeriesFileInfoLazyQuery();

    const [getAllEpisodesCallback, getAllEpisodesResult] = useGetAllEpisodesLazyQuery(
        data => {
            const [latest] = data;
            const movieIDs = data.map(i => i.id);

            if(latest !== undefined) {
                setActiveEpisode(latest.id);
            }

            /** Make request to the server to fetch episodes fileInfo*/
            if(movieIDs.length > 0) {
                getSeriesFileInfoCallback(movieIDs);
            }
        }
    );

    const activateSeasonCallback = React.useCallback<(id: number) => void>( id => {
        setActiveSeason(id);
    }, [setActiveSeason]);

    const activateEpisodeCallback = React.useCallback<(id: number) => void>( id => {
        setActiveEpisode(id);
    }, [setActiveEpisode]);

    const {data: episodes} = getAllEpisodesResult;
    const {data: episodeFiles, refetch: refetchEpisodesData} = getSeriesFileInfoResult;
    
    /** Effect to refetch episodes info after adding of new episodes */
    React.useEffect(() => {
        if(episodes.length !== episodeFiles.length) {
            refetchEpisodesData && refetchEpisodesData({movieID: episodes.map(i => i.id)})
        }
    }, [episodes, episodeFiles, refetchEpisodesData]);

    const value = {
        getAllSeasonsResult,
        getAllEpisodesResult,
        getSeriesFileInfoResult,
        activeSeason,
        activeEpisode,
        getAllEpisodesCallback, 
        activateSeasonCallback,
        activateEpisodeCallback
    }

    return (
        <SerialFilesContext.Provider value={value}>
            { children }
        </SerialFilesContext.Provider>
    );
}

const useSerialFilesContext = () => React.useContext(SerialFilesContext);

export default SerialFilesProvider
export { useSerialFilesContext }