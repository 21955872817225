import { FormikHandlers, FormikHelpers, FormikState } from 'formik'
import { EditActorSelector, EditCountrySelector, EditDirectorSelector, EditGenreSelector, EditTagSelector } from 'src/app/components/ContentService/EditContent/components/EditSelectors/components/EditSelectorsInput'
import { ValueType } from 'react-select'
import { SelectorEditField, SelectorEditForm } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'

type EditSelectorsFormProps = FormikState<SelectorEditForm> & FormikHelpers<SelectorEditForm> & FormikHandlers

// TODO: extract it to a separate file
const getFieldCSSClasses = (touched: any, errors: any) => {
    const classes = ['form-control'];
    if (errors) {
      classes.push('is-invalid');
    }
  
    if (touched && !errors) {
      classes.push('is-valid');
    }
  
    return classes.join(' ');
};
  
function EditSelectorsForm (props: EditSelectorsFormProps) {
    const { values, touched, errors, handleSubmit, setFieldValue, handleBlur } = props;

    return (
        <form onSubmit={handleSubmit} className='form form-label-right' id='add-basic-info-form'>
            <div className='form-group row'>
                <div className="col-12">
                    <small className='form-text text-muted my-2'>
                        <b>Жанры</b> 
                    </small>

                    <EditGenreSelector
                        className={getFieldCSSClasses(touched['genres'], errors['genres'])}
                        name='genres'
                        placeholder='Жанры'
                        changeHandler={( data: ValueType<SelectorEditField[], boolean> ) => {
                            setFieldValue('genres', data);
                        }}
                        onBlur={handleBlur}
                        value = {values.genres}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Тэги</b> 
                    </small>

                    <EditTagSelector
                        className={getFieldCSSClasses(touched['tags'], errors['tags'])}
                        name='tags'
                        placeholder='Список тэгов'
                        changeHandler={( data: ValueType<SelectorEditField[], boolean> ) => {
                            setFieldValue('tags', data);
                        }}
                        onBlur={handleBlur}
                        value = {values.tags}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Страны</b> 
                    </small>

                    <EditCountrySelector
                        className={getFieldCSSClasses(touched.countries, errors.countries)}
                        name='countries'
                        placeholder='Список стран'
                        changeHandler={( data: ValueType<SelectorEditField[], boolean> ) => {
                            setFieldValue('countries', data);
                        }}
                        onBlur={handleBlur}
                        value = {values.countries}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Актеры</b> 
                    </small>

                    <EditActorSelector
                        className={getFieldCSSClasses(touched.actors, errors.actors)}
                        name='actors'
                        placeholder='Список актеров'
                        changeHandler={( data: ValueType<SelectorEditField[], boolean> ) => {
                            setFieldValue('actors', data);
                        }}
                        onBlur={handleBlur}
                        value = {values.actors}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Режисеры</b> 
                    </small>

                    <EditDirectorSelector
                        className={getFieldCSSClasses(touched.directors, errors.directors)}
                        name='directors'
                        placeholder='Список режисеров'
                        changeHandler={( data: ValueType<SelectorEditField[], boolean> ) => {
                            setFieldValue('directors', data);
                        }}
                        onBlur={handleBlur}
                        value = {values.directors}
                    />
                </div>
{/* 
                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Языки</b> 
                    </small>
                    <input
                        className={getFieldCSSClasses(touched['languages'], errors['languages'])}
                        name='languages'
                        placeholder='Список Языков'
                        onChange={(e: any) => {
                            setFieldValue('languages', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.languages}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Актеры</b> 
                    </small>
                    <input
                        className={getFieldCSSClasses(touched['actors'], errors['actors'])}
                        name='actors'
                        placeholder='Список Актеров'
                        onChange={(e: any) => {
                            setFieldValue('actors', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.actors}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Страны</b> 
                    </small>
                    <textarea
                        className={getFieldCSSClasses(touched['countries'], errors['countries'])}
                        name='countries'
                        placeholder='Список стран'
                        onChange={(e: any) => {
                            setFieldValue('countries', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.countries}
                    />
                </div> */}
            </div>
        </form>
    );
}

export default EditSelectorsForm