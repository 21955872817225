import { Route, Switch } from 'react-router-dom'
import AbonentList from 'src/app/components/ClientService/AbonentsList'
import ComplaintsList from 'src/app/components/ClientService/ComplaintsList';
import { ROUTES_LIST } from 'src/app/routes/RootRoute'

function ClientServiceRoute() {
    const { ABONENTS, USERS, COMPLAINTS } = ROUTES_LIST;

    return (
        <>
            <Switch>
                <Route path={ABONENTS}>
                    <AbonentList/>
                </Route>

                <Route path={USERS}>
                    Users List
                </Route>

                <Route path={COMPLAINTS}>
                    <ComplaintsList/>
                </Route>
            </Switch>
        </>
    )
}

export default ClientServiceRoute