import MaterialThemeProvider from './contexts/MaterialThemeContext'
import SplashScreenProvider from './contexts/SplashScreenContext'
import NotificationProvider from './contexts/NotificationContext'
import RootRoute from './routes/RootRoute'
import AuthenticationProvider from './contexts/AuthenticationContext'
import MetronicSubheaderProvider from './contexts/MetronicSubheaderContext'
import { MetronicHtmlClassServiceProvider } from '../metronic/core/MetronicLayout'
import { NavigationLinksContextProvider } from './contexts/NavigationLinkContext'

function App() {
    return (
        <MetronicHtmlClassServiceProvider>
            <MetronicSubheaderProvider>
                <MaterialThemeProvider>
                    <SplashScreenProvider>
                        <NotificationProvider>
                            <AuthenticationProvider>
                                <NavigationLinksContextProvider>
                                    <RootRoute/>
                                </NavigationLinksContextProvider>
                            </AuthenticationProvider>
                        </NotificationProvider>
                    </SplashScreenProvider>
                </MaterialThemeProvider>
            </MetronicSubheaderProvider>
        </MetronicHtmlClassServiceProvider>
    );
}

export default App