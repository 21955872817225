import React from 'react'
import { useQuery } from '@apollo/client'
import { GetAllTagsData, GetAllTagsArgs, GET_ALL_TAGS_QUERY } from 'src/api/graphql/queries/getAllTags'

export function useTags() {
    const { loading, data } = useQuery<GetAllTagsData, GetAllTagsArgs>(GET_ALL_TAGS_QUERY);
    
    const tags = React.useMemo(() => {
        if(data !== undefined) {
            return {
                loading,
                tags: data.getAllTags
            };
        }

        return {
            loading,
            tags: []
        };
    }, [data, loading]);

    return tags;
}