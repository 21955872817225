import { useQuery } from "@apollo/client";
import { UserQueryArgs, UserQueryData, USER_QUERY } from 'src/api/graphql/queries/userQuery';

function useUserQuery(token:string | null){
    const {loading, data, error} = useQuery<UserQueryData, UserQueryArgs>(USER_QUERY, {
        variables: {
            input: {
                token: token ? token : ''
            }
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'cache-and-network'
    });
    return {loading, data, error}
}

export default useUserQuery