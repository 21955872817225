import { gql } from '@apollo/client'
import { Participant, ParticipantInput } from '../types'

export interface UpdateParticipantMutationArgs {
    participantID: number,
    input: ParticipantInput
} 
export interface UpdateParticipantMutationData {
    UpdateParticipant: Participant
}

export const UPDATE_PARTICIPANT_MUTATION = gql`
    mutation UpdateParticipantMutation( $participantID: Int!, $input: ParticipantInput! ) {
        UpdateParticipant(participantID: $participantID, input: $input) {
            id
            name
        }
    }
`