export const USER_ROLES = [
    {
        role: 1,
        name: 'Оператор'
    },
    {
        role: 2,
        name: 'Админ'
    },
    {
        role: 4,
        name: 'Супер Админ'
    }
]