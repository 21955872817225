import { gql } from '@apollo/client'
import { Response } from '../types'

export interface AddTagsToMovieMutationArgs {
    movieID: number,
    tagID: number[]
} 
export interface AddTagsToMovieMutationData {
    AddTagsToMovie: Response
}

export const ADD_TAGS_TO_MOVIE_MUTATION = gql`
    mutation AddTagsToMovieMutation( $movieID: Int!, $tagID: [Int!]! ) {
        AddTagsToMovie(movieID: $movieID, tagID: $tagID) {
            status
            message
        }
    }
`