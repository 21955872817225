import { useQuery } from '@apollo/client'
import { GetAllLanguagesArgs, GetAllLanguagesData, GET_ALL_LANGUAGES_QUERY } from 'src/api/graphql/queries/getAllLanguages'

export function useGetAllLanguages() {
    const { loading, data, error, refetch } = useQuery<GetAllLanguagesData,GetAllLanguagesArgs>(GET_ALL_LANGUAGES_QUERY);

    return {
        loading,
        data: data ? data.getAllLanguages : [],
        error,
        refetch: () => refetch().then(result => result.data.getAllLanguages).catch(() => [])
    }
}
