import { useQuery } from '@apollo/client'
import { GetMovieFileSourceArgs, GetMovieFileSourceData, GET_MOVIE_FILE_SOURCE_QUERY } from 'src/api/graphql/queries/GetMovieFileSource'

export function useGetFilmFileSource(movieID: number) {
    const { data } = useQuery<GetMovieFileSourceData, GetMovieFileSourceArgs>(GET_MOVIE_FILE_SOURCE_QUERY, {
        variables: { movieID }
    });

    return {
        data: data ? data.GetMovieFileSource : null
    }
}