import React from 'react'
import { useContextSelector } from 'use-context-selector'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import { ProcessActivateActionColumnFormatter, ProcessIDFormatter, ProcessNameFormatter, ProcessOrderFormatter, QualityIDFormatter } from '../../helpers/ProcessingFormatter'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { TranscodeRequestFile } from 'src/api/graphql/types'
import ProccessingContext from '../../contexts/ProccessingContext'
import { addUniqueID } from '../../helpers/addUniqueID'

function FullfilledTable() {
    const fullfilled = useContextSelector(ProccessingContext, v => v.fullfilledFilesResult);
    const typeID = useContextSelector(ProccessingContext, v => v.typeID);

    const entities = React.useMemo<Array<TranscodeRequestFile>>(() => { 
        return addUniqueID(fullfilled.data);
    }, [fullfilled]);

    const columns: ColumnDescription<TranscodeRequestFile>[] = [
        {
            dataField: 'order',
            text: '#',
            isDummyField: true,
            formatter: ProcessOrderFormatter,
        },
        {
            dataField: 'MovieId',
            text: 'ID',
            formatter: ProcessIDFormatter,
        },
        {
            dataField: 'qualityID',
            text: 'Качество',
            formatter: QualityIDFormatter
        },
        {
            dataField: 'Name',
            text: 'Название',
            formatter: ProcessNameFormatter,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '50px',
            }
        },
        {
            dataField: 'action',
            text: 'Действия',
            formatter: ProcessActivateActionColumnFormatter,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
              minWidth: '100px',
            },
            formatExtraData: typeID
        }
    ];

    return (
        <PerfectScrollbar 
            className='processing-list-scrollbar' 
            options={{suppressScrollX: true}}
        >
            <BootstrapTable
                wrapperClasses='table-responsive overflow-x-none'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                data={entities}
                columns={columns}
                keyField={'id'}
            />
        </PerfectScrollbar>
    );
}

export default FullfilledTable