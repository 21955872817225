export enum CONTENT_TYPES {
    FILM =  1,
    SERIAL = 2,
    SEASON = 3,
    EPISODE = 4,
    TV_SHOW = 5
}

export const CONTENT_TYPES_TEXT = [
    { typeID: CONTENT_TYPES.FILM, name:  'Фильмы' },
    { typeID: CONTENT_TYPES.SERIAL, name: 'Сериалы' },
    { typeID: CONTENT_TYPES.SEASON, name: 'Сезоны' },
    { typeID: CONTENT_TYPES.EPISODE, name: 'Эпизоды' },
    { typeID: CONTENT_TYPES.TV_SHOW, name: 'ТВ Шоу'}
]