import React from 'react'
import * as Yup from 'yup'
import { MovieInput, TVSeriesInput } from 'src/api/graphql/types'
import { Formik, FormikProps } from 'formik'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { LANGUAGES } from 'src/app/assets/Languages'
import useAddMovieMutation from 'src/app/hooks/mutation/useAddMovie'
import { useHistory } from 'react-router-dom'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { comment } from 'src/util/comment'
import { CATEGORIES } from 'src/app/assets/Categories'
import useAddTVSeriesMutation from 'src/app/hooks/mutation/useAddTVSeries'

export interface MovieTVSeriesUnionInput extends Omit<(MovieInput|TVSeriesInput)&{typeId?: number}, 'age'|'ratingKP'|'ratingIMDB'> {
    age?: number
    ratingKP?: number
    ratingIMDB?: number
}
/**
 * Function to reformating the input data
 * @param values (MovieInput|TVSeriesInput) for using in addMovie mutation
 * @returns prepared value for passing into mutation hook
 */
const prepareToSend = (
    values: MovieTVSeriesUnionInput
) => {
    // TODO: Add correct implementataion
    const { name, age, language, category, release, ratingKP, ratingIMDB, description } = values;
    const currentReleaseTime = new Date(release);
    const timeOffset = currentReleaseTime.getTimezoneOffset();
    const correctedReleaseTime = new Date(currentReleaseTime.getTime() - timeOffset * 60 * 1000 ).toJSON();
    
    const preparedValues = {
        name,
        age: age as number,
        language,
        category,
        release: correctedReleaseTime,
        ratingKP: ratingKP as number,
        ratingIMDB: ratingIMDB as number,
        description
    }

    return preparedValues;
};

const initialBasicInfo: MovieTVSeriesUnionInput = {
    name: '',
    release: '',
    age: undefined,
    ratingKP: undefined,
    ratingIMDB: undefined,
    description: '',
    language: 0 as LANGUAGES,
    category: 0 as CATEGORIES
};

/**
 * Validation chema for fields
 */
const validationSchema = Yup.object().shape({
    typeId: Yup.number().required('Нужно выбрать тип контента'),
    name: Yup.string().required('Наименование не должно быть пустым'),
    age: Yup.number().required('Возраст не должен быть пустым'),
    release: Yup.string().required('Дата выхода не должно быть пустым'),
    ratingKP: Yup.number().required('Рейтинг KP не должен быть пустым'),
    ratingIMDB: Yup.number().required('Рейтинг IMDB не должен быть пустым'),
    description: Yup.string().required('Описание не должно быть пустым'),
    language: Yup.number().required('Язык должен быть выбран'),
    category: Yup.number().required('Категория должна быть выбрана')
});

/**
 * HOC for injection formik context to the component
 * @param Wrapable component with (MovieInput|TVSeriesInput) props 
 * @returns Formik injected component
 */
export function withAddBasicInfoFormik( 
    Wrapable: React.ComponentType<FormikProps<MovieTVSeriesUnionInput>> 
) {
    return () => {
        const history = useHistory();
        const { setNotification } = useNotification();
        
        const addMovie = useAddMovieMutation({
            completeHandler: (data) => {
                const { id, typeId, name } = data.AddMovie;
                setNotification( { 
                    message: `Добавлен ${typeId === CONTENT_TYPES.FILM ? 'фильм' : 'сериал'} с названием: ${name}`, 
                    variant: NotificationVariants.success 
                } );
                // Redirect to the Edit page
                history.push( ROUTES_LIST.EDIT_CONTENT.concat(`/${typeId}/${id}`) );
            },
            errorHandler: (error) => {
                setNotification( { 
                    message: `Текст ошибки: ${error.message}`, 
                    variant: NotificationVariants.error 
                } );
                console.error('Error while trying to add movie', error);
            }
        });

        const addTVSeries = useAddTVSeriesMutation({
            completeHandler: (data) => {
                const { id, typeId, name } = data.AddTVSeries;
                setNotification( { 
                    message: `Добавлен ${typeId === CONTENT_TYPES.FILM ? 'фильм' : 'сериал'} с названием: ${name}`, 
                    variant: NotificationVariants.success 
                } );
                // Redirect to the Edit page
                history.push( ROUTES_LIST.EDIT_CONTENT.concat(`/${typeId}/${id}`) );
            },
            errorHandler: (error) => {
                setNotification( { message: `Текст ошибки: ${error.message}`, variant: NotificationVariants.error } );
                console.error('Error while trying to add movie', error);
            }
        });

        const submitHandler = (values: MovieTVSeriesUnionInput) => {
            const preparedValues = prepareToSend(values);
            values.typeId === CONTENT_TYPES.FILM 
                ? addMovie(preparedValues) 
                : addTVSeries(preparedValues);
        };

        return (
            <Formik
                initialValues={initialBasicInfo}
                validationSchema={validationSchema}
                onSubmit={ (values, helpers) => {
                    comment('Add content Formik, onSubmit method','sumbitting of AddBasicInfoForm');
                    submitHandler(values);
                }}
            >
                { formikProps => <Wrapable { ...formikProps }/> }
            </Formik>
        );
    }
}