import {createContext, useState, useEffect, Dispatch, SetStateAction, useContext} from 'react'
import { hideLoader, showLoader } from 'src/util/loader';

const initilValue = (() => {} ) as Dispatch<SetStateAction<boolean>>;

const SplashScreenContext = createContext(initilValue);

function SplashScreenProvider(props: ReactContainer ) {
    const [visible, setVisible] = useState(true);  

    useEffect(() => {
      visible ? showLoader() : hideLoader();
    }, [visible]);
    
    const { children } = props;

    return (
      <SplashScreenContext.Provider value={setVisible}>
        {children}
      </SplashScreenContext.Provider>
    );
}

export default SplashScreenProvider
export const useSplashScreen = () => useContext(SplashScreenContext);