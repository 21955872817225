import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Genre, Selector, GenreInput } from 'src/api/graphql/types'
import BootstrapTable from 'react-bootstrap-table-next'
import { IdColumnFormatter, NameColumnEditorRenderer, NameColumnFormatter, NameColumnValidator, OrderColumnFormatter } from '../../../helpers/SelectorConfigHelpers'
// @ts-ignore // This lib is JS only and for this reason added '@ts-ignore' to say ts compiler to ignore it
import cellEditFactory from 'react-bootstrap-table2-editor' 
import NoRecordsFound from '../../NoRecordsFound'
import { SelectorConfigContextValue, SelectorParams } from '../../../contexts/SelectorConfigContext'

type GenresConfigTableProps = {
  useSelectorConfigContext: () => SelectorConfigContextValue<SelectorParams, GenreInput, Selector>
}

function GenresConfigTable(props: GenresConfigTableProps) {
  // GenresConfig context
  const genresConfigContext = props.useSelectorConfigContext();

  // Context's memoized values
  const genresConfigProps = React.useMemo(() => {
    return {
      loading: genresConfigContext.loading,
      error: genresConfigContext.error,
      selectors: genresConfigContext.selectors,
      searchText: genresConfigContext.params.searchText,
      setParams: genresConfigContext.setParams,
      updateCallback: genresConfigContext.updateCallback
    }
  }, [genresConfigContext]);

  const { selectors, searchText, updateCallback } = genresConfigProps;
  
  /**
   * Columns for bootstrap-table-next
   * # - order
   * id - id of the element
   * name - name of the element
   */
  const columns = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: OrderColumnFormatter,
      editable: false
    },
    {
      dataField: 'id',
      text: 'ID',
      formatter: IdColumnFormatter,
      editable: false
    },
    {
      dataField: 'name',
      text: 'Название жанра',
      formatter: NameColumnFormatter,
      formatExtraData: genresConfigContext,
      editable: true,
      validator: NameColumnValidator,
      editorRenderer: NameColumnEditorRenderer,
    },
  ];

  return (
    <PerfectScrollbar 
      className='selectors-config-scrollbar' 
      options={{
        suppressScrollX: true,
        wheelPropagation: false
      }}
    >
      <BootstrapTable
        wrapperClasses='table-responsive'
        classes='table table-head-custom table-vertical-center overflow-hidden'
        bordered={false}
        bootstrap4
        data={selectors}
        columns={columns}
        keyField='id'
        noDataIndication={() => <NoRecordsFound searchText={searchText}/>}
        cellEdit={ cellEditFactory({
          mode: 'dbclick',
          blurToSave: false,
          afterSaveCell: (oldValue: string, newValue: string, row: Genre, column: {dataField: string, text: string, editable: boolean, formatExtraData: any}) => {
            if(oldValue !== newValue && updateCallback) {
              updateCallback({id: row.id, name: newValue});
            }
            return {async: true}
          }
        }) }
      />
    </PerfectScrollbar>
  );
}

export default GenresConfigTable