import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_PARTICIPANTS_QUERY } from 'src/api/graphql/queries/getAllParticipants'
import { GetAllParticipantsArgs, GetAllParticipantsData } from 'src/api/graphql/queries/getAllParticipants'
import { SearchParticipantByNameArgs, SearchParticipantByNameData, SEARCH_PARTICIPANTS_BY_NAME_QUERY } from 'src/api/graphql/queries/SearchParticipantByName'

export function useGetAllParticipants(args: ParticipantsQueryParams) {
    const { searchText, pageSize, pageNumber } = args;
    const sanitizedSearchText = searchText.trim();
    const [ getPaginatedList, paginatedList ] = useLazyQuery<GetAllParticipantsData,GetAllParticipantsArgs>(GET_ALL_PARTICIPANTS_QUERY, {
        variables: {
            limit: pageSize,
            page: pageNumber 
        },
        fetchPolicy: 'no-cache'
    });

    const [ getSearchResult, searchResult ] = useLazyQuery<SearchParticipantByNameData, SearchParticipantByNameArgs>(SEARCH_PARTICIPANTS_BY_NAME_QUERY, {
        variables: {
            name: searchText
        },
        fetchPolicy: 'no-cache'
    });

    React.useEffect(() => {
        if(sanitizedSearchText.length > 0) {
            getSearchResult();
        } 
        if(sanitizedSearchText.length === 0) {
            getPaginatedList();
        }
    }, [sanitizedSearchText, getSearchResult, getPaginatedList]);

    const result = React.useMemo(() => {
        if(sanitizedSearchText.length > 0) {
            const { loading, data, error } = searchResult;
            return {
                loading,
                participants: data ? data.SearchParticipantByName : [],
                totalSize: data ? data.SearchParticipantByName.length : 0,
                error
            }
        } 
        if(sanitizedSearchText.length === 0) {
            const { loading, data, error } = paginatedList;
            return {
                loading,
                participants: data ? data.getAllParticipants.Participants : [],
                totalSize: data ? data.getAllParticipants.Pagination.TotalCount : 0,
                error
            }
        }

        return {
            loading: false,
            participants: [],
            totalSize: 0,
            error: undefined
        }
    }, [sanitizedSearchText, paginatedList, searchResult]);

    return result;
}
