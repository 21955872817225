import { Route, Switch } from 'react-router-dom'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import AddContent from 'src/app/components/ContentService/AddContent'
import EditContent from 'src/app/components/ContentService/EditContent'
import FilmList from 'src/app/components/ContentService/FilmList'
import MainPage from 'src/app/components/ContentService/MainPage'
import ProccessingList from 'src/app/components/ContentService/ProcessingList'
import SelectorsConfig from 'src/app/components/ContentService/SelectorConfig'
import ParticipantsList from 'src/app/components/ContentService/SelectorConfig/components/ParticipantsList'
import SerialList from 'src/app/components/ContentService/SerialList'
import UplaodingList from 'src/app/components/ContentService/UploadingList'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'

function ContentServiceRoute() {
    const { 
        MAIN_PAGE,
        ADD_CONTENT, 
        EDIT_CONTENT, 
        FILM,
        SERIAL, 
        PARTICIPANT,
        UPLOADING, 
        PROCESSING_FILM, 
        PROCESSING_SERIAL,
        SELECTORS_CONFIG 
    } = ROUTES_LIST;

    return (
        <Switch>
            <Route path={MAIN_PAGE}>
                <MainPage/>
            </Route>

            <Route path={ADD_CONTENT}>
                <AddContent/>
            </Route>

            <Route path={ EDIT_CONTENT.concat('/:typeId').concat('/:contentId') }>
                <EditContent/>
            </Route>

            <Route path={FILM}>
                <FilmList/>
            </Route>

            <Route path={SERIAL}>
                <SerialList/>
            </Route>

            <Route path={PARTICIPANT}>
                <ParticipantsList/>
            </Route>

            <Route path={UPLOADING}>
                <UplaodingList/>
            </Route>

            <Route path={PROCESSING_FILM}>
                <ProccessingList typeID={CONTENT_TYPES.FILM}/>
            </Route>

            <Route path={PROCESSING_SERIAL}>
                <ProccessingList typeID={CONTENT_TYPES.EPISODE}/>
            </Route>

            <Route path={SELECTORS_CONFIG}>
                <SelectorsConfig/>
            </Route>
        </Switch>
    )
}

export default ContentServiceRoute