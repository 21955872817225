import { Link } from 'react-router-dom'
import { BreadCrumb } from 'src/app/contexts/MetronicSubheaderContext';
import { ROUTES_LIST } from 'src/app/routes/RootRoute';

type BreadCrumbsProps = {
    items: BreadCrumb[]
}

export function BreadCrumbs({ items } : BreadCrumbsProps) {
  if (!items || !items.length) {
    return <span></span>;
  }

  return (
    <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2'>
      <li className='breadcrumb-item'>
        <Link to={ROUTES_LIST.DASHBOARD}>
          <i className='flaticon2-shelter text-muted icon-1x' />
        </Link>
      </li>
      {items.map((item, index) => (
        <li key={`bc${index}`} className='breadcrumb-item'>
          <Link className='text-muted' to={{ pathname: item.pathname }}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}