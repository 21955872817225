import { gql } from '@apollo/client'
import { QUALITY_TYPES, TranscodeRequestFile } from '../types'

export interface GetMoviesListArgs {
    qualityID: QUALITY_TYPES
} 

export interface GetMoviesListData {
    GetMoviesList: TranscodeRequestFile[]
}

export const GET_MOVIES_LIST_QUERY = gql`
    query GetMoviesListQuery($qualityID: Int!) {
        GetMoviesList(qualityID: $qualityID) {
            MovieId
            Name
            RequestId
            FileId
            qualityID
        }
    }
`