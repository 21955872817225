import useGetAllLanguagesQuery from 'src/app/hooks/query/useGetAllLanguages'
import withOptions from 'src/app/components/ContentService/PlainSelect/hocs/withOptions'
import PlainSelect, { Options } from 'src/app/components/ContentService/PlainSelect'
import { ApolloError } from '@apollo/client'

function useLanguageOptions(): {loading?:boolean, options: Options[], error?: ApolloError} {
    const { loading, data, error } = useGetAllLanguagesQuery();
    const options = data ? data.map( language => ({value: language.id, label: language.name}) ) : [];
    options.unshift({ value: 0, label: 'Не выбрано' });
    
    return { 
        loading, 
        options, 
        error
    };
}

const LanguageSelectInput = withOptions(
    useLanguageOptions, 
    'не удалось загрузить список языков'
)(PlainSelect);

if(process.env.NODE_ENV !== 'production') {
    LanguageSelectInput.displayName = 'LanguageSelectInput'
}

export default LanguageSelectInput
