import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import * as uiHelpers from 'src/app/components/ContentService/helpers/FilmSerialUIHelpers'
import { filmsSerialsColumnFormatters } from 'src/app/components/ContentService/helpers/FilmSerialUIHelpers'
import { sortCaret, headerSortingClasses } from 'src/metronic/core/TableSortingHelpers'
import { Pagination } from 'src/metronic/components/Pagination'
import { useHistory } from 'react-router-dom'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import useSearchMovieByNameLazyQuery from 'src/app/hooks/query/useSearchMovieByName'
import useFilterByGenreAndTagQuery from 'src/app/hooks/query/useFilterByGenreAndTag'
import { Movie } from 'src/api/graphql/types'
import { useSerialsQueryParams } from '../hooks/useSerialsQueryParams'
import { useTableChangeHandler } from '../hooks/useTableChangeHandler'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'

interface SerialsTableProps {
  loadingTrigger: React.Dispatch<React.SetStateAction<boolean>>
}

function SerialsTable(props: SerialsTableProps) {
  const { loadingTrigger } = props;
  const history = useHistory();
  const queryParams = useSerialsQueryParams();
  const { navigationLinks: {serialsLink} } = useNavigationLinksContext();

  const tableChangeHandler = useTableChangeHandler();

  /**
   * FilterByGenreAndTag querie
   */
  // Toggler to switch off useFilterByGenreAndTagQuery when searchText has a value except an empty string
  const skipFilterQuery = queryParams.searchText.length > 0;
  // Query to graphql server
  const filterQueryResult = useFilterByGenreAndTagQuery({
    tagID: queryParams.tagID,
    genreID: queryParams.genreID,
    params: {
      typeID: CONTENT_TYPES.SERIAL, 
      limit: queryParams.pageSize, 
      page: queryParams.pageNumber,
      sort: uiHelpers.getSortParamForFilmSerial(queryParams.sortField, queryParams.sortOrder)
    }
  }, skipFilterQuery);

  /**
   * SearchMovieByName querie
   */
  // Toggler to switch off useSearchMovieByNameQuery when searchText value is an empty string
  const skipSearchQuery = !skipFilterQuery;
  // Query to graphql server
  const searchQueryResult = useSearchMovieByNameLazyQuery({
    typeID: CONTENT_TYPES.SERIAL, 
    name:  queryParams.searchText,
    limit: queryParams.pageSize, 
    page: queryParams.pageNumber,
  }, skipSearchQuery);

  /**
   * Destructurize the query result depending on which query is executed.
   * Note: Only one query is executed at a moment
   */
  const { loading, data, error } = skipFilterQuery ? searchQueryResult : filterQueryResult;
  const dataPayloadLink = skipFilterQuery ? 'SearchMovieByName' : 'FilterByGenreAndTag';

  /**
   * Initialize pagination properties for react-bootstrap-table2-paginator and
   * payload data property for bootstrap-table-next itself
   */
  const entities = data ? data[dataPayloadLink].Movie : [];
  const totalCount = data && data[dataPayloadLink].Page ? data[dataPayloadLink].Page.TotalCount : 0;
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const defaultSorted = React.useMemo(() => {
    if(serialsLink === '') return uiHelpers.defaultSorted
    return undefined;
  }, [serialsLink]);

  // Switch FilmsLisUIContext loading property depending on response
  React.useEffect(() => {
    if(loading) {
      loadingTrigger(true);
    } else if(data) {
      loadingTrigger(false);
    } else if(error) {
      loadingTrigger(false);
    }
  }, [loadingTrigger, loading, data, error]);

  /** Table columns for bootstrap-table-next
  * # - order number
  * id - id of the element
  * image - avatar image of the element
  * name - name of the element
  * release - release data of the element
  * ratingIMDB - ratingIMDB of the element
  * language - languages list of the element
  * status - status accessiblity (public or private) of the element
  * addedDate - date of adding of the element
  * actions - edit or delete actions depending on user roleID
  */
  const columns: ColumnDescription<Movie>[] = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: filmsSerialsColumnFormatters.OrderColumnFormatter,
      formatExtraData: {
        getOrder: (index: number) => {
          return index + (queryParams.pageNumber - 1) * queryParams.pageSize
        }
      },
    },
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'thumbnail',
      text: 'Аватар',
      sort: false,
      formatter: filmsSerialsColumnFormatters.ImageColumnFormatter,
      formatExtraData: CONTENT_TYPES.FILM
    },
    {
      dataField: 'name',
      text: 'Название',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'language',
      text: 'Язык',
      sort: false,
      sortCaret: sortCaret,
      formatter: filmsSerialsColumnFormatters.LanguageColumnFormatter,
    },
    {
      dataField: 'status',
      text: 'Статус',
      sort: true,
      sortCaret: sortCaret,
      formatter: filmsSerialsColumnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'release',
      text: 'Дата выхода',
      sort: true,
      sortCaret: sortCaret,
      formatter: filmsSerialsColumnFormatters.DateColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'ratingIMDB',
      text: 'рейтинг IMDB',
      sort: true,
      sortCaret: sortCaret,
      formatter: filmsSerialsColumnFormatters.RatingColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: 'Действия',
      formatter: filmsSerialsColumnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        moveToEditRoute: (id: number) => {
          history.push(`${ROUTES_LIST.EDIT_CONTENT}/${CONTENT_TYPES.SERIAL}/${id}`);
        }
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                remote
                defaultSorted={defaultSorted}
                onTableChange={tableChangeHandler}                 
                {...paginationTableProps}
                data={entities}
                columns={columns}
                keyField={'id'}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default SerialsTable