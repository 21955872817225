import { ApolloError, FetchResult, useMutation } from '@apollo/client'
import { UpdateTVSeriesMutationArgs, UpdateTVSeriesMutationData, UPDATE_TV_SERIES_MUTATION } from 'src/api/graphql/mutations/UpdateTVSeriesMutation'

export interface useUpdateTVSeriesMutationArgs {
    completeHandler?: (data: UpdateTVSeriesMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use UpdateTVSeries mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useUpdateTVSeriesMutation(args: useUpdateTVSeriesMutationArgs = {}): ( { seriesID, input }: UpdateTVSeriesMutationArgs ) => Promise<FetchResult<UpdateTVSeriesMutationData>> {
    const [ UpdateTVSeries ] = useMutation<UpdateTVSeriesMutationData, UpdateTVSeriesMutationArgs>( UPDATE_TV_SERIES_MUTATION, {
        onCompleted: data => {
            args.completeHandler && args.completeHandler(data);
        },
        onError: error => {
            args.errorHandler && args.errorHandler(error);
        },
        errorPolicy: 'all'
    } );
    
    return async function({ seriesID, input}: UpdateTVSeriesMutationArgs) {
        return await UpdateTVSeries({
            variables: {
                seriesID,
                input
            }
        });
    }
}

export default useUpdateTVSeriesMutation