import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type FilmNameProps = {
    name: string
}

function FilmName(props: FilmNameProps) {
    const { name } = props;

    return (
        <div className='film-name unselectable mb-2'>
            <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                <SVG
                    description='Добавить'
                    title='Добавить'
                    src={toAbsoluteUrl('/media/svg/icons/Text/H1.svg')}
                />
            </span>

            <span>
                { name }
            </span>
        </div>
    );
}


export default FilmName