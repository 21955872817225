import React from 'react'
import { Formik, FormikErrors, FormikHelpers, FormikProps, FormikSharedConfig, FormikTouched } from 'formik'
import { EditUIProps } from '../contexts/ContentEditContext'
import { Notification, useNotification } from 'src/app/contexts/NotificationContext'

/**
 * Modified formik configuration type, that contains all fields without 'onSubmit'
 * but Edititionally has 'submitHandler' instead.
 * T - is type of value of the formik component 
 */
export interface HOCConfig<T> extends FormikSharedConfig {
    children?: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode,
    initialStatus?: any,
    initialErrors?: FormikErrors<T>,
    initialTouched?: FormikTouched<T>,
    onReset?: (values: T, formikHelpers: FormikHelpers<T>) => void,
    validationSchema?: any | (() => any);
    validate?: (values: T) => void | object | Promise<FormikErrors<T>>,
    innerRef?: (instance: any) => void,
    submitHandler: (uiProps: any, values: T, helpers:  FormikHelpers<T>, setNotification: (notification: Notification) => void) => void
}

/**
 * withEditContentFormik<T> hoc's return type is second hoc, 
 * that takes React component with props of FormikProps<T>.
 * Second hoc returns wrapped React component
 */
type FormikWrappedComponent<T> = (Wrapable: React.ComponentType<FormikProps<T>>) => React.ComponentType

/**
 * Wraps Component with Formik and EditContentContext and helpers
 * @param config - Modified configuration of Formik described above
 * @param useEditContextForInitialize - hooks that would be delivered to the Formik initializer initial value and other arg to sumbit form
 * @returns - Second hoc that returns Formik injected React component
 */
export function withEditContentFormik<T>( config: HOCConfig<T>, useEditContextForInitialize: () => [EditUIProps, T] ): FormikWrappedComponent<T> {
    // Second hoc
    return (Wrapable) => {
        // React component with injected Formik  
        return () => {
            // Hook that delivers uiProps to the submitHandler
            const [uiProps, initialValues] = useEditContextForInitialize();
            const { setNotification } = useNotification();

            return (
                <Formik
                    // Deliver all Formik modified props
                    { ...config }
                    initialValues = {initialValues}
                    onSubmit = { (values, helpers) => {
                        // Delivers to the EditContentContext new Values from form 
                        config.submitHandler(uiProps, values, helpers, setNotification);
                    }}
                >
                    { formikProps => <Wrapable { ...formikProps }/> }
                </Formik>
            );
        }
    }
}