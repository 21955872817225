import { makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export type MediaCardClasses = ClassNameMap<"media" | "card" | "title" | "description" | "content">

export const useMediaCardStyles = makeStyles( theme => ({
  card: {
    maxWidth: 375,
  },
  media: {
    height: 200,
    backgroundSize: 'contain',
    backgroundPositionY: 20, 
  },
  content: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),
  },
  title: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: 16
  },
  description: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: 10
  }
}) );


export const useAdditionalMediaCardStyles = makeStyles( theme => ({
  error: {
    fontFamily: 'Poppins, Helvetica, sans-serif',
    color: theme.palette.secondary.main,
    fontSize: 10
  },
  redShaded: {
    boxShadow: '0 0 5px red !important'
  }
}) );
