import { useMutation } from '@apollo/client'
import { AddEpisodesMutationArgs, AddEpisodesMutationData, ADD_EPISODE_MUTATION } from 'src/api/graphql/mutations/AddEpisodes'
import { Episode, EpisodeInput } from 'src/api/graphql/types'

type AddEpisodeMutationCallback = (
    input: EpisodeInput, count: number, currentCount: number
) => Promise<Episode[] | Error[] | null>

export function useAddEpisodeMutation(completeHandler?: (data: Episode[]) => void): AddEpisodeMutationCallback {
    const [AddEpisodeCallback] = useMutation<AddEpisodesMutationData, AddEpisodesMutationArgs>(ADD_EPISODE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data.AddEpisode);
        }, 
    });

    return async function(input: EpisodeInput, count: number, currentCount: number) {
        const result = await AddEpisodeCallback({
            variables: {
                input,
                count,
                currentCount
            }
        });

        if(result.data) {
            return result.data.AddEpisode
        }

        if(result.errors) {
            return result.errors.map( error => new Error(error.message));
        }

        return null;
    }
}