import React from 'react'
import { useSerialFilesContext } from '../../contexts/SerialFilesContext'
import PerfectScrollbar from 'react-perfect-scrollbar'
import EpisodeAddElement from './components/EpisodeAddElement'
import EpisodeElement from './components/EpisodeElement'
import withCard from '../../../hocs/withCard'
import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'
import EpisodeCollectionHeader from './components/EpisodeCollectionHeader'
import { isActiveId } from '../../helpers/isActive'
import EpisodeEditModal from './components/EpisodeEditModal'
import EpisodeAddModal from './components/EpisodeAddModal'
import { isNull } from 'src/util/isNull'
import { Spinner } from 'react-bootstrap'

function EpisodesCollection() {
    const serialFilesContext = useSerialFilesContext();
    const serialCollectionProps = React.useMemo(() => {
        if( isNull(serialFilesContext) ) {
           throw new Error('Context value could not be null');
        } 

        return {
            episodes: serialFilesContext.getAllEpisodesResult.data.slice(),
            episodesFileData: serialFilesContext.getSeriesFileInfoResult.data,
            loading: serialFilesContext.getAllEpisodesResult.loading,
            error: serialFilesContext.getAllEpisodesResult.error,
            activeSeason: serialFilesContext.activeSeason,
            activeEpisode: serialFilesContext.activeEpisode,
            activateEpisodeCallback: serialFilesContext.activateEpisodeCallback,
            getAllEpisodesCallback: serialFilesContext.getAllEpisodesCallback,
        }
    }, [serialFilesContext]);

    const [showAddModal, setShowAddModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [editModalID, setEditModalID] = React.useState(0);

    const closeAddModal = () => {
        setShowAddModal(false);
    }
    const openAddModal = () => {
        setShowAddModal(true);
    }

    const closeEditModal = () => {
        setShowEditModal(false);
    }
    const openEditModal = () => {
        setShowEditModal(true);
    }

    const episodeAddCallback: React.MouseEventHandler = e => {
        openAddModal();
    }

    const episodeEditCallback = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        openEditModal();
        setEditModalID(id);
    }
    
    const { getAllEpisodesCallback, activeSeason, activeEpisode } = serialCollectionProps;

    /**
     * Effect to fetch episodes list form API
     */
    React.useLayoutEffect(() => {
        if(activeSeason !== 0) {
            getAllEpisodesCallback({
                variables: {
                    parentID: activeSeason
                }
            });
        }
    }, [getAllEpisodesCallback, activeSeason]);

    const { loading, episodes, error, episodesFileData } = serialCollectionProps;

    if(loading) {
        return (
            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                <Spinner animation='border' variant='primary' />
            </div>
        )
    }

    if(error) {
        return(
            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                Error: {error.message.toUpperCase()} while trying to load episodes list 
            </div>
        );
    }

    return (
        <>
            <PerfectScrollbar 
                className='episodes-collection-scrollbar' 
                options={{
                    suppressScrollX: true,
                    wheelPropagation: false
                }}
            >
                <div className='episodes-collection-content overflow-hidden'>
                <EpisodeAddElement episodeAddHandler={episodeAddCallback}/>

                    {
                        episodes.map( 
                            (episode, index, array) => <EpisodeElement 
                                key={episode.id} 
                                data={episode}
                                fileData={episodesFileData[index]}
                                activateEpisodeCallback={serialCollectionProps.activateEpisodeCallback}
                                isActive={ isActiveId(activeEpisode, episode.id, array.length, index) }
                                episodeEditCallback={episodeEditCallback}
                            /> 
                        )
                    }

                </div>
            </PerfectScrollbar>
            
            <EpisodeAddModal show={showAddModal} onHide={closeAddModal}/>
            <EpisodeEditModal show={showEditModal} onHide={closeEditModal} episodeID={editModalID}/>
        </>
    );
}

const CardWrappedEpisodesCollection = withCard(
    EpisodesCollection, 
    <CardHeaderTitle>
        <EpisodeCollectionHeader/>
    </CardHeaderTitle>,
    'episodes-card'
);

export default CardWrappedEpisodesCollection