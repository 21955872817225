import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { FileID, SeriesFileInfo } from 'src/api/graphql/types'

type EpisodeUploadStatusProps = {
    statusData: SeriesFileInfo|undefined,
}

// const UPLOAD_STATUS_PLACEHOLDER = <div className='episode-status unselectable'>
//     <span className={`svg-icon svg-icon-md svg-icon-${getClassFromStatus(statusData.UploadStatus)} mr-1`}>
//         <SVG
//             description='Загруженные'
//             title='Загруженные'
//             src={toAbsoluteUrl('/media/svg/icons/Files/Upload.svg')}
//         />
//     </span>

//     <span>
//         { getTextFromStatus(statusData.UploadStatus) }
//     </span>
// </div>

function EpisodeUploadStatus(props: EpisodeUploadStatusProps) {
    const { statusData } = props;

    if(statusData !== undefined) {
        return (
            <div className='episode-status unselectable'>
                <span className={`svg-icon svg-icon-md svg-icon-${getClassFromStatus(statusData.UploadStatus)} mr-1`}>
                    <SVG
                        description='Загруженные'
                        title='Загруженные'
                        src={toAbsoluteUrl('/media/svg/icons/Files/Upload.svg')}
                    />
                </span>

                <span>
                    { getTextFromStatus(statusData.UploadStatus) }
                </span>
            </div>
        )
    }

    return null;
}

const getTextFromStatus = (status: FileID): string => {
    if(status === 0) return 'Не загр.';
    else return 'Загр.';
}

const getClassFromStatus = (status: FileID): string => {
    if(status === 0) return 'danger';
    else return 'primary';
}

export default EpisodeUploadStatus