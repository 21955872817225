import { useLazyQuery } from '@apollo/client';
import React from 'react'
import { GetMovieFileSourceArgs, GetMovieFileSourceData, GET_MOVIE_FILE_SOURCE_QUERY } from 'src/api/graphql/queries/GetMovieFileSource';
import { isNull } from 'src/util/isNull';
import { useSerialFilesContext } from '../contexts/SerialFilesContext'

export function useGetEpisodeFileSources() {
    const filesContext = useSerialFilesContext();
    const [getMovieFileSourceCallback, {data}] = useLazyQuery<GetMovieFileSourceData, GetMovieFileSourceArgs>(GET_MOVIE_FILE_SOURCE_QUERY);

    const activeEpisode = React.useMemo(() => {
        if(isNull(filesContext)) {
            throw new Error('SerialFilesContext could not be null at useGetEpisodeFiles hook');
        }
        return filesContext.activeEpisode;
    }, [filesContext]);

    React.useEffect(() => {
        if(activeEpisode !== 0) {
            getMovieFileSourceCallback({
                variables: { movieID: activeEpisode }
            })
        }
    }, [getMovieFileSourceCallback, activeEpisode]);
    
    return data ? data.GetMovieFileSource : null
}