import { Episode } from 'src/api/graphql/types'

export function isEpisodeArray(x: unknown): x is Episode[] {
    if(isArray(x)) {
        return x.every(isEpisode);
    }
    return false;
}

function isArray(x: unknown): x is Array<any> {
    return x instanceof Array;
}

function isEpisode(x: unknown): x is Episode {
    const assertedEpisode = x as Episode;
    const {id, name, duration, status, parentID } = assertedEpisode;

    return [id, name, duration, status, parentID].every(isUndefined);
} 

function isUndefined(x: unknown): x is undefined {
    return x === undefined;
}