import { ExternalID, IMapLike } from "../types"
import { createExternalID } from './createExternalID'

class MapLike<Payload, Key extends ExternalID> implements IMapLike<Payload, Key> {
    constructor(private map: Map<ExternalID, Payload>) {}

    public get(movieID: number|ExternalID, uploadType: number|undefined): Payload|undefined {
        const map = this.map;
        if( typeof movieID === 'number' && uploadType !== undefined) {
            const externalID = createExternalID(movieID, uploadType);
            return map.get(externalID);
        }
        return map.get(movieID as ExternalID);
    }

    public has(movieID: number|ExternalID, uploadType: number|undefined): boolean {
        const map = this.map;
        if( typeof movieID === 'number' && uploadType !== undefined) {
            const externalID = createExternalID(movieID, uploadType);
            return map.has(externalID);
        }
        return map.has(movieID as ExternalID);
    }

    public keys(): IterableIterator<ExternalID> {
        return this.map.keys();
    }
    public values() {
        return this.map.values();
    }

    public get size() {
        return this.map.size
    }
}

export function createMapLike<Payload>(map: Map<ExternalID, Payload>): IMapLike<Payload> {
    return new MapLike(map);
}