import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { Selector } from 'src/api/graphql/types'
import { Pagination } from 'src/metronic/components/Pagination'
import { 
  EditableNameColumnFormatter, 
  IdColumnFormatter, 
  OrderColumnFormatter, 
  ParticipantImageColumnFormatter, 
  ParticipantRolesColumnFormatter 
} from '../utils/ParticipantsTableHelpers'
import NoRecordsFound from '../../NoRecordsFound'
import { useParticipantsQueryParams } from '../hooks/useParticipantsQueryParams'
import { useTableChangeHandler } from '../hooks/useTableChangeHandler'
import { sizePerPageList } from '../utils/ParticipantsConfigAssets'

interface ParticipantsConfigTableProps {
  participants: Selector[]
  loading: boolean
  totalSize: number
}

function ParticipantsConfigTable(props: ParticipantsConfigTableProps ) {
  const { participants, totalSize, loading } = props;
  const params = useParticipantsQueryParams();
  const tableChangeHandler = useTableChangeHandler();

  const paginationOptions = {
    custom: true,
    totalSize,
    sizePerPageList: sizePerPageList,
    sizePerPage: params.pageSize,
    page: params.pageNumber
  };

  const columns = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: OrderColumnFormatter,
      formatExtraData: (index: number) => {
        return index + (params.pageNumber - 1) * params.pageSize
      }
    },
    {
      dataField: 'id',
      text: 'ID',
      formatter: IdColumnFormatter,
    },
    {
      dataField: 'name',
      text: 'Имя участника',
      formatter: EditableNameColumnFormatter
    },
    {
      dataField: 'roleID',
      text: 'Позиции',
      style: {
        maxWidth: 100
      },
      formatter: ParticipantRolesColumnFormatter,
      formatExtraData: participants
    },
    {
      dataField: 'image',
      text: 'Аватар',
      formatter: ParticipantImageColumnFormatter,
    }
  ];
  
  return (
    <div
      style={{
        position: 'relative',
        height: '60vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        paddingRight: 10
      }}
    >
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
           return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bordered={false}
                bootstrap4
                remote
                {...paginationTableProps}
                columns={columns}
                onTableChange={tableChangeHandler}              
                data={participants}
                keyField='unique_key'
                noDataIndication={() => <NoRecordsFound searchText={params.searchText}/>}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </div>
  );
}

export default ParticipantsConfigTable