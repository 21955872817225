import { gql } from '@apollo/client'
import { Response } from '../types'

export interface ChangeComplaintStatusMutationArgs {
    complaintID: number
    status: number
} 
export interface ChangeComplaintStatusMutationData {
    ChangeComplaintStatus: Response
}

export const CHANGE_COMPLAINT_STATUS_MUTATION = gql`
    mutation ChangeComplaintStatusMutation( $complaintID: Int!, $status: Int! ) {
        ChangeComplaintStatus(complaintID: $complaintID, status: $status) {
            status
            message
        }
    }
`