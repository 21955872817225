import { axiosImageApiInstance } from 'src/api/rest/axios'
import { IMAGE_API_ENDPOINT } from 'src/api/rest/urls/endpoints'
import { RemovedImageData, UploadedImageData } from 'src/api/rest/types'
import IMAGE_REMOVE_API from 'src/api/rest/urls/imageRemove'
import { getToken } from 'src/util/token'

async function removeParticipantImage( url: string, id: number ): Promise<RemovedImageData|null> {
    const payload = new FormData();
    payload.append('participantID', String(id));

    try {
        const response = await axiosImageApiInstance.delete<UploadedImageData>(url, {
            data: payload,
            headers: {
                Authorization: getToken(),
            }
        });
        
        return response.data
    } catch (error) {
        console.error('uploadParticipantsImage api call', error);
        return null
    }
}   

export const removeImageVertical = removeParticipantImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_REMOVE_API.actor);
