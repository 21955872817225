import { gql } from '@apollo/client'
import { SeasonInput, Season} from '../types'

export interface AddSeasonMutationArgs {
    input: SeasonInput
} 
export interface AddSeasonMutationData {
    AddSeason: Season
}

export const ADD_SEASON_MUTATION = gql`
    mutation AddSeasonMutation( $input: SeasonInput! ) {
        AddSeason(input: $input) {
            id
            name
            status
            release
        }
    }
`