import React from 'react'
import { getKtContent } from 'src/util/ktContent'

export function usePrettyHeight() {
    const [prettyHeight, setPrettyHeight] = React.useState('');

    const calculateHeight = React.useCallback(() => {
        const ktContent = getKtContent();
        if(ktContent !== null) {
            setHeightDependingOnWindow(ktContent, setPrettyHeight);
        } else setPrettyHeight('');
    }, []);

    /** On initialization */
    React.useEffect(() => {
        calculateHeight();
    }, [calculateHeight]);

    /** On window size changes*/
    React.useEffect(() => {
        window.addEventListener('resize', calculateHeight);
        return () => window.removeEventListener('resize', calculateHeight);
    }, [calculateHeight]);

    return prettyHeight;
}

function setHeightDependingOnWindow(content: HTMLElement, setHeight: React.Dispatch<React.SetStateAction<string>>) {
    const paddingTop = window.getComputedStyle(content).paddingTop;
    const paddingBottom = window.getComputedStyle(content).paddingBottom;
    const height = `calc(${window.innerHeight - content.offsetTop}px - ${paddingTop} - ${paddingBottom})`;
    setHeight(height);
}