import { gql } from '@apollo/client'
import { Language, LanguageInput } from '../types'

export interface UpdateLanguageMutationArgs {
    languageID: number,
    input: LanguageInput
} 
export interface UpdateLanguageMutationData {
    UpdateLanguage: Language
}

export const UPDATE_LANGUAGE_MUTATION = gql`
    mutation UpdateLanguageMutation( $languageID: Int!, $input: LanguageInput! ) {
        UpdateLanguage(languageID: $languageID, input: $input) {
            id
            name
        }
    }
`