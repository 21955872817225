import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllRegionsArgs, GetAllRegionsData, GET_ALL_REGIONS_QUERY } from 'src/api/graphql/queries/getAllRegions'
import { Region } from 'src/api/graphql/types'

type useGetAllRegionsQueryArgs = {
    completeHandler?: (data: Region[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllRegionsResult = {
    loading: boolean,
    data: Region[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<GetAllRegionsArgs> | undefined) => Promise<ApolloQueryResult<GetAllRegionsData>>
}

function useGetAllRegionsQuery(args: useGetAllRegionsQueryArgs = {}): useGetAllRegionsResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllRegionsData, GetAllRegionsArgs>(GET_ALL_REGIONS_QUERY, {
        onCompleted: (data: GetAllRegionsData) => {
            completeHandler && completeHandler(data.getAllRegions);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error)
        }
    });
    return {
        loading, 
        data: data ? data.getAllRegions: undefined,
        error, 
        refetch
    }
}

export default useGetAllRegionsQuery