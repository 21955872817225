import { TranscodeRequestFile } from 'src/api/graphql/types'

export function addUniqueID(array: TranscodeRequestFile[]) {
    return array.map((item, i) => new Proxy(item, {
        get(target, prop) {
            if(prop === 'id') {
                return `{movieID:${target.MovieId}, qualityID:${target.qualityID}}`
            }
            if(prop === 'order') {
                return i
            }
            return target[prop]
        }
    }))
}