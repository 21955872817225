export enum ABONENT_SORT_TYPES {
    DEFAULT = 0,
    TELECOM = 1,
    GTS = 2,
    NON_ACTIVE = 3,
    ACTIVE = 4
}

export enum ABONENT_PROVIDER_TYPES {
    TELECOM = 1,
    GTS = 2
}

export enum ABONENT_STATUS_TYPES {
    INACTIVE = 0,
    ACTIVE = 1
}