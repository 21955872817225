import React from 'react'
import { useSerialFilesContext } from '../../../../contexts/SerialFilesContext'

function EpisodeCollectionHeader() {
    const serialFilesContext = useSerialFilesContext();
    const episodes = React.useMemo(
        () => serialFilesContext ? serialFilesContext.getAllEpisodesResult.data : [], 
        [serialFilesContext]
    );

    return <span className='episodes-collection-title'>
        Эпизодов:
        <span className='label label-primary ml-1'>{episodes.length}</span> 
    </span>
}

export default EpisodeCollectionHeader
