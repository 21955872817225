import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import { Tape } from 'src/api/graphql/types'
import { useTapesList } from './hooks/useTapesList'
import XTable, { XTableDescriptor } from './XTable'
import { 
    OrderColumnFormatter,
    IdColumnFormatter,
    TitleColumnFormatter,
    GenreIdColumnFormatter,
    PositionColumnFormatter,
    CategoryTypeColumnFormatter,
    GenreNameColumnFormatter,
    SortValueColumnFormatter,
    ItemSizeValueColumnFormatter,
    ActiveColumnFormatter,
    ActionColumnFormatter
} from './utils/TapesListTableFormatters'

export default function TapesList() {
    const { tapes } = useTapesList();
    const descriptors: ColumnDescription<Tape>[] = React.useMemo(() => [
        {
            dataField: '#',
            text: '#',
            isDummyField: true,
            formatter: OrderColumnFormatter
        },
        {
            dataField: 'id',
            text: 'ID',
            formatter: IdColumnFormatter
        },
        {
            dataField: 'TitleTk',
            text: 'Название туркменское',
            formatter: TitleColumnFormatter
        },
        {
            dataField: 'TitleRu',
            text: 'Название русское',
            formatter: TitleColumnFormatter
        },
        {
            dataField: 'genreID',
            text: 'Жанр/Тэг ID',
            formatter: GenreIdColumnFormatter
        },
        {
            dataField: 'position',
            text: 'Позиция',
            formatter: PositionColumnFormatter
        },
        {
            dataField: 'categoryType',
            text: 'Тип Ленты',
            formatter: CategoryTypeColumnFormatter
        },
        {
            dataField: 'sortValue',
            text: 'Сортировка',
            formatter: SortValueColumnFormatter
        },
      
        {
            dataField: 'itemSizeValue',
            text: 'Размер',
            formatter: ItemSizeValueColumnFormatter
        },
        {
            dataField: 'active',
            text: 'Активный',
            formatter: ActiveColumnFormatter
        },
        {
            dataField: 'action',
            text: 'Действия',
            formatter: ActionColumnFormatter,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
              minWidth: '100px',
            },
        },
    ], []); 

    return (
        <BootstrapTable
            wrapperClasses='table-responsive'
            bordered={false}
            classes='table table-head-custom table-vertical-center overflow-hidden'
            bootstrap4
            remote
            data={tapes.slice().sort((a, b) => a.position - b.position)}
            columns={descriptors}
            keyField={'id'}
        />
    );
}

export function TapesListAlternative() {
    const { tapes } = useTapesList();
    const descriptors: XTableDescriptor<Tape> = [
        {
            dataField: 'id',
            title: 'ID',
            formatter: IdColumnFormatter
        },
        {
            dataField: 'TitleTk',
            title: 'Название туркменское',
            formatter: TitleColumnFormatter
        },
        {
            dataField: 'TitleRu',
            title: 'Название русское',
            formatter: TitleColumnFormatter
        },
        {
            dataField: 'genreID',
            title: 'Название Жанра/Тэга',
            formatter: GenreIdColumnFormatter
        },
        {
            dataField: 'position',
            title: 'Позиция',
            formatter: PositionColumnFormatter
        },
        {
            dataField: 'categoryType',
            title: 'Тип Ленты',
            formatter: CategoryTypeColumnFormatter
        },
        {
            dataField: 'GenreName',
            title: 'Имя Жанра/Тэга',
            formatter: GenreNameColumnFormatter
        },
        {
            dataField: 'sortValue',
            title: 'Сортировка',
            formatter: SortValueColumnFormatter
        },
      
        {
            dataField: 'itemSizeValue',
            title: 'Размер',
            formatter: ItemSizeValueColumnFormatter
        },
        {
            dataField: 'active',
            title: 'Активный',
            formatter: ActiveColumnFormatter
        },
        // {
        //     dataField: 'action',
        //     title: 'Действия',
        //     formatter: CategoryTypeColumnFormatter
        // },
    ];

    return (
        <XTable
            descriptors={descriptors}
            payload={tapes}
        />
    );
}