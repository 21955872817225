export const SELECTOR_CODES = {
    genre: 'genre',
    tag: 'tag',
    country: 'country',
    language: 'language',
    participant: 'participant',
}

export const SELECTORS: AppSelector[] = [
    {
        code: SELECTOR_CODES.genre, 
        name: 'Жанры'
    }, 
    {
        code: SELECTOR_CODES.tag,
        name: 'Тэги'
    }, 
    {   
        code: SELECTOR_CODES.country,
        name: 'Страны'
    }, 
    {
        code: SELECTOR_CODES.language,
        name: 'Языки'
    },
    {
        code: SELECTOR_CODES.participant,
        name: 'Участники'
    },
]