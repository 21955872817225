import { useQuery } from '@apollo/client'
import { GetAllCountriesArgs, GetAllCountriesData, GET_ALL_COUNTRIES_QUERY } from 'src/api/graphql/queries/getAllCountries'

export function useGetAllCountries() {
    const { loading, data, error, refetch } = useQuery<GetAllCountriesData,GetAllCountriesArgs>(GET_ALL_COUNTRIES_QUERY);

    return {
        loading,
        data: data ? data.getAllCountries : [],
        error,
        refetch: () => refetch().then(result => result.data.getAllCountries).catch(() => [])
    }
}