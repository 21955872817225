import { ApolloError } from '@apollo/client'
import { isEqual } from 'lodash'
import React, { createContext, useContext, useState } from 'react'
import { Selector, SelectorInput } from 'src/api/graphql/types'
import { hasUniqueInput, search } from 'src/app/components/ContentService/SelectorConfig/helpers/SelectorConfigHelpers'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

// Base Type for initialParams
export interface SelectorParams {
    searchText: string
}

// Types for addMutationHook
export type AddCompleteHandler<V extends Selector> = (data: V) => void
export type AddErrorHandler = (error: ApolloError) => void
export type AddMutationHookResult<K extends SelectorInput> = [ addCallback: (args: K, rest?: any) => Promise<void> ]

// Types for updateMutationHook
export type UpdateCompleteHandler<V extends Selector> = (data: V) => void
export type UpdateErrorHandler = (error: ApolloError) => void
export type UpdateMutationHookResult<K extends SelectorInput & {id: number}> = [ updateCallback: (args: K, rest?: any) => Promise<void> ]

export interface SelectorConfigContextValue<T extends SelectorParams, K extends SelectorInput, V extends Selector> {
    params: T,
    loading: boolean,
    selectors: V[],
    pendingUpdates: Set<number>
    addPermission: boolean,
    error?: ApolloError,
    setParams?: React.Dispatch<React.SetStateAction<T>>,
    updateCallback?: (args: K & {id: number}, rest?: any ) => Promise<void>,
    addCallback?: (args: K, rest?: any) => Promise<void>
}

export type useSelectorConfigContextHook<T extends SelectorParams = SelectorParams, K extends SelectorInput=SelectorInput, V extends Selector=Selector> = () => SelectorConfigContextValue<T, K, V>

type SelectorConfigProviderProps<K extends SelectorInput, V extends Selector> = {
    children: React.ReactChild,
    useGetQueryHook: () => {loading: boolean, data: V[], error?: ApolloError, refetch:() => Promise<V[]>},
    useAddMutationHook: (
        completeHandler?: AddCompleteHandler<V>, 
        errorHandler?: AddErrorHandler
    ) => AddMutationHookResult<K>

    useUpdateMutationHook: (
        completeHandler?: UpdateCompleteHandler<V>, 
        errorHandler?: UpdateErrorHandler 
    ) => UpdateMutationHookResult<K & {id: number}>
}

/**
 * Component that uses that hook must wrapped by React.memo to prevent
 * useless re-renders and prevent from occuring Error related with state
 * update on onmounted component
 * @param initialValue initial values for context
 * @returns tuple of context provider and hook for use that context
 */
export function useSelectorConfig<T extends SelectorParams, K extends SelectorInput, V extends Selector>(initialValue:SelectorConfigContextValue<T,K,V> ): [React.ComponentType<SelectorConfigProviderProps<K,V>>, () => SelectorConfigContextValue<T,K,V> ] {
    const SelectorConfigContext = createContext<SelectorConfigContextValue<T,K,V>>(initialValue);
    const useSelectorConfigContext = () => useContext(SelectorConfigContext);

    function SelectorConfigProvider(props: SelectorConfigProviderProps<K, V>): React.ReactElement {
        const { children, useGetQueryHook, useUpdateMutationHook, useAddMutationHook } = props;
        const MIN_ENABLE_SELECTOR_LENGTH = 3;
    
        const { setNotification } = useNotification();
    
        /**
         * Hooks for get, add and update selectors
         */
        const { loading, data, error, refetch } = useGetQueryHook();
    
        const [ addCallback ] = useAddMutationHook( 
            data => {
                setNotification({
                    message: `${data.name} добавлен успешно`, 
                    variant: NotificationVariants.success
                });
                refetch();
            }, 
            error => {
                setNotification({
                    message: `Ошибка при добавлении ${error.message.slice(0, 15)}`, 
                    variant: NotificationVariants.error
                });
    
                console.error(error)
            }
        );
        const [ updateCallback ] = useUpdateMutationHook(
            data => {
                setNotification({
                    message: `Успешно изменен на '${data.name}'`, 
                    variant: NotificationVariants.info
                });
                refetch();
            }, 
            error => {
                setNotification({
                    message: `Ошибка при попытке изменения ${error.message.slice(0, 15)}`, 
                    variant: NotificationVariants.error
                });
    
                console.error(error);
            }
        );
        
        const [params, setParamsBase] = useState<T>( initialValue.params );
        const [pendingUpdates, setPendingUpdates] = useState( new Set<number>() );
    
        // Selectors calculates automatically depending on params
        const selectors: V[] = React.useMemo(() => {
            const { searchText } = params;
            const rawData = searchText === '' ? data : search(data, searchText);
    
            // Drop readonly attribute of recived Apollo Data (fix for react-bootstrap-table2-editor)
            return JSON.parse(JSON.stringify(rawData));
        }, [params, data]);
    
        // Permision to add calculated automatically depending on params
        const addPermission: boolean = React.useMemo(() => {
            const { searchText } = params;
    
            if(searchText.trim().length < MIN_ENABLE_SELECTOR_LENGTH) return false;
    
            return hasUniqueInput(data, searchText)
        }, [params, data]);
    
        const setParams = React.useCallback( nextParams => {
            setParamsBase( prevParams => {
                if( nextParams instanceof Function) {
                    nextParams = nextParams(prevParams);
                }
    
                if(isEqual(prevParams, nextParams)) {
                    return prevParams;
                }
    
                return nextParams;
            })
        }, []);
    
        const value = {
            loading,        // data fetch loading indicator
            error,          // data fetch error
            params,         // params of the search
            pendingUpdates, // items that updated currently
            selectors,      // calculated selectors depending on params
            addPermission,  // sign of permission status to add selector to DB is granted 
            setParams,      // callback for set params (to automatically search) 
    
            // update mutation callback wrapped with handler to process state after update complete
            updateCallback: (args: K & {id: number}) => {
                setPendingUpdates(prev => {
                    prev.add(args.id);
                    return new Set(prev);
                });
    
                return updateCallback({...args}).finally(() => setPendingUpdates(prev => {
                    prev.delete(args.id);
                    return new Set(prev);
                }));
            },
            addCallback,    // add mutation callback
        };
    
        return (
            <SelectorConfigContext.Provider value={value}>
                { children }
            </SelectorConfigContext.Provider>
        );
    }

    return [SelectorConfigProvider, useSelectorConfigContext];
}