import { makeStyles } from '@material-ui/core'
import { MediaCardClasses } from 'src/app/components/MediaCard/MediaCardHelpers'

export const useVerticalImageStyles: () => MediaCardClasses = makeStyles( theme => ({
    card: {
        width: 250,
        marginBottom: 20
    },
    media: {},
    content: {},
    title: {},
    description: {}
}))

export const useHorizontalImageStyles: () => MediaCardClasses = makeStyles( theme => ({
    card: {
        width: 250,
        marginBottom: 20
    },
    media: {},
    content: {},
    title: {},
    description: {}
}))

export const useLogoImageStyles: () => MediaCardClasses = makeStyles( theme => ({
    card: {
        width: 250,
        marginBottom: 20
    },
    media: {},
    content: {},
    title: {},
    description: {}
}))

export const useAvatarImageStyles = makeStyles( theme => ({
    card: {
        maxWidth: 'auto',
        width: '100%',
        height: '100%',
    },
    media: {
        width: '80px',
        height: '80px !important',
        backgroundPositionY: 0,
        backgroundSize: 'cover',
    },
    content: {
        display: 'none'
    },
    title: {},
    description: {}
}));

export const useMediaCardProgressStyles = makeStyles( theme => ({
    root: {
        height: 10,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    successText: {
        color: theme.palette.success.main,
        fontFamily: 'Poppins, Helvetica, sans-serif'
    }
}))