export const sizePerPageList = [
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 }
];

export const defaultParticipantsQueryParams: ParticipantsQueryParams = {
    searchText: '',
    sortOrder: 'desc', // asc||desc
    sortField: 'id',
    pageNumber: 1,
    pageSize: 10
};

export const defaultSearchParams = new URLSearchParams([
    ['pageSize', '25'],
    ['pageNumber', '1'],
    ['sortField', 'unique_key'],
    ['sortOrder', 'asc']
])