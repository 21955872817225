import { gql } from '@apollo/client'
import { TVSeries, TVSeriesInput } from '../types'

export interface AddTVSeriesMutationArgs {
    input: TVSeriesInput
} 
export interface AddTVSeriesMutationData {
    AddTVSeries: TVSeries
}

export const ADD_TV_SERIES_MUTATION = gql`
    mutation AddTVSeriesMutation( $input: TVSeriesInput! ) {
        AddTVSeries(input: $input) {
            id
            typeId
            name
        }
    }
`