import { FetchResult, useMutation } from '@apollo/client'
import { AddTagsToMovieMutationArgs, AddTagsToMovieMutationData, ADD_TAGS_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/AddTagsToMovieMutation'
import { AddCountriesToMovieMutationArgs, AddCountriesToMovieMutationData, ADD_COUNTRIES_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/AddCountriesToMovieMutation'
import { ADD_PARTICIPANTS_TO_MOVIE_MUTATION, AddParticipantsToMovieMutationArgs, AddParticipantsToMovieMutationData } from 'src/api/graphql/mutations/AddParticipantsToMovieMutation'
import { AddGenresToMovieMutationArgs, AddGenresToMovieMutationData, ADD_GENRES_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/AddGenresToMovieMutation'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'

export interface SelectorsAddArgs extends 
    AddGenresToMovieMutationArgs, 
    AddTagsToMovieMutationArgs, 
    AddCountriesToMovieMutationArgs
{
    actorID: number[],
    directorID: number[]
}

export type AddSelectorsResult = [
    PromiseSettledResult<FetchResult<AddGenresToMovieMutationData>>,
    PromiseSettledResult<FetchResult<AddTagsToMovieMutationData>>,
    PromiseSettledResult<FetchResult<AddCountriesToMovieMutationData>>,
    PromiseSettledResult<FetchResult<AddParticipantsToMovieMutationData>>,
    PromiseSettledResult<FetchResult<AddParticipantsToMovieMutationData>>,
]

function useAddSelectorsToMovieMutation() {
    const [ AddGenres ] = useMutation<AddGenresToMovieMutationData, AddGenresToMovieMutationArgs>(ADD_GENRES_TO_MOVIE_MUTATION);
    const [ AddTags ] = useMutation<AddTagsToMovieMutationData, AddTagsToMovieMutationArgs>(ADD_TAGS_TO_MOVIE_MUTATION);
    const [ AddCountries ] = useMutation<AddCountriesToMovieMutationData, AddCountriesToMovieMutationArgs>(ADD_COUNTRIES_TO_MOVIE_MUTATION);
    const [ AddActors ] = useMutation<AddParticipantsToMovieMutationData, AddParticipantsToMovieMutationArgs>(ADD_PARTICIPANTS_TO_MOVIE_MUTATION);
    const [ AddDirectors ] = useMutation<AddParticipantsToMovieMutationData, AddParticipantsToMovieMutationArgs>(ADD_PARTICIPANTS_TO_MOVIE_MUTATION);

    return async function({ movieID, genreID, tagID, countryID, actorID, directorID }: SelectorsAddArgs ): Promise<AddSelectorsResult> {
        return await Promise.allSettled([
            AddGenres({ variables: {movieID, genreID} }),
            AddTags({ variables: {movieID, tagID} }),
            AddCountries({ variables: {movieID, countryID} }),
            AddActors({ variables: {movieID, participantID: actorID, positionID: PARTICIPANT_TYPES.ACTOR} }),
            AddDirectors({ variables: {movieID, participantID: directorID, positionID: PARTICIPANT_TYPES.DIRECTOR} })
        ]);
    }
}

export default useAddSelectorsToMovieMutation