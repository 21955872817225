import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client'
import { UpdateSeasonMutationArgs, UpdateSeasonMutationData, UPDATE_SEASON_MUTATION } from 'src/api/graphql/mutations/UpdateSeason'
import { Season } from 'src/api/graphql/types'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext';

type UpdateSeasonMutationCallback = (
    options?: MutationFunctionOptions<UpdateSeasonMutationData, UpdateSeasonMutationArgs>
) => Promise<FetchResult<UpdateSeasonMutationData>>

export function useUpdateSeasonMutation(completeHandler?: (data: Season) => void): UpdateSeasonMutationCallback {
    const { setNotification } = useNotification();
    const [UpdateSeasonCallback ] = useMutation<UpdateSeasonMutationData, UpdateSeasonMutationArgs>(UPDATE_SEASON_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data.UpdateSeason);
            setNotification({
                message: `Успешно изменен: ${data.UpdateSeason.name}`,
                variant: NotificationVariants.success
            });
        },
        onError: error => {
            setNotification({
                message: `Ошибка при попытке изминения сезона: ${error.message}`,
                variant: NotificationVariants.error
            });
            console.error('useUpdateSeasonMutation', error);
        },
    });

   return UpdateSeasonCallback;
}