/* Pagination Helprs */
import { ColumnSortCaret } from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/util/url';

export const sortCaret = ((order: string, column: any) => {
  if (!order) return (
    <span className='svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort'>
      <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Sort1.svg')}/>
    </span>
  );
  else if (order === 'asc')
    return (
      <span className='svg-icon svg-icon-sm svg-icon-primary ml-1'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Up-2.svg')}/>
      </span>
    );
  else if (order === 'desc')
    return (
      <span className='svg-icon svg-icon-sm svg-icon-primary ml-1'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Down-2.svg')}/>
      </span>
    );
  return null;
}) as ColumnSortCaret<any, any> 

export const headerSortingClasses = (column: any, sortOrder: string, isLastSorting: boolean, colIndex: number) => (
  (sortOrder === 'asc' || sortOrder === 'desc') ? 'sortable-active' : ''
);