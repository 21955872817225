import React from 'react'
import EnteredInput from './components/EnteredInput'
import ProcessingList from './components/ProcessingList'
import EnteredToolbar from './components/EnteredToolbar'
import EnteredWrap from './components/EnteredWrap'
import { DELIMITER, MAX_PARTICIPANT_COUNT } from './utils/configs'
import WaitingList from './components/WaitingList'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { useAddMultipleParticipants } from './hooks/useAddMultipleParticipants'

function ParticipantsMultipleAddBar() {
    const [value, setValue] = React.useState('');
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [addParticipants, loading] = useAddMultipleParticipants();
    const {setNotification} = useNotification();

    const entered = React.useMemo(() => {
        return value.split(DELIMITER)
        .filter(v => v.trim() !== '')
        .map(v => v.trim());
    }, [value]);

    const processing = React.useMemo(() => {
        return entered.slice(0, MAX_PARTICIPANT_COUNT);
    }, [entered]);

    const waiting = React.useMemo(() => {
        return entered.slice(MAX_PARTICIPANT_COUNT);
    }, [entered]);

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = React.useCallback( e => {
        const text = removeUnusedDelimiters(e.target.value, DELIMITER);
        setValue(text);
    }, []);

    const addHandler: React.MouseEventHandler = React.useCallback( e => {
        addParticipants(processing).then(() => {
            setValue(waiting.join(DELIMITER));
            setNotification({
                message: 'Успешное добавление участников',
                variant: NotificationVariants.success
            })
        }).catch(() => {
            setNotification({
                message: 'Ошибка при добавлении участников',
                variant: NotificationVariants.error
            })
        })
        
    }, [processing, waiting, addParticipants, setNotification]);

    const clearHandler: React.MouseEventHandler = React.useCallback( e => {
        setValue('');
    }, []);

    React.useEffect(() => {
        const input = inputRef.current;
        if(input !== null) {
            input.focus();
        }
    });

    return (
        <EnteredWrap className='participants-multiple-add-bar'>
            <EnteredInput 
                innerProps = {{
                    type: 'text',
                    className: 'form-control',
                    value,
                    disabled: loading,
                    onChange: changeHandler
                }}
                inputRef={inputRef}
            />
            <div className='d-flex'>
                {
                    isEmpty(processing) || <ProcessingList list={processing}/>
                }
                {
                    isEmpty(waiting) || <WaitingList list={waiting}/>
                }
            </div>
            
            <EnteredToolbar 
                addHandler={addHandler}
                clearHandler={clearHandler}
                isDisabled={value.trim().length === 0}
            />
        </EnteredWrap>
    );
}

export const removeUnusedDelimiters = (target: string, delimiter: string) => {
    return target.replaceAll( new RegExp(`${delimiter}+`, 'g'), delimiter )
    .replaceAll(new RegExp(`^${delimiter}`, 'g'), '');
}

export const isEmpty = (array: unknown[]): boolean => {
    return array.length === 0;
}

export default ParticipantsMultipleAddBar