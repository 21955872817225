import clsx from 'clsx';
import React from 'react'
import { FileError, useDropzone } from 'react-dropzone'

// TODO: Split it in multiple files
export interface BasicVideoUploaderProps {
    maxSize: number,
    accept: string,
    validator: (file: File) => FileError|FileError[]|null,
    onDrop: ( acceptedFiles: File[], fileRejection: Array<{file: File, errors: FileError[]}> ) => void
}

function BasicVideoUploader(props: BasicVideoUploaderProps): React.ReactElement {
    const { maxSize, accept, onDrop, validator } = props;
    const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
        maxSize, accept, onDrop, validator, noClick: true,
        maxFiles: 1, multiple: false,
    });

    const dbclickHandler = React.useCallback<React.ReactEventHandler>(() => {
        open();
    }, [open]);

    const rootProps = getRootProps({className: clsx('dropzone', isDragActive && 'active') });

    return (
        <div { ...rootProps }
            onDoubleClick={dbclickHandler}
        >
            <input { ...getInputProps() }/>
        </div>
    );
}

export default BasicVideoUploader


export interface ImplementedUploadElement {
    onDrop: (file: File|undefined, fileRejection: Array<{file: File, errors: FileError[]}>) => void
}

function withImplementation( maxSize: number, accept: string, validator: (maxSize: number, type: string, file: File) => FileError|FileError[]|null, wrapperClasses?: string) {
    // Second HOC for wrapping BasicUploader. That is its particular implementation.
    return (BasicUploader: React.ComponentType<BasicVideoUploaderProps>): React.ComponentType<ImplementedUploadElement> => {
        // Particular implementation for BasicUploader with injected basicProps
        const WrappedUploader = ({onDrop}: ImplementedUploadElement): React.ReactElement => {
            const bindedValidator = React.useCallback<(file: File) => FileError|FileError[]|null>( file => {
                return validator(maxSize, accept, file);
            }, []);

            const singleFileOnDrop = React.useCallback((files: File[], fileRejection: Array<{file: File, errors: FileError[]}>) => {
                const [firstFile] = files;
                onDrop(firstFile, fileRejection);
            }, [onDrop]);

            return (
                <div className={wrapperClasses}>
                    <BasicUploader 
                        maxSize={maxSize} 
                        accept={accept} 
                        validator={bindedValidator} 
                        onDrop={singleFileOnDrop}
                    />
                </div>
            );
        }

        return WrappedUploader;
    }
}


const basicVideoValidator = (maxSize: number, type: string, file: File): FileError|null => {
    const videoFile = file

    if(videoFile.size >= maxSize) {
        return {
            message: `Размер: ${Math.round(videoFile.size/1024/1024)}Mb не может быть больше 5Gb`,
            code: 'file-invalid-size'
        }
    } else if(videoFile.type !== type) {
        return {
            message: `Формат не соответсвует ${type}`,
            code: 'file-invalid-type'
        }
    } 

    return null;
}

const MAX_VIDEO_FILE_SIZE = 5*1024*1024*1024;
const VIDEO_FILE_MIME_TYPE = 'video/mp4';

export const VideoUploader = withImplementation(
    MAX_VIDEO_FILE_SIZE, 
    VIDEO_FILE_MIME_TYPE, 
    basicVideoValidator,
    'video-uploader'
)(BasicVideoUploader)