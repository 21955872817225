import useDeleteCountryToMovieMutation from "src/app/hooks/mutation/useDeleteCountryToMovie"
import useDeleteGenreToMovieMutation from "src/app/hooks/mutation/useDeleteGenreToMovie"
import useDeleteTagToMovieMutation from "src/app/hooks/mutation/useDeleteTagToMovie"
import useGetAllCountriesQuery from "src/app/hooks/query/useGetAllCountries"
import useGetAllGenresQuery from "src/app/hooks/query/useGetAllGenres"
import useGetAllTagsQuery from "src/app/hooks/query/useGetAllTags"
import { withSelector } from "./hocs/withSelector"
import useDeleteActorToMovieMutation from 'src/app/hooks/mutation/useDeleteActorToMovie'
import useDeleteDirectorToMovieMutation from "src/app/hooks/mutation/useDeleteDirectorToMovie"
import SortableSelectInput from './components/SortableSelectInput'
import { withAsyncSelector } from './hocs/withAsyncSelector'
import { useGetDirectorsByName } from "src/app/hooks/query/useGetDirectorsByName"
import { useGetActorsByName } from "src/app/hooks/query/useGetActorsByName"

const EditGenreSelector = withSelector(useGetAllGenresQuery, useDeleteGenreToMovieMutation)(SortableSelectInput);
const EditTagSelector = withSelector(useGetAllTagsQuery, useDeleteTagToMovieMutation)(SortableSelectInput);
const EditCountrySelector = withSelector(useGetAllCountriesQuery, useDeleteCountryToMovieMutation)(SortableSelectInput);

const EditActorSelector = withAsyncSelector(useGetActorsByName, useDeleteActorToMovieMutation);
const EditDirectorSelector = withAsyncSelector(useGetDirectorsByName, useDeleteDirectorToMovieMutation);

export {
  EditGenreSelector,
  EditTagSelector,
  EditCountrySelector,
  EditActorSelector,
  EditDirectorSelector
}