import { useQuery } from '@apollo/client'
import { SORT_TYPES } from 'src/app/assets/SortTypes'
import { FilterByGenreAndTagArgs, FilterByGenreAndTagData, FILTER_BY_GENRE_AND_TAG_QUERY } from 'src/api/graphql/queries/FilterByGenreAndTagQuery'

/**
 * Query for fetching Films or Serials by tag and genre or without them 
 * ( by setting their values to 0 ). Has additional ability to skip call of query
 */
function useFilterByGenreAndTagQuery({ genreID = 0, tagID = 0, params: { typeID, limit, page, sort = SORT_TYPES.BY_ID} }: FilterByGenreAndTagArgs, skip: boolean = false ) {
  const { loading, data, error} = useQuery<FilterByGenreAndTagData, FilterByGenreAndTagArgs>(FILTER_BY_GENRE_AND_TAG_QUERY, {
      variables: {
        genreID,
        tagID,
        params: { 
          typeID, 
          limit, 
          page, 
          sort
        }
      },
      skip,
      // Fix to provide page's data up to date after adding of new content
      fetchPolicy: page === 1 ? 'network-only' : 'cache-and-network',
      nextFetchPolicy: page === 1 ? 'network-only' : 'cache-and-network'
  });

  return { loading, data, error}
}

export default useFilterByGenreAndTagQuery