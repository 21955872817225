import { axiosImageApiInstance } from 'src/api/rest/axios'
import { IMAGE_API_ENDPOINT } from 'src/api/rest/urls/endpoints'
import IMAGE_UPLOAD_API from 'src/api/rest/urls/imageUpload'
import { UploadedImageData, UploadImageProgressEvent } from 'src/api/rest/types'
import { getToken } from 'src/util/token'

async function uploadParticipantsImage( url: string, id: number, file: File, progressHandler: (event: UploadImageProgressEvent) => void ): Promise<UploadedImageData|null> {
    const payload = new FormData();
    payload.append('image', file);
    payload.append('participantID', String(id));

    try {
        const response = await axiosImageApiInstance.post<UploadedImageData>(url, payload, {
            onUploadProgress: progressHandler,
            headers: {
                Authorization: getToken(),
            }
        });
        
        return response.data
    } catch (error) {
        console.error('uploadParticipantsImage api call', error);
        return null
    }
}   

export const uploadImageVertical = uploadParticipantsImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_UPLOAD_API.actor);
