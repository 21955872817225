import { gql } from '@apollo/client'
import { EpisodeInput, Episode} from '../types'

export interface AddEpisodesMutationArgs {
    input: EpisodeInput,
    count: number,
    currentCount: number,
} 
export interface AddEpisodesMutationData {
    AddEpisode: Episode[]
}

export const ADD_EPISODE_MUTATION = gql`
    mutation AddEpisodesMutation( $input: EpisodeInput!, $count: Int!, $currentCount: Int!) {
        AddEpisodes(input: $input, count: $count, currentCount: $currentCount) {
            id
            name
            status
            duration
        }
    }
`