import { gql } from '@apollo/client'
import { TVSeries, TVSeriesInput } from 'src/api/graphql/types'

export interface UpdateTVSeriesMutationArgs {
    seriesID: number,
    input: TVSeriesInput
} 
export interface UpdateTVSeriesMutationData {
    UpdateTVSeries: TVSeries
}

export const UPDATE_TV_SERIES_MUTATION = gql`
    mutation UpdateTVSeriesMutation( $seriesID: Int!, $input: TVSeriesInput! ) {
        UpdateTVSeries(seriesID: $seriesID, input: $input) {
            typeId,
            name,
            age,
            language {
                id
                name
                abbreviated
            },
            category{
                id
                name
            }
            release,
            ratingKP,
            ratingIMDB,
            description
        }
    }
`