import { Image } from 'src/api/graphql/types'

export function parseImageUrl(image: string): Image['url']|undefined {
    try {
        const parsed = JSON.parse(image);
        if(isImageType(parsed)) {
            return parsed.url;
        } else return undefined;
    } catch (error) {
        console.warn('Error on parse Image', error);
        return undefined;
    }
}


function isImageType(x: unknown): x is Image {
    const asserted = x as Image;
    return 'url' in asserted && 'width' in asserted && 'height' in asserted;
}