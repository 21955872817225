import { Host } from 'src/api/graphql/types'
import { gql } from '@apollo/client'

export interface SearchHostByPhoneArgs {
   phone: string
}

export interface SearchHostByPhoneData {
    SearchHostByPhone: Host[]
}

export const SEARCH_HOST_BY_PHONE_QUERY = gql`
     query SearchHostByPhoneQuery($phone: String!) {
        SearchHostByPhone(phone: $phone) {
            ip
            phone
            code
            name
            active
            type
        }
    }
`