import { useQuery } from '@apollo/client'
import { GET_ALL_TAGS_QUERY } from 'src/api/graphql/queries/getAllTags'
import { GetAllTagsArgs, GetAllTagsData } from 'src/api/graphql/queries/getAllTags'

export function useGetAllTags() {
    const { loading, data, error, refetch } = useQuery<GetAllTagsData,GetAllTagsArgs>(GET_ALL_TAGS_QUERY);

    return {
        loading,
        data: data ? data.getAllTags : [],
        error,
        refetch: () => refetch().then(result => result.data.getAllTags).catch(() => [])
    }
}
