import { useLazyQuery } from '@apollo/client'
import { IsHaveTrailerFileArgs, IsHaveTrailerFileData, IS_HAVE_TRAILER_FILE_QUERY } from 'src/api/graphql/queries/isHaveTrailerFile'

const mySuccessEventType: "HaveTrailerFileSuccess" = "HaveTrailerFileSuccess";
const myErrorEventType: "HaveTrailerFileError" = "HaveTrailerFileError";

class HaveTrailerFileSuccessEvent extends CustomEvent<HaveTrailerFileSuccessEventDetail> {
    constructor(detail: HaveTrailerFileSuccessEventDetail) {
        super(mySuccessEventType, { bubbles: true, detail });
    }
}

interface HaveTrailerFileSuccessEventDetail {
    have: boolean;
}

class HaveTrailerFileErrorEvent extends CustomEvent<HaveTrailerFileErrorEventDetail> {
    constructor(detail: HaveTrailerFileErrorEventDetail) {
        super(myErrorEventType, { bubbles: true, detail });
    }
}

interface HaveTrailerFileErrorEventDetail {
    message: string,
}

// augment your global namespace
// Augmenting 'WindowEventMap' from 'lib.dom.d.ts'
declare global {
    interface WindowEventMap {
        [mySuccessEventType]: HaveTrailerFileSuccessEvent,
        [myErrorEventType]: HaveTrailerFileErrorEvent
    }
}

export function useIsHaveTrailerFileLazyQuery(movieID: number) {
    const [movieExistCheckCallback] = useLazyQuery<IsHaveTrailerFileData, IsHaveTrailerFileArgs>(IS_HAVE_TRAILER_FILE_QUERY, {
        onCompleted: data => {
            window.dispatchEvent(
                new HaveTrailerFileSuccessEvent({ 
                    have: data.IsHaveTrailerFile
                })
            );
        },
        onError: error => {
            window.dispatchEvent(
                new HaveTrailerFileErrorEvent({ 
                    message: error.message
                })
            );
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });

    /*  Here alternative way for solution:
        return new Promise((res, rej) => {
            movieExistCheckCallback({
                variables: {
                    movieID
                }
            });

            const { error, data } = movieExistCheckQueryResult;

            const stopInterval = setInterval(() => {
                if(data) {
                    setTimeout(() => res(data) )
                    clearInterval(stopInterval)
                } 
                if(error) {
                    setTimeout(() => rej(error) )
                    clearInterval(stopInterval)
                } 
            })
        })
    */

    return () => {
        movieExistCheckCallback({
            variables: {
                movieID
            }
        });

        return new Promise<boolean|{message: string}>((resolve, reject ) => {
            window.addEventListener(mySuccessEventType, function successHandler(e) {
                if(isHaveTrailerFileSuccessEvent(e))
                    resolve(e.detail.have);
                window.removeEventListener(mySuccessEventType, successHandler);
            });

            window.addEventListener(myErrorEventType, function errorHandler(e) {
                if(isHaveTrailerFileErrorEvent(e))
                    reject(e.detail);
                window.removeEventListener(myErrorEventType, errorHandler);
            });
        });
    }
}

// Type guard for HaveTrailerFileSuccessEvent
function isHaveTrailerFileSuccessEvent(x: unknown): x is HaveTrailerFileSuccessEvent {
    if(x instanceof HaveTrailerFileSuccessEvent){
        return true;
    }

    throw new Error('Argument is not type of HaveTrailerFileSuccessEventDetail');
}

// Type guard for HaveTrailerFileErrorEvent
function isHaveTrailerFileErrorEvent(x: unknown): x is HaveTrailerFileSuccessEvent {
    if(x instanceof HaveTrailerFileErrorEvent){
        return true;
    }

    throw new Error('Argument is not type of HaveMovieFileSuccessEventDetail');
}