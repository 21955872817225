import { gql } from '@apollo/client'
import { GetArgumentsInput, MovieAndPage } from 'src/api/graphql/types'

export interface FilterByGenreAndTagArgs {
    genreID: number|0,
    tagID: number|0,
    params: GetArgumentsInput
} 

export interface FilterByGenreAndTagData {
    FilterByGenreAndTag: MovieAndPage
}

export const FILTER_BY_GENRE_AND_TAG_QUERY = gql`
    query FilterByGenreAndTagQuery( $genreID: Int!, $tagID: Int!, $params: GetArgumentsInput! ) {
        FilterByGenreAndTag( genreID: $genreID, tagID: $tagID, params: $params ) {
            Movie {
                id
                name
                release
                ratingIMDB
                
                status
                addedDate
                language {
                    id
                    name
                    abbreviated
                }
                thumbnail {
                    standard {
                        url
                        width
                        height
                    }
                }
            }
            Page {
                TotalCount
            }
        }
    }
`