import React from 'react'
import { QUALITY_TYPES, TranscodeRequestFile, URGENT_TYPES } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { ProcessingFilesResult } from '../types'
import { useGetReadyToSendFilesByQuality } from '../hooks/useGetReadyToSendFiles'
import { createContext } from 'use-context-selector'

export interface ReadyToSendFilesContextValue {
    typeID: CONTENT_TYPES,
    selectedIds: Record< QUALITY_TYPES, Map<number, SelectedReadyToSendtem> >,
    readyToSendFilesResult: Record< QUALITY_TYPES, ProcessingFilesResult >,
    setSelectedIds: Record< 
        QUALITY_TYPES, 
        React.Dispatch<React.SetStateAction<Map<number, SelectedReadyToSendtem>>>|undefined
    > 
}

export interface SelectedReadyToSendtem {
    urgent: URGENT_TYPES,
}

type ReadyToSendFilesContextProviderProps = {
    typeID: CONTENT_TYPES
} & ReactMultipleContainer

const readyToSendInitialValue: ProcessingFilesResult = {
    loading: true, 
    data: [] as TranscodeRequestFile[], 
    error: undefined
};
const selectedIdsInitialValue = new Map<number, SelectedReadyToSendtem>();

const initialValue = {
    typeID: CONTENT_TYPES.FILM,
    selectedIds: {
        [QUALITY_TYPES.QUALITY_480]: selectedIdsInitialValue,
        [QUALITY_TYPES.QUALITY_1080]: selectedIdsInitialValue
    },
    readyToSendFilesResult: {
        [QUALITY_TYPES.QUALITY_480]: readyToSendInitialValue,
        [QUALITY_TYPES.QUALITY_1080]: readyToSendInitialValue
    },
    setSelectedIds: {
        [QUALITY_TYPES.QUALITY_480]: undefined,
        [QUALITY_TYPES.QUALITY_1080]: undefined
    }
}

const ReadyToSendFilesContext = createContext<ReadyToSendFilesContextValue>(initialValue);

function ReadyToSendFilesContextProvider(props: ReadyToSendFilesContextProviderProps) {
    const { children, typeID } = props;

    /** Inner API for provider*/
    const [selected480p, setSelected480p] = React.useState<Map<number, SelectedReadyToSendtem>>(new Map());
    const [selected1080p, setSelected1080p] = React.useState<Map<number, SelectedReadyToSendtem>>(new Map());
    
    /** Outer API for consumers* */
    const selectedIds = React.useMemo< Record<QUALITY_TYPES, Map<number, SelectedReadyToSendtem>> >(() => {
        return {
            [QUALITY_TYPES.QUALITY_480]: selected480p,
            [QUALITY_TYPES.QUALITY_1080]: selected1080p,
        }
    }, [selected480p, selected1080p]);
    const setSelectedIds = React.useMemo< 
        Record< 
            QUALITY_TYPES, 
            React.Dispatch<React.SetStateAction<Map<number, SelectedReadyToSendtem>>>
        > 
    >(() => {
        return {
            [QUALITY_TYPES.QUALITY_480]: setSelected480p,
            [QUALITY_TYPES.QUALITY_1080]: setSelected1080p,
        }
    }, [setSelected480p, setSelected1080p]);

    /** Inner API for provider*/
    const readyToSend_480p_FilesResult = useGetReadyToSendFilesByQuality(typeID, QUALITY_TYPES.QUALITY_480);
    const readyToSend_1080p_FilesResult = useGetReadyToSendFilesByQuality(typeID, QUALITY_TYPES.QUALITY_1080);

    const readyToSendFilesResult = React.useMemo(() => {
        return {
            [QUALITY_TYPES.QUALITY_480]: readyToSend_480p_FilesResult,
            [QUALITY_TYPES.QUALITY_1080]: readyToSend_1080p_FilesResult,
        }
    }, [readyToSend_480p_FilesResult, readyToSend_1080p_FilesResult]);

    /** 
     * Cause of Context used by multiple types of consumers different by typeID 
     * on every change of typeID would be created new selectedIds */
    React.useEffect(() => {
        return () => {
            setSelected480p(new Map());
            setSelected1080p(new Map());
        }
    }, [typeID]);

    const value = {
        typeID,
        selectedIds,
        setSelectedIds,
        readyToSendFilesResult
    }
    
    return (
        <ReadyToSendFilesContext.Provider value={value}>
            { children }
        </ReadyToSendFilesContext.Provider>
    );
}

if(process.env.NODE_ENV !== 'production') {
    ReadyToSendFilesContext.displayName = 'ReadyToSendFilesProvider';
}

export default ReadyToSendFilesContext
export { ReadyToSendFilesContextProvider }


