import React from 'react'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { useSerialFilesContext } from '../../../../contexts/SerialFilesContext'
import SVG from 'react-inlinesvg'
import clsx from 'clsx'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { toAbsoluteUrl } from 'src/util/url'
import { useChangeSerialStatusMutation } from '../../../../hooks/useChangeSerialStatusMutation'

function SeasonCollectionHeader() {
    const serialFilesContext = useSerialFilesContext();
    const seasons = React.useMemo(
        () => serialFilesContext ? serialFilesContext.getAllSeasonsResult.data : [], 
        [serialFilesContext]
    );

    return (
        <div className='d-flex justify-content-between'>
            <span className='seasons-collection-title'>
                Сезонов:
                <span className='label label-primary ml-1'>{seasons.length}</span> 
            </span>

            <SeasonCollectionHeaderStatusBar/>
        </div>
    );
}

function SeasonCollectionHeaderStatusBar() {
    const changeSerialStatusCallback = useChangeSerialStatusMutation();
    const contentEditContext = useContentEditContext();
    const { status, id, setInfo } = contentEditContext;

    const statusDisplayClass = React.useMemo(() => {
        return status === ACTIVATION_STATUS.ACTIVE 
            ? 'svg-icon-success' 
            : 'svg-icon-danger';
    }, [status]);

    const statusChangeHandler = React.useCallback<React.MouseEventHandler>( e => {
        e.stopPropagation();
        const changedStatus = status === ACTIVATION_STATUS.ACTIVE ? ACTIVATION_STATUS.INACTIVE : ACTIVATION_STATUS.ACTIVE;
        changeSerialStatusCallback(id, changedStatus)
            .then( response => {
                const successfull = response.data != null && response.data.ChangeMovieStatus.status === 'ok';
                if(successfull) {
                    setInfo( 
                        prev => ({
                            ...prev,
                            status: changedStatus
                        })
                    );
                }
            });
    }, [changeSerialStatusCallback, id, status, setInfo]);

    return (
        <button
            title='Сменить статус'
            className='btn btn-icon btn-light btn-sm m-0'
            onClick={statusChangeHandler}
        >   
            <span className={clsx('svg-icon', 'svg-icon-md', statusDisplayClass)}>
                <SVG
                    description='Сменить статус'
                    title='Сменить статус'
                    src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                />
            </span>
        </button>
    )
}

export default SeasonCollectionHeader
