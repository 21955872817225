import BasicModal from '../../../BasicModal'
import SeasonEditModalBody from './components/SeasonEditModalBody'

type SeasonEditModalProps = {
    seasonID: number,
    show: boolean,
    onHide: () => void
}

function SeasonEditModal(props: SeasonEditModalProps) {
    const { show, onHide, seasonID } = props;

    return (
        <BasicModal show={show} onHide={onHide}>
            <SeasonEditModalBody seasonID={seasonID} closeCallback={onHide}/>
        </BasicModal>
    );
}

export default SeasonEditModal