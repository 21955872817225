import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { Response } from 'src/api/graphql/types'
import { isErrorsArray } from 'src/uploader/helpers/isErrorsArray'
import { isValidResponse } from 'src/uploader/helpers/isValidResponse'
import { AddedData, CompletedFileItem, ErrorFile, FullfilledFile, InternalID, PendingFile } from 'src/uploader/types'


const useItemFinishEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<InternalID, AddedData>>, 
    pending: Map<InternalID, PendingFile>,
    setPending:  React.Dispatch<React.SetStateAction<Map<InternalID, PendingFile>>>,
    fullfilled: Map<InternalID, FullfilledFile>,
    setFullfilled: React.Dispatch<React.SetStateAction<Map<InternalID, FullfilledFile>>>,
    rejected: Map<InternalID, ErrorFile>,
    setRejected: React.Dispatch<React.SetStateAction<Map<InternalID, ErrorFile>>>,
    afterUploadHandler: (filePath: string, id: number, uploadType: number) => Promise<Error|Response|Error[]>
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item: CompletedFileItem) => {
            const filePayload = addedFiles.current.get(item.id);
            const filepath = item.uploadResponse.data.filepath;
            const status = item.uploadStatus;

            if(status === 200) {
                if(filePayload) {
                    const doAfterUploadRequest = async function() {
                        const afterUploadResponse = await afterUploadHandler(filepath, filePayload.movieID, filePayload.uploadType); // TODO: TypeId could not be always 1. Rewrite it

                        if(afterUploadResponse instanceof Error) {
                            console.error('Uploader element, useItemFinishEffect', 'afterUploadHandler request is error', afterUploadResponse);
                            pending.delete(filePayload.externalID);
                            rejected.set(filePayload.externalID, { ...item, title: filePayload.title});
                            setPending(new Map(pending));
                            setRejected(new Map(rejected));
                        }

                        if( isErrorsArray(afterUploadResponse) ) {
                            console.error('Uploader element, useItemFinishEffect', 'afterUploadHandler request is error', afterUploadResponse);
                            pending.delete(filePayload.externalID);
                            rejected.set(filePayload.externalID, { ...item, title: filePayload.title});
                            setPending(new Map(pending));
                            setRejected(new Map(rejected));
                        }

                        if( isValidResponse(afterUploadResponse) ) {
                            if(afterUploadResponse.status !== 'error') {
                                pending.delete(filePayload.externalID);
                                fullfilled.set(filePayload.externalID, {itemId: item.id, title: filePayload.title});
                                setPending(new Map(pending));
                                setFullfilled(new Map(fullfilled));
                
                                /**
                                 * After successfully upload and other requests record of current item in addedFiles doesn't need any more
                                 */
                                addedFiles.current.delete(item.id);
                            } else {
                                console.error('Uploader element, useItemFinishEffect', 'afterUploadHandler request status is error', afterUploadResponse);
                                pending.delete(filePayload.externalID);
                                rejected.set(filePayload.externalID, { ...item, title: filePayload.title});
                                setPending(new Map(pending));
                                setRejected(new Map(rejected));
                            }
                        }
                    }
    
                    doAfterUploadRequest();
                }
            } else {
                console.error('Uploader element, useItemFinishEffect', 'Upload status is not SUCCESS(200)', status);
                if(filePayload) {
                    pending.delete(filePayload.externalID);
                    rejected.set(filePayload.externalID, { ...item, title: filePayload.title});
                    setPending(new Map(pending));
                    setRejected(new Map(rejected));
                }
            }
        });

        return () => {
            uploader.off(UPLOADER_EVENTS.ITEM_FINISH);
        }
    }, [uploader, addedFiles, pending, setPending, fullfilled, setFullfilled, rejected, setRejected, afterUploadHandler]);
}

export default useItemFinishEffect