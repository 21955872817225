import { useMutation } from '@apollo/client'
import { SendToTranscodeArgs, SendToTranscodeData, SEND_TO_TRANSCODE_MUTATION } from 'src/api/graphql/mutations/SendToTranscode'
import { GET_EPISODES_LIST_QUERY } from 'src/api/graphql/queries/GetEpisodesList';
import { GET_FILES_BY_PROCESS_STATUS_QUERY } from 'src/api/graphql/queries/GetFilesByProcessStatus';
import { GET_MOVIES_LIST_QUERY } from 'src/api/graphql/queries/GetMoviesList';
import { QUALITY_TYPES, TRANSCODE_STATUS } from 'src/api/graphql/types';
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes';

export function useSendToTranscodeMutation() {
    const [ sendToTranscode, sendToTranscodeResult ] = useMutation<SendToTranscodeData, SendToTranscodeArgs>(SEND_TO_TRANSCODE_MUTATION);

    return async (args: SendToTranscodeArgs, refetch: boolean = false ) => {
    
        const refetchQueries = refetch ? getActiveQuery(args.typeID, args.qualityID) : undefined;

        await sendToTranscode({
            variables: {
                ...args
            },
            refetchQueries
        });

        return {
            loading: sendToTranscodeResult.loading,
            data: sendToTranscodeResult.data ? sendToTranscodeResult.data.SendToTranscode : undefined,
            error: sendToTranscodeResult.error
        }
    }
}

const getActiveQuery = (typeID: CONTENT_TYPES, qualityID: QUALITY_TYPES) => {
    switch (typeID) {
        case CONTENT_TYPES.FILM: return [
            { query: GET_MOVIES_LIST_QUERY, variables: {qualityID} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.WAITING} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.PENDING} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.TRANSCODE_ERROR}},
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.FILE_STORAGE_ERROR}}
        ];
        case CONTENT_TYPES.EPISODE: return [
            { query: GET_EPISODES_LIST_QUERY, variables: {qualityID} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.WAITING} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.PENDING} },
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.TRANSCODE_ERROR}},
            { query: GET_FILES_BY_PROCESS_STATUS_QUERY, variables: {typeID, status: TRANSCODE_STATUS.FILE_STORAGE_ERROR}}
        ];
        default: throw new Error('Unknown typeID in getActiveQuery')
    }
}