import { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import objectPath from 'object-path'
import { useHtmlClassService } from 'src/metronic/core/MetronicLayout'
import { UserProfileDropdown } from './UserProfileDropdown'
import { useAuthGateway } from 'src/app/contexts/AuthenticationContext'

export function QuickUserToggler() {
  const { authResult } = useAuthGateway();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id='quick-user-tooltip'>Посмотреть данные пользователя</Tooltip>}
        >
          <div className='topbar-item'>
            <div
              className='btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
              id='kt_quick_user_toggle'
            >
              <>
                <span className='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'>
                  {authResult.user?.name}
                </span>
                <span className='symbol symbol-35 symbol-light-success'>
                  <span className='symbol-label font-size-h5 font-weight-bold'>
                    {authResult.user?.id}
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
