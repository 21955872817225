import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import FullfilledTable from './components/FullfilledTable'
import PendingTable from './components/PendingTable'
import RejectedTable from './components/RejectedTable'
import WaitingTable from './components/WaitingTable'
import { ProccessingContextProvider } from './contexts/ProccessingContext'
import { withProcessingCard } from './hocs/withProcessingCard'
import { usePrettyHeight } from '../../../hooks/util/usePrettyHeight'
import { ReadyToSendFilesContextProvider } from './contexts/ReadyToSendFilesContext'
import { ReadyToSendTable1080p, ReadyToSendTable480p } from './components/ReadyToSendTable'

const CardWrappedReadyToSend480pTable = withProcessingCard( 
    ReadyToSendTable480p, 
    'Готовые к обработке 480p', 
    'ready-to-send-table',
    'CardWrappedReadyToSend480pTable',
);
const CardWrappedReadyToSend1080pTable = withProcessingCard( 
    ReadyToSendTable1080p, 
    'Готовые к обработке 1080p', 
    'ready-to-send-table',
    'CardWrappedReadyToSend1080pTable',
);
const CardWrappedWaitingTable = withProcessingCard( 
    WaitingTable, 
    'Ожидающие в очереди', 
    'waiting-table',
    'CardWrappedWaitingTable',
);
const CardWrappedPendingTable = withProcessingCard( 
    PendingTable, 
    'В обработке',
    'pending-table',
    'CardWrappedPendingTable'
);
const CardWrappedFullfilledTable = withProcessingCard(
    FullfilledTable, 
    'Завершенные успешно', 
    'fullfilled-table',
    'CardWrappedFullfilledTable'
);
const CardWrappedRejectedTable = withProcessingCard(
    RejectedTable,
    'Завершенные с ошибкой', 
    'rejected-table',
    'CardWrappedRejectedTable'
);

function ProcessingList(props: {typeID: CONTENT_TYPES}) {
    const prettyHeight = usePrettyHeight();
    const cardStyle = {height: prettyHeight};

    return (
        <div className='processing-list' >
            <ReadyToSendFilesContextProvider typeID={props.typeID}>
                <CardWrappedReadyToSend480pTable style={cardStyle}/>
                <CardWrappedReadyToSend1080pTable style={cardStyle}/>
            </ReadyToSendFilesContextProvider>

            <ProccessingContextProvider typeID={props.typeID}>
                <CardWrappedWaitingTable style={cardStyle}/>
                <CardWrappedPendingTable style={cardStyle}/>
                <CardWrappedRejectedTable style={cardStyle}/>
                <CardWrappedFullfilledTable style={cardStyle}/>
            </ProccessingContextProvider>
        </div>
    );
}

export default ProcessingList