import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import ErrorBoundary from 'src/app/components/ErrorBoundary'

interface WithCardArg<Props> {
    Target: React.ComponentType<Props>
    CardHeaderTitle: React.ComponentType<unknown>|string
    cardClassName: string
    cardHeaderClassName: string
    wrappedComponentName: string
    CardHeaderToolbar?: React.ComponentType<unknown>|undefined
    errorFallback?: React.ElementType<unknown> | string | undefined
}

/** HOC to wrap Component with Error Boudary and Card Component*/
export function withCard<Props>(arg: WithCardArg<Props> ): React.ComponentType<Props> {
    const {
        Target,
        CardHeaderTitle,
        cardClassName,
        cardHeaderClassName,
        wrappedComponentName,
        CardHeaderToolbar,
        errorFallback
    } = arg;
    
    const CardWrappedComponent: React.ComponentType<Props> = props => {
        const toolbar = CardHeaderToolbar ? <CardHeaderToolbar/> : undefined;
        const header = typeof CardHeaderTitle === 'string' 
        ? CardHeaderTitle
        : <CardHeaderTitle/>;
        
        return (
            <Card className={cardClassName}>
                <CardHeader 
                    title={header} 
                    toolbar={toolbar}
                    className={cardHeaderClassName}
                />
                <CardBody>
                    <ErrorBoundary fallback={errorFallback}>
                        <Target {...props}/>
                    </ErrorBoundary>
                </CardBody>
            </Card>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        CardWrappedComponent.displayName = wrappedComponentName;
    }
    
    return CardWrappedComponent;
}