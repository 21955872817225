import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import App from './app/App'
import { apolloClient as client } from './api/graphql/client'
import VideoFileUploadProvider from './app/contexts/VideoFileUploadContext'
import { VIDEO_API_ENDPOINT } from './api/rest/urls/endpoints'
import { MOVIE_UPLOAD_PREFETCH_API } from './api/rest/urls/videoUpload'
import './index.scss'

const { PUBLIC_URL } = process.env;
const VIDEO_UPLOAD = VIDEO_API_ENDPOINT + MOVIE_UPLOAD_PREFETCH_API;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router basename={PUBLIC_URL}>
      <VideoFileUploadProvider endpoint={VIDEO_UPLOAD}>
        <App />
      </VideoFileUploadProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
