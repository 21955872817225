export const getFieldCSSClasses = (touched: any, errors: string|undefined) => {
    const classes = ['form-control'];
    if (errors) {
      classes.push('is-invalid');
    }
  
    if (touched && !errors) {
      classes.push('is-valid');
    }
  
    return classes.join(' ');
};
  