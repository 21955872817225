import React from 'react'
import * as Yup from 'yup'
// import { isEqual } from 'lodash'
import { ContentEditProviderState, EditSelectorsUIProps, SelectorEditField, SelectorEditForm, useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { withEditContentFormik } from 'src/app/components/ContentService/EditContent/hocs/withEditContentFormik'
import { FormikErrors, FormikHelpers } from 'formik'
import EditSelectorsCard from './components/EditSelectorsCard'
import { Notification, NotificationVariants } from 'src/app/contexts/NotificationContext'
import { SelectorsAddArgs } from 'src/app/hooks/mutation/useAddSelectorsToMovie'

// Simple Yup validation schema
const validationSchema = Yup.object().shape({
    genres: Yup.array().of(
        Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        })
    ).required('Жанры обязательны к заполнению'),

    tags: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string()
        })
    ),

    countries: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string()
        })
    ),

    actors: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string()
        })
    ),

    languages: Yup.array().of(
        Yup.object().shape({
            value: Yup.number(),
            label: Yup.string()
        })
    ),
});

const customValidator = (values: SelectorEditForm): FormikErrors<SelectorEditForm> => {
    const error = {};
    // TODO: Add correct implementation
    return error
}

/**
 * Util function for preparing data sending to the server. Because of there are one data type to sending to server and
 * another in formik's flow, then must ensure to have valid data type for sending
 */
const prepareToSend = (id: number, selectors: SelectorEditForm, values: SelectorEditForm): [SelectorsAddArgs, boolean] => {
    const { genres, tags, countries, actors, directors } = values;

    // This could be used loadash 'differenceBy' function
    const truncate = (childs: SelectorEditField[], parrents: SelectorEditField[]): number[] => {
        const parentValues = parrents.map( p => p.value );
        return childs
            .filter( child => !parentValues.includes(child.value) )
            .map( filtredChild => filtredChild.value ); 
    }
    
    const movieID       = id;
    const genreID       = truncate(genres, selectors.genres);
    const tagID         = truncate(tags, selectors.tags);
    const countryID     = truncate(countries, selectors.countries);
    const actorID       = truncate(actors, selectors.actors);
    const directorID    = truncate(directors, selectors.directors);

    const isEmpty = genreID.length + tagID.length + countryID.length + actorID.length + directorID.length === 0; 
    return [ { movieID, genreID, tagID, countryID, actorID, directorID }, isEmpty ];
};

// Submit handler function
const submitHandler = (
    { id, selectors, setInfo, updateSelectors}: EditSelectorsUIProps, 
    values: SelectorEditForm, 
    helpers: FormikHelpers<SelectorEditForm>, 
    notificationSetter: (notification: Notification) => void 
) => {
    const [ preparedValues, isEmpty ] = prepareToSend(id, selectors, values);

    // If nothing to add show notification then exit
    if(isEmpty) {
        notificationSetter({
            message: 'Выбранные селекторы не отличаются от прежних', 
            variant: NotificationVariants.info
        });
        helpers.setSubmitting(false);
        return;
    } else {
        /**
         * Send Mutation to server, then if request has succeeded
         * alert success notification else error notification
         * if occurs network error also alert error notification 
         */
        updateSelectors && updateSelectors(
            preparedValues
        ).then( result => {
            const fullfilledResult = result.filter( i => isFullfilledResult(i) );
            const rejectedResult = result.filter( i => isRejectedResult(i) );

            if(fullfilledResult.length !== 0) {
                console.log('FullfiledResult', fullfilledResult)

                setInfo( 
                    (prev: ContentEditProviderState) => ({
                        ...prev,
                        selectors: { ...values }
                    })
                );

                notificationSetter({
                    message: 'Успешно изменены Селекторы', 
                    variant: NotificationVariants.success
                });
            }

            if(rejectedResult.length !== 0) {
                notificationSetter({
                    message: 'Ошибка при попытке отправки на изменение Селекторы',
                    variant: NotificationVariants.warning
                });
            }
        }).catch( (error: Error) => {
            helpers.setErrors( {genres: error.message} );
            notificationSetter({message: 'Ошибка при попытке отправки на изменение Селекторы', variant: NotificationVariants.error});
            console.error(`Response from useUpdateMovieMutation could not be ${error}`, error);
        }).finally( () => {
            helpers.setSubmitting(false);
        });
    }

    // if ( !isEqual(values, selectors) ) {

    //     setInfo( 
    //         (prev: ContentEditProviderState) => ({
    //             ...prev,
    //             ...values
    //         })
    //     );
    // } 
};

function isRejectedResult(x: unknown): x is PromiseRejectedResult {
    const target = x as PromiseRejectedResult;
    return 'status' in target && target.status === 'rejected';
}

function isFullfilledResult(x: unknown): x is PromiseFulfilledResult<unknown> {
    const target = x as PromiseFulfilledResult<unknown> ;
    return 'status' in target && target.status === 'fulfilled';
}
/**
 * Custom hook used in initialization of the HOC withEditContentFormik
 * @returns EditSelectorsUIProps object that used in Formik's initializer hoc 
 * and further could be called to save EditBasicInfo form's state in EditContentContext
 * in submitHandler function
 */
function useEditContextForInitializeBasicInfo(): [EditSelectorsUIProps, SelectorEditForm] {
    const contentEditContextValue = useContentEditContext();
    const editSelectorsUIProps = React.useMemo(() => {
        return {
            id: contentEditContextValue.id,
            selectors: contentEditContextValue.selectors,
            setInfo: contentEditContextValue.setInfo,
            updateSelectors: contentEditContextValue.updateSelectors
        };
    }, [contentEditContextValue]);
    
    const initialValues = {
        genres: editSelectorsUIProps.selectors.genres,
        tags: editSelectorsUIProps.selectors.tags,
        countries: editSelectorsUIProps.selectors.countries,
        actors: editSelectorsUIProps.selectors.actors, 
        directors: editSelectorsUIProps.selectors.directors,
        languages: editSelectorsUIProps.selectors.languages
    }

    return [editSelectorsUIProps, initialValues];
}

// EditBasicInfoCard wrapped with Formik context and it's helpers
const EditSelectors = withEditContentFormik<SelectorEditForm>({
    validationSchema: validationSchema,
    validate: customValidator,
    submitHandler
}, useEditContextForInitializeBasicInfo)(EditSelectorsCard);

export default EditSelectors