import React from 'react'
import SelectorConfigCard from '../SelectorConfigCard'
import TagsConfigTable from './TagsConfigTable'
import { useAddTag } from './hooks/useAddTag'
import { useUpdateTag } from './hooks/useUpdateGenre'
import { useGetAllTags } from './hooks/useGetTags'
import { SelectorParams, useSelectorConfig } from '../../contexts/SelectorConfigContext'
import { Tag, TagInput } from 'src/api/graphql/types'
import SelectorConfigSearchBar from '../SelectorConfigSearchBar'

const initialValue = {
    params: {
        searchText: ''
    },
    loading: false,
    selectors: [],
    pendingUpdates: new Set<number>(),
    addPermission: false
}

function TagsConfig() {
    const [SelectorConfigProvider, useSelectorConfigContext] = useSelectorConfig<SelectorParams, TagInput, Tag>(initialValue);

    return (
        <SelectorConfigProvider 
            useGetQueryHook={useGetAllTags} 
            useAddMutationHook={useAddTag} 
            useUpdateMutationHook={useUpdateTag}
        >
            <SelectorConfigCard title='Тэги' className='margin-bottom-0 tags-config-card'>
                <SelectorConfigSearchBar useSelectorConfigContext={useSelectorConfigContext}/>
                <TagsConfigTable useSelectorConfigContext={useSelectorConfigContext}/>
            </SelectorConfigCard>
        </SelectorConfigProvider>
    );
}

// Wrapped into memo for reason of performance and preventing error 
export default React.memo(TagsConfig)