import { BreadCrumb } from "src/app/contexts/MetronicSubheaderContext";

type BreadcrumbResult = { 
    breadcrumbs: BreadCrumb[],
    title: string,
}

export function getBreadcrumbsAndTitle(menuId:string, pathName:string):BreadcrumbResult {
    const result:BreadcrumbResult  = {
        breadcrumbs: [],
        title: "",
    };

    const menu = document.getElementById(menuId);
    if (!menu) {
        return result;
    }

    const activeLinksArray = (Array.from(
        menu.getElementsByClassName("active") || []
    )) 
    const activeLinks = activeLinksArray.filter((el) => el.tagName === "A") as (Element&BreadCrumb)[];;
    if (!activeLinks) {
        return result;
    }

    activeLinks.forEach((link) => {
        const titleSpans = link.getElementsByClassName("menu-text");
        if (titleSpans) {
            const titleSpan = Array.from(titleSpans).find(
                (t) => t.innerHTML && t.innerHTML.trim().length > 0
            );
            if (titleSpan) {
                result.breadcrumbs.push({
                    pathname: link.pathname.replace(process.env.PUBLIC_URL, ""),
                    title: titleSpan.innerHTML,
                });
            }
        }
    });

    result.title = getTitle(result.breadcrumbs, pathName);
    return result;
}

export function getTitle(breadCrumbs: any[], pathname: string) {
  if (!breadCrumbs || !pathname) {
    return "";
  }

  const length = breadCrumbs.length;
  if (!length) {
    return "";
  }

  return breadCrumbs[length - 1].title;
}