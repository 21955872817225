import React from 'react'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { FilmsFilter } from './FilmsFilter'
import FilmsTable from './FilmsTable'

function FilmsCard(): React.ReactElement {
    const [loading, setLoading] = React.useState(false);
    
    return (
        <Card className='mb-0'>
            <CardHeader title={'Список фильмов'}/>
            <CardBody>
                <FilmsFilter listLoading={loading}/>
                {/* Unused, errors occur for reason of empty pagination */}
                <ErrorBoundary> 
                    <FilmsTable loadingTrigger={setLoading}/>
                </ErrorBoundary>
            </CardBody>
        </Card>
    );
}

export default FilmsCard