import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllCategoriesArgs, GetAllCategoriesData, GET_ALL_CATEGORIES_QUERY } from 'src/api/graphql/queries/getAllCategories'
import { Country } from 'src/api/graphql/types'

type useGetAllCategoriesQueryArgs = {
    completeHandler?: (data: Country[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllCategoriesResult = {
    loading: boolean,
    data: Country[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<GetAllCategoriesArgs> | undefined) => Promise<ApolloQueryResult<GetAllCategoriesData>>
}

function useGetAllCategoriesQuery(args: useGetAllCategoriesQueryArgs = {}): useGetAllCategoriesResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllCategoriesData, GetAllCategoriesArgs>(GET_ALL_CATEGORIES_QUERY, {
        variables: {},
        onCompleted: (data: GetAllCategoriesData) => {
            completeHandler && completeHandler(data.getAllCategories);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error);
        }

    });
    return {
        loading, 
        data: data ? data.getAllCategories: undefined,
        error, 
        refetch
    }
}

export default useGetAllCategoriesQuery