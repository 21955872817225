import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { AddedData, FileItem, InternalID } from 'src/uploader/types'
import { FILE_STATES } from '@rpldy/shared'
import { comment } from 'src/util/comment'


const useItemFinalizeEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<InternalID, AddedData>>, 
    // finalizeHandler: (filePath: string, id: number, uploadType: number) => Promise<Error|Response|Error[]>
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.ITEM_FINALIZE, (item: FileItem) => {
            if(item.state === FILE_STATES.FINISHED) {
                comment('useItemFinalizeEffect', 'item is fineshed successfully', 'here could be handler after item upload is done');
            }
        });

        return () => {
            uploader.off(UPLOADER_EVENTS.ITEM_FINALIZE);
        }
    }, [uploader, addedFiles]);
}

export default useItemFinalizeEffect