import React from 'react'
import { Button } from '@material-ui/core'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { CardHeaderToolbar } from 'src/metronic/components/Card/CartHeader/CartHeaderToolbar'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import AddBasicInfoForm from '../AddBasicInfoForm'
import { CardFooter } from 'src/metronic/components/Card/CartFooter'
import { FormikProps } from 'formik'
import { Spinner } from 'react-bootstrap'
import ErrorStatusBar from 'src/app/components/ContentService/ErrorStatusBar'
import { MovieTVSeriesUnionInput } from '../../hocs/withAddBasicInfoFormik'

type AddBasicInfoCardProps = FormikProps<MovieTVSeriesUnionInput>

// TODO: css classnames leave untouched, think about renaming
function AddBasicInfoCard(props: AddBasicInfoCardProps): React.ReactElement {
   
    const { handleSubmit } = props;
    const submitOnClick = () => handleSubmit();
    

    const { errors, isSubmitting } = props;
    return (
        // Maded 
        <Card className='m-auto w-100'>
            <CardHeader title={'Базовая информация'}>
                <CardHeaderToolbar>
                    <CardHeaderToolbar>
                        <span></span>
                    </CardHeaderToolbar>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ErrorBoundary> 
                    <AddBasicInfoForm {...props}/>
                </ErrorBoundary>
            </CardBody>
            <CardFooter className='d-flex justify-content-end align-items-center' >
                <ErrorStatusBar errors={errors}/>
                <Button color='primary' variant='contained' onClick={ submitOnClick } className='add-form-submit-btn'>
                    {
                        isSubmitting 
                            && 
                        Object.values(errors).length === 0 
                            &&
                        <span>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </span>
                    }
                    Сохранить
                </Button>
            </CardFooter>
        </Card>
    );
}

export default AddBasicInfoCard