export const defaultComplaintsQueryParams: ComplaintsQueryParams = {
    pageSize: 25,
    pageNumber: 1,
    sortField: 'complaintID', 
    sortOrder: 'asc'
}

export const defaultComplaintsSearchParams = new URLSearchParams([
    ['pageSize', '25'],
    ['pageNumber', '1'],
    ['sortField', 'movieID'],
    ['sortOrder', 'asc']
])