import { gql } from '@apollo/client'
import { Response } from '../types'

export interface DeleteCountryToMovieMutationArgs {
    movieID: number,
    countryID: number,
} 
export interface DeleteCountryToMovieMutationData {
    DeleteCountryToMovie: Response
}

export const DELETE_COUNTRY_TO_MOVIE_MUTATION = gql`
    mutation DeleteCountryToMovieMutation( $movieID: Int!, $countryID: Int!) {
        DeleteCountryToMovie( movieID: $movieID, countryID: $countryID ) {
           status
           message
        }
    }
`