import { FileError } from 'react-dropzone'
import { useIsHaveMovieFileLazyQuery } from 'src/app/components/ContentService/EditContent/components/EditFiles/hooks/useIsHaveMovieFileLazyQuery';
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { UploadTypes, VideoFileUploadContext } from 'src/app/contexts/VideoFileUploadContext'
import { IMapLike } from 'src/uploader/types'
import { useContextSelector } from 'use-context-selector'

const useEpisodeDropHandler = (id: number, name: string) => {
    const waiting = useContextSelector(VideoFileUploadContext, v => v.waiting);
    const pending = useContextSelector(VideoFileUploadContext, v => v.pending);
    const addFileToQueue = useContextSelector(VideoFileUploadContext, v => v.addFileToQueue);

    const { setNotification } = useNotification();

    const movieExistCheckCallback = useIsHaveMovieFileLazyQuery(id);
    
    const dropHandler = async (file: File|undefined, fileRejections: Array<{file: File, errors: FileError[]}>) => {
        if(file !== undefined) {
            if( hasNotInMaps(id, UploadTypes.MOVIE, waiting, pending) ) {
                try {
                    const movieExistInDB = await movieExistCheckCallback();

                    if(!movieExistInDB){
                        addFileToQueue && addFileToQueue(file, id, UploadTypes.MOVIE, name); // TODO: name must be full name of serial + season + episode
                        setNotification({
                            message: name,
                            variant: NotificationVariants.success
                        });
                    } else {
                        setNotification({
                            message: `${name} уже есть на сервере`,
                            variant: NotificationVariants.warning
                        });
                    }
                } catch (error) {
                    setNotification({
                        message: 'Ошибка при попытке проверки файла' + name,
                        variant: NotificationVariants.error
                    });
                }
            } else {
                setNotification({
                    message: `${name} уже есть в списках загрузки файлов`,
                    variant: NotificationVariants.warning
                });
            }
        }

        if(fileRejections.length !== 0) {
            setNotification({
                message: 'Rejected files: ' + fileRejections.map(i => i.file.name).join(),
                variant: NotificationVariants.error
            });
        }
    }

    return dropHandler;
}

function hasNotInMaps(id: number, uploadType: UploadTypes,  ...rest: IMapLike<unknown>[]) {
    return !rest.some( i => i.has(id, uploadType) );
}

export default useEpisodeDropHandler