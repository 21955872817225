import { useMemo } from 'react'
import { useHtmlClassService } from 'src/metronic/core/MetronicLayout'

type SidebarMenuProps = {
  disableScroll: boolean
  sidebarMenuContent: React.FC<{ 
    layoutProps: {
      ulClasses: string
    } & unknown
  }>
}

function SidebarMenu(props: SidebarMenuProps) {
    const SidebarMenuContent = props.sidebarMenuContent;
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
      return {
        layoutConfig: uiService.config,
        asideMenuAttr: uiService.getAttributes("aside_menu"),
        ulClasses: uiService.getClasses("aside_menu_nav", true),
        asideClassesFromConfig: uiService.getClasses("aside_menu", true)
      };
    }, [uiService]);

    return (
        <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <SidebarMenuContent layoutProps={layoutProps} />
      </div>
      {/* end::Menu Container */}
    </>
    )
}

export default SidebarMenu