import React from 'react'
import SelectorConfigCard from '../SelectorConfigCard'
import { useGetAllParticipants } from './hooks/useGetParticipants'
import ParticipantConfigSearchBar from './components/ParticipantConfigSearchBar'
import ParticipantsConfigTable from './components/ParticipantsConfigTable'
import { useSetInitialQueryParams } from './hooks/useSetInitialQueryParams'
import { useSaveParticipantsQueryParams } from './hooks/useSaveParticipantsQueryParams'
import { useParticipantsQueryParams } from './hooks/useParticipantsQueryParams'
import { isUniqueName } from './utils/isUniqueName'
import { usePrettyHeight } from 'src/app/hooks/util/usePrettyHeight'

function ParticipantsList() {
    const prettyHeight = usePrettyHeight();
    const cardStyle = {height: prettyHeight};
    useSetInitialQueryParams();
    useSaveParticipantsQueryParams();
    const params = useParticipantsQueryParams();
    const { searchText } = params;
    const {participants, loading, totalSize } = useGetAllParticipants(params);

    const addPermision = React.useMemo(() => {
        if(loading) return false;
        return isUniqueName(participants, searchText);
    }, [participants, searchText, loading]);

    const proxiedParticipants = React.useMemo(() => {
        const proxied =  participants.map( p => new Proxy(p, {
            get: function(target, prop) {
                if(prop === 'unique_key') {
                    return `${target.id}-${target.roleID}`;
                }
                return target[prop];
            }
        }));
        return proxied;
    }, [participants]);

    return (
        <SelectorConfigCard 
            title='Участники' 
            className='margin-bottom-0 participants-config-card'
            style={cardStyle}
        >
            <ParticipantConfigSearchBar
                loading={loading}
                addPermision={addPermision}
            />
            <ParticipantsConfigTable
                participants={proxiedParticipants}
                loading={loading}
                totalSize={totalSize}
            />
        </SelectorConfigCard>
    );
}

export default ParticipantsList