import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllTagsArgs, GetAllTagsData, GET_ALL_TAGS_QUERY } from 'src/api/graphql/queries/getAllTags'
import { Tag } from 'src/api/graphql/types'

type useGetAllTagsQueryArgs = {
    completeHandler?: (data: Tag[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllTagsResult = {
    loading: boolean,
    data: Tag[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<useGetAllTagsQueryArgs> | undefined) => Promise<ApolloQueryResult<GetAllTagsData>>
}


function useGetAllTagsQuery(args: useGetAllTagsQueryArgs = {}): useGetAllTagsResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllTagsData, GetAllTagsArgs>(GET_ALL_TAGS_QUERY, {
        variables: {},
        onCompleted: (data: GetAllTagsData) => {
            completeHandler && completeHandler(data.getAllTags);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error)
        }

    });
    return {
        loading, 
        data: data ? data.getAllTags : undefined, 
        error, 
        refetch
    }
}

export default useGetAllTagsQuery