import React from 'react'
import { QUALITY_TYPES, Sources, TRANSCODE_STATUS } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { isNull } from 'src/util/isNull'
import { ProcessData } from '../../../../../../types'
import { useSerialFilesContext } from '../../../../contexts/SerialFilesContext'
import { useGetEpisodeFileSources } from '../../../../hooks/useGetEpisodeFileSources'

export function withSerialFileInfo(
    Component: React.ComponentType<{
        playerData: Sources|null, 
        processData: ProcessData | undefined
    }>
) {
    const FileInfoInjectedComponent: React.ComponentType<unknown> = () => {
        const filesContext = useSerialFilesContext();
        
        const processData = React.useMemo<(ProcessData)|undefined>(() => {
            if( isNull(filesContext) ) {
                throw new Error('SerialFilesContext value could not be null in withSerialFileInfo HOC');
            } 
            const activeEpisodeID = filesContext.activeEpisode;
            const activeEpisodeIndex = filesContext.getAllEpisodesResult.data.findIndex(i => i.id === activeEpisodeID);
            const activeEpisodeData = filesContext.getSeriesFileInfoResult.data[activeEpisodeIndex];

            if( activeEpisodeData === undefined ) {
                return undefined;
            }

            return {
                movieID: activeEpisodeID,
                fileID: activeEpisodeData.UploadStatus,
                typeID: CONTENT_TYPES.EPISODE,
                uploaded: activeEpisodeData.UploadStatus !== 0,
                qualities: getAvailableQuality(
                    {id: QUALITY_TYPES.QUALITY_480, status: activeEpisodeData.ProcessStatusLQ},
                    {id: QUALITY_TYPES.QUALITY_1080, status: activeEpisodeData.ProcessStatusHQ}
                ) 
            }
        }, [filesContext]);

        const playerData = useGetEpisodeFileSources();

        return (
            <ErrorBoundary>
                <Component playerData={playerData} processData={processData}/>
            </ErrorBoundary>
        );
    }

    return FileInfoInjectedComponent;
}

const getAvailableQuality = (...qualityIDs: {id: QUALITY_TYPES, status: TRANSCODE_STATUS}[]): QUALITY_TYPES[] => {
    const validStates = new Set([
        TRANSCODE_STATUS.NON, 
        TRANSCODE_STATUS.FILE_STORAGE_ERROR, 
        TRANSCODE_STATUS.TRANSCODE_ERROR
    ]);

    return qualityIDs.filter(q => validStates.has(q.status)).map( i => i.id );
}