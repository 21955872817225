import { ApolloError, useQuery } from "@apollo/client";
import { GetMovieFileInfoArgs, GetMovieFileInfoData, GET_MOVIE_FILE_INFO_QUERY } from "src/api/graphql/queries/GetMovieFileInfo";
import { FileInfo, QUALITY_TYPES, TRANSCODE_STATUS } from "src/api/graphql/types";
import { CONTENT_TYPES } from "src/app/assets/ContentTypes";
import { ProcessData } from "../../../types";

export function useGetMovieFileInfo(movieID: number): {
    loading: boolean,
    data: [ProcessData, { ProcessStatusLQ: TRANSCODE_STATUS, ProcessStatusHQ: TRANSCODE_STATUS}] | [undefined, undefined],
    error: ApolloError | undefined,
} {
    const {loading, data, error} = useQuery<GetMovieFileInfoData, GetMovieFileInfoArgs>(GET_MOVIE_FILE_INFO_QUERY, {
        variables: {movieID}
    });

    return {
        loading,
        data: data ? createProcessData(data.GetMovieFileInfo): [undefined, undefined],
        error
    }
}

const createProcessData = (data:  FileInfo): [ProcessData, { ProcessStatusLQ: TRANSCODE_STATUS, ProcessStatusHQ: TRANSCODE_STATUS}] => {
    const validStates = new Set([
        TRANSCODE_STATUS.NON, 
        TRANSCODE_STATUS.FILE_STORAGE_ERROR, 
        TRANSCODE_STATUS.TRANSCODE_ERROR
    ]);

    const qualities = [
        {id: QUALITY_TYPES.QUALITY_480, status: data.ProcessStatusLQ},
        {id: QUALITY_TYPES.QUALITY_1080, status: data.ProcessStatusHQ}
    ].filter(q => validStates.has(q.status)).map( i => i.id );

    return [
        {
            movieID: data.MovieID,
            fileID: data.FileID,
            typeID: CONTENT_TYPES.FILM,
            uploaded: data.UploadStatus,
            qualities
        },
        {
            ProcessStatusLQ: data.ProcessStatusLQ,
            ProcessStatusHQ: data.ProcessStatusHQ
        }
    ]
}