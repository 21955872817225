import { gql } from '@apollo/client'
import { Response } from '../types'

export interface DeleteTagToMovieMutationArgs {
    movieID: number,
    tagID: number,
} 
export interface DeleteTagToMovieMutationData {
    DeleteTagToMovie: Response
}

export const DELETE_TAG_TO_MOVIE_MUTATION = gql`
    mutation DeleteTagToMovieMutation( $movieID: Int!, $tagID: Int!) {
        DeleteTagToMovie( movieID: $movieID, tagID: $tagID ) {
           status
           message
        }
    }
`