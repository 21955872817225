import { gql } from '@apollo/client'
import { MovieFileInput, Response } from '../types'

export interface AddMovieFileMutationArgs {
    input: MovieFileInput
} 
export interface AddMovieFileMutationData {
    AddMovieFile: Response
}

export const ADD_MOVIE_FILE_MUTATION = gql`
    mutation AddMovieFileMutation( $input: MovieFileInput! ) {
        AddMovieFile(input: $input) {
            status
            message
        }
    }
`