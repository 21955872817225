import { usePrettyHeight } from 'src/app/hooks/util/usePrettyHeight'
import FullfilledTable from './components/FullfilledTable'
import PendingTable from './components/PendingTable'
import RejectedTable from './components/RejectedTable'
import WaitingTable from './components/WaitingTable'
import { withUploadingTableCard } from './hocs/withUploadingCard'

const CardWrappedWaitingTable = withUploadingTableCard( 
    WaitingTable, 
    'Ожидающие в очереди', 
    'waiting-table',
    'CardWrappedWaitingTable',
);
const CardWrappedPendingTable = withUploadingTableCard( 
    PendingTable, 
    'Активные загрузки',
    'pending-table',
    'CardWrappedPendingTable'
);
const CardWrappedFullfilledTable = withUploadingTableCard(
    FullfilledTable, 
    'Завершенные успешно', 
    'fullfilled-table',
    'CardWrappedFullfilledTable'
);
const CardWrappedRejectedTable = withUploadingTableCard(
    RejectedTable,
    'Завершенные с ошибкой', 
    'rejected-table',
    'CardWrappedRejectedTable'
);

function UplaodingList() {
    const prettyHeight = usePrettyHeight();
    const style = {height: prettyHeight};

    return (
        <div className='uploading-list'>
            <CardWrappedWaitingTable style={style}/>
            <CardWrappedPendingTable style={style}/>
            <CardWrappedFullfilledTable style={style}/>
            <CardWrappedRejectedTable style={style}/>
        </div>
    );
}


export default UplaodingList