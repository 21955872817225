type EditFilesFallbackProps = {
    id: number
}

function EditFilesFallback(props: EditFilesFallbackProps) {
    const { id } = props;
    
    return (
        <div>
            File with id {id} is not serial or film
        </div>
    )
}

export default EditFilesFallback