import React from 'react'
import { useSerialFilesContext } from '../../../contexts/SerialFilesContext'

function SelectedFileHeader() {
    const serialFilesContext = useSerialFilesContext();
    const episode = React.useMemo(
        () => {
            if(serialFilesContext !== null) {
                const activeEpisode = serialFilesContext.getAllEpisodesResult.data.find( e => e.id === serialFilesContext.activeEpisode )
                if(activeEpisode !== undefined) {
                    return activeEpisode.name;
                }
            }

            return null;
        }, 
        [serialFilesContext]
    );

    return <span className='player-collection-title'>
        <span className='ml-1'>{episode}</span> 
    </span>
}

export default SelectedFileHeader