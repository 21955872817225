import { ApolloError, useMutation } from '@apollo/client'
import { AddTVSeriesMutationArgs, AddTVSeriesMutationData, ADD_TV_SERIES_MUTATION } from 'src/api/graphql/mutations/AddTVSeriesMutation'
import { TVSeriesInput } from 'src/api/graphql/types'
import { comment } from 'src/util/comment'

type useAddTVSeriesMutationArgs = {
    completeHandler?: (data: AddTVSeriesMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use AddTVSeries mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useAddTVSeriesMutation(args: useAddTVSeriesMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;

    const [ AddTVSeries ] = useMutation<AddTVSeriesMutationData, AddTVSeriesMutationArgs>(ADD_TV_SERIES_MUTATION, {
        onCompleted: data => {
            if(data) {
                comment('UseAddTVSeriesMutation','Attempting to add', data);
            } else {
                console.error(`Response from UseAddTVSeriesMutation could not be ${data}`, data);
            }

            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function(input: TVSeriesInput ) {
        await AddTVSeries({
            variables: {
                input
            }
        });
    }
}

export default useAddTVSeriesMutation