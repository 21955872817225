import { gql } from '@apollo/client'
import { TapeSize, TapeSort, TapeType } from 'src/api/graphql/types'

export interface InitHomePageFormArgs {} 

export interface InitHomePageFormData {
    InitHomePageForm: {
        Sort: TapeSort[]
        CategoryType: TapeType[]
        ItemSize: TapeSize[]
    }
}

export const INIT_HOME_PAGE_FORM_QUERY = gql`
    query InitHomePageFormQuery {
        InitHomePageForm { 
            Sort {
                Value
                Title
            }
            CategoryType {
                Value
                Title
            }
            ItemSize {
                Value
                Title
            }
        }
    }
`