import { ApolloError } from '@apollo/client'
import useGetAllCategoriesQuery from 'src/app/hooks/query/useGetAllCategories'
import withOptions from 'src/app/components/ContentService/PlainSelect/hocs/withOptions'
import PlainSelect, { Options } from 'src/app/components/ContentService/PlainSelect'

function useCategoriesOptions(): {loading?:boolean, options: Options[], error?: ApolloError} {
    const { loading, data, error } = useGetAllCategoriesQuery();
    const options = data ? data.map( language => ({value: language.id, label: language.name}) ) : [];
    /** Pathed to be unselected */
    options.unshift({ value: 0, label: 'Не выбрано'})

    return { 
        loading, 
        options, 
        error
    };
}

const CategorySelectInput = withOptions(
    useCategoriesOptions, 
    'не удалось загрузить список языков'
)(PlainSelect);

if(process.env.NODE_ENV !== 'production') {
    CategorySelectInput.displayName = 'CategorySelectInput'
}

export default CategorySelectInput
