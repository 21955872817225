import React from 'react'
import { ValueType } from 'react-select'
import Select from 'react-select'
import { CONTENT_TYPES, CONTENT_TYPES_TEXT } from 'src/app/assets/ContentTypes'

type ContentTypeSelectorProps = {
    name: string,
    typeChangeHandler: (value: number) => void,
    value?: number,
    placeholder?: string,
    className?: string,
    onBlur?: React.FocusEventHandler
}

type ContentTypeOptions = { 
    value: number,
    label: string
}
function ContentTypeSelector(props: ContentTypeSelectorProps) {
    const { name, value, placeholder, typeChangeHandler } = props;

    const handleChange = ( option: ValueType<ContentTypeOptions, boolean>) => {
        const { value } =  option as ContentTypeOptions;
        typeChangeHandler(value as number);
    }

    const options = CONTENT_TYPES_TEXT
        .filter(i => i.typeID !== CONTENT_TYPES.SEASON && i.typeID !== CONTENT_TYPES.EPISODE)
        .map( contentType => ({value: contentType.typeID, label: contentType.name}) );

    return (
        <Select
            options={options}
            name={name}
            value={ options.find( option => option.value === value ) }
            placeholder={placeholder}
            onChange={ handleChange }
            // onBlur={onBlur}
        />
    );
}

export default  ContentTypeSelector