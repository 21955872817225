import { gql } from '@apollo/client'
import { Country, CountryInput } from '../types'

export interface UpdateCountryMutationArgs {
    countryID: number,
    input: CountryInput
} 
export interface UpdateCountryMutationData {
    UpdateCountry: Country
}

export const UPDATE_COUNTRY_MUTATION = gql`
    mutation UpdateCountryMutation( $countryID: Int!, $input: CountryInput! ) {
        UpdateCountry(countryID: $countryID, input: $input) {
            id
            name
        }
    }
`