import { useMutation } from '@apollo/client'
import { AddLanguageMutationArgs, AddLanguageMutationData, ADD_LANGUAGE_MUTATION } from 'src/api/graphql/mutations/AddLanguageMutation'
import { Language, LanguageInput } from 'src/api/graphql/types'
import { AddCompleteHandler, AddErrorHandler, AddMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

export function useAddLanguage(completeHandler?: AddCompleteHandler<Language>, errorHandler?: AddErrorHandler): AddMutationHookResult<LanguageInput> {
    const [ AddInitialCallback ] = useMutation<AddLanguageMutationData, AddLanguageMutationArgs>(ADD_LANGUAGE_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.AddLanguage);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const addCallback = async ({name} : {name: string}) => {
        await AddInitialCallback({
            variables: { 
                input: {
                    name,
                    abbreviated: ''
                }  
            }
        });
    }

    return [ addCallback ]
}