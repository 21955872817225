import { useMutation } from '@apollo/client'
import { AddParticipantMutationArgs, AddParticipantMutationData, ADD_PARTICIPANT_MUTATION } from 'src/api/graphql/mutations/AddParticipantMutation'
import { GET_ALL_ACTORS_QUERY } from 'src/api/graphql/queries/getAllActors';
import { GET_ALL_DIRECTORS_QUERY } from 'src/api/graphql/queries/getAllDirectors';
import { GET_ALL_PARTICIPANTS_QUERY } from 'src/api/graphql/queries/getAllParticipants';
import { Participant, ParticipantInput } from 'src/api/graphql/types'
import { AddCompleteHandler, AddErrorHandler, AddMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

export function useAddParticipant(completeHandler?: AddCompleteHandler<Participant>, errorHandler?: AddErrorHandler): AddMutationHookResult<ParticipantInput> {

    const [ AddInitialCallback ] = useMutation<AddParticipantMutationData, AddParticipantMutationArgs>(ADD_PARTICIPANT_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.AddParticipant as Participant);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        },
        refetchQueries: [GET_ALL_PARTICIPANTS_QUERY, GET_ALL_ACTORS_QUERY, GET_ALL_DIRECTORS_QUERY]
    });

    const addCallback = async (input : {name: string }, positionID: number) => {
        await AddInitialCallback({
            variables: { 
                input,
                positionID
            }
        });
    }

    return [ addCallback ]
}