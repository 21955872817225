interface EnteredWrapProps {
    className: string
    children: React.ReactElement[]
}
function EnteredWrap(props: EnteredWrapProps) {
    return <div className={props.className}>
        { props.children }
    </div>
}

export default EnteredWrap