import React from 'react'
import Select, { ValueType } from 'react-select'

export interface PlainSelectProps {
    name: string,
    value: number|string,
    optionChangeHandler: (option: number|string) => void,
    className?: string,
    placeholder?: string,
    disabled?: boolean|undefined,
    onBlur?:  React.FocusEventHandler,
} 

export interface Options {
    value: number|string,
    label: string
}

function PlainSelect(props: PlainSelectProps & {options: Options[]} ) {
    const { options, value, placeholder, name, onBlur, disabled } = props;

    const handleChange = ( option: ValueType<Options, boolean> ) => {
        const { value } = option as Options;
        props.optionChangeHandler(value);
    }

    return (
        <Select
            options={options}
            value={ options.find(option => option.value === value) }
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            onBlur={onBlur}
            onChange={handleChange}
        />
    );
}

export default PlainSelect