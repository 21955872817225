import React from 'react'
import { 
    useMutation, 
    QueryResult, 
    useApolloClient
} from '@apollo/client'
import { 
    AddParticipantMutationArgs, 
    AddParticipantMutationData, 
    ADD_PARTICIPANT_MUTATION 
} from 'src/api/graphql/mutations/AddParticipantMutation'
import { 
    SearchParticipantByNameArgs, 
    SearchParticipantByNameData, 
    SEARCH_PARTICIPANTS_BY_NAME_QUERY 
} from 'src/api/graphql/queries/SearchParticipantByName'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'

type MultipleParticipantsAddHookResult = [
    (multi: string[]) => Promise<unknown>,
    boolean
]

interface SearchParticipantQueryResultProxied extends QueryResult<SearchParticipantByNameData, SearchParticipantByNameArgs> {
    targetName: string
}

export function useAddMultipleParticipants(): MultipleParticipantsAddHookResult {
    const [loading, setLoading] = React.useState(false);
    const [ addCallbackBase ] = useMutation<AddParticipantMutationData, AddParticipantMutationArgs>(ADD_PARTICIPANT_MUTATION);
    const { query: searchQuery } = useApolloClient();

    const addCallback = React.useCallback(async (multipleNames: string[]) => {
        setLoading(true);

        /** Search all names an set result into array and then inject name to results */
        const searchResultRaw = await Promise.allSettled(
            multipleNames.map( (name, index) => {
                return searchQuery<SearchParticipantByNameData, SearchParticipantByNameArgs>({
                    query: SEARCH_PARTICIPANTS_BY_NAME_QUERY,
                    variables: { 
                        name 
                    },
                    fetchPolicy: 'network-only'
                })
                .then( result => new Proxy(result, {
                    get(target, prop) {
                        if(prop === 'targetName') {
                            return name;
                        }
                        return target[prop];
                    }
                }));
                   
            })
        ) as PromiseSettledResult<SearchParticipantQueryResultProxied>[];

        /** Filtered fulfilled, then transform to meta result and filter unique ones */
        const searchResultRawFullfilled = searchResultRaw.filter(result => result.status === 'fulfilled') as PromiseFulfilledResult<SearchParticipantQueryResultProxied>[];
        const searchResultUnique = searchResultRawFullfilled.map( result => {
            const otherParticipants = result.value.data ? result.value.data.SearchParticipantByName : [];
            const isUnique = !otherParticipants.some(
                other => sanitazeSpace(other.name) === sanitazeSpace(result.value.targetName)
            );
            return {
                isUnique,
                value: result.value.targetName
            }
        }).filter(result => result.isUnique);

        /** Add all unique data*/
        const addParticipantResultRaw = await Promise.allSettled(
            searchResultUnique.map( 
                result => addCallbackBase({
                    variables: {
                        positionID: PARTICIPANT_TYPES.ACTOR,
                        input: {
                            name: result.value
                        }
                    }
                })
            )
        );

        setLoading(false);

        return addParticipantResultRaw;
    }, [searchQuery, addCallbackBase]);

    return [addCallback, loading];
}

export const sanitazeSpace = (text: string) => {
    return text.replaceAll(/\s/g, '')
}