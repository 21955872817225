import React from 'react'
import { Language, LanguageInput, Selector, SelectorInput, Tag, TagStatus } from 'src/api/graphql/types'
import SVG  from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { compareForInclude } from '../../helpers/SelectorUIHelpers'
import NameColumnRenderer from '../components/NameColumnRenderer'
import { SelectorConfigContextValue, SelectorParams } from '../contexts/SelectorConfigContext'
import TextEditRenderer from '../components/TextEditRenderer'
import { useMutation } from '@apollo/client'
import { ChangeStatusTagMutationArgs, ChangeStatusTagMutationData, CHANGE_STATUS_TAG } from 'src/api/graphql/mutations/ChangeStatusTag'
import { GET_ALL_TAGS_QUERY } from 'src/api/graphql/queries/getAllTags'

/**
 * Transform text to lower case and trim unneed spaces
 * @param input text to process
 * @returns processed text
 */
const clear = (input: string): string => {
  return input.toLowerCase().trim();
}

/**
 * Check is searched word (target) similar to source word (target)
 * @param source Original string to compare
 * @param target compared string
 * @param ratio accurasy ratio
 * @returns is target similar to target 
 */
const hasSimilarity = (source: string, target: string, ratio: number): boolean => {
  const clearSource = clear(source);
  const clearTarget = clear(target);
  let matches = 0;
  if (clearSource.indexOf(clearTarget) > -1) return true; // covers basic partial matches
  for (let i = 0; i < clearTarget.length; i++) {
    clearSource.indexOf(clearTarget[i]) > -1 ? matches += 1 : matches -=1;
  }
  return (matches/source.length >= ratio || target === '')
};

/**
 * Search util for finding selectors that matches the provided text
 * @param data Selector or Language type array 
 * @param text text to compare
 * @returns finded elements array or empty array
 */
export const search = (data: Selector[]|Language[]|undefined, text: string) => {
  if(data) {
    return data.filter(d => hasSimilarity(d.name, text, 0.75));
  }
  return [];
}

/**
 * Check is the input text in Selector array
 * @param data Selector or Language type arrat
 * @param input word to check
 * @returns Has or not input text in data array of Selectors
 */
export const hasUniqueInput = (data: Selector[]|Language[]|undefined, input: string): boolean => {
  if(data) {
    const finded = data.filter( d => compareForInclude(d.name, input) );
    return finded.length === 0
  }

  return false;
}
// Formatters--------------------------------------
export function ActionsColumnFormatter(
  cellContent: any,
  row: Selector,
  rowIndex: number,
  { deleteSelector }: { deleteSelector: (id: number) => void }
) {
  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    deleteSelector(row.id)
  }

  return (
    <>
      <button
        title='Удалить'
        className='btn btn-icon btn-light btn-hover-danger btn-sm'
        onClick={clickHandler}
      >
        <span className='svg-icon svg-icon-md svg-icon-danger'>
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
        </span>
      </button>
    </>
  );
}

export function NameColumnFormatter(
  cellContent: string,
  row: Selector,
  rowIndex: number,
  extraData: SelectorConfigContextValue<SelectorParams, SelectorInput, Selector>,
) {
  
  const { pendingUpdates } = extraData;

  return (
    <NameColumnRenderer pendingUpdates={pendingUpdates} content={cellContent} id={row.id}/>
  );
}

export function LanguageNameColumnFormatter(
  cellContent: string,
  row: Selector,
  rowIndex: number,
  extraData: SelectorConfigContextValue<SelectorParams, LanguageInput, Language>,
) {
  
  const { pendingUpdates } = extraData;

  return (
    <NameColumnRenderer pendingUpdates={pendingUpdates} content={cellContent} id={row.id}/>
  );
}

export function AbbreviateColumnFormatter(
  cellContent: string,
  row: Selector,
  rowIndex: number,
  extraData: SelectorConfigContextValue<SelectorParams, LanguageInput, Language>,
) {
  
  const { pendingUpdates } = extraData;

  return (
    <NameColumnRenderer pendingUpdates={pendingUpdates} content={cellContent} id={row.id}/>
  );
}

export function OrderColumnFormatter(cellContent: any, row: Selector, rowIndex: number) {
  return (
    <span className='unselectable'>
      { rowIndex + 1 }
    </span>
  );
}

export function IdColumnFormatter(cellContent: number, row: Selector, rowIndex: number) {
  return (
    <span className='unselectable label label-lg label-light label-inline'>
      { cellContent }
    </span>
  );
}
// Formatters--------------------------------------

// Validators--------------------------------------
export const NameColumnValidator = (newValue:string, row: Selector, column:any) => {
  if(newValue.length < 3) {
    console.log('validator', column)
    return {
      valid: false,
      message: 'Название должно быть 3 или более символов'
    }
  }
  return true;
}
export const AbbreviateColumnValidator = (newValue:string, row: Selector, column:any) => {
  if(newValue.length < 2) {
    console.log('validator', column)
    return {
      valid: false,
      message: 'Название должно быть 2 или более символов'
    }
  }
  return true;
}
// Validators--------------------------------------

// Edit Renderers----------------------------------
export const NameColumnEditorRenderer = (
  props: any, 
  value: string, 
  row: Selector, 
  column: string, 
  rowIndex: number, 
  columnIndex: number
) => {
  return <TextEditRenderer {...props} value={value} initialValue = {row.name}/>;
}

export const AbbreviateColumnEditorRenderer = (
  props: any, 
  value: string, 
  row: Selector, 
  column: string, 
  rowIndex: number, 
  columnIndex: number
) => {
  return <TextEditRenderer {...props} value={value} initialValue = {row.name}/>;
}
// Edit Renderers----------------------------------

export const ActivateTagActionColumnFormatter = (
  cellContent: TagStatus,
  row: Tag,
  rowIndex: number,
) => {
  return <ActivateTagActionColumnRenderer tag={row}/>
}

function ActivateTagActionColumnRenderer(props: {tag: Tag}) {
  const [trigger] = useMutation<ChangeStatusTagMutationData, ChangeStatusTagMutationArgs>(CHANGE_STATUS_TAG, {
    refetchQueries: [GET_ALL_TAGS_QUERY]
  });

  const spanClassName = props.tag.status === TagStatus.ACTIVE 
  ? 'svg-icon svg-icon-md svg-icon-success'
  : 'svg-icon svg-icon-md svg-icon-danger';
  
  return (
    <button
      title='Удалить'
      className={'btn btn-icon btn-light btn-success btn-sm'}
      onClick={() => trigger({
        variables: {
          tagID: props.tag.id,
          status: props.tag.status === TagStatus.ACTIVE ? TagStatus.INACTIVE : TagStatus.ACTIVE
        }
      })}
    >
      <span className={spanClassName}>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} />
      </span>
    </button>
  );
}