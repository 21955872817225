import ProcessingItem from './ProcessingItem'

interface ProcessingListProps {
    list: string[]
}

function ProcessingList(props: ProcessingListProps) {
    return <ul className='processing-list'>
        <h5 className='title'>Список Активных</h5>
        {
            props.list.map( (value, index) => 
                <ProcessingItem key={`${index}-${value}`} value={value}/>
            ) 
        }
    </ul>
}

export default ProcessingList