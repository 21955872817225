import BasicModal from '../../../BasicModal'
import EpisodeEditModalBody from './components/EpisodeEditModalBody'

type EpisodeEditModalProps = {
    episodeID: number,
    show: boolean,
    onHide: () => void
}

function EpisodeEditModal(props: EpisodeEditModalProps) {
    const { show, onHide, episodeID } = props;

    return (
        <BasicModal show={show} onHide={onHide}>
            <EpisodeEditModalBody episodeID={episodeID} closeCallback={onHide}/>
        </BasicModal>
    );
}

export default EpisodeEditModal