import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { FilmFilterFormValues } from '../components/FilmsFilter'
import { createFilmsRouteLink } from '../utils/createFilmsRouteLink'
import { defaultMovieQueryParams } from '../utils/defaultParams'

export function useFilterChangehandler() {
    const history = useHistory();
    const { search } = useLocation();

    const filterChangeHandler = React.useCallback((payload: FilmFilterFormValues) => {
        const { tagID, genreID, searchText } = payload;

        const searchParams = new URLSearchParams(search);
        /** pre process */
        searchParams.set('pageNumber', String(defaultMovieQueryParams.pageNumber));

        /** process */
        searchParams.set('tagID', String(tagID));
        searchParams.set('genreID', String(genreID));
        searchParams.set('searchText', searchText);

        /** post process */
        if(searchText === '') {
            searchParams.delete('searchText');
        }

        history.push(
            createFilmsRouteLink(searchParams)
        );
    }, [history, search]);

    return filterChangeHandler;
}