import { AddPayload } from 'src/uploader/types'

export function extractPayload(serializedFileName: string): AddPayload|null {
    const addPayload = JSON.parse(serializedFileName);

    if(isAddPayload(addPayload)) return addPayload;
    else return null;
}

function isAddPayload(x: unknown): x is AddPayload {
    const test = x as AddPayload;
    return 'movieID' in test && 'uploadType' in test && 'name' in test && 'title' in test;
}