import React from 'react'
import UploadImageCard from 'src/app/components/UploadImageCard'
import ImageUploader from 'src/app/components/ContentService/ImageUploader'
import { uploadImageHorizontal, uploadImageHorizontalRaw, uploadImageTitle, uploadImageVertical } from 'src/api/rest/post/upload/uploadImage'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { Image, ImageSize } from 'src/api/graphql/types'
import { extractSrcFromImage, createSrcFromImageSize } from './helpers/createSrcFrom'
import { useBindedUploader } from './hooks/useBindedUploader'
import { useBindedRemover } from './hooks/useBindedRemover'
import { removeImageHorizontalRaw, removeImageTitle, removeImageVertical } from 'src/api/rest/post/remove/removeImage'

export interface ImageStandartSize extends Pick<ImageSize, 'standard'> {}

type ImagesCollection = {
    vertical: ImageStandartSize|null,
    horizontal: ImageStandartSize|null,
    horizontalRaw: ImageStandartSize|null,
    title: Image|null
}

function EditImageCollection() {
    const editContext = useContentEditContext();
    const images = React.useMemo<Partial<ImagesCollection>>(() => {
        return {
            vertical: editContext.images.Vertical,
            horizontal: editContext.images.HorizontalWithName,
            horizontalRaw: editContext.images.HorizontalWithoutName,
            title: editContext.images.Name
        }
    }, [editContext]);

    // Binded uploader instances to id defined in context
    const uploaderVertical = useBindedUploader(editContext, uploadImageVertical);
    const uploaderHorizontal = useBindedUploader(editContext, uploadImageHorizontal);
    const uploaderHorizontalRaw = useBindedUploader(editContext, uploadImageHorizontalRaw);
    const uploaderTitle = useBindedUploader(editContext, uploadImageTitle);

    // Binded remover instances to id defined in context
    const removerVertical = useBindedRemover(editContext, removeImageVertical);
    const removerHorizontal = useBindedRemover(editContext, removeImageHorizontalRaw);
    const removerHorizontalRaw = useBindedRemover(editContext, removeImageHorizontalRaw);
    const removerTitle = useBindedRemover(editContext, removeImageTitle);


    const { vertical, horizontal, horizontalRaw, title } = images;

    return (
        <div className='edit-image-collection'>
            <UploadImageCard.VerticalImageCard
                uploader={uploaderVertical}
                src={createSrcFromImageSize(vertical)}
                remover={removerVertical}
            >
                {ImageUploader.VerticalImageUploader}
            </UploadImageCard.VerticalImageCard>

            <UploadImageCard.HorizontalImageCard
                uploader={uploaderHorizontal}
                src={createSrcFromImageSize(horizontal)}
                remover={removerHorizontal}
            >
                {ImageUploader.HorizontalImageUploader}
            </UploadImageCard.HorizontalImageCard>

            <UploadImageCard.HorizontalRawImageCard
                uploader={uploaderHorizontalRaw}
                src={createSrcFromImageSize(horizontalRaw)}
                remover={removerHorizontalRaw}
            >
                {ImageUploader.HorizontalImageUploader}
            </UploadImageCard.HorizontalRawImageCard>

            <UploadImageCard.LogoImageCard
                uploader={uploaderTitle}
                src={extractSrcFromImage(title)}
                remover={removerTitle}
            >
                {ImageUploader.TitleImageUploader}
            </UploadImageCard.LogoImageCard>
        </div>
    );
}

export default EditImageCollection
