import { Participant } from 'src/api/graphql/types'

export const isUniqueName = (participants: Participant[], searchText: string): boolean => {
    const sanitizedSearchText = terminateSpaces(searchText);

    if(sanitizedSearchText.length === 0) return false;
    if(participants.length === 0) return true;

    return participants.every( p => terminateSpaces(p.name) !== sanitizedSearchText );
}

const terminateSpaces = (text: string) => text.replaceAll(/\s/g, '');