import { useQuery } from '@apollo/client'
import { GetMovieByIdArgs, GetMovieByIdData, GET_MOVIE_BY_ID_QUERY } from 'src/api/graphql/queries/getMoviesById'

/**
 * Hook for getMovieById query 
 * @param {id} 
 * @returns data formatted below
 * { id
    typeId
    name
    release
    ratingKP
    ratingIMDB
    language {
        id
        name
        abbreviated
    }
    Age,
    description,
    genres {
        id
        name
    }
    tags {
        id
        name
    }
 *}
 */
function useGetMovieByIdQuery( {id}: {id: number} ){
    const {loading, data, error} = useQuery<GetMovieByIdData, GetMovieByIdArgs>(GET_MOVIE_BY_ID_QUERY, {
        variables: {
            id
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });
    return {
        loading,
        data,
        error
    }
}

export default useGetMovieByIdQuery