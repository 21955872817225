import CustomContainer from 'src/app/components/CustomContainer'
import ContentEditProvider from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import EditBasicInfo from 'src/app/components/ContentService/EditContent/components/EditBasicInfo'
import EditSelectors from 'src/app/components/ContentService/EditContent/components/EditSelectors'
import { useParams } from 'react-router-dom'
import { LANGUAGES } from 'src/app/assets/Languages'
import useGetMovieByIdQuery from 'src/app/hooks/query/useGetMovieById'
import { CATEGORIES } from 'src/app/assets/Categories'
import { formatToSelectorEditField } from './helpers/EditContentHelpers'
import EditImages from './components/EditImages'
import EditFiles from './components/EditFiles'
import { Actor, Director, MovieImage } from 'src/api/graphql/types'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { Spinner } from 'react-bootstrap'
import { roundNumber } from 'src/util/roundNumber'

type EditContentRouteParams = {
    typeId: string,
    contentId: string,
}

function EditContent() {
    const params = useParams<EditContentRouteParams>();
    // Currently it is unnecessary to fetch initial info depending on type, just ID is enought
    const {loading, data, error } = useGetMovieByIdQuery( {id: Number(params.contentId)} );
    
    // Placeholder for loading case
    const placeholder = {
        // basic
        id: Number(params.contentId),
        typeId: Number(params.typeId),
        name: '',
        age: 0, 
        status: ACTIVATION_STATUS.INACTIVE,
        duration: 0,
        release: '', 
        ratingKP: 0,
        ratingIMDB: 0,
        description: '',
        trailerUrl: '',
        language: {
            id: LANGUAGES.RU,
            name: 'Русский',
            abbreviated: 'RU'
        },
        category: {
            id: CATEGORIES.FILM,
            name: 'Фильм'
        },
        // image
        image: {} as Partial<MovieImage>,
        // selectors
        genres: [],
        tags: [],
        countries: [],
        actors: [] as Actor[],
        directors: [] as Director[]
    }

    // Dynamically passing values depending on do data exist or don't 
    const { 
        typeId, 
        id, 
        name, 
        age, 
        status,
        duration,
        release, 
        ratingKP, 
        ratingIMDB, 
        trailerUrl,
        description, 
        language, 
        category, 
        genres, 
        tags,
        countries,
        image,
        actors,
        directors
    } = data ? data.getMovieById : placeholder;

    // Initial values for context
    const initialInfo = {
        id,
        typeId,
        status,
        duration,
        trailerUrl,
        basicInfo: { 
            name,
            age, 
            release, 
            ratingKP: roundNumber(ratingKP, 2),
            ratingIMDB: roundNumber(ratingIMDB, 2),
            description,
            language: language.id,
            category: category.id,
        },
        selectors: {
            // Formated to valid EditSelectorForm format to SelectorEditField
            genres: formatToSelectorEditField(genres),
            tags:   formatToSelectorEditField(tags),
            countries: formatToSelectorEditField(countries),
            actors: formatToSelectorEditField(actors),
            directors: formatToSelectorEditField(directors),
             // Not implemented yet by backend
            languages: []
        },
        images: {
            ...image
        }
    };

    if(loading) {
        return <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
            <Spinner animation='grow' variant='primary'/>
        </div>;
    }

    if(error) {
        // TODO: Change it with more elegent & preferable Error fallback
        return <div>Error for reason {error.message} </div>
    }

    return (
        <ContentEditProvider initialInfo={initialInfo} loading={loading}>
            <CustomContainer className='content-add-container'>
                <EditBasicInfo />
                <EditSelectors/>
                <EditImages/>
                <EditFiles/>
            </CustomContainer>
        </ContentEditProvider>
    );
}

export default EditContent