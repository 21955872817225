import { useLazyQuery } from '@apollo/client'
import { IsHaveMovieFileArgs, IsHaveMovieFileData, IS_HAVE_MOVIE_FILE_QUERY } from 'src/api/graphql/queries/isHaveMovieFile'

const mySuccessEventType: "HaveMovieFileSuccess" = "HaveMovieFileSuccess";
const myErrorEventType: "HaveMovieFileError" = "HaveMovieFileError";

class HaveMovieFileSuccessEvent extends CustomEvent<HaveMovieFileSuccessEventDetail> {
    constructor(detail: HaveMovieFileSuccessEventDetail) {
        super(mySuccessEventType, { bubbles: true, detail });
    }
}

interface HaveMovieFileSuccessEventDetail {
    have: boolean;
}

class HaveMovieFileErrorEvent extends CustomEvent<HaveMovieFileErrorEventDetail> {
    constructor(detail: HaveMovieFileErrorEventDetail) {
        super(myErrorEventType, { bubbles: true, detail });
    }
}

interface HaveMovieFileErrorEventDetail {
    message: string,
}

// augment your global namespace
// Augmenting 'WindowEventMap' from 'lib.dom.d.ts'
declare global {
    interface WindowEventMap {
        [mySuccessEventType]: HaveMovieFileSuccessEvent,
        [myErrorEventType]: HaveMovieFileErrorEvent
    }
}

export function useIsHaveMovieFileLazyQuery(movieID: number) {
    const [movieExistCheckCallback] = useLazyQuery<IsHaveMovieFileData, IsHaveMovieFileArgs>(IS_HAVE_MOVIE_FILE_QUERY, {
        onCompleted: data => {
            window.dispatchEvent(
                new HaveMovieFileSuccessEvent({ 
                    have: data.IsHaveMovieFile
                })
            );
        },
        onError: error => {
            window.dispatchEvent(
                new HaveMovieFileErrorEvent({ 
                    message: error.message
                })
            );
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });

    /*  Here alternative way for solution:
        return new Promise((res, rej) => {
            movieExistCheckCallback({
                variables: {
                    movieID
                }
            });

            const { error, data } = movieExistCheckQueryResult;

            const stopInterval = setInterval(() => {
                if(data) {
                    setTimeout(() => res(data) )
                    clearInterval(stopInterval)
                } 
                if(error) {
                    setTimeout(() => rej(error) )
                    clearInterval(stopInterval)
                } 
            })
        })
    */

    return () => {
        movieExistCheckCallback({
            variables: {
                movieID
            }
        });

        return new Promise<boolean|{message: string}>((resolve, reject ) => {
            window.addEventListener(mySuccessEventType, function successHandler(e) {
                if(isHaveMovieFileSuccessEvent(e))
                    resolve(e.detail.have);
                window.removeEventListener(mySuccessEventType, successHandler);
            });

            window.addEventListener(myErrorEventType, function errorHandler(e) {
                if(isHaveMovieFileErrorEvent(e))
                    reject(e.detail);
                window.removeEventListener(myErrorEventType, errorHandler);
            });
        });
    }
}

// Type guard for HaveMovieFileSuccessEvent
function isHaveMovieFileSuccessEvent(x: unknown): x is HaveMovieFileSuccessEvent {
    if(x instanceof HaveMovieFileSuccessEvent){
        return true;
    }

    throw new Error('Argument is not type of HaveMovieFileSuccessEventDetail');
}

// Type guard for HaveMovieFileErrorEvent
function isHaveMovieFileErrorEvent(x: unknown): x is HaveMovieFileSuccessEvent {
    if(x instanceof HaveMovieFileErrorEvent){
        return true;
    }

    throw new Error('Argument is not type of HaveMovieFileSuccessEventDetail');
}