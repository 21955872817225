import { gql } from '@apollo/client'
import { Category } from '../types'

export interface GetAllCategoriesArgs {} 

export interface GetAllCategoriesData {
    getAllCategories: Category[]
}

export const GET_ALL_CATEGORIES_QUERY = gql`
    query GetAllCategories {
        getAllCategories {
            id
            name
        }
    }
`