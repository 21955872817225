import { useMemo } from 'react'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import { AbonentFilter, ListUIContextValue, useListUIContext } from 'src/app/contexts/ListUIContext'
import SelectorsListSelect from 'src/app/components/ContentService/SelectorsListSelect'
import useGetAllRegionsQuery  from 'src/app/hooks/query/useGetAllRegions'
import ListSearchInput from 'src/app/components/ContentService/ListSearchInput'
import { AbonentsParams, HOST_ACTIONS } from './../..'

type AbonentsFilterProps = {
  listLoading: boolean
}

const prepareFilter = (queryParams: AbonentsParams, values: AbonentFilter) => {
  const { regionID, searchText, searchPhone } = values;
  const newQueryParams = { ...queryParams };

  newQueryParams.filter = {searchText: '', searchPhone: '', regionID};
  newQueryParams.actionType = HOST_ACTIONS.FILTER;

  if(values.regionID === 0) {
    newQueryParams.actionType = HOST_ACTIONS.GET;
  }

  // Disable other filters when using searchText
  if (searchPhone.length > 0) {
    newQueryParams.filter.regionID  = 0;
    newQueryParams.filter.searchPhone = searchPhone;
    newQueryParams.actionType = HOST_ACTIONS.SEARCH_BY_PHONE;
  }

  // Disable other filters when using searchText
  if (searchText.length > 0) {
    newQueryParams.filter.regionID  = 0;
    newQueryParams.filter.searchText = searchText;
    newQueryParams.actionType = HOST_ACTIONS.SEARCH_BY_IP;
  }
  
  return newQueryParams;
};

const initialFilterValues: AbonentFilter = {
  searchText: '',
  searchPhone: '',
  regionID: 0
}

export function AbonentsFilter(props: AbonentsFilterProps) {
  const {listLoading} = props;
  /**
   * getAllregions and getAllTags queries
   */
  const regionsQueryResult = useGetAllRegionsQuery();

  // Customers UI Context
  const AbonentsUIContextValue = useListUIContext() as ListUIContextValue<AbonentsParams<AbonentFilter>>;
  const AbonentsUIProps = useMemo(() => {
    return {
      queryParams: AbonentsUIContextValue.queryParams,
      setQueryParams: AbonentsUIContextValue.setQueryParams,
    };
  }, [AbonentsUIContextValue]);

  // queryParams, setQueryParams,
  const applyFilter = (values: AbonentFilter) => {
    const newQueryParams = prepareFilter(AbonentsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, AbonentsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      AbonentsUIProps.setQueryParams && AbonentsUIProps.setQueryParams(newQueryParams);
    }
  };

  /**
   * regions and tags array that a added into them {id: 0, name: 'All'} object for default value
   */
  const regions = regionsQueryResult.data ? [ {id: 0, name: 'Все регионы'}, ...regionsQueryResult.data ] : [{id: 0, name: 'Все регионы'}];

  /**
   * regions and Tags select element's loading indicators
   */
  const genreFieldLoading = listLoading || regionsQueryResult.loading;
  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values, helpers) => {          
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          getFieldProps
        }) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              <div className='col-lg-2'>
                <SelectorsListSelect
                  selectors={ regions }
                  disabled={genreFieldLoading}
                  className='form-control'
                  name='regionID'
                  placeholder='Filter by Genre'
                  // TODO: Change this code
                  onChange={(e: any) => {
                    setFieldValue('regionID', +e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.regionID}
                />
                <small className='form-text text-muted'>
                  Отфильтровать по <b>Региону</b> 
                </small>
              </div>
              
              <div className='col-lg-2'>
                <ListSearchInput
                  field={ getFieldProps('searchText') }
                  className='form-control'
                  name='searchText'
                  placeholder='Введите IP-адрес'
                  onBlur={handleBlur}
                  value={values.searchText}
                  changeHandler={(value: string) => {
                    setFieldValue('searchText', value);
                  }}
                  submitHandler={handleSubmit}
                />
                <small className='form-text text-muted'>
                  <b>Поиск</b> по абонентам
                </small>
              </div>

              <div className='col-lg-2'>
                <ListSearchInput
                  field={ getFieldProps('searchPhone') }
                  className='form-control'
                  name='searchPhone'
                  placeholder='Введите номер телефона'
                  onBlur={handleBlur}
                  value={values.searchPhone}
                  changeHandler={(value: string) => {
                    setFieldValue('searchPhone', value);
                  }}
                  submitHandler={handleSubmit}
                />
                <small className='form-text text-muted'>
                  <b>Поиск</b> по номеру телефона
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
