import React, { useContext, useState } from 'react'
import { MovieImage, MovieInput } from 'src/api/graphql/types'
import { isEqual, isFunction } from 'lodash'
import { OptionTypeBase } from 'react-select'
import useUpdateMovieMutation from 'src/app/hooks/mutation/useUpdateMovie'
import { UpdateMovieMutationArgs, UpdateMovieMutationData } from 'src/api/graphql/mutations/UpdateMovieMutation'
import { FetchResult } from '@apollo/client'
import useAddSelectorsToMovieMutation, { AddSelectorsResult, SelectorsAddArgs } from 'src/app/hooks/mutation/useAddSelectorsToMovie'
import useUpdateTVSeriesMutation from 'src/app/hooks/mutation/useUpdateTVSeries'
import { UpdateTVSeriesMutationArgs, UpdateTVSeriesMutationData } from 'src/api/graphql/mutations/UpdateTVSeriesMutation'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'

/**
 * types for EditSelectors form
 */
export interface SelectorEditField extends OptionTypeBase {
    value: number, 
    label: string
}
export interface SelectorEditForm {
    countries: SelectorEditField[],
    genres: SelectorEditField[],
    tags: SelectorEditField[],
    actors: SelectorEditField[],
    directors: SelectorEditField[],
    languages: SelectorEditField[]
}

/**
 * types for EditBasicInfo form
 * Exteneded from MovieInput because TVSeriesInput has 
 * same signatures
 */
export interface BasicInfoEditForm extends Omit<MovieInput, 'trailerUrl'> {}

/**
 * State of context provider
 * type - type of content
 * basicInfo - Major information about film or serial
 * selectors - object containing array of selectors as field
 * images - array of image files
 * files - would be file list or other info
 */
export interface ContentEditProviderState {
    id: number,
    typeId: number,
    status: ACTIVATION_STATUS,
    duration: number,
    basicInfo: BasicInfoEditForm,
    selectors: SelectorEditForm,
    images: Partial<MovieImage>,
    trailerUrl: string
}

/**
 * Props of the context provider
 */
export interface ContentEditProviderProps extends ReactMultipleContainer {
    initialInfo: ContentEditProviderState,
    loading: boolean,
} 

/**
 * Context value type
 */
export interface ContentEditContextValue extends ContentEditProviderState {
    setInfo: React.Dispatch<React.SetStateAction<ContentEditProviderState>>
    updateMovieBasicInfo?: ( { movieID, input }: UpdateMovieMutationArgs) => Promise<FetchResult<UpdateMovieMutationData>>,
    updateTVSeriesBasicInfo?: ( { seriesID, input }: UpdateTVSeriesMutationArgs ) =>  Promise<FetchResult<UpdateTVSeriesMutationData>>,
    updateSelectors?: (args: SelectorsAddArgs) =>  Promise<AddSelectorsResult>
}

/**
 * interfaces for different variants of UIProps data
 */
export type EditUIProps = EditBasicInfoUIProps | EditSelectorsUIProps | EditImagesUIProps  
export interface EditBasicInfoUIProps {
    id: number,
    typeId: number,
    basicInfo:  BasicInfoEditForm,
    setInfo:  React.Dispatch<React.SetStateAction<ContentEditProviderState>>,
    updateMovieBasicInfo?: ( { movieID, input }: UpdateMovieMutationArgs ) =>  Promise<FetchResult<UpdateMovieMutationData>>
    updateTVSeriesBasicInfo?: ( { seriesID, input }: UpdateTVSeriesMutationArgs ) =>  Promise<FetchResult<UpdateTVSeriesMutationData>>
}
export interface EditSelectorsUIProps {
    id: number,
    selectors:  SelectorEditForm,
    setInfo:  React.Dispatch<React.SetStateAction<ContentEditProviderState>>
    updateSelectors?: (args: SelectorsAddArgs) =>  Promise<AddSelectorsResult>
}
export interface EditImagesUIProps extends Pick<ContentEditProviderState,'images'> {}

/**
 * Wrappes passed children with ContentEdit Context providing childrens with UI props
 * @param props contains children, loading state and initialInfo to initialization
 * @returns React JSX element wrapped by context
 */
function ContentEditProvider(props: ContentEditProviderProps): React.ReactElement {
    const { children, initialInfo } = props;
    const [info, setInfoBase] = useState<ContentEditProviderState>(initialInfo);

    const updateMovieBasicInfo = useUpdateMovieMutation();
    const updateTVSeriesBasicInfo = useUpdateTVSeriesMutation();
    const updateSelectors = useAddSelectorsToMovieMutation();

    /**
     * Method to pass 'info' state new values
     * from children component's formik context's 
     * onSubmit succesful execution
     */
    const setInfo = React.useCallback( nextInfo => {
        setInfoBase( prevInfo => {
            if (isFunction(nextInfo)) {
                nextInfo = nextInfo(prevInfo);
            }
    
            if (isEqual(prevInfo, nextInfo)) {
                return prevInfo;
            }
    
            return nextInfo;
        })
    }, [setInfoBase]);
    
    /**
     * Context value
     */
    const value = {
        id: info.id,
        typeId: info.typeId,
        status: info.status,
        trailerUrl: info.trailerUrl,
        duration: info.duration,
        basicInfo: info.basicInfo,
        selectors: info.selectors,
        images: info.images,
        setInfo,
        updateMovieBasicInfo,
        updateTVSeriesBasicInfo,
        updateSelectors,
    };

    return (
        <ContentEditContext.Provider value={value}>
            { children }
        </ContentEditContext.Provider>
    )
}

const ContentEditContext = React.createContext<ContentEditContextValue|undefined>(undefined);
const useContentEditContext = () => {
    const contentEditContext = useContext(ContentEditContext);
    if(contentEditContext !== undefined) return contentEditContext;
    else throw new Error('ContentEdit context could not be undefined when calling inside of calling component')
}

export default ContentEditProvider
export { useContentEditContext }