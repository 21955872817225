export function isErrorsArray(x: unknown): x is Array<Error> {
    if(x instanceof Array) {
        return x.every(isError);
    }

    return false;
}

function isError(x: unknown): x is Error {
    return x instanceof Error;
}