import CountriesConfig from './components/CountriesConfig'
import GenresConfig from './components/GenresConfig'
import LanguagesConfig from './components/LanguagesConfig'
import TagsConfig from './components/TagsConfig'

function SelectorsConfig() {
    return(
        <div className='selectors-config'>
            <GenresConfig/>
            <TagsConfig />
            <CountriesConfig/>
            <LanguagesConfig/>
        </div>
    )
}

export default SelectorsConfig