import React from 'react'
import { useQuery } from '@apollo/client'
import { GetAllCategoriesData, GetAllCategoriesArgs, GET_ALL_CATEGORIES_QUERY } from 'src/api/graphql/queries/getAllCategories'

export function useCategories() {
    const { loading, data } = useQuery<GetAllCategoriesData, GetAllCategoriesArgs>(GET_ALL_CATEGORIES_QUERY);
    
    const categories = React.useMemo(() => {
        if(data !== undefined) {
            return {
                loading,
                categories: data.getAllCategories
            };
        }

        return {
            loading,
            categories: []
        };
    }, [data, loading]);

    return categories;
}