export function isErrorArray(x: unknown): x is Error[] {
    if(isArray(x)) {
        return x.every(isError);
    }
    return false;
}

function isArray(x: unknown): x is Array<any> {
    return x instanceof Array;
}

function isError(x: unknown): x is Error {
    return x instanceof Error;
} 