import React from 'react'
import { ApolloError } from '@apollo/client'
import { Options, PlainSelectProps } from '..'

type OptionsInjectedComponent = (Wrappable: React.FC<PlainSelectProps & {options: Options[]}>) => React.FC<PlainSelectProps>
type useOptionsHookResult = {loading?:boolean, options: Options[], error?: ApolloError}

function withOptions( useOptionsHook: () => useOptionsHookResult, errorMessage: string): OptionsInjectedComponent {
    return (Wrappable: React.FC<PlainSelectProps & {options: Options[]}>) => {
        const OptionsInjectedSelect: React.FC<PlainSelectProps> = (props: PlainSelectProps) => {
            const { loading, options, error } = useOptionsHook();
    
            if(error) {
                return <div>{errorMessage}</div>
            }
    
            return (
                <Wrappable {...props} options={options} disabled={loading}/>
            );
        }
    
        if(process.env.NODE_ENV === 'production') {
            OptionsInjectedSelect.displayName = 'OptionsInjectedSelect'
        }
    
        return OptionsInjectedSelect;
    }
}

export default withOptions