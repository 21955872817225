import { gql } from '@apollo/client'
import { Episode } from '../types'

export interface GetAllEpisodesArgs {
    parentID: number
} 

export interface GetAllEpisodesData {
    GetAllEpisodes: Episode[]
}

export const GET_ALL_EPISODES_QUERY = gql`
    query GetAllEpisodesQuery($parentID: Int!) {
        GetAllEpisodes(parentID: $parentID) {
            id
            name
            duration
            status
            parentID
        }
    }
`