import React from 'react'
import { FormikErrors, useFormik } from 'formik'
import * as Yup from 'yup'
import { useAddSeasonMutation } from '../hooks/useAddSeasonMutation'
import { useSerialFilesContext } from '../../../../../contexts/SerialFilesContext'
import { SeasonInput } from 'src/api/graphql/types'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import  DatePicker  from 'react-datepicker'
import { getFieldCSSClasses } from 'src/app/components/ContentService/AddContent/AddBasicInfo/helpers/AddBasicInfoHelpers'
import useEscapeCloseListener from '../../../../../hooks/useEscapeCloseListener'
import { isNull } from 'src/util/isNull'

type SeasonAddModalBodyProps = {
    closeCallback: () => void,
}

type AddSeasonForm = {
    order: number|undefined,
    name: string,
    release: string|undefined,
}

const validationSchema = Yup.object({
    order: Yup
        .number()
        .min(1, 'Номер сезона должно быть не меньше 1-го')
        .required('Номер сезона не должен быть пустым'),
    name: Yup
        .string()
        .min(3, 'Название сезона должно состоять минимум из 3-х символов')
        .required('Название сезона не должно быть пустым'),
    release: Yup
        .string()
});

const customValidator = (values: AddSeasonForm): FormikErrors<AddSeasonForm> => {
    const error:FormikErrors<AddSeasonForm> = {};
    
    if(values.release) {
        const today = new Date();
        const release = new Date(values.release);
        const todayPlusYear = new Date( today.setFullYear( today.getFullYear() + 1 ));
        const MoreThanTodayPlusYear = release > todayPlusYear;

        if(values.release === undefined) {
            return error;
        }

        if( MoreThanTodayPlusYear ) {
            error.release = 'Дата выхода не должна быть больше текущей даты более чем на год';
        } 

        const MIN_DATE = new Date('01.01.1900');
        if( release < MIN_DATE ) {
            error.release = 'Год выхода не может быть меньше чем 1900 год';
        }
    }
    
    return error;
}

function prepareToSend(values: AddSeasonForm, parentID: number, languageID: number, delimiter: string): SeasonInput {
    const mutationArgs: SeasonInput = {
        name: `${values.order}${delimiter} ${values.name}`,
        parentID,
        languageID,
    };

    if(values.release !== undefined) {
        mutationArgs.release = values.release;
    }

    return mutationArgs;
}

function SeasonAddModalBody(props: SeasonAddModalBodyProps) {
    const {closeCallback} = props;
    const DELIMITER = '';

    useEscapeCloseListener(closeCallback);

    const serialFilesContext = useSerialFilesContext();
    
    const [latestSeasonOrder, activeSeason] = React.useMemo(() => {
        if(isNull(serialFilesContext)) {
           throw new Error('SeasonAddModal used serialFilesContext that is null');
        }
        const seasons = serialFilesContext.getAllSeasonsResult.data;
        return [
            seasons.length + 1,
            serialFilesContext.activeSeason
        ]
    }, [serialFilesContext]);

    const [triggerRefetching, activateSeasonCallback ] = React.useMemo(() => {
        if(isNull(serialFilesContext)) {
            throw new Error('SeasonAddModal used serialFilesContext that is null');
        }
        return [
            serialFilesContext.getAllSeasonsResult.refetch,
            serialFilesContext.activateSeasonCallback
        ]
    }, [serialFilesContext]);

    const addSeasonCallback = useAddSeasonMutation(data => {
        if(activeSeason === 0) {
            activateSeasonCallback(data.id);
        }
        triggerRefetching();
    });

    const contentEditContext = useContentEditContext();
    const { languageID, parentID } = React.useMemo(() => {
        return {
            languageID: contentEditContext.basicInfo.language,
            parentID: contentEditContext.id
        }
    }, [contentEditContext]);

    const formik = useFormik<AddSeasonForm>({
        initialValues: {
          order: latestSeasonOrder,
          name: 'сезон',
          release: undefined
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            await addSeasonCallback({
                variables: {
                    input: prepareToSend(values, parentID, languageID, DELIMITER )
                }
            }).finally( closeCallback )
        },
        validate: customValidator
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mb-5'>
                <TextField
                    variant='outlined'
                    fullWidth
                    id='order'
                    type='number'
                    name='order'
                    label='Номер'
                    value={formik.values.order}
                    onChange={formik.handleChange}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                    disabled={formik.isSubmitting}
                />
            </div>
            
            <div className='mb-5'>
                <TextField
                    variant='outlined'
                    fullWidth
                    id='name'
                    name='name'
                    label='Наименование'
                    type='text'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    disabled={formik.isSubmitting}
                />
            </div>

            <div className='mb-5'>
                <DatePicker
                    className={ `${getFieldCSSClasses(formik.touched['release'], formik.errors['release'])} p-4` }
                    name='release'
                    dateFormat='dd.MM.yyyy'
                    placeholderText='Выберите дату выхода'
                    selected={  formik.values.release ? new Date(formik.values.release) : undefined }
                    onChange={(date: Date) => {
                        formik.setFieldValue('release', date.toJSON());
                    }}
                    onBlur={formik.handleBlur}
                    disabled={formik.isSubmitting}
                />
            </div>
            
            <div className='d-flex justify-content-end'>
                <Button color='primary' variant='primary' type='submit' disabled={formik.isSubmitting}>
                    Добавить
                </Button>
            </div>
      </form>
    );
}

export default SeasonAddModalBody