import React from 'react'
import './styles.css'

type CustomContainerProps = ReactMultipleContainer & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

function CustomContainer( props: CustomContainerProps ) {
    const { children, className } = props;

    return (
        <div { ...props } className={`container-custom ${className}`}>
            { children }
        </div>
    );
}

export default CustomContainer