import { gql } from '@apollo/client'
import { Response } from '../types'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'

export interface DeleteParticipantToMovieMutationArgs {
    movieID: number,
    participantID: number,
    positionID: PARTICIPANT_TYPES
} 
export interface DeleteParticipantToMovieMutationData {
    DeleteParticipantToMovie: Response
}

export const DELETE_PARTICIPANT_TO_MOVIE_MUTATION = gql`
    mutation DeleteParticipantToMovieMutation( $movieID: Int!, $participantID: Int!, $positionID: Int!) {
        DeleteParticipantToMovie( movieID: $movieID, participantID: $participantID, positionID: $positionID ) {
           status
           message
        }
    }
`