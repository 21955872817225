import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { AddedData, ErrorFileItem, ErrorFile, InternalID, PendingFile } from 'src/uploader/types'

const useItemErrorEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<InternalID, AddedData>>, 
    pending: Map<InternalID, PendingFile>,
    setPending: React.Dispatch<React.SetStateAction<Map<InternalID, PendingFile>>>,
    rejected: Map<InternalID, ErrorFile>,
    setRejected: React.Dispatch<React.SetStateAction<Map<InternalID, ErrorFile>>>
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.ITEM_ERROR, (item: ErrorFileItem) => {
            const filePayload = addedFiles.current.get(item.id);

            if(filePayload) {
                pending.delete(filePayload.externalID);
                rejected.set(filePayload.externalID, {...item, title: filePayload.title});
                setRejected(new Map(rejected));
                setPending(new Map(pending));

                addedFiles.current.delete(item.id);
            }
        });
        
        return () => {
            uploader.off(UPLOADER_EVENTS.ITEM_ERROR);
        }
    }, [uploader, addedFiles, pending, setPending, rejected, setRejected]);
}

export default useItemErrorEffect