import { useMutation } from '@apollo/client'
import { comment } from 'src/util/comment'
import { AddTrailerFileMutationData, AddTrailerFileMutationArgs, ADD_TRAILER_FILE_MUTATION } from 'src/api/graphql/mutations/AddTrailerFileMutation'
import { Response } from 'src/api/graphql/types'

function useAddTrailerFileMutation(): (filePath: string, movieID: number) => Promise<Error | Response | Error[]> {
    const [ AddTrailerFile ] = useMutation<AddTrailerFileMutationData, AddTrailerFileMutationArgs>(ADD_TRAILER_FILE_MUTATION, {
        onCompleted: data => {
            const {status, message} = data.AddTrailerFile;

            if(status === 'error') {
                console.error(`Ошибка при попытке сохранения фильма в базу: ${message}`)
            } 

            comment('useAddTrailerFileMutation', data)
        },
        onError: error => {
            console.error(`Ошибка при попытке сохранения фильма в базу`, error);
        }
    });

    return async function(filePath: string, movieID: number) {
        const result = await AddTrailerFile({
            variables: {
                input: {
                    filePath,
                    movieID,
                    typeID: 1,
                }
            }
        });

        if(result.data) {
            return result.data.AddTrailerFile;
        }

        if(result.errors) {
            return result.errors.map( err => new Error(err.message) )
        }

        else {
            return new Error('AddTrailerFile mutation responded with undefined data and errors field');
        }
    }
}

export default useAddTrailerFileMutation