import React from 'react'
import { useLocation } from 'react-router'
import { NavigationLinksActionTypes, useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { comment } from 'src/util/comment'

export function useSaveParticipantsQueryParams() {
    const { dispatch } = useNavigationLinksContext();
    const { search } = useLocation();

    React.useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if(search !== '') {
            comment('useSaveParticipantsQueryParams on ParticipantsList','Saved params to context', searchParams.toString());
            dispatch({
                type: NavigationLinksActionTypes.setParticipantsLink, 
                payload: searchParams.toString()
            });
        }
    }, [search, dispatch]);    
}