import { gql } from '@apollo/client'
import { Response } from 'src/api/graphql/types'

export interface AddCountriesToMovieMutationArgs {
    movieID: number,
    countryID: number[]
} 

export interface AddCountriesToMovieMutationData {
    AddCountriesToMovie: Response,
}

export const ADD_COUNTRIES_TO_MOVIE_MUTATION = gql`
    mutation AddCountriesToMovieMutation( $movieID: Int!, $countryID: [Int!]! ) {
        AddCountriesToMovie(movieID: $movieID, countryID: $countryID) {
            status
            message
        }
    }
`