import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes';
import PlainSelect, { Options } from 'src/app/components/ContentService/PlainSelect'

type ParticipantsOptionsSelectProps = {
    optionChangeHandler: (option: number|string) => void,
    value: number,
    className: string,
}

function ParticipantsOptionsSelect(props: ParticipantsOptionsSelectProps) {
    const { optionChangeHandler, value, className } = props;
    const options: Options[] = [
        {value: PARTICIPANT_TYPES.ACTOR, label: 'Актеры'},
        {value: PARTICIPANT_TYPES.DIRECTOR, label: 'Режисеры'}
    ];

    return (
        <div className={className}>
            <PlainSelect
                name='participants'
                placeholder='Выбери'
                optionChangeHandler={optionChangeHandler}
                value={value}
                options={options}
            />
        </div>
    );
}

export default ParticipantsOptionsSelect