import { useMutation } from '@apollo/client'
import { UpdateTagMutationArgs, UpdateTagMutationData, UPDATE_TAG_MUTATION } from 'src/api/graphql/mutations/UpdateTagMutation'
import { Selector, Tag } from 'src/api/graphql/types';
import { UpdateCompleteHandler, UpdateErrorHandler, UpdateMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

type UpdateCallbackArgs = { 
    id: number,
    name: string,
}

export function useUpdateTag(completeHandler?: UpdateCompleteHandler<Tag>, errorHandler?: UpdateErrorHandler): UpdateMutationHookResult<Selector> {
    const [ UpdateInitialCallback ] = useMutation<UpdateTagMutationData, UpdateTagMutationArgs>(UPDATE_TAG_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.UpdateTag);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const UpdateCallback = async (args: UpdateCallbackArgs ) => {
        const { id, name } = args;
        await UpdateInitialCallback({
            variables: { 
                tagID: id, 
                input: {
                    name
                }
            }
        });
    }

    return [ UpdateCallback ]
}