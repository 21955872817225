import React, { forwardRef } from 'react';
import clsx from 'clsx';

type CardHeaderBodyProps = {
  children: React.ReactElement|React.ReactElement[],
  fit?: any,
  fluid?: any,
  className?: string
}

export const CardBody = forwardRef<HTMLDivElement | null, CardHeaderBodyProps>(
    ({ fit, fluid, className, ...props }, ref) => (
      <div
        {...props}
        ref={ref}
        className={clsx(
          'card-body',
          {
            'card-body-fit': fit,
            'card-body-fluid': fluid
          },
          className
        )}
      />
    )
  );