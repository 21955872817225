import React from 'react'
import {FileError, useDropzone} from 'react-dropzone'
import { FileExtra } from '../helpers/ImageUploaderHelpers';

export interface BasicImageUploaderProps {
    maxSize: number,
    accept: string,
    validator: <T extends FileExtra>(file: T) => FileError|FileError[]|null,
    onDrop: <T extends FileExtra>( acceptedFiles: T[], fileRejection: Array<{file: T, errors: FileError[]}> ) => void
}

/**
 * Component for image uploading to which should be injected styles
 * Basic behaviour is constructed based on single image loader
 * @param props BasicImageUploader props described above
 * @returns React element containing single image uploader with validation
 */
function BasicImageUploader(props: BasicImageUploaderProps): React.ReactElement {
    const { maxSize, accept, onDrop, validator } = props;
    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        maxSize, accept, onDrop, validator, 
        maxFiles: 1, multiple: false,
        getFilesFromEvent: async e => {
            const event = e as React.DragEvent & React.BaseSyntheticEvent;
            const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            const promises: Promise<FileExtra>[] = [];
            
            for(let i=0; i<files.length; i++) {
                const file = files[i] as FileExtra

                const promise: Promise<FileExtra> = new Promise( resolve => {
                  const image = new Image();
                  let url: string;
                  image.onload = function () {
                    file.width = image.width;
                    file.height = image.height;
                    resolve(file)
                  }
                  url = URL.createObjectURL(file)
                  image.src = url
                })
                promises.push(promise)
            }
            
            return await Promise.all(promises);
        },
    });

   

    return (
        <div { ...getRootProps({className: `dropzone ${isDragActive ? 'active' : ''}`}) }>
            <input { ...getInputProps() } />
        </div>
    );
}

export default BasicImageUploader