import React from 'react'
import { Season } from 'src/api/graphql/types'
import SeasonStatus from './components/SeasonStatus'
import SeasonRelease from './components/SeasonRelease'
import SeasonName from './components/SeasonName'
import SeasonHeader from './components/SeasonHeader'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { useChangeSeasonStatusMutation } from './hooks/useChangeSeasonStatusMutation'

type SeasonProps = {
    data: Season,
    isActive: boolean,
    activateSeasonCallback: (id: number) => void,
    seasonEditCallback: (e: React.MouseEvent, id: number) => void
}

function SeasonElement(props: SeasonProps) {
    const { data, isActive, activateSeasonCallback, seasonEditCallback } = props;
    const changeSeasonStatusCallback = useChangeSeasonStatusMutation(data.id, data.parentID);

    const selectHandler: React.MouseEventHandler = e => {
        activateSeasonCallback(data.id);
    }

    const editHandler = React.useCallback<React.MouseEventHandler>( e => {
        seasonEditCallback(e, data.id);
    }, [seasonEditCallback, data.id]);

    const statusChangeHandler = React.useCallback<React.MouseEventHandler>( e => {
        e.stopPropagation();
        const status = data.status === ACTIVATION_STATUS.ACTIVE ? ACTIVATION_STATUS.INACTIVE : ACTIVATION_STATUS.ACTIVE;
        changeSeasonStatusCallback(data.id, status);
    }, [changeSeasonStatusCallback, data.status, data.id]);

    const classes = isActive ? 'season active' : 'season';

    return (
        <div className={classes} onClick={selectHandler}>
            <SeasonHeader 
                editHandler={editHandler} 
                statusChangeHandler={statusChangeHandler}
                isActive={isActive}
                status={data.status}
            />
            <SeasonName name={data.name}/>
            <SeasonStatus status={data.status} />
            <SeasonRelease release={data.release} />
        </div>
    )
}

export default SeasonElement