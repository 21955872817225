import { createContext, useContext, useState } from 'react'

enum NotificationVariants {
    'success' = 'success',
    'warning' = 'warning',
    'error' = 'error',
    'info' = 'info'
}

export interface Notification {
    message: string, 
    variant: NotificationVariants
}

type NotificationContextValue = { 
    notification: Notification,
    setNotification: (notification: Notification) => void
}

const initialValue: NotificationContextValue = {
    notification: {
        message: '',
        variant: NotificationVariants.info
    }, 
    setNotification: () => {},
}

function NotificationProvider(props: ReactContainer) {
    const [notification, setNotification] = useState<Notification>({
        message: '',
        variant: NotificationVariants.info
    });

    const messenger = {notification, setNotification,};

    const { children } = props;

    return (
        <NotificationContext.Provider value={messenger}>
            {children}
        </NotificationContext.Provider>
    )
}

const NotificationContext = createContext<NotificationContextValue> (initialValue);
const useNotification = () => useContext(NotificationContext);

export default NotificationProvider
export { NotificationVariants, useNotification }