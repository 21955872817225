import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import * as uiHelpers from 'src/app/components/ContentService/helpers/FilmSerialUIHelpers'
import { sortCaret, headerSortingClasses } from 'src/metronic/core/TableSortingHelpers'
import { Pagination } from 'src/metronic/components/Pagination'
import { Complaint } from 'src/api/graphql/types'
import { useComplaintsQueryParams } from '../hooks/useComplaintsQueryParams'
import { useTableChangeHandler } from '../hooks/useTableChangeHandler'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { useMutation, useQuery } from '@apollo/client'
import { GetAllComplaintsArgs, GetAllComplaintsData, GET_ALL_COMPLAINTS_QUERY } from 'src/api/graphql/queries/getAllComplaints'
import { 
  ActionsColumnFormatter, 
  ComplaintDescriptionColumnFormatter, 
  ComplaintIDColumnFormatter, 
  ContentIDColumnFormatter, 
  DateColumnFormatter, 
  MovieIDColumnFormatter, 
  OrderColumnFormatter, 
  StatusColumnFormatter, 
  UserIDColumnFormatter 
} from '../utils/complaintsColumnFormatters'
import { ChangeComplaintStatusMutationArgs, ChangeComplaintStatusMutationData, CHANGE_COMPLAINT_STATUS_MUTATION } from 'src/api/graphql/mutations/ChangeComplaintStatus'

function ComplaintsTable() {
  const queryParams = useComplaintsQueryParams();
  const { navigationLinks: {complaintsLink} } = useNavigationLinksContext();

  const tableChangeHandler = useTableChangeHandler();

  /**
   * FilterByGenreAndTag querie
   */
  // Query to graphql server
  const { data, loading } = useQuery<GetAllComplaintsData, GetAllComplaintsArgs>(GET_ALL_COMPLAINTS_QUERY, {
    variables: {
      limit: queryParams.pageSize, 
      page: queryParams.pageNumber,
    }
  });

  /**
   * Destructurize the query result depending on which query is executed.
   * Note: Only one query is executed at a moment
   */
  const entities = data ? data.GetAllComplaints.Complaints : [];
  const totalCount = data && data.GetAllComplaints.Pagination ? data.GetAllComplaints.Pagination.TotalCount : 0;
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const defaultSorted = React.useMemo(() => {
    if(complaintsLink === '') return uiHelpers.defaultSorted
    return undefined;
  }, [complaintsLink]);

  const [changeComplaintStatus] = useMutation<ChangeComplaintStatusMutationData, ChangeComplaintStatusMutationArgs>(
    CHANGE_COMPLAINT_STATUS_MUTATION
  );

  /** Table columns for bootstrap-table-next
  * # - order number
  * id - id of the element
  * image - avatar image of the element
  * name - name of the element
  * release - release data of the element
  * ratingIMDB - ratingIMDB of the element
  * language - languages list of the element
  * status - status accessiblity (public or private) of the element
  * addedDate - date of adding of the element
  * actions - edit or delete actions depending on user roleID
  */
  const columns: ColumnDescription<Complaint>[] = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: OrderColumnFormatter,
      formatExtraData: {
        getOrder: (index: number) => {
          return index + (queryParams.pageNumber - 1) * queryParams.pageSize
        }
      },
    },
    {
      dataField: 'id',
      text: 'id',
      sort: false,
      sortCaret: sortCaret,
      formatter: ComplaintIDColumnFormatter
    },
    {
      dataField: 'userID',
      text: 'Пользователь',
      sort: false,
      formatter: UserIDColumnFormatter,
    },
    {
      dataField: 'movieID',
      text: 'ID Фильма/Сериала',
      sort: false,
      formatter: MovieIDColumnFormatter,
    },
    {
      dataField: 'typeID',
      text: 'Тип контента',
      sort: false,
      formatter: ContentIDColumnFormatter
    },
    {
      dataField: 'movieName',
      text: 'Название',
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: 'complaintsTitle',
      text: 'Заголовок',
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: 'complaintsDescription',
      text: 'Содержание',
      sort: false,
      sortCaret: sortCaret,
      formatter: ComplaintDescriptionColumnFormatter,
    },
    {
      dataField: 'status',
      text: 'Статус',
      sort: false,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'startDate',
      text: 'Дата открытия',
      sort: false,
      sortCaret: sortCaret,
      formatter: DateColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'endDate',
      text: 'Дата закрытия',
      sort: false,
      sortCaret: sortCaret,
      formatter: DateColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: 'Действия',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        changeComplaintStatus
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            isLoading={loading}
            paginationProps={paginationProps}
          >
            <BootstrapTable
              wrapperClasses='table-responsive'
              bordered={false}
              classes='table table-head-custom table-vertical-center overflow-hidden'
              bootstrap4
              remote
              defaultSorted={defaultSorted}
              onTableChange={tableChangeHandler}                 
              {...paginationTableProps}
              data={entities}
              columns={columns}
              keyField={'id'}
            />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
}

export default ComplaintsTable