import { ApolloError, useMutation } from '@apollo/client'
import { AddMovieMutationArgs, AddMovieMutationData, ADD_MOVIE_MUTATION } from 'src/api/graphql/mutations/AddMovieMutation'
import { MovieInput } from 'src/api/graphql/types'
import { comment } from 'src/util/comment'

type useAddMovieMutationArgs = {
    completeHandler?: (data: AddMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use AddMovie mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useAddMovieMutation(args: useAddMovieMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;

    const [ AddMovie ] = useMutation<AddMovieMutationData, AddMovieMutationArgs>(ADD_MOVIE_MUTATION, {
        onCompleted: data => {
            if(data) {
                comment('UseAddMovieMutation','Attempting to add', data);
            } else {
                console.error(`Response from UseAddMovieMutation could not be ${data}`, data);
            }

            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function(input: MovieInput ) {
        await AddMovie({
            variables: {
                input
            }
        });
    }
}

export default useAddMovieMutation