import { Host } from 'src/api/graphql/types'
import { gql } from '@apollo/client'

export interface SearchHostByIPArgs {
   ip: string
}

export interface SearchHostByIPData {
    SearchHostByIP: Host
}

export const SEARCH_HOST_BY_IP_QUERY = gql`
     query SearchHostByIPQuery($ip: String!) {
        SearchHostByIP(ip: $ip) {
            ip
            phone
            code
            name
            active
            type
        }
    }
`