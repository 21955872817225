import { makeStyles } from '@material-ui/core'
import { Spinner } from 'react-bootstrap'

type NameColumnRendererProps = {
  content: string, 
  id: number, 
  pendingUpdates: Set<number>
}

const useStyles = makeStyles( theme => ({
  spinnerWrapper: {
    display: 'flex', 
    width: '100%', 
    height: '100%'
  },
  spinner: {
    width: 10, 
    height: 10, 
    marginRight: 5
  }, 
  pointer: {
    cursor: 'pointer'
  }
}));

function NameColumnRenderer(props: NameColumnRendererProps) {
  const { content, id, pendingUpdates } = props;
  const classes = useStyles();
  const loading = pendingUpdates.has(id);

  if(loading) {
    return (
      <div className={classes.spinnerWrapper}>
        <Spinner animation='grow' className={classes.spinner} variant='primary'/>
        <Spinner animation='grow' className={classes.spinner} variant='primary'/>
        <Spinner animation='grow' className={classes.spinner} variant='primary'/>
      </div>
    );
  } else {
    return (
      <span className={classes.pointer + ' unselectable'} draggable={false}>
        { content }
      </span>
    );
  }
}

export default NameColumnRenderer