import { gql } from '@apollo/client'
import { Director } from '../types'

export interface GetAllDirectorsArgs {} 

export interface GetAllDirectorsData {
    getAllDirectors: Director[]
}

export const GET_ALL_DIRECTORS_QUERY = gql`
    query GetAllDirectors {
        getAllDirectors {
            id
            name
            image
        }
    }
`