import React from 'react'
import TrailerElement from './components/TrailerElement'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import SelectedFileTrailer from './components/SelectedFileTrailer'
import withCard from '../hocs/withCard';

function EditFilesTrailer() {
    const contentEditContext = useContentEditContext();
    const [data] = React.useMemo(() => {
        return [
            {
                id: contentEditContext.id,
                name: contentEditContext.basicInfo.name,
                typeId: contentEditContext.typeId,
                trailerUrl: contentEditContext.trailerUrl
            },
            contentEditContext.setInfo
        ]
    }, [contentEditContext]);

    return (
        <div className='edit-files-trailer'>
            <TrailerElement data={data} isActive/>
            <SelectedFileTrailer/>
        </div>
    );
}

const CardWrapedTrailerElement = withCard(
    EditFilesTrailer,
    'Трейлер',
    'trailer-card'
);

export default CardWrapedTrailerElement