import React from 'react'
import { IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarCloseReason } from '@material-ui/core'
import { NotificationVariants, useNotification } from '../../contexts/NotificationContext'
import SnackbarContentWrapper from './components/SnackbarContentWrapper'

function NotificationBar(): React.ReactElement| null {
    const { notification:{ message, variant }, setNotification } = useNotification();

    const handleSnacBarClose = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification({message: '', variant: NotificationVariants.success});
    };

    const handleClose: React.MouseEventHandler<HTMLButtonElement> = () => {
        setNotification({message: '', variant: NotificationVariants.success});
    }

    const time = Date.now().toString();

    if(message !== '') {
        return (
            <Snackbar
                key={ message.concat(time) }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={true}
                autoHideDuration={4000}
                onClose={handleSnacBarClose}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >   
                <div>
                    <SnackbarContentWrapper
                        onClose={handleClose}
                        variant={variant}
                        message={message}
                    />
                </div>
            </Snackbar>
        )
    } else {
        return null;
    }
}

export default NotificationBar