import React, { ErrorInfo, GetDerivedStateFromError } from 'react'

type ErrorBoundaryProps = {
  fallback?: string|React.ElementType<unknown>
} & ReactMultipleContainer
type ErrorBoundaryState = {
    hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: GetDerivedStateFromError<ErrorBoundaryProps, ErrorBoundaryState>) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // You can also log the error to an error reporting service
      console.error(error, errorInfo);
    }
  
    render() {
      const { fallback } = this.props;
      if (this.state.hasError) {
        // You can render any custom fallback UI

        if(fallback) return <h2>{fallback}</h2>
        return <h2>Something went wrong.</h2>;
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary