import { gql } from '@apollo/client'
import { TranscodeRequestFile, TRANSCODE_STATUS } from '../types'

export interface GetFilesByProcessStatusArgs {
    typeID: number,
    status: TRANSCODE_STATUS
} 

export interface GetFilesByProcessStatusData {
    GetFilesByProcessStatus: TranscodeRequestFile[]
}

export const GET_FILES_BY_PROCESS_STATUS_QUERY = gql`
    query GetFilesByProcessStatusQuery($typeID: Int!, $status: Int!) {
        GetFilesByProcessStatus(typeID: $typeID, status: $status){
            MovieId
            Name
            RequestId
            FileId
            qualityID,
            MovieStatus
        }
    }
`