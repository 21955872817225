import React from 'react'
import { useLocation } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { defaultParticipantsQueryParams } from '../utils/ParticipantsConfigAssets'

export function useParticipantsQueryParams(): ParticipantsQueryParams {
    const { search } = useLocation();
    const { navigationLinks: {participantsLink} } = useNavigationLinksContext();

    const queryParams = React.useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const savedSearchParams = new URLSearchParams(participantsLink);

        return {
            searchText: getCorrectSearchText(
                searchParams.get('searchText'), 
                savedSearchParams.get('searchText') 
            ),
            pageNumber: getCorrectPageNumber(
                searchParams.get('pageNumber'), 
                savedSearchParams.get('pageNumber')
            ),
            pageSize: getCorrectPageSize( 
                searchParams.get('pageSize'), 
                savedSearchParams.get('pageSize')
            ),
            sortField: getCorrectSortField(
                searchParams.get('sortField'), 
                savedSearchParams.get('sortField')
            ), 
            sortOrder: getCorrectSortOrder(
                searchParams.get('sortOrder'), 
                savedSearchParams.get('sortOrder')
            )
        }

    }, [search, participantsLink]);

    return queryParams; 
}

const getCorrectSearchText = (
    searchText: string|null, 
    savedSortSearchText: ParticipantsQueryParams['searchText']|null
): ParticipantsQueryParams['searchText'] => {
    if(searchText !== null) {
        return searchText;
    }

    if(savedSortSearchText !== null) return savedSortSearchText;

    return defaultParticipantsQueryParams.searchText;
}

const getCorrectPageSize = (
    pageSize: string|null,
    savedPageSize: string|null
): ParticipantsQueryParams['pageSize'] => {
    if(pageSize !== null) {
        return Number(pageSize);
    }
    if(savedPageSize !== null) return Number(savedPageSize);

    return defaultParticipantsQueryParams.pageSize;
}

const getCorrectPageNumber = (
    pageNumber: string|null,
    savedPageNumber: string|null    
): ParticipantsQueryParams['pageNumber'] => {
    if(pageNumber !== null) {
        return Number(pageNumber);
    }
    if(savedPageNumber !== null) return Number(savedPageNumber);

    return defaultParticipantsQueryParams.pageNumber;
}

const getCorrectSortField = (
    sortField: string|null, 
    savedSortField: ParticipantsQueryParams['sortField']|null
): ParticipantsQueryParams['sortField'] => {
    if(sortField !== null) {
        return sortField
    }

    if(savedSortField !== null) return savedSortField;

    return defaultParticipantsQueryParams.sortField;
}

const getCorrectSortOrder = (
    order: unknown, 
    savedOrder: unknown|null
): ParticipantsQueryParams['sortOrder'] => {
    if(order === 'asc' || order === 'desc') {
        return order
    };

    if(savedOrder === 'asc' || savedOrder === 'desc') return savedOrder;

    return defaultParticipantsQueryParams.sortOrder;
}