import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { 
    ProcessCheckFormatter,
    ProcessIDFormatter,
    ProcessNameFormatter, 
    ProcessOrderFormatter, 
    ProcessUrgentFormatter
} from '../../../helpers/ProcessingFormatter'
import { QUALITY_TYPES, TranscodeRequestFile } from 'src/api/graphql/types'
import { SELECT_LIMIT } from '../../../helpers/selectLimit'
import { useLimitReachNotification } from '../../../hooks/useLimitReachNotification'
import ProcessingSenderBar from './ProcessingSenderBar'
import { useContextSelector } from 'use-context-selector'
import ReadyToSendFilesContext from '../../../contexts/ReadyToSendFilesContext'
import { addUniqueID } from '../../../helpers/addUniqueID'

function ReadyToSendTable(props: {qualityID: QUALITY_TYPES}) {
    const { qualityID } = props;
    const selectedIds = useContextSelector(ReadyToSendFilesContext, v => v.selectedIds[qualityID]);
    /**Type assertion to non-undefined value because context had initialized */
    const setSelectedIds = useContextSelector(ReadyToSendFilesContext, v => v.setSelectedIds[qualityID]!);

    const readyToSend = useContextSelector(ReadyToSendFilesContext, v => v.readyToSendFilesResult[qualityID]);
    const typeID = useContextSelector(ReadyToSendFilesContext, v => v.typeID);

    const urgentExtraData = React.useMemo(() => ({
        context: ReadyToSendFilesContext,
        qualityID
    }), [qualityID]);

    useLimitReachNotification(selectedIds, SELECT_LIMIT);

    const entities = React.useMemo< Array<TranscodeRequestFile>>(() => { 
        return addUniqueID(readyToSend.data);
    }, [readyToSend]);

    const columns: ColumnDescription<TranscodeRequestFile>[] = [
        {
            dataField: 'check',
            text: '',
            isDummyField: true,
            formatter: ProcessCheckFormatter,
            formatExtraData: [setSelectedIds, SELECT_LIMIT],
        },
        {
            dataField: 'order',
            text: '#',
            isDummyField: true,
            formatter: ProcessOrderFormatter,
        },
        {
            dataField: 'MovieId',
            text: 'ID',
            formatter: ProcessIDFormatter,
        },
        {
            dataField: 'urgent',
            text: '',
            formatter: ProcessUrgentFormatter,
            formatExtraData: urgentExtraData,
            style: {
                width: '140px',
            },
        },
        {
            dataField: 'Name',
            text: 'Название',
            formatter: ProcessNameFormatter,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                width: '50px',
            },
        },
        // {
        //     dataField: 'action',
        //     text: 'Действия',
        //     formatter: UploadRemoveActionFormatter,
        //     formatExtraData: clearFullfilled,
        //     classes: 'text-right pr-0',
        //     headerClasses: 'text-right pr-3',
        //     style: {
        //         minWidth: '50px',
        //     },
        // },
    ];

    return (
        <>
            <PerfectScrollbar 
                className='processing-list-scrollbar' 
                options={{suppressScrollX: true}}
            >
                <ProcessingSenderBar 
                    ids={selectedIds} 
                    typeID={typeID} 
                    qualityID={qualityID}
                    entities ={entities} 
                    setIds={setSelectedIds}
                />
                <BootstrapTable
                    wrapperClasses='table-responsive overflow-x-none'
                    bordered={false}
                    classes='table table-head-custom table-vertical-center overflow-hidden'
                    bootstrap4
                    data={entities}
                    columns={columns}
                    keyField={'id'}
                />
            </PerfectScrollbar>
        </>
    );
}

export default ReadyToSendTable