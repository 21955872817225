import { ApolloError, useMutation } from '@apollo/client'
import { DeleteGenreToMovieMutationArgs, DeleteGenreToMovieMutationData, DELETE_GENRE_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/DeleteGenreToMovie';
// import { GET_MOVIE_BY_ID_QUERY } from 'src/graphql/queries/getMoviesById';

type useDeleteGenreToMovieMutationArgs = {
    completeHandler?: (data: DeleteGenreToMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use DeleteGenreToMovie mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useDeleteGenreToMovieMutation(args: useDeleteGenreToMovieMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;

    const [ DeleteGenre ] = useMutation<DeleteGenreToMovieMutationData, DeleteGenreToMovieMutationArgs>(DELETE_GENRE_TO_MOVIE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function( movieID: number, genreID: number ) {
        return await DeleteGenre({
            variables: {
                movieID,
                genreID
            },
            // refetchQueries: [{query: GET_MOVIE_BY_ID_QUERY, variables: {id: movieID}}]
        });
    };
}

export default useDeleteGenreToMovieMutation