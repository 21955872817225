import BasicModal from '../../../BasicModal'
import SeasonAddModalBody from './components/SeasonAddModalBody'

type SeasonAddModalProps = {
    show: boolean,
    onHide: () => void
}

function SeasonAddModal(props: SeasonAddModalProps) {
    const { show, onHide } = props;

    return (
        <BasicModal show={show} onHide={onHide}>
            <SeasonAddModalBody closeCallback={onHide}/>
        </BasicModal>
    );
}

export default SeasonAddModal