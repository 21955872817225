import { Host } from 'src/api/graphql/types'
import { ABONENT_PROVIDER_TYPES, ABONENT_STATUS_TYPES } from 'src/app/assets/AbonentTypes'
import useGetAllRegionsQuery from 'src/app/hooks/query/useGetAllRegions'
import { toAbsoluteUrl } from 'src/util/url'
import SVG from 'react-inlinesvg'
import { Spinner } from 'react-bootstrap';

function OrderColumnFormatter(cellContent: number, row: any, rowIndex: number, {getOrder}: { getOrder: (index: number) => number} ) {
    const orderNumber = getOrder(rowIndex+1);
  
    return (
      <span>
        { orderNumber }.
      </span>
    );
}

// type
function ProviderColumnFormatter(cellContent: number, row: Host, rowIndex: number ) {
  const getProviderName = (code: number): string => {
    switch (code) {
      case ABONENT_PROVIDER_TYPES.TELECOM: return 'Телеком'
      case ABONENT_PROVIDER_TYPES.GTS: return 'ГТС'
      default: return 'Неизвестный'
    }
  } 

  return (
    <span>
      { getProviderName(cellContent) }
    </span>
  );
}

function IpColumnFormatter(cellContent: number, row: Host, rowIndex: number ) {
  return (
    <span className='label label-lg label-light label-inline'>
      { cellContent }
    </span>
  );
}

// code, name
function RegionColumnFormatter(cellContent: number, row: Host, rowIndex: number ) {
  return (
    <RegionColumnRenderer code={cellContent}/>
  );
}

function ActiveColumnFormatter(cellContent: number, row: Host, rowIndex: number ) {
  const getAbonentStatus = (status: number): JSX.Element => {
    switch (status) {
      case ABONENT_STATUS_TYPES.ACTIVE: 
        return <span className='svg-icon svg-icon-md svg-icon-success'>
          <SVG
            description='Изменить'
            title='Изменить'
            src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
          />
        </span>
      case ABONENT_STATUS_TYPES.INACTIVE: 
        return <span className='svg-icon svg-icon-md svg-icon-danger'>
          <SVG
            description='Изменить'
            title='Изменить'
            src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
          />
        </span>
      default: 
        return <span>Неизвестный</span> 
    }
  }

  return (
    <div>
      { getAbonentStatus(cellContent) }
    </div>
  );
}

function RegionColumnRenderer(props: {code: number}) {
  const {loading, data, error} = useGetAllRegionsQuery();

  if(loading) return <span>
    <Spinner animation='grow' variant='primary' size='sm'/>
  </span>;

  if(error) return <span>{error.message}</span>;

  if(data) {
    const region = data.find(i => i.id === props.code);
    return (
      <span>
        { region ? region.name : '' }
      </span>
    );
  }

  return (
    <span>
      Данные отсутсвуют
    </span>
  );
}

type getHandlerTableChangeProps = {
  page: number,
  sizePerPage: number, 
  sortField: any, 
  sortOrder: string,
  data?: any
}

export function getHandlerTableChange(setQueryParams:(nextQueryParams: any) => void ) {
  return (type: string, { page, sizePerPage, sortField, sortOrder, data }: getHandlerTableChangeProps) => {
    const pageNumber = page || 1;
    setQueryParams((prev: any) =>
      type === 'sort'
        ? { ...prev, sortOrder, sortField }
        : type === 'pagination'
        ? { ...prev, pageNumber, pageSize: sizePerPage }
        : prev
    );
  };
}

export {
  OrderColumnFormatter,
  ProviderColumnFormatter,
  IpColumnFormatter,
  RegionColumnFormatter,
  ActiveColumnFormatter
}