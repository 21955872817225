import { gql } from '@apollo/client'
import { Response } from '../types'

export interface UpdateBannerToHomePageMutationArgs {
    movieID: number
} 
export interface UpdateBannerToHomePageMutationData {
    UpdateBannerToHomePage: Response
}

export const UPDATE_BANNER_TO_HOME_PAGE_MUTATION = gql`
    mutation UpdateBannerToHomePageMutation( $movieID: Int! ) {
        UpdateBannerToHomePage(movieID: $movieID) {
            status
            message
        }
    }
`