import { gql } from '@apollo/client'

export interface IsHaveMovieFileArgs {
    movieID: number
} 

export interface IsHaveMovieFileData {
    IsHaveMovieFile: boolean
}

export const IS_HAVE_MOVIE_FILE_QUERY = gql`
    query IsHaveMovieFile( $movieID: Int!) {
        IsHaveMovieFile(movieID: $movieID)
    }
`