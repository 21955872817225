import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AddCategoryToHomePageArgs } from 'src/api/graphql/mutations/AddCategoryToHomePageMutation'
import { TextField } from '@material-ui/core'
import { useTapeOptions } from '../../TapesList/hooks/useTapeOptions'
import { useGenres } from '../../TapesList/hooks/useGenres'
import { useTags } from '../../TapesList/hooks/useTags'
import PlainSelect from 'src/app/components/ContentService/PlainSelect'
import { TAPE_TYPES } from 'src/app/assets/TapeTypes'
import { TapeFormProps } from '../types'
import Classes from './TapeForm.module.css'
import { useCategories } from '../../TapesList/hooks/useCategories'

function TapeForm(props: TapeFormProps) {
    const { id, initialValues, formRef, submitHandler, closeHandler } = props;
    const validationSchema = React.useMemo(() => {
        return Yup.object().shape({
            TitleTk: Yup.string()
                .min(3, 'Введите минимум 3 символов')
                .max(40, 'Введите максимум 40 символов')
                .required('Обязательное поле'),
            TitleRu: Yup.string()
                .min(3, 'Введите минимум 3 символов')
                .max(40, 'Введите максимум 40 символов')
                .required('Обязательное поле'),
            categoryType: Yup.string()
                .min(1, 'Обязательное поле')
                .required('Обязательное поле'),
            categoryID: Yup.number()
                .min(1, 'Обязательное поле')
                .required('Обязательное поле'),
            sort: Yup.string()
                .min(1, 'Обязательное поле')   
                .required('Обязательное поле'),
            itemSize: Yup.string()
                .min(1, 'Обязательное поле')   
                .required('Обязательное поле'),
            position: Yup.number()
                .min(4, 'Значение должно быть больше 3-х')   
                .required('Обязательное поле'),
        })
    }, []);

    const onSubmit = React.useCallback((values: AddCategoryToHomePageArgs['input']) => {
        return submitHandler(id, values).then(closeHandler);
    }, [submitHandler, closeHandler, id]);

    const formik = useFormik<AddCategoryToHomePageArgs['input']>({
        initialValues,
        validationSchema,
        onSubmit
    });

    const { loading, CategoryType, ItemSize, Sort } = useTapeOptions();
    const { loading: genresLoading, genres } = useGenres();
    const { loading: tagsLoading, tags } = useTags();
    const { loading: categoriesLoading, categories } = useCategories();
    const tapeTypeOptions = React.useMemo(() => {
        const options = [{value: '', label: 'Не выбрано'}]
        CategoryType.forEach( type => options.push({
            value: type.Value,
            label: type.Title
        }));
        return options;
    }, [CategoryType]);

    const sizeOptions = React.useMemo(() => {
        const options = [{value: '', label: 'Не выбрано'}]
        ItemSize.forEach( type => options.push({
            value: type.Value,
            label: type.Title
        }));
        return options;
    }, [ItemSize]);

    const sortOptions = React.useMemo(() => {
        const options = [{value: '', label: 'Не выбрано'}]
        Sort.forEach( type => options.push({
            value: type.Value,
            label: type.Title
        }));
        return options;
    }, [Sort]);

    const genreOptions = React.useMemo(() => {
        const options = [{value: 0, label: 'Не выбрано'}]
        genres.forEach( type => options.push({
            value: type.id,
            label: type.name
        }));
        return options;
    }, [genres]);

    const tagOptions = React.useMemo(() => {
        const options = [{value: 0, label: 'Не выбрано'}]
        tags.forEach( type => options.push({
            value: type.id,
            label: type.name
        }));
        return options;
    }, [tags]);

    const categoryOptions = React.useMemo(() => {
        const options = [{value: 0, label: 'Не выбрано'}]
        categories.forEach( type => options.push({
            value: type.id,
            label: type.name
        }));
        return options;
    }, [categories]);

    const tapeTypeDependingOptions = React.useMemo(() => {
        switch (formik.values.categoryType) {
            case TAPE_TYPES.GENRE: return genreOptions;
            case TAPE_TYPES.TAG: return tagOptions;
            case TAPE_TYPES.CATEGORY: return categoryOptions;
            default: return [{value: 0, label: 'Не выбрано'}];
        }
    }, [formik, tagOptions, genreOptions, categoryOptions]);

    const tapeDependingOptionsActive = React.useMemo(() => {
        return loading || genresLoading || tagsLoading || categoriesLoading || formik.values.categoryType === '';
    }, [loading, genresLoading, tagsLoading, categoriesLoading, formik]);

    return (
        <form 
            className='form form-label-right' 
            ref={formRef} 
            id='tape-form'
            onSubmit={ 
                e => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                }
            }
        >
            <div className='form-group row'>
                <div className='col-lg-5'>
                    <TextField
                        fullWidth
                        disabled={loading}
                        id='TitleTk'
                        name='TitleTk'
                        label={'Наименование на туркменском'}
                        value={formik.values.TitleTk}
                        onChange={formik.handleChange}
                        error={formik.touched.TitleTk && Boolean(formik.errors.TitleTk)}
                        helperText={formik.touched.TitleTk && formik.errors.TitleTk}
                        variant='outlined'
                    />
                </div>

                <div className='col-lg-5'>
                    <TextField
                        fullWidth
                        disabled={loading}
                        id='TitleRu'
                        name='TitleRu'
                        label={'Наименование на русском'}
                        value={formik.values.TitleRu}
                        onChange={formik.handleChange}
                        error={formik.touched.TitleRu && Boolean(formik.errors.TitleRu)}
                        helperText={formik.touched.TitleRu && formik.errors.TitleRu}
                        variant='outlined'
                    />
                </div>

                <div className='col-lg-2'>
                    <TextField
                        fullWidth
                        disabled={loading}
                        id='position'
                        name='position'
                        label={'Позиция'}
                        value={formik.values.position}
                        onChange={formik.handleChange}
                        error={formik.touched.position && Boolean(formik.errors.position)}
                        helperText={formik.touched.position && formik.errors.position}
                        type='number'
                        variant='outlined'
                    />
                </div>
            </div>

            <div className='form-group row'>
                <div className='col-lg-6'>
                    <small className='form-text text-muted'>
                        { 'Тип ленты' } 
                    </small>

                    <PlainSelect
                        options={ tapeTypeOptions }
                        disabled={loading}
                        className='form-control w-100'
                        name='categoryType'
                        optionChangeHandler={ option => {
                            formik.setFieldValue('categoryType', option);
                        }}
                        value={formik.values.categoryType}
                    />

                    <span className={Classes['error-label']}>
                        {
                            formik.touched.categoryType && formik.errors.categoryType
                            ? formik.errors.categoryType 
                            : undefined
                        }
                    </span>
                </div>

                
                <div className='col-lg-6'>
                    <small className='form-text text-muted'>
                        { 'Селектор для компоновки' } 
                    </small>

                    <PlainSelect
                        options={ tapeTypeDependingOptions }
                        disabled={ tapeDependingOptionsActive }
                        className='form-control w-100'
                        name='categoryID'
                        optionChangeHandler={ option => {
                            formik.setFieldValue('categoryID', option);
                        }}
                        value={formik.values.categoryID}
                    />

                    <span className={Classes['error-label']}>
                        {
                            formik.touched.sort && formik.errors.sort
                            ? formik.errors.sort 
                            : undefined
                        }
                    </span>
                </div>
            </div>

            <div className='form-group row'>
                <div className='col-lg-6'>
                    <small className='form-text text-muted'>
                        { 'Сортировка' } 
                    </small>

                    <PlainSelect
                        options={ sortOptions }
                        disabled={loading}
                        className='form-control w-100'
                        name='sort'
                        optionChangeHandler={ option => {
                            formik.setFieldValue('sort', option);
                        }}
                        value={formik.values.sort}
                    />

                    <span className={Classes['error-label']}>
                        {
                            formik.touched.sort && formik.errors.sort
                            ? formik.errors.sort 
                            : undefined
                        }
                    </span>
                </div>

                <div className='col-lg-6'>
                    <small className='form-text text-muted'>
                        { 'Размер' } 
                    </small>

                    <PlainSelect
                        options={ sizeOptions }
                        disabled={loading}
                        className='form-control w-100'
                        name='itemSize'
                        optionChangeHandler={ option => {
                            formik.setFieldValue('itemSize', option);
                        }}
                        value={formik.values.itemSize}
                    />

                    <span className={Classes['error-label']}>
                        {
                            formik.touched.itemSize && formik.errors.itemSize
                            ? formik.errors.itemSize 
                            : undefined
                        }
                    </span>
                </div>
            </div>
        </form>
    );
}

export default TapeForm