import { useTheme } from '@material-ui/core'
import React from 'react'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'
import { toUpperCamelCase } from 'src/app/components/ContentService/helpers/SelectorUIHelpers'
import { toAbsoluteUrl } from 'src/util/url'
import ParticipantsOptionsSelect from '../../ParticipantsConfigSearchBar/ParticipantOptionsSelect'
import SVG from 'react-inlinesvg'
import { useAddParticipant } from '../hooks/useAddParticipant'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { useFilterChangehandler } from '../hooks/useFilterChangeHandler'
import { defaultParticipantsQueryParams } from '../utils/ParticipantsConfigAssets'

interface ParticipantsConfigSearchBarProps {
    loading: boolean
    addPermision: boolean
}

function ParticipantConfigSearchBar(props: ParticipantsConfigSearchBarProps) {
    const { loading, addPermision } = props;

    const [ searchText, setSearchText ] = React.useState(defaultParticipantsQueryParams.searchText);
    const [ currentOptionValue, setCurrentOptionValue ] = React.useState<number>(PARTICIPANT_TYPES.ACTOR);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const timerRef = React.useRef<NodeJS.Timeout>();
    
    const [addParticipant] = useAddParticipant();
    const {setNotification} = useNotification();
    const filterChangeHandler = useFilterChangehandler();

    const theme = useTheme();

    const processTextChanging = React.useCallback((text: string) => {
        setSearchText(text);
        const timer = timerRef.current;

        if(timer !== undefined) {
            clearTimeout(timer);
        }

        timerRef.current = setTimeout(() => {
            filterChangeHandler({searchText: text});
        }, 500);
    }, [filterChangeHandler]);

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(event => {
        const text = event.target.value;
        processTextChanging(text);
    }, [processTextChanging]);

    const clearHandler: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
        processTextChanging(defaultParticipantsQueryParams.searchText);
    }, [processTextChanging]);

    const addHandler: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback( event => {
        event.persist();

        if(addPermision && event.ctrlKey && event.key === 'Enter') {
            addParticipant(
                {
                    name: toUpperCamelCase(searchText),
                }, 
                currentOptionValue
            ).then(() => {
                setNotification({
                  message: ' Участник успешно переименован',
                  variant: NotificationVariants.success
                });
            }).catch((e) => {
                setNotification({
                  message: 'Ошибка при попытке переименования' + e.message,
                  variant: NotificationVariants.error
                });
            });
        }
    }, [addPermision, searchText, addParticipant, currentOptionValue, setNotification]);

    const optionChangeHandler = (option: number|string) => {
        setCurrentOptionValue(Number(option));
        const input = inputRef.current;
        if(input !== null) {
            input.focus();
        }
    }

    const isClearButtonActive = React.useMemo(() => {
        return searchText.length > 0;
    }, [searchText]);

    React.useEffect(() => {
        const input = inputRef.current;
        if(input !== null) {
            input.focus();
        }
    })

    return (
        <div className='selectors-config-searchbar participants' >
            <input
                type='text'
                className='form-control'
                placeholder='Введите для поиска'
                disabled={loading}
                value={searchText}
                name='searchText'
                onChange={changeHandler}
                onKeyDown={addHandler}
                ref={inputRef}
            />

            {
                addPermision && <>
                    <ParticipantsOptionsSelect 
                        className='add-options'
                        optionChangeHandler={optionChangeHandler}
                        value={currentOptionValue}
                    />

                    <button 
                        onClick={clearHandler} 
                        className={isClearButtonActive ? '' : 'd-none'}
                        style={{right: 100}}
                    >
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Times.svg')}/>
                    </button>  

                    <div className='add-label' style={{display: 'inline-flex'}}>
                        <span>Ctrl + Enter</span>
                        <p style={{color: theme.palette.text.secondary}}> нажмите ctrl + Enter для добавления</p>
                    </div>
                </>
            }
        </div>
    );
}

export default ParticipantConfigSearchBar