import React, { useRef, useState } from 'react'
import { FileError } from 'react-dropzone'
import { UploadedImageData, UploadImageProgressEvent } from 'src/api/rest/types'
import { FileExtra } from 'src/app/components/ContentService/ImageUploader/helpers/ImageUploaderHelpers'
import MediaCard from 'src/app/components/MediaCard'
import { MediaCardClasses } from 'src/app/components/MediaCard/MediaCardHelpers'
import { comment } from 'src/util/comment'
import MediaCardActions from '../components/MediaCardActions'
import MediaCardProgress from '../components/MediaCardProgress'

type withMediaCardProps = {
    title: string,
    description: string,
    placeholder: string,
}

type WrappedWithMediaCard = {
    uploader: (file: File, progressHandler: (event: UploadImageProgressEvent) => void) => Promise<UploadedImageData|null> 
    children: React.ComponentType<{ onDrop: <T extends FileExtra>( files: T[], filesRejections: Array<{file: T, errors: FileError[]}> ) => void }>
    remover: () => Promise<unknown>
    src?: string,
}

function withMediaCard(props: withMediaCardProps, useStylesHook: () => MediaCardClasses) {
    const { title, description, placeholder } = props;

    const MediaCardWrappedComponent: React.FC<WrappedWithMediaCard> = ({src, children, uploader, remover}: WrappedWithMediaCard) => {
        const overridedClasses = useStylesHook();
        const [error, setError] = useState<string|undefined>(undefined);
        const [progress, setProgress] = useState<number>(0);
        const [pseudoSrc, setPseudoSrc] = useState(src);
        // Ref to store image file if crashing occured
        const fileRef = useRef<File|null>(null);

        const Children = children;
        const Extra = <>
            <MediaCardProgress value={progress}/>
            <MediaCardActions 
                deleteHandler={deleteHandler}
                resendHandler={resendHandler}
            />
        </>

        /**
         * onDrop Event handler function for alerting errors and sending data to the server
         * used Rest API call for upload image showing progress bar during upload 
         * @param files Accepted files from uploader
         * @param rejections File rejections from uploader
         */
        const dropHandler = (files: FileExtra[], rejections: Array<{file: FileExtra, errors: FileError[]}>) => {
            setProgress(0);
            comment(`MediaWrapperWrapped component's dropHandler fuction`, 'files value:', files);

            const fileRejections: string = rejections.reduce((prev, { file, errors }, i, rejections) => {
                // Pack all errors to the single string message
                const error = errors.reduce((prev, curr, i, errors) => {
                    // Accumulates all messages in one and add ',' if there is not last element
                    return prev + curr.message.concat(i === errors.length - 1 ? '' : ', ' );
                }, '');

                return prev + `${file.name} (${file.size}Байт): ${error}`.concat(i === rejections.length - 1 ? '' : ', ' )
            }, '');

            if(fileRejections.length !== 0) {
                setError(fileRejections);
            } else {
                setError(undefined);
                uploadImage(files[0]);
            }
        };

        function progressHandler({total, loaded}: UploadImageProgressEvent) {
            const progress = Math.round((loaded * 100) / total);
            setProgress(progress);
        }

        function uploadImage(file: File) {
            remover()
            .then(() => uploader(file, progressHandler) )
            // After uploading use pseoudo url as src for image as query refetching is not occured after uploading image
            .then( payload => {
                if(payload !== null) {
                    setPseudoSrc(payload.data);
                }
            })
            // No matter if promise will be rejected or fulfilled save the file
            .finally(() => fileRef.current = file);
        }

        function deleteHandler() {
            remover()
            .then(() => setPseudoSrc(undefined))
            .finally(() => setProgress(0));
        };

        function resendHandler() {
            const file = fileRef.current;
            if(file) {
                setProgress(0);
                uploadImage(file);
            }
        };

        return (
            <MediaCard 
                title={title} 
                description={description} 
                placeholder={placeholder}
                error={error}
                src={pseudoSrc} 
                overridedClasses={overridedClasses}
                extra={ Extra }
            >
                <Children onDrop={dropHandler}/>
            </MediaCard>
        );
    };

    if(process.env.NODE_ENV !== 'production') {
        MediaCardWrappedComponent.displayName = 'MediaCardWrapped'
    }

    return MediaCardWrappedComponent;
}   

export default withMediaCard