import React from 'react';
import { SelectorEditField } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext';
import AsyncSelectInput from '../components/AsyncSelectInput';
import { SortableSelectInputBase } from './withSelector';

export interface UseAsyncOptionsLoader {
    (): (inputValue: string) => Promise<SelectorEditField[]>
}

export interface UseDeleteHandler {
    (): (selectorID: number) => Promise<unknown>;
}

export function withAsyncSelector(
    useAsyncOptionsLoader: UseAsyncOptionsLoader,
    useDeleteHandler: UseDeleteHandler
): React.ComponentType<SortableSelectInputBase> {
    const AsyncSelector: React.ComponentType<SortableSelectInputBase> = function AsyncSelector(props): JSX.Element {

        return (
            <AsyncSelectInput
                useAsyncOptionsLoader={useAsyncOptionsLoader}
                useDeleteHandler={useDeleteHandler}
                innerProps={props}
            />
        );
    }

    return AsyncSelector;
}

