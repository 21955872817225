import React, { useContext } from 'react'

type HeaderProviderProps = {
    selectors: React.ReactElement
} & ReactContainer

type HeaderContextValue = React.ReactElement|null

function HeaderProvider( { children, selectors }: HeaderProviderProps) {
    
    return (
        <HeaderContext.Provider value={selectors}>
            { children }
        </HeaderContext.Provider>
    );
}

const HeaderContext = React.createContext<HeaderContextValue>(null);
const useHeader = () => useContext(HeaderContext);

export default HeaderProvider
export { useHeader }