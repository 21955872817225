import React from 'react'
import { useHistory } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { comment } from 'src/util/comment'
import { createParticipantsRouteLink } from '../utils/createParticipantsRouteLink'
import { defaultSearchParams } from '../utils/ParticipantsConfigAssets'

export function useSetInitialQueryParams() {
    const { 
        navigationLinks: {participantsLink} 
    } = useNavigationLinksContext();

    const history = useHistory();

    React.useEffect(() => {
        const constraition = participantsLink === '';

        if(constraition) {
            comment('useSetInitialParams on ParticipantsList','Setted initial params');
            history.push(
                createParticipantsRouteLink(defaultSearchParams)
            )
        }
    }, [participantsLink, history]);    
}