import { ApolloError, useMutation } from '@apollo/client'
import { LoginMutationArgs, LoginMutationData, LOGIN_MUTATION } from 'src/api/graphql/mutations/loginMutation'
import { useAuthGateway } from 'src/app/contexts/AuthenticationContext'
import { setToken } from 'src/util/token'
import { LoginInput } from 'src/api/graphql/types'
import { comment } from 'src/util/comment'

type useLoginMutationArgs = {
    completeHandler?: (data: LoginMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use Login mutatation, then save returned token to localStorage and User in AuthGatewayContext
 * Finally move to the '/dashboard' path. Additionally could execute users compleHandler
 * and errorHandler
 */
function useLoginMutation(args: useLoginMutationArgs) {
    const { completeHandler, errorHandler } = args;

    const { setAuthResult } = useAuthGateway();

    const [ login ] = useMutation<LoginMutationData, LoginMutationArgs>(LOGIN_MUTATION, {
        onCompleted: data => {
            // if(data) {
                const { authToken, user } = data.login;

                setAuthResult && setAuthResult({ user });
                setToken(authToken);
                
                comment('UseLoginMutation','Login response', data);
            // } else {
            //     console.error(`Response from Login Mutation could not be ${data}`, data);
            // }

            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        },
        fetchPolicy: 'no-cache'
    });

    return async function(input: LoginInput ) {
        await login({
            variables: {
                input
            }
        });
    }
}

export default useLoginMutation