import React from 'react'
import { useSerialFilesContext } from '../../contexts/SerialFilesContext'
import SeasonElement from './components/SeasonElement'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isActiveId } from '../../helpers/isActive'
import withCard from '../../../hocs/withCard'
import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'
import SeasonCollectionHeader from './components/SeasonCollectionHeader'
import SeasonAddElement from './components/SeasonAddElement'
import SeasonAddModal from './components/SeasonAddModal'
import SeasonEditModal from './components/SeasonEditModal'
import { isNull } from 'src/util/isNull'

function SeasonsCollection() {
    const serialFilesContext = useSerialFilesContext();
    const serialCollectionProps = React.useMemo(() => {
        if( isNull(serialFilesContext) ) {
            throw new Error('serialFilesContext could not be null');
        }  
        
        return {
            seasons: serialFilesContext.getAllSeasonsResult.data.slice(),
            loading: serialFilesContext.getAllSeasonsResult.loading,
            error: serialFilesContext.getAllSeasonsResult.error,
            activeSeason: serialFilesContext.activeSeason,
            activateSeasonCallback: serialFilesContext.activateSeasonCallback,
        }
    }, [serialFilesContext]);

    const [showAddModal, setShowAddModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [editModalID, setEditModalID] = React.useState(0);

    const closeAddModal = () => {
        setShowAddModal(false);
    }
    const openAddModal = () => {
        setShowAddModal(true);
    }

    const closeEditModal = () => {
        setShowEditModal(false);
    }
    const openEditModal = () => {
        setShowEditModal(true);
    }

    const seasonAddCallback: React.MouseEventHandler = e => {
        openAddModal();
    }

    const seasonEditCallback = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        openEditModal();
        setEditModalID(id);
    }
    
    const { loading, seasons, error, activeSeason, activateSeasonCallback } = serialCollectionProps;

    if(loading) {
        return (
            <div>
                Seasons are loading...
            </div>
        )
    }

    if(error) {
        return(
            <div>
                Error: {error.message.toUpperCase()} while trying to load seasons list 
            </div>
        );
    }

    return (
        <>
            <PerfectScrollbar 
                className='seasons-collection-scrollbar' 
                options={{
                    suppressScrollX: true,
                    wheelPropagation: false
                }}
            >
                <div className='seasons-collection-content overflow-hidden'>
                <SeasonAddElement seasonAddCallback={seasonAddCallback}/>

                    {
                        seasons.map(
                            (season, index, array) => <SeasonElement 
                                key={season.id} 
                                data={season}
                                activateSeasonCallback={activateSeasonCallback}
                                isActive={ isActiveId(activeSeason, season.id, array.length, index) }
                                seasonEditCallback={seasonEditCallback}
                            /> 
                        )
                    }

                </div>
            </PerfectScrollbar>

            <SeasonAddModal show={showAddModal} onHide={closeAddModal}/>
            <SeasonEditModal show={showEditModal} onHide={closeEditModal} seasonID={editModalID}/>
        </>
    );
}

const CardWrappedSeasonsCollection = withCard(
    SeasonsCollection, 
    <CardHeaderTitle className='w-100'>
        <SeasonCollectionHeader/>
    </CardHeaderTitle>,
    'seasons-card'
);

export default CardWrappedSeasonsCollection