import { FileError } from 'react-dropzone'

export interface FileExtra extends File {
    width?: number, 
    height?: number,
}

const VERTICAL_IMAGE = {
    width: 640,
    height: 900
}
const HORIZONTAL_IMAGE = {
    width: 1920,
    height: 1080
}

const AVATAR_IMAGE = {
    width: 1024,
    height: 1024
}

const basicValidator = <T extends FileExtra = FileExtra>(width: number, height: number, file: T): FileError|null => {
    const imageFile = file

    if(imageFile.width !== width) {
        return {
            message: `Ширина: ${imageFile.width}px не соответсвует`,
            code: 'file-invalid-dimensions'
        }
    } else if(imageFile.height !== height) {
        return {
            message: 'Высота не соответсвует',
            code: 'file-invalid-dimensions'
        }
    } else if(imageFile.width !== width && imageFile.height !== height) {
        return {
            message: 'Высота и ширина не соответсвует',
            code: 'file-invalid-dimensions'
        }
    }

    return null;
}

const verticalImageValidator = basicValidator.bind(null, VERTICAL_IMAGE.width, VERTICAL_IMAGE.height)
const horizontalImageValidator = basicValidator.bind(null, HORIZONTAL_IMAGE.width, HORIZONTAL_IMAGE.height)
const titleImageValidator = () => null;
const avatarImageValidator = basicValidator.bind(null, AVATAR_IMAGE.width, AVATAR_IMAGE.height);

const MAX_IMAGE_SIZE = 1024 * 1024;
const VERTICAL_IMAGE_MIME_TYPE = 'image/jpeg';
const HORIZONTAL_IMAGE_MIME_TYPE = 'image/jpeg';
const TITLE_IMAGE_MIME_TYPE = 'image/png';
const AVATAR_IMAGE_MIME_TYPE = 'image/jpeg';

export const ASSETS = {
    MAX_IMAGE_SIZE,
    VERTICAL_IMAGE_MIME_TYPE,
    HORIZONTAL_IMAGE_MIME_TYPE,
    TITLE_IMAGE_MIME_TYPE,
    AVATAR_IMAGE_MIME_TYPE
}

export {
    verticalImageValidator,
    horizontalImageValidator,
    titleImageValidator,
    avatarImageValidator
}