import React, { createContext, useContext, useEffect, useState } from 'react'
import { User } from 'src/api/graphql/types';
import { comment } from 'src/util/comment';
import { clearToken, getToken } from 'src/util/token'
import useUserQuery from '../hooks/query/useUser';
import { NotificationVariants, useNotification } from './NotificationContext';
import { useSplashScreen } from './SplashScreenContext';

type AuthResult = { 
    user: User|null, 
    error?: string,
}

type AuthGatewayContextValue = {
    authResult: AuthResult,
    setAuthResult?: React.Dispatch<React.SetStateAction<AuthResult>>,
}

// Token stored in localStorage persistently
const authToken = getToken();

/**
 * Stores Currently active user. And Validate it on application load form localStorage
 * Until validating proccessing doen't load any app content
 */
function AuthGatewayProvider(props: ReactContainer) {
    const [authResult, setAuthResult] = useState<AuthResult>( { user: null } );
    const setSplashScreenVisible = useSplashScreen(); 
    const { setNotification } = useNotification();
    const {loading, data, error} = useUserQuery(authToken);
    const [validating, setValidating] = useState(true);
    
    useEffect(() => {
        if(loading) {
            setSplashScreenVisible(true);
        } else if(data) {
            setAuthResult({user: data.user});
            comment('AuthGatewayContext','token from localStorage verified and User set to Context');

            setValidating(false);
            setSplashScreenVisible(false);
        } else if(error) {
            setAuthResult({user: null, error: error.message});
            setSplashScreenVisible(false);
            setNotification({message: 'Валидация токена прошла не успешно ', variant: NotificationVariants.error});
            clearToken();
            console.error(error.message);
            setValidating(false);
        }
    }, [setSplashScreenVisible,setNotification,setAuthResult, loading, data, error]);

    const { children } = props;
    const value = { authResult, setAuthResult };

    return (
        <AuthGatewayContext.Provider value={value}>
            { validating ? <></> : children }
        </AuthGatewayContext.Provider>
    );
}

const initialValue = {
    authResult: { user: null },
};

const AuthGatewayContext = createContext<AuthGatewayContextValue> (initialValue);
const useAuthGateway = () => useContext(AuthGatewayContext);

export default AuthGatewayProvider
export { useAuthGateway }