import { gql } from '@apollo/client'
import { Actor } from '../types'

export interface GetAllActorsArgs {} 

export interface GetAllActorsData {
    getAllActors: Actor[]
}

export const GET_ALL_ACTORS_QUERY = gql`
    query GetAllActors {
        getAllActors {
            id
            name
            image
        }
    }
`