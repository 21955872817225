import { gql } from '@apollo/client'
import { Movie } from 'src/api/graphql/types'

export interface GetMovieByIdArgs {
   id: number,
} 

export interface GetMovieByIdData {
    getMovieById: Movie
}

export const GET_MOVIE_BY_ID_QUERY = gql`
    query getMovieByIdQuery( $id: Int!) {
        getMovieById(id: $id) {
            id
            typeId
            name
            release
            ratingKP
            ratingIMDB
            status
            duration
            language {
                id
                name
                abbreviated
            }
            category {
                id
                name
            }
            age
            description
            genres {
                id
                name
            }
            tags {
                id
                name
            }
            countries {
                id
                name
            }
            actors {
                id
                name
            }
            directors {
                id
                name
            }
            image {
                HorizontalWithName {
                    standard {
                      url
                    }
                }
                HorizontalWithoutName {
                    standard {
                        url
                    }
                }
                Vertical {
                    standard {
                        url
                    }
                }
                Name {
                    url
                }
            }
        }
    }
`