import { gql } from '@apollo/client'
import { FileInfo } from '../types'

export interface GetMovieFileInfoArgs {
    movieID: number
} 

export interface GetMovieFileInfoData {
    GetMovieFileInfo: FileInfo
}

export const GET_MOVIE_FILE_INFO_QUERY = gql`
    query GetMovieFileInfo($movieID: Int!) {
        GetMovieFileInfo(movieID: $movieID) {
            UploadStatus
            ProcessStatusLQ
            ProcessStatusHQ
            FileID
            MovieID
        }
    }
`