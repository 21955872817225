const TOKEN = 'BeletAdminToken';
const { stringify, parse } = JSON;

function getToken(): string|null {
    const token = localStorage.getItem(TOKEN);
    return token ? parse(token) : null;
}

function setToken(token: string) {
    localStorage.setItem(TOKEN, stringify(token) );
}

function clearToken() {
    localStorage.removeItem(TOKEN)
}

export { getToken, setToken, clearToken }