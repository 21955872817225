import BasicImageUploader from './components/BasicImageUploader'
import withImplementation from './hocs/withImplementation'
import { ASSETS, avatarImageValidator, horizontalImageValidator, titleImageValidator, verticalImageValidator } from './helpers/ImageUploaderHelpers'

const { 
    MAX_IMAGE_SIZE, 
    VERTICAL_IMAGE_MIME_TYPE, 
    HORIZONTAL_IMAGE_MIME_TYPE, 
    TITLE_IMAGE_MIME_TYPE,
    AVATAR_IMAGE_MIME_TYPE
} = ASSETS;

/**
 * HOC wrapped implementations
 */
const VerticalImageUploader = withImplementation(
    MAX_IMAGE_SIZE, 
    VERTICAL_IMAGE_MIME_TYPE, 
    verticalImageValidator,
    'vertical-image-uploader'
)(BasicImageUploader);

const HorizontalImageUploader = withImplementation(
    MAX_IMAGE_SIZE, 
    HORIZONTAL_IMAGE_MIME_TYPE, 
    horizontalImageValidator,
    'horizontal-image-uploader'
)(BasicImageUploader);

const TitleImageUploader = withImplementation(
    MAX_IMAGE_SIZE, 
    TITLE_IMAGE_MIME_TYPE, 
    titleImageValidator,
    'title-image-uploader'
)(BasicImageUploader);

const AvatarImageUploader = withImplementation(
    MAX_IMAGE_SIZE, 
    AVATAR_IMAGE_MIME_TYPE,
    avatarImageValidator,
    'avatar-image-uploader',
)(BasicImageUploader);

// eslint-disable-next-line
export default {
    VerticalImageUploader,
    HorizontalImageUploader,
    TitleImageUploader,
    AvatarImageUploader
}