import { makeStyles } from '@material-ui/core'

export const useSortableSelectInputStyles = makeStyles( theme => ({
    value: {
        backgroundColor: '#404040'
    },
    remove: {
        backgroundColor: 'black',
    },
    label: {
        color: 'white !important',
    }
}) );