import { withUploader } from 'src/app/components/ContentService/EditContent/hocs/withUploader'
import { VideoUploader } from 'src/app/components/ContentService/VideoUploader'
import withCard from '../../../hocs/withCard'
import useFilmDropHandler from './hooks/useFilmDropHandler'
import FilmHeader from './components/FilmHeader'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import FilmName from './components/FilmName'
import FilmDuration from './components/FilmDuration'
import FilmActiveStatus from './components/FilmActiveStatus'
import FilmProcessStatus from './components/FilmProcessStatus'
import { QUALITY_TYPES, TRANSCODE_STATUS } from 'src/api/graphql/types'
import FilmUploadStatus from './components/FilmUploadStatus'

type FilmElementProps = {
    data: {
        id: number,
        name: string,
        status: ACTIVATION_STATUS,
        duration: number,
        uploaded: boolean|undefined,
        processStatus: {
            ProcessStatusLQ: TRANSCODE_STATUS;
            ProcessStatusHQ: TRANSCODE_STATUS;
        } | undefined
    },
    isActive: boolean,
    statusChangeHandler: React.MouseEventHandler
}

function FilmElement(props: FilmElementProps) {
    const { statusChangeHandler } = props;
    const { name, status, duration, uploaded, processStatus } = props.data;

    const ProcessStatusLQ = processStatus ? processStatus.ProcessStatusLQ : undefined
    const ProcessStatusHQ = processStatus ? processStatus.ProcessStatusHQ : undefined

    return (
        <div>
            <FilmHeader status={status} statusChangeHandler={statusChangeHandler}/>
            <FilmName name={name}/>
            <FilmActiveStatus status={status}/>
            <FilmProcessStatus statusData={ProcessStatusLQ} qualityID={QUALITY_TYPES.QUALITY_480}/>
            <FilmProcessStatus statusData={ProcessStatusHQ} qualityID={QUALITY_TYPES.QUALITY_1080}/>
            <FilmUploadStatus uploaded={uploaded}/>
            <FilmDuration duration={duration} />
        </div>
    );
}

const UploaderWrappedFilmElement = withUploader(
    FilmElement,
    VideoUploader,
    useFilmDropHandler, 
    'film'
);

const CardAndUploaderWrapedFilmElement = withCard<FilmElementProps>(
    UploaderWrappedFilmElement,
    'Файл',
    'film-card'
);

export default CardAndUploaderWrapedFilmElement