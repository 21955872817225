const vertical = '/remove/vertical';
const horizontal = '/remove/horizontalWithName';
const horizontalRaw = '/remove/horizontalWithOutName';
const title = '/remove/name';
const actor = '/remove/actorImage';

const IMAGE_REMOVE_API = {
    vertical,
    horizontal,
    horizontalRaw,
    title,
    actor
}

export default IMAGE_REMOVE_API