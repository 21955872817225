import React from 'react'
import { Modal } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import './styles.css'

type BasicModalProps = {
    show: boolean,
    onHide: () => void,
    children: React.ReactElement
}

function BasicModal(props: BasicModalProps) {
    const { show, onHide, children } = props;

    return (
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header className='d-flex justify-content-end'>
            <span onClick={onHide} className='basic-modal-close-btn'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Times.svg')} />
            </span>
            
          </Modal.Header>

          <Modal.Body>
            { children }
          </Modal.Body>
        </Modal>
      );
}

export default BasicModal