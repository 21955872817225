import React from 'react'
import { useQuery } from '@apollo/client'
import { GetAllGenresData, GetAllGenresArgs, GET_ALL_GENRES_QUERY } from 'src/api/graphql/queries/getAllGenres'

export function useGenres() {
    const { loading, data } = useQuery<GetAllGenresData, GetAllGenresArgs>(GET_ALL_GENRES_QUERY);
    
    const genres = React.useMemo(() => {
        if(data !== undefined) {
            return {
                loading,
                genres: data.getAllGenres
            };
        }

        return {
            loading,
            genres: []
        };
    }, [data, loading]);

    return genres;
}