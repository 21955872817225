import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllLanguagesArgs, GetAllLanguagesData, GET_ALL_LANGUAGES_QUERY } from 'src/api/graphql/queries/getAllLanguages'
import { Language } from 'src/api/graphql/types'

type useGetAllLanguagesQueryArgs = {
    completeHandler?: (data: Language[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllLanguagesResult = {
    loading: boolean,
    data: Language[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<GetAllLanguagesArgs> | undefined) => Promise<ApolloQueryResult<GetAllLanguagesData>>
}

function useGetAllLanguagesQuery(args: useGetAllLanguagesQueryArgs = {}): useGetAllLanguagesResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllLanguagesData, GetAllLanguagesArgs>(GET_ALL_LANGUAGES_QUERY, {
        variables: {},
        onCompleted: (data: GetAllLanguagesData) => {
            completeHandler && completeHandler(data.getAllLanguages);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error)
        }

    });
    return {
        loading, 
        data: data ? data.getAllLanguages: undefined,
        error, 
        refetch
    }
}

export default useGetAllLanguagesQuery