import { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import HeaderSelectorModal from './HeaderSelectorModal'

type HeaderSelectorElementProps = {
  selector: AppSelector
}

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      display: 'flex'
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
}));

function HeaderSelectorElement(props: HeaderSelectorElementProps) {
    const classes = useStyles();
    const { selector } = props;
    const [isOpen, setIsOpen] = useState(false);

    const hideHandler = () => setIsOpen(false);
    const showHandler = () => setIsOpen(true);

    return (
      <>
        {
          <HeaderSelectorModal show={isOpen} hideHandler={hideHandler} selector={selector}/>
        }

        { /*begin::1 Level*/ }
        <li className={`menu-item menu-item-rel`} key={selector.code}>
            <Button color='primary' variant='text' className={`${classes.button}`} onClick={ showHandler }>
                <span className='menu-text'>{selector.name}</span>
            </Button>
        </li>
        { /*end::1 Level*/ }
      </>
    )
}

export default HeaderSelectorElement