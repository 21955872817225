import React, { forwardRef } from 'react';
import clsx from 'clsx';

type CardHeaderToolbarProps = {
  children: React.ReactElement,
  className?: string,
  props?: any[]
}

export const CardHeaderToolbar = forwardRef<HTMLDivElement | null, CardHeaderToolbarProps>(
    ({ children, className, ...props }, ref) => (
      <div {...props} ref={ref} className={clsx('card-toolbar', className)}>
        {children}
      </div>
    )
  );