import React from 'react'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import EditFilesTrailer from './components/EditFilesTrailer'
import { getEditFilesByType } from './helpers/getEditFilesByType'

function EditFiles(): React.ReactElement {
    const editContentContext = useContentEditContext();
    const editFilesUIProps = React.useMemo(() => ({
        id: editContentContext.id,
        type: editContentContext.typeId,
    }), [editContentContext]);

    const EditFilesElement = getEditFilesByType(editFilesUIProps.type, editFilesUIProps.id);

    return (
        <>
            <EditFilesTrailer/>
            <EditFilesElement/>
        </>
    );
}

export default EditFiles