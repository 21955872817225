import React from 'react'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'

type CardWrappedProcessingTableProps = {
    style?: React.CSSProperties, 
}

export function withProcessingCard(ProcessingTable: React.ComponentType<unknown>, title: string, className: string, name: string) {
    const CardWrappedProcessingTable: React.ComponentType<CardWrappedProcessingTableProps> = props => {
        return (
            <Card className={className} style={props.style}>
                <CardHeader title={title}/>
                <CardBody>
                    <ErrorBoundary> 
                        <ProcessingTable {...props}/>
                    </ErrorBoundary>
                </CardBody>
            </Card>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        CardWrappedProcessingTable.displayName = name
    }

    return CardWrappedProcessingTable;
}
