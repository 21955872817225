import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tape, TapeStatus } from 'src/api/graphql/types'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { useTapeOptions } from '../hooks/useTapeOptions'
import { useMutation } from '@apollo/client'
import { ChangeStatusHomePageCategoryArgs, ChangeStatusHomePageCategoryData, CHANGE_STATUS_HOME_PAGE_MUTATION } from 'src/api/graphql/mutations/ChangeStatusHomePageCategory'
import { GET_HOME_PAGE_CATEGORIES_QUERY } from 'src/api/graphql/queries/GetHomePageCategoriesQuery'

export const OrderColumnFormatter = (cell: unknown, row: Tape, index: number) => {
    return (
        <span>
            { index + 1 }
        </span>
    );
}

export const IdColumnFormatter = (cell: Tape['id'], row: Tape, index: number) => {
    return (
        <span className='label label-lg label-light-primary label-inline'>
            { cell }
        </span>
    );
}


export const TitleColumnFormatter = (cell: Tape['TitleTk']|Tape['TitleRu'], row: Tape, index: number) => {
    return (
        <span>
            { cell }
        </span>
    );
}


export const GenreIdColumnFormatter = (cell: Tape['genreID'], row: Tape, index: number) => {
    return (
        <span className='label label-lg label-light label-inline'>
            { cell }
        </span>
    );
}


export const PositionColumnFormatter = (cell: Tape['position'], row: Tape, index: number) => {
    return (
        <span>
            { cell }
        </span>
    );
}


export const CategoryTypeColumnFormatter = (cell: Tape['categoryType'], row: Tape, index: number) => {
    return (
        <CategoryTypeColumnRenderer categoryType={cell}/>
    );
}

function CategoryTypeColumnRenderer(props: {categoryType: Tape['categoryType']}) {
    const { categoryType } = props;
    const { CategoryType, loading } = useTapeOptions();
    const size = React.useMemo(() => {
        const finded = CategoryType.find(item => item.Value === categoryType);
        if(loading) return null;
        if(finded !== undefined) {
            return finded.Title;
        }
        return 'Не выбран';
    }, [CategoryType, categoryType, loading]);
    return (
        <span>
            { size }
        </span>
    );
}

export const GenreNameColumnFormatter = (cell: Tape['GenreName'], row: Tape, index: number) => {
    return (
        <span>
            { cell }
        </span>
    );
}


export const SortValueColumnFormatter = (cell: Tape['sortValue'], row: Tape, index: number) => {
    return (
        <SortValueColumnRenderer sortValue={ cell }/>
    );
}

function SortValueColumnRenderer(props: {sortValue: Tape['sortValue']}) {
    const { sortValue } = props;
    const {Sort, loading } = useTapeOptions();
    const size = React.useMemo(() => {
        const finded = Sort.find(item => item.Value === sortValue);
        if(loading) return null;
        if(finded !== undefined) {
            return finded.Title;
        }
        return 'Без размера';
    }, [Sort, sortValue, loading]);
    return (
        <span>
            { size }
        </span>
    );
}

export const ItemSizeValueColumnFormatter = (cell: Tape['itemSizeValue'], row: Tape, index: number) => {
    return (
        <ItemSizeValueColumnRenderer sizeValue={ cell}/>
    );
}

function ItemSizeValueColumnRenderer(props: { sizeValue: Tape['itemSizeValue']}) {
    const { sizeValue } = props;
    const { ItemSize, loading } = useTapeOptions();
    const size = React.useMemo(() => {
        const finded = ItemSize.find(item => item.Value === sizeValue);
        if(loading) return null;
        if(finded !== undefined) {
            return finded.Title;
        }
        return 'Без размера';
    }, [ItemSize, sizeValue, loading]);
    return (
        <span>
            { size }
        </span>
    );
}

export const ActiveColumnFormatter = (cell: Tape['active'], row: Tape, index: number) => {
    return <ActiveColumnRenderer active={cell} id={row.id}/>
}

function ActiveColumnRenderer(props: { active: Tape['active'], id: number}) {
    const { active, id } = props;
    const className = `svg-icon svg-icon-md svg-icon-${
        Boolean(active) ? 'success' : 'danger'
    }`;

    const [changeTrigger] = useMutation<ChangeStatusHomePageCategoryData, ChangeStatusHomePageCategoryArgs>(CHANGE_STATUS_HOME_PAGE_MUTATION, {
        refetchQueries: [GET_HOME_PAGE_CATEGORIES_QUERY]
    });

    const changeStatusHandler = () => {
        changeTrigger({
            variables: {
                id,
                status: active === TapeStatus.ACTIVE ? TapeStatus.INACTIVE : TapeStatus.ACTIVE
            }
        })
    }

    return (
        <span className={className} onClick={changeStatusHandler} style={{cursor: 'pointer'}}>
            <SVG
              description='Изменить'
              title='Изменить'
              src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
            />
        </span>
    );
}

export function ActionColumnFormatter(
    cellContent: any,
    row: Tape,
    rowIndex: number
  ) {
    return (
        <ActionColumnRenderer id={row.id}/>
    );
}

function ActionColumnRenderer({id}: {id: number}) {
    const history = useHistory();
    const clickHandler: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
        history.push(
            ROUTES_LIST.MAIN_PAGE
            .concat('/edit')
            .concat(`?id=${id}`)
        );
    }, [history, id]);

    return (
        <button
          title='Изменить'
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={clickHandler}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <SVG
              description='Изменить'
              title='Изменить'
              src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
            />
          </span>
        </button>
    );
}