import { gql } from '@apollo/client'
import { Response } from '../types'

export interface ChangeMovieStatusMutationArgs {
    movieID: number
    status: number
    typeID: number
} 
export interface ChangeMovieStatusMutationData {
    ChangeMovieStatus: Response
}

export const CHANGE_MOVIE_STATUS_MUTATION = gql`
    mutation ChangeMovieStatusMutation( $movieID: Int!, $status: Int!, $typeID: Int! ) {
        ChangeMovieStatus(movieID: $movieID, status: $status, typeID: $typeID) {
            status
            message
        }
    }
`