import { gql } from '@apollo/client'
import { Response, TapeStatus } from 'src/api/graphql/types'

export interface ChangeStatusHomePageCategoryArgs {
    id: number
    status: TapeStatus
} 
export interface ChangeStatusHomePageCategoryData {
    ChangeStatusHomePageCategory: Response
}

export const CHANGE_STATUS_HOME_PAGE_MUTATION = gql`
    mutation ChangeStatusHomePageCategoryMutation($id: Int!, $status: Int!) {
        ChangeStatusHomePageCategory(id: $id, status: $status) {
            status
            message
        }
    }
`