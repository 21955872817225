import { useApolloClient } from '@apollo/client';
import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { VideoFileUploadContext } from 'src/app/contexts/VideoFileUploadContext'
import { ExternalID } from 'src/uploader/types';
import { useContextSelector } from 'use-context-selector'
import { MovieLike, UploadIDFormatter, UploadNameFormatter, UploadOrderFormatter, UploadRemoveActionFormatter } from '../../helpers/UploadFormatter';

function RejectedTable() {
    const rejected = useContextSelector(VideoFileUploadContext, v => v.rejected);
    const clearRejected = useContextSelector(VideoFileUploadContext, v => v.clearRejected);
    // const clearAllRejected = useContextSelector(VideoFileUploadContext, v => v.clearAllRejected);

    const client = useApolloClient();
    const normalizedCache  = client.cache.extract();

    const data = React.useMemo<MovieLike[]>(() => { 
        return Array.from(rejected.keys()).map( (id: ExternalID) => {
            const rejectedItem = rejected.get(id, undefined);
            const title = rejectedItem ? rejectedItem.title : 'Неизвестный';
            return {
                id,
                cache: normalizedCache as Record<string, object & {name: string}>,
                title
            }
        });
    }, [rejected, normalizedCache]);
    
    const columns: ColumnDescription<MovieLike>[] = [
        {
            dataField: '#',
            text: '#',
            isDummyField: true,
            formatter: UploadOrderFormatter,
        },
        {
            dataField: 'id',
            text: 'ID',
            formatter: UploadIDFormatter,
        },
        {
            dataField: 'name',
            text: 'Название',
            formatter: UploadNameFormatter,
        },
        {
            dataField: 'action',
            text: 'Действия',
            formatter: UploadRemoveActionFormatter,
            formatExtraData: clearRejected,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '50px',
            },
        },
    ];
      
    const entities = data ?? [];

    return (
        <>
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                data={entities}
                columns={columns}
                keyField={'id'}
            />
        </>
    );
}

export default RejectedTable