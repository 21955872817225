import { Host, HostAndPagination } from 'src/api/graphql/types'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { GetAllHostsArgs, GetAllHostsData, GET_ALL_HOSTS_QUERY } from 'src/api/graphql/queries/getAllHosts'
import { GetHostsByRegionsData, GetHostsByRegionsArgs, GET_ALL_HOSTS_BY_REGION_QUERY } from 'src/api/graphql/queries/getHostsByRegions'
import { SearchHostByIPData, SearchHostByIPArgs, SEARCH_HOST_BY_IP_QUERY } from 'src/api/graphql/queries/SearchHostByIP'
import { HOST_ACTIONS } from '..'
import React from 'react'
import { ABONENT_SORT_TYPES } from 'src/app/assets/AbonentTypes'
import { SearchHostByPhoneArgs, SearchHostByPhoneData, SEARCH_HOST_BY_PHONE_QUERY } from 'src/api/graphql/queries/SearchHostByPhone'

export interface HostResult {
    data: HostAndPagination,
    error: ApolloError|undefined,
    loading: boolean
}

export interface ExtractHostArgs {
    ip: string
    phone: string
    regionID: number
    limit: number
    page: number
    sortField: keyof Host
    sortOrder: 'asc'|'desc'
    actionType: HOST_ACTIONS
}

export function useExtractHosts(args: ExtractHostArgs): HostResult {
    const { ip, phone, regionID, limit, page, sortField, sortOrder, actionType } = args;

    const [ getAllHosts, getAllHostsResult ] = useLazyQuery<GetAllHostsData, GetAllHostsArgs>(GET_ALL_HOSTS_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    const [ filterHostsByRegion, filterHostsByRegionResult ] = useLazyQuery<GetHostsByRegionsData, GetHostsByRegionsArgs>(GET_ALL_HOSTS_BY_REGION_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    const [ searchHostsByIP, searchHostsByIPResult ] = useLazyQuery<SearchHostByIPData, SearchHostByIPArgs>(SEARCH_HOST_BY_IP_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    const [ searchHostsByPhone, searchHostsByPhoneResult ] = useLazyQuery<SearchHostByPhoneData, SearchHostByPhoneArgs>(SEARCH_HOST_BY_PHONE_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    React.useEffect(() => {
        switch (actionType) {
            case HOST_ACTIONS.GET: {
                getAllHosts({variables: {
                    limit, page, sort: getSortParams(sortField, sortOrder)
                }});
                break;
            }
                
            case HOST_ACTIONS.FILTER: {
                filterHostsByRegion({variables: {
                    limit, page, regionID, sort: getSortParams(sortField, sortOrder)
                }});
                break;
            }
            case HOST_ACTIONS.SEARCH_BY_IP: {
                searchHostsByIP({variables: {
                    ip
                }});
                break;
            }
            case HOST_ACTIONS.SEARCH_BY_PHONE: {
                searchHostsByPhone({variables: {
                    phone
                }});
                break;
            }
            default: throw new Error();
        }
    }, [
        getAllHosts, filterHostsByRegion, searchHostsByIP, searchHostsByPhone,
        ip, phone, regionID, limit, page, sortField, sortOrder, actionType
    ]);
    

    const executionResult = React.useMemo<HostResult>(() => {
        switch (actionType) {
            case HOST_ACTIONS.GET: {
                return {
                    data: getAllHostsResult.data ? getAllHostsResult.data.getAllHosts : placeholder,
                    error: getAllHostsResult.error,
                    loading: getAllHostsResult.loading
                };
            }
                
            case HOST_ACTIONS.FILTER: {
                return {
                    data: filterHostsByRegionResult.data ? filterHostsByRegionResult.data.getHostsByRegions : placeholder,
                    error: filterHostsByRegionResult.error,
                    loading: filterHostsByRegionResult.loading
                };
            }
            case HOST_ACTIONS.SEARCH_BY_IP: {
                return {
                    data: searchHostsByIPResult.data ? {hosts: [searchHostsByIPResult.data.SearchHostByIP], Pagination: placeholder.Pagination} : placeholder,
                    error: searchHostsByIPResult.error,
                    loading: searchHostsByIPResult.loading
                };
            }
            case HOST_ACTIONS.SEARCH_BY_PHONE: {
                return {
                    data: searchHostsByPhoneResult.data ? {hosts: searchHostsByPhoneResult.data.SearchHostByPhone, Pagination: placeholder.Pagination} : placeholder,
                    error: searchHostsByPhoneResult.error,
                    loading: searchHostsByPhoneResult.loading
                };
            }
            default: throw new Error();
        }
    }, [
        getAllHostsResult,
        filterHostsByRegionResult,
        searchHostsByIPResult,
        searchHostsByPhoneResult,
        actionType
    ]);
    
    return executionResult;
}

function getSortParams( sortField: keyof Host, sortOrder: 'asc'|'desc'): ABONENT_SORT_TYPES {
    switch (sortField) {
        case 'type': {
            if(sortOrder === 'asc') return ABONENT_SORT_TYPES.TELECOM;
            return ABONENT_SORT_TYPES.GTS;
        }
        case 'active': {
            if(sortOrder === 'asc') return ABONENT_SORT_TYPES.ACTIVE;
            return ABONENT_SORT_TYPES.NON_ACTIVE;
        }
        default: return ABONENT_SORT_TYPES.DEFAULT;
    }
}

const placeholder: HostAndPagination = {
    hosts: [],
    Pagination: {
        TotalCount: 0,
        TotalPage: 0,
        CurrentPage: 0, 
        Limit: 0
    }
}