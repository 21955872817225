import React, { createContext, useContext, useState } from 'react'

export interface BreadCrumb {
    pathname: string, 
    title: string
}

type SubheaderContextValue = { 
    title: string,
    setTitle?: React.Dispatch<React.SetStateAction<string>>,
    breadcrumbs: BreadCrumb[],
    setBreadcrumbs?: React.Dispatch<React.SetStateAction<any>>
}


function MetronicSubheaderProvider({ children }: ReactContainer) {
  const [title, setTitle] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const value = { title, setTitle, breadcrumbs, setBreadcrumbs };
  return (
    <SubheaderContext.Provider value={value}>
      {children}
    </SubheaderContext.Provider>
  );
}

const subheaderContextInitial = { 
  title: '',
  breadcrumbs: [],
}

const SubheaderContext = createContext<SubheaderContextValue>(subheaderContextInitial);
const useSubheader = () =>  useContext(SubheaderContext);
const SubheaderConsumer = SubheaderContext.Consumer;

export default MetronicSubheaderProvider

export { useSubheader, SubheaderConsumer }