import { gql } from '@apollo/client'
import { Movie, MovieInput } from 'src/api/graphql/types'

export interface UpdateMovieMutationArgs {
    movieID: number,
    input: MovieInput
} 
export interface UpdateMovieMutationData {
    UpdateMovie: Movie
}

export const UPDATE_MOVIE_MUTATION = gql`
    mutation UpdateMovieMutation( $movieID: Int!, $input: MovieInput! ) {
        UpdateMovie(movieID: $movieID, input: $input) {
            typeId,
            name,
            age,
            language {
                id
                name
                abbreviated
            },
            category {
                id
                name
            }
            release,
            ratingKP,
            ratingIMDB,
            description
        }
    }
`