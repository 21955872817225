import { useMutation } from '@apollo/client'
import { ChangeMovieStatusMutationArgs, ChangeMovieStatusMutationData, CHANGE_MOVIE_STATUS_MUTATION } from 'src/api/graphql/mutations/ChangeMovieStatus'
import { GET_ALL_EPISODES_QUERY } from 'src/api/graphql/queries/GetAllEpisodes'
import { GET_ALL_SEASONS_QUERY } from 'src/api/graphql/queries/GetAllSeasons'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

export function useChangeSerialStatusMutation() {
    const {setNotification} = useNotification();
    const [changeMovieStatusCallback] = useMutation<ChangeMovieStatusMutationData, ChangeMovieStatusMutationArgs>(CHANGE_MOVIE_STATUS_MUTATION, {
        refetchQueries:  [
            GET_ALL_EPISODES_QUERY, 
            GET_ALL_SEASONS_QUERY
        ],
        onCompleted: data => {
            if(data.ChangeMovieStatus.status === 'error') {
                setNotification({
                    message: data.ChangeMovieStatus.message,
                    variant: NotificationVariants.warning
                })
            }
        },
        onError: error => {
            setNotification({
                message: error.message,
                variant: NotificationVariants.error
            })
        }
    });

    return (movieID: number, status: ACTIVATION_STATUS) => changeMovieStatusCallback({
        variables:{
            movieID,
            status,
            typeID: CONTENT_TYPES.SERIAL
        }
    });
}