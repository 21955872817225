import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Language, LanguageInput } from 'src/api/graphql/types'
import BootstrapTable from 'react-bootstrap-table-next'
import { AbbreviateColumnEditorRenderer, AbbreviateColumnFormatter, AbbreviateColumnValidator, IdColumnFormatter, LanguageNameColumnFormatter, NameColumnEditorRenderer, NameColumnValidator, OrderColumnFormatter } from '../../../helpers/SelectorConfigHelpers'
// @ts-ignore // This lib is JS only and for this reason added '@ts-ignore' to say ts compiler to ignore it
import cellEditFactory from 'react-bootstrap-table2-editor' 
import NoRecordsFound from '../../NoRecordsFound'
import { SelectorConfigContextValue, SelectorParams } from '../../../contexts/SelectorConfigContext'

type LanguagesConfigTableProps = {
  useSelectorConfigContext: () => SelectorConfigContextValue<SelectorParams, LanguageInput, Language>
}

function LanguagesConfigTable(props: LanguagesConfigTableProps) {
  // LanguagesConfig context
  const languagesConfigContext = props.useSelectorConfigContext();

  // Context's memoized values
  const languagesConfigProps = React.useMemo(() => {
    return {
      loading: languagesConfigContext.loading,
      error: languagesConfigContext.error,
      selectors: languagesConfigContext.selectors,
      searchText: languagesConfigContext.params.searchText,
      setParams: languagesConfigContext.setParams,
      updateCallback: languagesConfigContext.updateCallback
    }
  }, [languagesConfigContext]);

  const { selectors, searchText, updateCallback } = languagesConfigProps;
  
  /**
   * Columns for bootstrap-table-next
   * # - order
   * id - id of the element
   * name - name of the element
   */
  const columns = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: OrderColumnFormatter,
      editable: false
    },
    {
      dataField: 'id',
      text: 'ID',
      formatter: IdColumnFormatter,
      editable: false
    },
    {
      dataField: 'name',
      text: 'Название языка',
      formatter: LanguageNameColumnFormatter,
      formatExtraData: languagesConfigContext,
      editable: true,
      validator: NameColumnValidator,
      editorRenderer: NameColumnEditorRenderer,
    },
    {
      dataField: 'abbreviated',
      text: 'Сокращенное название',
      formatter: AbbreviateColumnFormatter,
      formatExtraData: languagesConfigContext,
      editable: true,
      validator: AbbreviateColumnValidator,
      editorRenderer: AbbreviateColumnEditorRenderer,
    },
  ];

  return (
    <PerfectScrollbar 
      className='selectors-config-scrollbar' 
      options={{
        suppressScrollX: true,
        wheelPropagation: false
      }}
    >
      <BootstrapTable
        wrapperClasses='table-responsive'
        classes='table table-head-custom table-vertical-center overflow-hidden'
        bordered={false}
        bootstrap4
        data={selectors}
        columns={columns}
        keyField='id'
        noDataIndication={() => <NoRecordsFound searchText={searchText}/>}
        cellEdit={ cellEditFactory({
          mode: 'dbclick',
          blurToSave: false,
          afterSaveCell: (oldValue: string, newValue: string, row: Language, column: {dataField: string, text: string, editable: boolean, formatExtraData: any}) => {
            if(oldValue !== newValue && updateCallback) {
              if(column.dataField === 'name') {
                updateCallback({id: row.id, name: newValue, abbreviated: row.abbreviated });
              }

              if(column.dataField === 'abbreviated') {
                updateCallback({id: row.id, name: row.name, abbreviated: newValue });
              }
            }

            return {async: true}
          }
        }) }
      />
    </PerfectScrollbar>
  );
}

export default LanguagesConfigTable