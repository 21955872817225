import { useMutation } from '@apollo/client'
import { AddMovieFileMutationArgs, AddMovieFileMutationData, ADD_MOVIE_FILE_MUTATION } from 'src/api/graphql/mutations/AddMovieFileMutation'
import { Response } from 'src/api/graphql/types'
import { comment } from 'src/util/comment'

function useAddMovieFileMutation(): (filePath: string, movieID: number) => Promise<Error | Response | Error[]> {
    const [ AddMovieFile ] = useMutation<AddMovieFileMutationData, AddMovieFileMutationArgs>(ADD_MOVIE_FILE_MUTATION, {
        onCompleted: data => {
            const {status, message} = data.AddMovieFile;

            if(status === 'error') {
                console.error(`Ошибка при попытке сохранения фильма в базу: ${message}`)
            } 

            comment('useAddMovieFileMutation', data)
        },
        onError: error => {
            console.error(`Ошибка при попытке сохранения фильма в базу`, error);
        }
    });

    return async function(filePath: string, movieID: number) {
        const result = await AddMovieFile({
            variables: {
                input: {
                    filePath,
                    movieID,
                    typeID: 1,
                }
            }
        });

        if(result.data) {
            return result.data.AddMovieFile;
        }

        if(result.errors) {
            return result.errors.map( err => new Error(err.message) )
        }

        else {
            return new Error('AddMovieFile mutation responded with undefined data and errors field');
        }
    }
}

export default useAddMovieFileMutation