import React from 'react'
import { Country, CountryInput } from 'src/api/graphql/types'
import { SelectorParams, useSelectorConfig } from '../../contexts/SelectorConfigContext'
import SelectorConfigCard from '../SelectorConfigCard'
import SelectorConfigSearchBar from '../SelectorConfigSearchBar'
import CountriesConfigTable from './CountriesConfigTable'
import { useAddCountry } from './hooks/useAddCountry'
import { useGetAllCountries } from './hooks/useGetCounties'
import { useUpdateCountry } from './hooks/useUpdateCountry'

const initialValue = {
    params: {
        searchText: ''
    },
    loading: false,
    selectors: [],
    pendingUpdates: new Set<number>(),
    addPermission: false
}

function CountriesConfig() {
    const [SelectorConfigProvider, useSelectorConfigContext] = useSelectorConfig<SelectorParams, CountryInput, Country>(initialValue);

    return (
        <SelectorConfigProvider 
            useGetQueryHook={useGetAllCountries} 
            useAddMutationHook={useAddCountry} 
            useUpdateMutationHook={useUpdateCountry}
        >
            <SelectorConfigCard title='Страны' className='margin-bottom-0 countries-config-card'>
                <SelectorConfigSearchBar useSelectorConfigContext={useSelectorConfigContext}/>
                <CountriesConfigTable useSelectorConfigContext={useSelectorConfigContext}/>
            </SelectorConfigCard>
        </SelectorConfigProvider>
    )
}

// Wrapped into memo for reason of performance and preventing error 
export default React.memo(CountriesConfig)