import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { QUALITY_TYPES, QUALITY_TYPES_LABEL, SeriesFileInfo, TRANSCODE_STATUS } from 'src/api/graphql/types'

type EpisodeProcessStatusProps = {
    statusData: SeriesFileInfo|undefined,
    qualityID: QUALITY_TYPES
}

function EpisodeProcessStatus(props: EpisodeProcessStatusProps) {
    const { statusData, qualityID } = props;

    if(statusData !== undefined) {
        return (
            <div className='episode-status unselectable'>
                <span className={`svg-icon svg-icon-md svg-icon-${getStatusClass(statusData, qualityID)} mr-1`}>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/Files/Folder-cloud.svg')}
                    />
                </span>

                <span className='mr-1'>
                    { getQualityText(qualityID) }: 
                </span>
                <span>
                    { getStatusText(statusData, qualityID) }
                </span>
            </div>
        );
    }

    return null;
}

const getStatusText = (info: SeriesFileInfo, qualityID: QUALITY_TYPES): string => {
    const status = getStatusByQuality(info, qualityID);

    switch (status) {
        case TRANSCODE_STATUS.NON: return 'Не обраб.';
        case TRANSCODE_STATUS.WAITING: return 'В ожидании';
        case TRANSCODE_STATUS.PENDING: return 'Обрабатывается';
        case TRANSCODE_STATUS.TRANSCODE_ERROR: return 'Ошибка при обработке';
        case TRANSCODE_STATUS.FILE_STORAGE_ERROR: return 'Ошибка на хранилище';
        case TRANSCODE_STATUS.FULLFILLED: return 'Обработан';
        default: throw new Error('Unknown status in EpisodeProcessStatus');
    }
}

const getStatusClass = (info: SeriesFileInfo, qualityID: QUALITY_TYPES): string => {
    const status = getStatusByQuality(info, qualityID);

    switch (status) {
        case TRANSCODE_STATUS.NON: return 'danger';
        case TRANSCODE_STATUS.WAITING: return 'primary';
        case TRANSCODE_STATUS.PENDING: return 'primary';
        case TRANSCODE_STATUS.TRANSCODE_ERROR: return 'danger';
        case TRANSCODE_STATUS.FILE_STORAGE_ERROR: return 'danger';
        case TRANSCODE_STATUS.FULLFILLED: return 'success';
        default: throw new Error('danger');
    }
}

const getQualityText = (qualityID: QUALITY_TYPES): string => {
    switch (qualityID) {
        case QUALITY_TYPES.QUALITY_480: return QUALITY_TYPES_LABEL.QUALITY_480;
        case QUALITY_TYPES.QUALITY_1080: return QUALITY_TYPES_LABEL.QUALITY_1080;
        default: throw new Error('Unknown qualityID in EpisodeProcessStatus');
    }
}

const getStatusByQuality = (info: SeriesFileInfo, qualityID: QUALITY_TYPES): TRANSCODE_STATUS => {
    switch (qualityID) {
        case QUALITY_TYPES.QUALITY_480: return info.ProcessStatusLQ;
        case QUALITY_TYPES.QUALITY_1080: return info.ProcessStatusHQ;
        default: throw new Error('Unknown qualityID in EpisodeProcessStatus');
    }
}

export default EpisodeProcessStatus