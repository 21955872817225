import React from 'react'
import WaitingItem from './WaitingItem'

interface WaitingListProps {
    list: string[]
}

function WaitingList(props: WaitingListProps) {
    return <ul className='waiting-list'>
        <h5 className='title'>Список Ожидающих</h5>
        {
            props.list.map( (value, index) => 
                <WaitingItem key={index} value={value}/>
            ) 
        }
    </ul>
}

export default React.memo(WaitingList)