import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllCountriesArgs, GetAllCountriesData, GET_ALL_COUNTRIES_QUERY } from 'src/api/graphql/queries/getAllCountries'
import { Country } from 'src/api/graphql/types'

type useGetAllCountriesQueryArgs = {
    completeHandler?: (data: Country[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllCountriesResult = {
    loading: boolean,
    data: Country[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<useGetAllCountriesQueryArgs> | undefined) => Promise<ApolloQueryResult<GetAllCountriesData>>
}


function useGetAllCountriesQuery(args: useGetAllCountriesQueryArgs = {}): useGetAllCountriesResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllCountriesData, GetAllCountriesArgs>(GET_ALL_COUNTRIES_QUERY, {
        variables: {},
        onCompleted: (data: GetAllCountriesData) => {
            completeHandler && completeHandler(data.getAllCountries);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error)
        }

    });
    return {
        loading, 
        data: data ? data.getAllCountries : undefined, 
        error, 
        refetch
    }
}

export default useGetAllCountriesQuery