import { useHeader } from '../contexts/HeaderContext'

export function HeaderMenu({ layoutProps }: {layoutProps: any}) {
    const selectors = useHeader();

    return (
        <div
            id='kt_header_menu'
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                { selectors }
            </ul>
            {/*end::Header Nav*/}
        </div>
    )
}

