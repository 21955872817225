import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { getHandlerTableChange } from 'src/metronic/core/TablePaginationHelpers'
import * as uiHelpers from 'src/app/components/ContentService/helpers/FilmSerialUIHelpers'
import { AbonentFilter, ListUIContextValue, useListUIContext } from 'src/app/contexts/ListUIContext'
import { Pagination } from 'src/metronic/components/Pagination'
import { 
  ActiveColumnFormatter, 
  IpColumnFormatter, 
  OrderColumnFormatter, 
  ProviderColumnFormatter, 
  RegionColumnFormatter 
} from '../helpers/AbonentsListHelpers'
import { AbonentsParams } from '..'
import { ExtractHostArgs, useExtractHosts } from '../hooks/useExtractHosts'
import { sortCaret, headerSortingClasses } from 'src/metronic/core/TableSortingHelpers'
import { Host } from 'src/api/graphql/types'

function AbonentsTable() {
  // AbonentsList UI Context
  const AbonentsListUIContext = useListUIContext() as ListUIContextValue<AbonentsParams>;

  // Memoized context params
  const AbonentsTableUIProps = React.useMemo(() => {
    return {
      queryParams: AbonentsListUIContext.queryParams,
      loading: AbonentsListUIContext.loading,
      setQueryParams: AbonentsListUIContext.setQueryParams,
      setLoading: AbonentsListUIContext.setLoading
    };
  }, [AbonentsListUIContext]);

  const hostExtractHookArgs = React.useMemo<ExtractHostArgs>(() => {
    const filter = AbonentsTableUIProps.queryParams.filter as AbonentFilter;
    return {
      ip: filter.searchText,
      phone: filter.searchPhone,
      regionID: filter.regionID,
      limit: AbonentsTableUIProps.queryParams.pageSize, 
      page: AbonentsTableUIProps.queryParams.pageNumber,
      sortField: AbonentsTableUIProps.queryParams.sortField as keyof Host,
      sortOrder: AbonentsTableUIProps.queryParams.sortOrder as 'asc'|'desc',
      actionType: AbonentsTableUIProps.queryParams.actionType
    }
  }, [AbonentsTableUIProps]);
  
  const { loading, data } = useExtractHosts(hostExtractHookArgs);

  const abonents = data.hosts;
  const totalCount = data.Pagination.TotalCount;
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: AbonentsTableUIProps.queryParams.pageSize,
    page: AbonentsTableUIProps.queryParams.pageNumber,
  };

  // Switch AbonentsLisUIContext loading property depending on response
  React.useEffect(() => {
    if(loading) {
      AbonentsListUIContext.setLoading && AbonentsListUIContext.setLoading(true);
    } else {
      AbonentsListUIContext.setLoading && AbonentsListUIContext.setLoading(false);
    }
  }, [AbonentsListUIContext, loading]);

  const columns = [
    {
      dataField: '#',
      text: '#',
      isDummyField: true,
      formatter: OrderColumnFormatter,
      formatExtraData: {
        getOrder: (index: number): number => {
          return index + (AbonentsTableUIProps.queryParams.pageNumber - 1) * AbonentsTableUIProps.queryParams.pageSize
        }
      },
    },
    {
      dataField: 'ip',
      text: 'IP-адрес',
      sort: false,
      formatter: IpColumnFormatter
    },
    {
      dataField: 'code',
      text: 'Регион',
      sort: false,
      formatter: RegionColumnFormatter
    },
    {
      dataField: 'type',
      text: 'Провайдер',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: ProviderColumnFormatter,
    },
    {
      dataField: 'phone',
      text: 'Телефон',
      sort: false,
    },
    {
      dataField: 'active',
      text: 'Статус',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: ActiveColumnFormatter
    },
  ];

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                remote
                defaultSorted={ [{ dataField: 'ip', order: 'asc' }] }
                onTableChange={getHandlerTableChange(
                  AbonentsTableUIProps.setQueryParams!
                )}              
                {...paginationTableProps}
                data={abonents}
                columns={columns}
                keyField={'ip'}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default AbonentsTable