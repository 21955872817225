import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type FilmDurationProps = {
    duration: number|null|undefined,
}

function FilmDuration(props: FilmDurationProps) {
    const { duration } = props;

    if(duration != null) {
        return (
            <div className='film-duration'>
                <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/Home/Clock.svg')}
                    />
                </span>
                <span>
                    { duration } мин
                </span>
            </div>
        );
    }

    return null;
}


export default FilmDuration