import React from 'react'
import SVG from 'react-inlinesvg'
import clsx from 'clsx'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { toAbsoluteUrl } from 'src/util/url'

type FilmHeaderProps = {
    statusChangeHandler: React.MouseEventHandler,
    status: ACTIVATION_STATUS
}

function FilmHeader(props: FilmHeaderProps) {
    const { statusChangeHandler, status } = props;

    const statusDisplayClass = status === ACTIVATION_STATUS.ACTIVE 
        ? 'svg-icon-success' 
        : 'svg-icon-danger';

    return (
        <div className='film-header'>
            <button
                title='Сменить статус'
                className='btn btn-icon btn-light btn-sm m-0'
                onClick={statusChangeHandler}
            >   
                <span className={clsx('svg-icon', 'svg-icon-md', statusDisplayClass)}>
                    <SVG
                        description='Сменить статус'
                        title='Сменить статус'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>
            </button>
        </div>
    );
}


export default FilmHeader