import { gql } from '@apollo/client'
import { Tape } from 'src/api/graphql/types'

export interface GetHomePageCategoriesArgs {} 

export interface GetHomePageCategoriesData {
    GetHomePageCategories: Tape[]
}

export const GET_HOME_PAGE_CATEGORIES_QUERY = gql`
    query GetHomePageCategoriesQuery {
        GetHomePageCategories{
            id
            TitleTk
            TitleRu
            genreID
            position
            categoryType
            GenreName
            sortValue
            itemSizeValue
            active
        }
    }
`