import React from 'react'
import { ApolloError, FetchResult } from '@apollo/client'
import { Selector } from 'src/api/graphql/types'
import { ValueType } from 'react-select'
import { SelectorEditField, useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { DeleteGenreToMovieMutationData } from 'src/api/graphql/mutations/DeleteGenreToMovie'
import { DeleteTagToMovieMutationData } from 'src/api/graphql/mutations/DeleteTagToMovie'
import { DeleteCountryToMovieMutationData } from 'src/api/graphql/mutations/DeleteCountryToMovie'
import { DeleteParticipantToMovieMutationData } from 'src/api/graphql/mutations/DeleteParticipantToMovie'
import { SortableSelectInputProps } from '../components/SortableSelectInput'

/**
 * Base SortableSelectInput props
 */
export interface SortableSelectInputBase {
    className: string,
    name: string,
    placeholder: string,
    value: SelectorEditField[],
    changeHandler: (data:  ValueType<SelectorEditField[], boolean>) => void,
    onBlur?: React.FocusEventHandler<any>
}

type SelectorWrappedComponentHOC = (SortableSelectComponent: React.ComponentType<SortableSelectInputProps>) => React.ComponentType<SortableSelectInputBase>

type dataFetchHookResult = { loading: boolean, data: Selector[]|undefined, error: ApolloError|undefined };

export type DeleteResult = DeleteGenreToMovieMutationData|DeleteTagToMovieMutationData|DeleteCountryToMovieMutationData|DeleteParticipantToMovieMutationData
type dateRemoveFetchHookResult = (movieID: number, selectorID: number) => Promise<FetchResult<DeleteResult>>

/**
 * HOC that injects selectors depending on hook passed as parameters
 * Schema of HOC working: withSelector(hook) (MustInjectComponent) => InjectedComponent
 * @param dataFetchHook - any hook that returns 'dataFetchHookResult' type
 * @returns - Second HOC that already has described above hook for injection of data (selectors) into the Wrappable component 
 */
export function withSelector( dataFetchHook: () => dataFetchHookResult, dataRemoveHook: () => dateRemoveFetchHookResult ): SelectorWrappedComponentHOC {
    // Second HOC
    return (SortableSelectComponent) => {
        // Selectors injected component
        return (props: SortableSelectInputBase) => {
            const {loading, data, error} = dataFetchHook();
            const deleteCallback = dataRemoveHook();

            const editContentContext = useContentEditContext();
            const deleteSelector = React.useCallback(
                (selectorID: number) => deleteCallback(editContentContext.id, selectorID),
                [editContentContext, deleteCallback]
            );

            

            // Formating fetched data correctly for passing into SortableSelectInput component
            const selectors: SelectorEditField[] = data ? data.map( d => ({value: d.id, label: d.name}) ) : [];
            
            // Fallback for error
            if(error) {
                return(
                    <div>Ошибка по причине: {error.message}</div>
                );
            }

            return (
                <SortableSelectComponent
                    {...props}
                    isLoading={loading}
                    selectors={selectors}
                    deleteSelector={deleteSelector}
                />
            );
        }
    }
}