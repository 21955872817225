import { ProcessData } from 'src/app/components/ContentService/EditContent/components/EditFiles/types'
import ProcessingSendCard from './ProcessingSendCard'

function ProcessingSender(props: {processData: ProcessData | undefined}) {
    const { processData } = props;

    if(processData !== undefined) {
        const {typeID, movieID, fileID, qualities, uploaded} = processData;
        return <div style={{display: 'flex', width: '100%', justifyContent: 'space-around'}} className='mb-4'>
            { 
                qualities.map( qualityID => <ProcessingSendCard 
                    key={qualityID} 
                    typeID={typeID} 
                    movieID={movieID} 
                    fileID={fileID}
                    uploaded={uploaded}
                    qualityID={qualityID}
                />)
            }
        </div>
    }

    return null;
}

export default ProcessingSender