import { gql } from '@apollo/client'
import { Genre, GenreInput } from '../types'

export interface AddGenreMutationArgs {
    input: GenreInput
} 
export interface AddGenreMutationData {
    AddGenre: Genre
}

export const ADD_GENRE_MUTATION = gql`
    mutation AddGenreMutation( $input: GenreInput! ) {
        AddGenre(input: $input) {
            id
            name
        }
    }
`