import { MutationTuple } from '@apollo/client';
import React from 'react'
import { ChangeComplaintStatusMutationArgs, ChangeComplaintStatusMutationData } from 'src/api/graphql/mutations/ChangeComplaintStatus';
import { GET_ALL_COMPLAINTS_QUERY } from 'src/api/graphql/queries/getAllComplaints';
import { Complaint, ComplaintStatus } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'

const ComplaintStatusCssClasses = ['danger', 'info', 'success'];
const ComplaintStatusTitle = ['ожидание', 'обработка', 'закрыт']

export function StatusColumnFormatter(cellContent: Complaint['status'], row: Complaint) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      ComplaintStatusCssClasses[cellContent-1] || ''
    } label-inline`;
  };

  return (
    <span className={getLabelCssClasses()}>
      { ComplaintStatusTitle[cellContent-1] || '' }
    </span>
  );
}

export function ComplaintIDColumnFormatter(cell: number, row: Complaint) {
  return (
    <span>
      {cell}
    </span>
  );
}

export function MovieIDColumnFormatter(cell: number, row: Complaint) {
  return (
    <span>
      {cell}
    </span>
  );
}

export function UserIDColumnFormatter(cell: number, row: Complaint) {
  return (
    <span>
      {cell}
    </span>
  );
}




export function OrderColumnFormatter(
  _cellContent: number,
  _row: Complaint, 
  rowIndex: number, 
  {getOrder}: { 
    getOrder: (index: number) => void
  } 
) {
  const orderNumber = getOrder(rowIndex+1);

  return (
    <span>
      { orderNumber }.
    </span>
  );
}

export function DateColumnFormatter(cellContent: string | null, _row: Complaint) {
  const classes = 'label label-lg label-light label-inline';
  const date = cellContent ? new Date(cellContent).toLocaleDateString() : 'открыт';

  return (
    <>
      <span className={classes}>
        { date }
      </span>
    </>
  )
}


export function ContentIDColumnFormatter(cellContent: Complaint['typeID']){
  return (
    <span>
      {getContentTypeText(cellContent)}
    </span>
  )
}

export function ComplaintDescriptionColumnFormatter(cellContent: string){
  return (
    <span>
      {cellContent}
    </span>
  )
}

const getContentTypeText = (type: CONTENT_TYPES): string => {
  switch (type) {
    case CONTENT_TYPES.FILM: return 'Фильм'
    case CONTENT_TYPES.EPISODE: return 'Сериал'
    default: throw new Error('Unknown content type in Films/Serials list formatter')
  }
}

interface ActionsColumnFormatterExtraFormatter {
  changeComplaintStatus: MutationTuple<
    ChangeComplaintStatusMutationData,
    ChangeComplaintStatusMutationArgs
  >[0]
}


export function ActionsColumnFormatter(
  _cellContent: any,
  row: Complaint,
  _rowIndex: number,
  { changeComplaintStatus }: ActionsColumnFormatterExtraFormatter
) {
  return (
    <ActionColumnRenderer
      complaint={row}
      changeComplaintStatus={changeComplaintStatus}
    />
  );
}

interface ActionColumnRendererProps {
  complaint: Complaint
  changeComplaintStatus: ActionsColumnFormatterExtraFormatter['changeComplaintStatus']
}

function ActionColumnRenderer(
  {complaint, changeComplaintStatus}: ActionColumnRendererProps
): JSX.Element {
  const [loading, setLoading] = React.useState(false);

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    setLoading(true);
    changeComplaintStatus({
      variables: {
        status: actionUtils.getStatusToChange(complaint.status),
        complaintID: complaint.id
      },
      refetchQueries: [GET_ALL_COMPLAINTS_QUERY]
    }).finally(() => setLoading(false));
  }

  return (
    <>
      <button
        style={{
          cursor: complaint.status === ComplaintStatus.FINISHED ? 'default' : 'pointer'
        }}
        disabled={complaint.status === ComplaintStatus.FINISHED || loading}
        title={actionUtils.getButtonTitle(complaint.status)}
        className={actionUtils.getButtonClass(complaint.status)}
        onClick={clickHandler}
      >
        <span className={actionUtils.getIconClass(complaint.status)}>
          { actionUtils.getButtonTitle(complaint.status) }
        </span>
      </button>
    </>
  );
}

const actionUtils = {
  getIconClass: (status: ComplaintStatus) => {
    switch (status) {
      case ComplaintStatus.WAITING:
        return 'svg-icon svg-icon-md svg-icon-primary';
      case ComplaintStatus.PROCESSING:
        return 'svg-icon svg-icon-md svg-icon-primary';
      case ComplaintStatus.FINISHED:
        return 'svg-icon svg-icon-md svg-icon-primary';
      default:
        return '';
    }
  },
  getButtonClass: (status: ComplaintStatus) => {
    switch (status) {
      case ComplaintStatus.WAITING: return 'btn btn-light btn-hover-warning btn-sm';
      case ComplaintStatus.PROCESSING: return 'btn btn-light btn-hover-primary btn-sm';
      case ComplaintStatus.FINISHED: return 'btn btn-light btn-hover-secondary btn-sm';
      default: return '';
    }
  },
  getButtonTitle: (status: ComplaintStatus) => {
    switch (status) {
      case ComplaintStatus.WAITING: return 'Начать';
      case ComplaintStatus.PROCESSING: return 'Завершить';
      case ComplaintStatus.FINISHED: return '-';
      default: return '';
    }
  },
  getStatusToChange: (status: ComplaintStatus): ComplaintStatus => {
    switch (status) {
      case ComplaintStatus.WAITING: return ComplaintStatus.PROCESSING;
      case ComplaintStatus.PROCESSING: return ComplaintStatus.FINISHED;
      case ComplaintStatus.FINISHED: throw new Error('Unable to create new status for FINISHED state');
      default: throw new Error('Incorrect status in Complaints ActionColumnFormatter');
    }
  }
};
