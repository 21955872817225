import React from 'react'
import { Props, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/util/url';
import { SortableSelectInputBase } from '../../hocs/withSelector';
import { SelectorEditField } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext';

interface AsyncSelectInputProps {
    useAsyncOptionsLoader: () => (inputValue: string) => Promise<SelectorEditField[]>;
    useDeleteHandler: () => (selectorID: number) => Promise<unknown>;
    innerProps: SortableSelectInputBase
}

function AsyncSelectInput({useAsyncOptionsLoader, useDeleteHandler, innerProps}: AsyncSelectInputProps): JSX.Element {
    const asyncOptionsLoader = useAsyncOptionsLoader();
    const deleteHandler = useDeleteHandler();
    const {name, placeholder, onBlur, changeHandler, value } = innerProps;

    const onChange: Props<
        SelectorEditField,
        true
    >['onChange'] = React.useCallback((newValues: ReadonlyArray<SelectorEditField>, actionMeta) => {
        changeHandler(newValues ? newValues.slice() : [] );
        return;
    }, [changeHandler]);

    return (
        <AsyncSelect
            isMulti
            defaultOptions
            loadOptions={asyncOptionsLoader}
            onChange={onChange}
            components={{
                MultiValueRemove: (
                    props: MultiValueRemoveProps<SelectorEditField>
                ) => <MultiValueRemoveCustom innerProps={props} deleteHandler={deleteHandler}/>
            }}
            name={name}
            // className={className}
            placeholder={placeholder}
            onBlur={onBlur}
            isClearable={false}
            defaultValue={value}
        />
    );
}

interface MultiValueRemoveCustomProps {
    innerProps: MultiValueRemoveProps<SelectorEditField>;
    deleteHandler:  (selectorID: number) => Promise<unknown>;
}


const MultiValueRemoveCustom: React.FC<MultiValueRemoveCustomProps> = function MultiValueRemove(props): JSX.Element {
    const { innerProps, deleteHandler } = props;

    return (
        <components.MultiValueRemove {...innerProps} >
            <SVG
                style={{width: '8px', height: '8px'}}
                src={toAbsoluteUrl('/media/svg/icons/General/Times-white.svg')}
                onClick={() => {
                    deleteHandler(innerProps.data.value);
                }}
            />
        </components.MultiValueRemove>
    );
};

export default AsyncSelectInput;