import React from 'react'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { SelectedReadyToSendtem } from '../contexts/ReadyToSendFilesContext'

export function useLimitReachNotification(ids: Map<number, SelectedReadyToSendtem>, limit: number) {
    const { setNotification } = useNotification();

    React.useEffect(() => {
        if(ids.size >= limit) {
            setNotification({
                message: `Достигнуто максимальное количество для выбора: ${limit}`, 
                variant: NotificationVariants.warning
            })
        }
    }, [ids, limit, setNotification]);
}