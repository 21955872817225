type TrailerNameProps = {
    name: string
}

function TrailerName(props: TrailerNameProps) {
    return (
        <div className='film-name unselectable'>
            { props.name }
        </div>
    )
}

export default TrailerName