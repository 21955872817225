import { gql } from '@apollo/client'
import { Movie, MovieInput } from '../types'

export interface AddMovieMutationArgs {
    input: MovieInput
} 
export interface AddMovieMutationData {
    AddMovie: Movie
}

export const ADD_MOVIE_MUTATION = gql`
    mutation AddMovieMutation( $input: MovieInput! ) {
        AddMovie(input: $input) {
            id
            typeId
            name
        }
    }
`