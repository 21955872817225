import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const theme = createMuiTheme({
  // direction: "rtl",
  typography: {
    fontFamily: ["Poppins"].join(",")
  },
  /**
   * @see https://material-ui.com/customization/globals/#default-props
   */
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: false // No more ripple, on the whole application 💣!
    },

    // Set default elevation to 1 for popovers.
    MuiPopover: {
      elevation: 1
    }
  }
});

function MaterialThemeProvider(props: ReactContainer) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default MaterialThemeProvider