import { useMutation } from '@apollo/client'
import { 
    ChangeMovieStatusMutationArgs, 
    ChangeMovieStatusMutationData, 
    CHANGE_MOVIE_STATUS_MUTATION 
} from 'src/api/graphql/mutations/ChangeMovieStatus'
import { GetAllEpisodesArgs, GetAllEpisodesData, GET_ALL_EPISODES_QUERY } from 'src/api/graphql/queries/GetAllEpisodes'
import { Episode } from 'src/api/graphql/types'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { comment } from 'src/util/comment'

export function useChangeEpisodeStatusMutation(parentID: number) {
    const {setNotification} = useNotification();
    const [changeMovieStatusCallback] = useMutation<ChangeMovieStatusMutationData, ChangeMovieStatusMutationArgs>(CHANGE_MOVIE_STATUS_MUTATION, {
        update: (cache, result, { variables }) => {
            cache.modify({
                fields: {
                    GetAllEpisodes(cachedEpisodes) {
                        comment('@Cache.modify: Cached episodes', cachedEpisodes)
                        if(variables !== undefined) {
                            const {movieID, status} = variables;
                            const updatedEpisode = cachedEpisodes.find((e: Episode) => e.id === movieID) as Episode|undefined;

                            if(updatedEpisode !== undefined) {
                                cache.writeQuery<GetAllEpisodesData, GetAllEpisodesArgs>({
                                    query: GET_ALL_EPISODES_QUERY,
                                    variables: {
                                        parentID
                                    },
                                    data: {
                                        GetAllEpisodes: [
                                            ...cachedEpisodes, 
                                            {
                                                ...updatedEpisode,
                                                status
                                            }
                                        ]
                                    }
                                })
                            }
                        }
                    }
                }
            });
        },
        onCompleted: data => {
            if(data.ChangeMovieStatus.status === 'error') {
                setNotification({
                    message: data.ChangeMovieStatus.message,
                    variant: NotificationVariants.warning
                })
            }
        },
        onError: error => {
            setNotification({
                message: error.message,
                variant: NotificationVariants.error
            })
        }
    });

    return (movieID: number, status: ACTIVATION_STATUS) => changeMovieStatusCallback({
        variables:{
            movieID,
            status,
            typeID: CONTENT_TYPES.EPISODE
        }
    });
}