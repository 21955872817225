import { axiosVideoUploadInstance } from 'src/api/rest/axios'
import { MOVIE_UPLOAD_PREFETCH_API } from 'src/api/rest/urls/videoUpload'
import { PrefetchData } from 'src/uploader/types'
import { comment } from 'src/util/comment'

async function prefetchMovieUploadUrl(id: number, name: string): Promise<PrefetchData|null> {
    try {
        const payload = new FormData();
        payload.append('movieId', String(id));
        payload.append('fileName', name);

        comment('prefetchMovieUploadUrl payload', payload);

        const response = await axiosVideoUploadInstance.post<PrefetchData>(MOVIE_UPLOAD_PREFETCH_API, payload);
        return response.data
    } catch (error) {
        console.error('prefetchMovieUploadUrl api call', error);
        return null
    }
}

export default prefetchMovieUploadUrl
