import { CardActions, Button, Box } from '@material-ui/core'
import React from 'react'

type MediaCardActionsProps = {
    deleteHandler: React.MouseEventHandler,
    resendHandler: React.MouseEventHandler
}

function MediaCardActions(props: MediaCardActionsProps){
    return (
        <CardActions>
            <Box width='100%' display='flex' justifyContent='space-between'>
                <Button size='small'  color='secondary' onClick={props.deleteHandler}>
                    удалить
                </Button>

                {/* <Button size='small' variant='contained' color='primary' onClick={props.resendHandler}>
                    переотправить
                </Button> */}
            </Box>
            
        </CardActions>
    );        
}

export default MediaCardActions