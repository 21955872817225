import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSerialFilesContext } from '../../../../../contexts/SerialFilesContext'
import { Episode, EpisodeInput } from 'src/api/graphql/types'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import { useUpdateEpisodeMutation } from '../hooks/useUpdateEpisodeMutation'
import useEscapeCloseListener from '../../../../../hooks/useEscapeCloseListener'
import { isNull } from 'src/util/isNull'

type EpisodeEditModalBodyProps = {
    episodeID: number, 
    closeCallback: () => void
}

type AddEpisodeForm = {
    name: string,
    duration: number|undefined,
}

const validationSchema = Yup.object({
    name: Yup
        .string()
        .min(3, 'Название сезона должно состоять минимум из 3-х символов')
        .required('Название сезона не должно быть пустым'),
    duration: Yup
        .number()
        .min(0)
        .optional()
});

function prepareToSend(values: AddEpisodeForm, parentID: number, languageID: number): EpisodeInput {
    const mutationArgs: EpisodeInput = {
        name: values.name,
        parentID,
        languageID,
    };

    if(values.duration !== undefined) {
        mutationArgs.duration = values.duration;
    }

    return mutationArgs;
}

function createInitialValue(episode: Episode|undefined): {name: string, duration: number|undefined} {
    if(episode !== undefined) {
        return {
            name: episode.name,
            duration: episode.duration
        }
    }

    throw new Error(`Episode could not be undefined`);
}

function EpisodeEditModalBody(props: EpisodeEditModalBodyProps) {
    const {episodeID, closeCallback} = props;
    const serialFilesContext = useSerialFilesContext();

    useEscapeCloseListener(closeCallback);

    const targetEpisode = React.useMemo(() => {
        if(isNull(serialFilesContext)){
            throw new Error('serialFilesContext could not be null in EpisodeEditModalBody'); 
        }
        
        const episodes = serialFilesContext.getAllEpisodesResult.data;
        return episodes.find( s => s.id === episodeID );
    }, [serialFilesContext, episodeID]);

    const addEpisodeCallback = useUpdateEpisodeMutation();
    const contentEditContext = useContentEditContext();
    const { languageID, parentID } = React.useMemo(() => {
        return {
            languageID: contentEditContext.basicInfo.language,
            parentID: contentEditContext.id
        }
    }, [contentEditContext]);

    const formik = useFormik<AddEpisodeForm>({
        initialValues: {
          ...createInitialValue(targetEpisode),
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            await addEpisodeCallback({
                variables: {
                    episodeID,
                    input: prepareToSend(values, parentID, languageID, )
                }
            }).finally( closeCallback );
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='name'
                    name='name'
                    label='Наименование'
                    type='text'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </div>

            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='duration'
                    name='duration'
                    label='Продолжительность'
                    type='text'
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    error={formik.touched.duration && Boolean(formik.errors.duration)}
                    helperText={formik.touched.duration && formik.errors.duration}
                />
            </div>
            
            <div className='d-flex justify-content-end'>
                <Button color='primary' variant='primary' type='submit' disabled={formik.isSubmitting}>
                    Изменить
                </Button>
            </div>
      </form>
    );
}

export default EpisodeEditModalBody