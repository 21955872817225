import { Modal } from 'react-bootstrap'
import { SELECTOR_CODES } from 'src/app/assets/Selectors'
import CountriesConfig from 'src/app/components/ContentService/SelectorConfig/components/CountriesConfig'
import GenresConfig from 'src/app/components/ContentService/SelectorConfig/components/GenresConfig'
import LanguagesConfig from 'src/app/components/ContentService/SelectorConfig/components/LanguagesConfig'
import ParticipantsMultipleAddBar from 'src/app/components/ContentService/SelectorConfig/components/ParticipantsMultipleAddBar'
import TagsConfig from 'src/app/components/ContentService/SelectorConfig/components/TagsConfig'

type HeaderSelectorModalProps = {
    selector: AppSelector,
    show: boolean,
    hideHandler: React.MouseEventHandler<HTMLButtonElement>
}

function HeaderSelectorModal(props: HeaderSelectorModalProps) {
    const {selector, show, hideHandler} = props;

    return (
        <Modal
            size='lg'
            show={show}
            onHide={hideHandler}
            aria-labelledby='example-modal-sizes-title-lg'
        >
            { 
                getSelectorModal(selector)
            }
        </Modal>
    );
}
// TODO: Add other implementations
function getSelectorModal(selector: AppSelector) {
    switch(selector.code){
        case SELECTOR_CODES.genre: return <GenresConfig/>
        case SELECTOR_CODES.country: return <CountriesConfig/>;
        case SELECTOR_CODES.tag: return <TagsConfig />;
        case SELECTOR_CODES.language: return <LanguagesConfig/>;
        case SELECTOR_CODES.participant: return <ParticipantsMultipleAddBar/>;
        default: return <></>
      }
}

export default HeaderSelectorModal