import React from 'react'
import { Language, LanguageInput } from 'src/api/graphql/types'
import { SelectorParams, useSelectorConfig } from '../../contexts/SelectorConfigContext'
import SelectorConfigCard from '../SelectorConfigCard'
import SelectorConfigSearchBar from '../SelectorConfigSearchBar'
import { useAddLanguage } from './hooks/useAddLanguage'
import { useGetAllLanguages } from './hooks/useGetLanguages'
import { useUpdateLanguage } from './hooks/useUpdateLanguage'
import LanguagesConfigTable from './LanguagesConfigTable'

const initialValue = {
    params: {
        searchText: ''
    },
    loading: false,
    selectors: [],
    pendingUpdates: new Set<number>(),
    addPermission: false
}

function LanguagesConfig() {
    const [SelectorConfigProvider, useSelectorConfigContext] = useSelectorConfig<SelectorParams, LanguageInput, Language>(initialValue);

    return (
        <SelectorConfigProvider 
            useGetQueryHook={useGetAllLanguages} 
            useAddMutationHook={useAddLanguage} 
            useUpdateMutationHook={useUpdateLanguage}
        >
            <SelectorConfigCard title='Языки' className='margin-bottom-0 languages-config-card'>
                <SelectorConfigSearchBar useSelectorConfigContext={useSelectorConfigContext}/>
                <LanguagesConfigTable useSelectorConfigContext={useSelectorConfigContext}/>
            </SelectorConfigCard>
        </SelectorConfigProvider>
    )
}

// Wrapped into memo for reason of performance and preventing error 
export default React.memo(LanguagesConfig)