import React, { forwardRef } from 'react'
import clsx from 'clsx'

type CardHeaderTitleProps = {
  children: React.ReactElement,
  className?: string,
  props?: any[]
}

export const CardHeaderTitle = forwardRef<HTMLDivElement | null, CardHeaderTitleProps>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3 {...props} ref={ref} className={clsx('card-label', className)} />
));