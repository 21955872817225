import FilmsCard from './components/FilmsCard'
import { useSaveFilmsQueryParams } from './hooks/useSaveFilmsQueryParams'
import { useSetInitialQueryParams } from './hooks/useSetInitialQueryParams'

function FilmList() {
    useSetInitialQueryParams();
    useSaveFilmsQueryParams();

    return (
        <FilmsCard/>
    );
}

export default FilmList