import { gql } from '@apollo/client'
import { Genre, GenreInput } from '../types'

export interface UpdateGenreMutationArgs {
    genreID: number,
    input: GenreInput
} 
export interface UpdateGenreMutationData {
    UpdateGenre: Genre
}

export const UPDATE_GENRE_MUTATION = gql`
    mutation UpdateGenreMutation( $genreID: Int!, $input: GenreInput! ) {
        UpdateGenre(genreID: $genreID, input: $input) {
            id
            name
        }
    }
`