import React from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useAuthGateway } from 'src/app/contexts/AuthenticationContext'
import { ROUTES_LIST } from '../RootRoute'
import Dashboard from './../../components/Dashboard/index'
import ClientServiceRoute from './ClientServiceRoute'
import ContentServiceRoute from './ContentServiceRoute/index'

function MainRoute() {
    const {  AUTH, ROOT, DASHBOARD, CONTENT_SERVICE, CLIENT_SERVICE } = ROUTES_LIST;
    const { authResult } = useAuthGateway();
    const unverified = Boolean( !authResult.user );
    const history = useHistory();

    React.useEffect(() => {
        if(unverified) history.push(AUTH);
    }, [history, unverified, AUTH]);
  
    return (
        <>
            { unverified && <Redirect to={AUTH}/> }

            <Switch>
                <Route exact path={ROOT}>
                    { 
                        unverified 
                        ? <Redirect to={AUTH}/> 
                        : <Redirect to={DASHBOARD}/>
                    }
                </Route>

                <Route path={DASHBOARD}>
                    <Dashboard/>
                </Route>

                <Route path={CONTENT_SERVICE}>
                    <ContentServiceRoute/>
                </Route>

                <Route path={CLIENT_SERVICE}>
                    <ClientServiceRoute/>
                </Route>
            </Switch>
        </>
    );
}

export default MainRoute