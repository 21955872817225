import React from 'react'
import * as Yup from 'yup'
import { isEqual } from 'lodash'
import EditBasicInfoCard from 'src/app/components/ContentService/EditContent/components/EditBasicInfo/components/EditBasicInfoCard'
import { BasicInfoEditForm, ContentEditProviderState, EditBasicInfoUIProps, useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { withEditContentFormik } from 'src/app/components/ContentService/EditContent/hocs/withEditContentFormik'
import { FormikErrors, FormikHelpers } from 'formik'
import { MovieInput } from 'src/api/graphql/types'
import { Notification, NotificationVariants } from 'src/app/contexts/NotificationContext'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'

// Simple Yup validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Наименование не должно быть пустым'),
    age: Yup.number()
        .min(0,'Не должно быть отрицательным')
        .integer('Должно быть целым числом')
        .required('Возраст не должен быть пустым'),
    release: Yup.string().required('Дата выхода не должно быть пустым'),
    ratingKP: Yup.number()
        .min(0,'Не должно быть отрицательным')
        .required('Рейтинг KP не должен быть пустым'),
    ratingIMDB: Yup.number()
        .min(0,'Не должно быть отрицательным')
        .required('Рейтинг IMDB не должен быть пустым'),
    description: Yup.string().required('Описание не должно быть пустым'),
});

const customValidator = (values: BasicInfoEditForm): FormikErrors<BasicInfoEditForm> => {
    const error: FormikErrors<BasicInfoEditForm> = {};
    
    if(values.release) {
        const today = new Date();
        const release = new Date(values.release);
        const todayPlusYear = new Date( today.setFullYear( today.getFullYear() + 1 ));
        const MoreThanTodayPlusYear = release > todayPlusYear;
        if( MoreThanTodayPlusYear ) {
            error.release = 'Дата выхода не должна быть больше текущей даты более чем на год';
        } 

        const MIN_DATE = new Date('01.01.1900');
        if( release < MIN_DATE ) {
            error.release = `Год выхода не может быть меньше чем ${MIN_DATE.getFullYear()} год`;
        }
    }
    
    return error;
}

/**
 * Util function for preparing data sending to the server. Because of there are one data type to sending to server and
 * another in formik's flow, then must ensure to have valid data type for sending
 */
const prepareToSend = (values: BasicInfoEditForm): MovieInput => {
    // TODO: Add right implementation, for now it is doing nothing
    const { name, age, language, category, release, ratingKP, ratingIMDB, description } = values;
    const preparedValues = {
        name,
        age,
        language,
        category,
        release,
        ratingKP,
        ratingIMDB,
        description
    }
    return preparedValues;
};

// Submit handler function
const submitHandler = ( 
    { id, typeId, basicInfo, setInfo, updateMovieBasicInfo, updateTVSeriesBasicInfo }: EditBasicInfoUIProps, 
    values: BasicInfoEditForm, 
    helpers: FormikHelpers<BasicInfoEditForm>, 
    notificationSetter: (notification: Notification) => void 
) => {
    if ( !isEqual(values, basicInfo) ) {

        setInfo( 
            (prev: ContentEditProviderState) => ({
                ...prev,
                basicInfo: {...values}
            })
        );

        // Prepare data for sending to the server
        const readyToSendValues = prepareToSend(values);
        
        // Check is it Movie
        if(typeId === CONTENT_TYPES.FILM) {
            /**
             * Send Mutation to server if request has succeeded
             * alert success notification else error notification
             * if occurs network error also alert error notification 
            */
            updateMovieBasicInfo && updateMovieBasicInfo({
                movieID: id,
                input: {
                    ...readyToSendValues
                } 
            }).then( result => {
                const {data, errors} = result;
                
                if(data) {
                    notificationSetter({message: 'Успешно изменена Базовая информация', variant: NotificationVariants.success});
                } 
                if(errors) {
                    helpers.setErrors( {name: errors.map(err => err.message).join('. ')} );
                    notificationSetter({message: 'Ошибка при попытке отправки на изменение Базовую информацию', variant: NotificationVariants.error});
                    console.error(`Response from useUpdateMovieMutation could not be ${errors}`, errors);
                }
            }).catch( (error: Error) => {
                helpers.setErrors( {name: error.message} );
                notificationSetter({message: 'Ошибка при попытке отправки на изменение Базовую информацию', variant: NotificationVariants.error});
                console.error(`Response from useUpdateMovieMutation could not be ${error}`, error);
            }).finally( () => {
                helpers.setSubmitting(false);
            });
        }

        // Check is it TVSeries
        if(typeId === CONTENT_TYPES.SERIAL) {
            /**
             * Send Mutation to server if request has succeeded
             * alert success notification else error notification
             * if occurs network error also alert error notification 
            */
            updateTVSeriesBasicInfo && updateTVSeriesBasicInfo({
                seriesID: id,
                input: {
                    ...readyToSendValues
                } 
            }).then( result => {
                const {data, errors} = result;
                
                if(data) {
                    notificationSetter({message: 'Успешно изменена Базовая информация', variant: NotificationVariants.success});
                } 
                if(errors) {
                    helpers.setErrors( {name: errors.map(err => err.message).join('. ')} );
                    notificationSetter({message: 'Ошибка при попытке отправки на изменение Базовую информацию', variant: NotificationVariants.error});
                    console.error(`Response from useUpdateMovieMutation could not be ${errors}`, errors);
                }
            }).catch( (error: Error) => {
                helpers.setErrors( {name: error.message} );
                notificationSetter({message: 'Ошибка при попытке отправки на изменение Базовую информацию', variant: NotificationVariants.error});
                console.error(`Response from useUpdateMovieMutation could not be ${error}`, error);
            }).finally( () => {
                helpers.setSubmitting(false);
            });
        }
    } else {
        notificationSetter({message: 'Базовая информация не изменена', variant: NotificationVariants.info});
        helpers.setSubmitting(false);
    }
};

/**
 * Custom hook used in initialization of the HOC withEditContentFormik
 * @returns EditBasicInfoUIProps object that used in Formik's initializer hoc 
 * and further could be called to save EditBasicInfo form's state in EditContentContext
 * in submitHandler function
 */
function useEditContextForInitializeBasicInfo(): [EditBasicInfoUIProps, BasicInfoEditForm] {
    const contentEditContextValue = useContentEditContext();
    const editBasicUIProps = React.useMemo(() => {
        return {
            id: contentEditContextValue.id,
            typeId: contentEditContextValue.typeId,
            basicInfo: contentEditContextValue.basicInfo,
            setInfo: contentEditContextValue.setInfo,
            updateMovieBasicInfo: contentEditContextValue.updateMovieBasicInfo,
            updateTVSeriesBasicInfo: contentEditContextValue.updateTVSeriesBasicInfo
        };
    }, [contentEditContextValue]);

    // Initial values of the formik from the context
    const initialValues = {
        typeId: editBasicUIProps.typeId,
        name: editBasicUIProps.basicInfo.name,
        age: editBasicUIProps.basicInfo.age,
        release: editBasicUIProps.basicInfo.release,
        ratingKP: editBasicUIProps.basicInfo.ratingKP,
        ratingIMDB: editBasicUIProps.basicInfo.ratingIMDB,
        description: editBasicUIProps.basicInfo.description,
        language: editBasicUIProps.basicInfo.language,
        category: editBasicUIProps.basicInfo.category
    }

    return [editBasicUIProps, initialValues];
}

// EditBasicInfoCard wrapped with Formik context and it's helpers
const EditBasicInfo = withEditContentFormik<BasicInfoEditForm>({
    validationSchema: validationSchema,
    validate: customValidator,
    submitHandler
}, useEditContextForInitializeBasicInfo)(EditBasicInfoCard);

export default EditBasicInfo