import SerialsCard from './components/SerialsCard'
import { useSaveSerialsQueryParams } from './hooks/useSaveSerialsQueryParams'
import { useSetInitialQueryParams } from './hooks/useSetInitialQueryParams'

function SerialList() {
    useSetInitialQueryParams();
    useSaveSerialsQueryParams();
    return (
        <SerialsCard/>
    );
}

export default SerialList