import clsx from 'clsx'
import React from 'react'
import { FileError } from 'react-dropzone'
import { ImplementedUploadElement } from '../../VideoUploader'

interface AbleToBeActive {
    isActive: boolean,
}

interface Uploadable {
    data: {
        id: number,
        name: string,
    }
}

export function withUploader<FileComponentProps extends Uploadable & AbleToBeActive>(
    Wrapable: React.ComponentType<FileComponentProps>, 
    Uploader: React.ComponentType<ImplementedUploadElement>,
    useDropHandler: (id: number, name: string) => (file: File|undefined, fileRejections: Array<{file: File, errors: FileError[]}>) => void,
    wrapperClasses?: string
) {
    const UploaderWrappedEpisodeElement: React.ComponentType<FileComponentProps> = (props: FileComponentProps) => {
        const innerRef = React.useRef<HTMLDivElement|null>(null);
        const dropHandler = useDropHandler(props.data.id, props.data.name);

        const wrapperClickHandler:React.MouseEventHandler = e => {
            const inner = innerRef.current;
            if(inner) {
                inner.click();
            }
        } 

        return (
            <div 
                className={clsx( wrapperClasses, wrapperClasses && props.isActive && 'active' )} 
                onClick={wrapperClickHandler}
            > 
                <Wrapable 
                    {...props}
                    innerRef={innerRef} 
                />
                <Uploader onDrop={dropHandler}/>
            </div>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        UploaderWrappedEpisodeElement.displayName = 'UploaderWrappedEpisodeElement';
    }

    return UploaderWrappedEpisodeElement;
}