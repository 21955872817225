import React from 'react'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import SeasonsCollection from './components/SeasonsCollection'
import SerialFilesProvider from './contexts/SerialFilesContext'
import EpisodesCollection from './components/EpisodeCollection'
import SelectedFileSerial from './components/SelectedFileSerial'

function EditFilesSerial() {
    const editContentContext = useContentEditContext();
    const editFilesUIProps = React.useMemo(() => ({
        id: editContentContext.id,
    }), [editContentContext]);
    
    return (
        <SerialFilesProvider serialId={editFilesUIProps.id}>
            <SeasonsCollection />
            <EpisodesCollection />
            <SelectedFileSerial />
        </SerialFilesProvider>
    );
}

export default EditFilesSerial