import React from 'react'
import { ImageRemoverCallback, ImageUploaderCallback, RemovedImageData, UploadedImageData, UploadImageProgressEvent } from 'src/api/rest/types'
import { uploadImageVertical } from 'src/api/rest/post/upload/uploadParticipantImage'
import UploadImageCard from 'src/app/components/UploadImageCard'
import ImageUploader from 'src/app/components/ContentService/ImageUploader'
import './styles.css'
import { removeImageVertical } from 'src/api/rest/post/remove/removeParticipantImage'
import { parseImageUrl } from '../../../../helpers/parseImageUrl'

type BindedImageUploaderCallback = (file: File, progressHandler: (event: UploadImageProgressEvent) => void) => Promise<UploadedImageData|null>
type BindedImageRemoverCallback = () => Promise<RemovedImageData|null>

type ParticipantImageColumnRendererProps = {
    jsonString: string|undefined,
    id: number
}

function useBindedUploader(id: number, imageUploader: ImageUploaderCallback) {
    return React.useCallback<BindedImageUploaderCallback>(
        (file, progressHandler) => imageUploader(id, file, progressHandler), 
        [id, imageUploader]
    );
}

function useBindedRemover(id: number, imageRemover: ImageRemoverCallback) {
    return React.useCallback<BindedImageRemoverCallback>(
        () => imageRemover(id), 
        [id, imageRemover]
    );
}


function ParticipantImageColumnRenderer(props: ParticipantImageColumnRendererProps) {
    const { jsonString, id } = props;
    const uploaderActor = useBindedUploader(id, uploadImageVertical);
    const deleterActor = useBindedRemover(id, removeImageVertical);

    const src = React.useMemo(() => {
        if(jsonString !== undefined) {
            return parseImageUrl(jsonString);
        }
        return undefined;
    }, [jsonString]);

    return (
        <div className='unselectable participant-image-column' draggable='false'>
            <UploadImageCard.AvatarImageUploader 
                src={src} 
                uploader={uploaderActor}
                remover={deleterActor}
            >
                { ImageUploader.AvatarImageUploader }
            </UploadImageCard.AvatarImageUploader>
        </div>
    );
}

export default ParticipantImageColumnRenderer
