import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { toUpperCamelCase } from 'src/app/components/ContentService/helpers/SelectorUIHelpers'
import { SelectorConfigContextValue, SelectorParams } from '../../contexts/SelectorConfigContext'
import { Selector, SelectorInput } from 'src/api/graphql/types'
import { useTheme } from '@material-ui/core'

type SelectorConfigSearchBarProps<T extends SelectorParams, K extends SelectorInput, V extends Selector> = {
    useSelectorConfigContext: () => SelectorConfigContextValue<T, K, V>,
}

function SelectorConfigSearchBar<T extends SelectorParams, K extends SelectorInput, V extends Selector>(props: SelectorConfigSearchBarProps<T, K, V>) {
    // SelectorConfig context
    const selectorsConfigContext = props.useSelectorConfigContext();
    const theme = useTheme()

    // Context's memoized values
    const selectorsConfigProps = React.useMemo(() => {
        return {
            loading: selectorsConfigContext.loading,
            error: selectorsConfigContext.error,
            searchText: selectorsConfigContext.params.searchText,
            addPermission: selectorsConfigContext.addPermission,
            setParams: selectorsConfigContext.setParams,
            addCallback: selectorsConfigContext.addCallback
        }
    }, [selectorsConfigContext]);

    const { loading, addPermission, searchText, setParams, addCallback } = selectorsConfigProps;

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = event => {
        const text = event.target.value;

        setParams && setParams(
            prev => ({
                ...prev, 
                searchText: text
            })
        );
    }

    const clearHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        setParams && setParams(
            prev => ({
                ...prev,
                searchText: ''
            })
        );
    }

    const addHandler: React.KeyboardEventHandler<HTMLInputElement> = event => {
        event.persist();
        
        if(addPermission && event.ctrlKey && event.key === 'Enter') {
            addCallback && addCallback({
                name: toUpperCamelCase(searchText),
            } as K);
        }
    }

    const isClearButtonActive = searchText === '';

    return (
        <div className='selectors-config-searchbar' >
            <input
                type='text'
                className='form-control'
                placeholder='Введите для поиска'
                disabled={loading}
                value={searchText}
                name='searchText'
                onChange={changeHandler}
                onKeyDown={addHandler}
            />

            <div className='add-label' style={{display: addPermission ? 'inline-flex' : 'none'}}>
                <span>Ctrl + Enter</span>
                <p style={{color: theme.palette.text.secondary}}> нажмите для добавления</p> 
            </div>

            <button disabled={isClearButtonActive} onClick={clearHandler} className={isClearButtonActive ? 'd-none' : ''}>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Times.svg')}/>
            </button>  
        </div>
    );
}

export default SelectorConfigSearchBar