import React from 'react'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import ErrorBoundary from '../../../ErrorBoundary'
import { SerialsFilter } from './SerialsFilter'
import SerialsTable from './SerialsTable'

function SerialsCard(): React.ReactElement {
    const [loading, setLoading] = React.useState(false);

    return (
        <Card className='mb-0'>
            <CardHeader title={'Список сериалов'}/>
            <CardBody>
                <SerialsFilter listLoading={loading}/>
                {/* Unused, errors occur for reason of empty pagination */}
                <ErrorBoundary> 
                    <SerialsTable loadingTrigger={setLoading}/>
                </ErrorBoundary>
            </CardBody>
        </Card>
    )

}

export default SerialsCard