import React from 'react'
import { useContextSelector } from 'use-context-selector'
import prefetchMovieUploadUrl from 'src/api/rest/post/prefetch/prefetchMovieUploadUrl'
import createUploaderInstance from 'src/uploader'
import { PrefetchData } from 'src/uploader/types'
import useAddMovieFileMutation from 'src/app/hooks/mutation/useAddMovieFile'
import prefetchTrailerUploadUrl from 'src/api/rest/post/prefetch/prefetchTrailerUploadUrl'
import { Response } from 'src/api/graphql/types'
import useAddTrailerFileMutation from 'src/app/hooks/mutation/useAddTrailerFile'

type VideoFileUploadProviderProps = {
    endpoint: string,
} & ReactMultipleContainer

export enum UploadTypes {
    MOVIE = 1,
    TRAILER = 2
}

const [UploaderContextProvider, UploaderContext ] = createUploaderInstance();

function usePrefetchUrl() {
    const prefetchUploadUrl = React.useCallback( (id: number, name: string, uploadType: UploadTypes): Promise<PrefetchData | null> => {
        switch (uploadType) {
            case UploadTypes.MOVIE:
                return prefetchMovieUploadUrl(id, name);
            case UploadTypes.TRAILER:
                return prefetchTrailerUploadUrl(id, name);
            default:
                throw new Error(`Unknown uploadType for prefetching: ${uploadType}`);
        }
    }, []);

    return prefetchUploadUrl;
}

function useAddFileToDB() {
    const addMovieFileToDB = useAddMovieFileMutation();
    const addTrailerFileToDB = useAddTrailerFileMutation();

    const prefetchUploadUrl = React.useCallback((filePath: string, id: number, uploadType: UploadTypes): Promise<Error | Response | Error[]> => {
        switch (uploadType) {
            case UploadTypes.MOVIE:
                return addMovieFileToDB(filePath, id);
            case UploadTypes.TRAILER:
                return addTrailerFileToDB(filePath, id);
            default:
                throw new Error(`Unknown uploadType for saving to DB: ${uploadType}`);
        }
    }, [addMovieFileToDB, addTrailerFileToDB]);

    return prefetchUploadUrl;
}

function VideoFileUploadProvider(props: VideoFileUploadProviderProps) {
    const { children, endpoint } = props;
    const prefetchUploadUrl = usePrefetchUrl();
    const addFileToDB = useAddFileToDB();

    return (
        <UploaderContextProvider
            endpoint={endpoint}
            prefetchHandler={prefetchUploadUrl}
            afterUploadHandler={addFileToDB}
            simultaniousCount={2}
        >
            <PageUploaderGuard/>
            { children }
        </UploaderContextProvider>
    );
}

// TODO: Need to be tested
function PageUploaderGuard() {
    const waitingSize = useContextSelector(UploaderContext, v => v.waiting.size);
    const pendingSize = useContextSelector(UploaderContext, v => v.pending.size);

    React.useEffect(() => {
        const closeHandler = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            return e.returnValue = 'Ty uweren Syn moy?'
        }

        const summarySize = waitingSize + pendingSize;

        if(summarySize !== 0) {
            window.addEventListener('beforeunload', closeHandler, {capture: true});
        }
        if(summarySize === 0) {
            window.removeEventListener('beforeunload', closeHandler);
        }

        return () => {
            if(summarySize !== 0) {
                window.removeEventListener('beforeunload', closeHandler, {capture: true});
            }
        };
    });

    return null;
}

export default VideoFileUploadProvider
export { UploaderContext as VideoFileUploadContext }
