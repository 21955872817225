import { Route } from 'react-router-dom'
import { withCard } from 'src/app/hocs/withCard'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import MainPageCardHeaderToolbar from './components/MainPageCardHeaderToolbar'
import { AddTapeModal, EditTapeModal } from './components/TapeModal'
import TapesList from './components/TapesList'
import { UpdatePosterModal } from './components/UpdatePosterModal'
import Classes from './styles.module.css'

function MainPage() {
    return (
        <>
            <TapesList/>
            <Route 
                path={
                    ROUTES_LIST.MAIN_PAGE
                    .concat('/add')
                }
            >
                <AddTapeModal/>
            </Route>
            <Route 
                path={
                    ROUTES_LIST.MAIN_PAGE
                    .concat('/edit')
                }
            >
                <EditTapeModal/>
            </Route>
            <Route 
                path={
                    ROUTES_LIST.MAIN_PAGE
                    .concat('/change-poster')
                }
            >
                <UpdatePosterModal/>
            </Route>
        </>
    ); 
}

export default withCard({
    Target: MainPage,
    CardHeaderTitle: 'Ленты на главном экране устройств',
    CardHeaderToolbar: MainPageCardHeaderToolbar,
    cardClassName: Classes['main-page-card'],
    cardHeaderClassName: Classes['card-header-title'],
    wrappedComponentName: 'MainPageCard'
})