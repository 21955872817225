import { useMemo } from 'react'
import SidebarBrand from './components/SidebarBrand'
import { Link } from 'react-router-dom'
import objectPath from 'object-path'
import { useHtmlClassService } from 'src/metronic/core/MetronicLayout'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import SidebarMenu from './components/SidebarMenu'

type SidebarProps = {
    sidebarMenuContent: React.FC<{ 
        layoutProps: {
          ulClasses: string
        } & unknown
    }>
}

function Sidebar(props: SidebarProps) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
        disableScroll:
            objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
            false,
        asideClassesFromConfig: uiService.getClasses("aside", true),
        disableAsideSelfDisplay:
            objectPath.get(uiService.config, "aside.self.display") === false,
        headerLogo: uiService.getLogo()
        };
    }, [uiService]);
    
    return (
        <aside id='kt_aside' className={`aside aside-left ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
            <SidebarBrand/>

            {/* begin::Aside Menu */}
            <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
                {layoutProps.disableAsideSelfDisplay && (
                    <>
                    {/* begin::Header Logo */}
                    <div className="header-logo">
                        <Link to={ROUTES_LIST.ROOT}/>
                    </div>
                    {/* end::Header Logo */}
                    </>
                )}
            
                <SidebarMenu 
                    disableScroll={layoutProps.disableScroll} 
                    sidebarMenuContent={props.sidebarMenuContent}
                />
            </div>
          {/* end::Aside Menu */}

        {/* end::Aside */}
        </aside>
    )
}

export default Sidebar