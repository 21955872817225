import { axiosImageApiInstance } from 'src/api/rest/axios'
import { IMAGE_API_ENDPOINT } from 'src/api/rest/urls/endpoints'
import IMAGE_UPLOAD_API from 'src/api/rest/urls/imageUpload'
import { UploadedImageData, UploadImageProgressEvent } from 'src/api/rest/types'
import { getToken } from 'src/util/token';

/**
 * Api call with response data
 * { 
 *  status: 'ok'|'error', 
 *  message: ''|string', 
 *  data:    string|null
 * }
 * @param url url to send request
 * @param movieId movie id to which attemting to add image 
 * @param file image file
 */
async function uploadImage( url: string, movieId: number, file: File, progressHandler: (event: UploadImageProgressEvent) => void ): Promise<UploadedImageData|null> {
    const payload = new FormData();
    payload.append('image', file);
    payload.append('movieId', `${movieId}`);

    try {
        const response = await axiosImageApiInstance.post<UploadedImageData>(url, payload, {
            onUploadProgress: progressHandler,
            headers: {
                Authorization: getToken(),
            }
        });
        
        return response.data
    } catch (error) {
        console.error('uploadImage api call', error);
        return null
    }
}   

export const uploadImageVertical = uploadImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_UPLOAD_API.vertical);
export const uploadImageHorizontal = uploadImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_UPLOAD_API.horizontal);
export const uploadImageHorizontalRaw = uploadImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_UPLOAD_API.horizontalRaw);
export const uploadImageTitle = uploadImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_UPLOAD_API.title);