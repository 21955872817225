import React from 'react'
import { useQuery } from '@apollo/client'
import { 
    GetHomePageCategoriesArgs, 
    GetHomePageCategoriesData,
    GET_HOME_PAGE_CATEGORIES_QUERY
} from 'src/api/graphql/queries/GetHomePageCategoriesQuery'

export function useTapesList() {
    const { data, loading } = useQuery<GetHomePageCategoriesData, GetHomePageCategoriesArgs>(GET_HOME_PAGE_CATEGORIES_QUERY);
    const getTapesResult = React.useMemo(() => {
        if(data !== undefined) {
            return {
                loading, 
                tapes: data.GetHomePageCategories
            };
        }

        return {
            loading,
            tapes: []
        };
    }, [data, loading]);

    return getTapesResult;
}