import { gql } from '@apollo/client'

export interface IsHaveTrailerFileArgs {
    movieID: number
} 

export interface IsHaveTrailerFileData {
    IsHaveTrailerFile: boolean
}

export const IS_HAVE_TRAILER_FILE_QUERY = gql`
    query IsHaveTrailerFile( $movieID: Int!) {
        IsHaveTrailerFile(movieID: $movieID)
    }
`