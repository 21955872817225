import { useMutation } from '@apollo/client'
import { ChangeMovieStatusMutationArgs, ChangeMovieStatusMutationData, CHANGE_MOVIE_STATUS_MUTATION } from 'src/api/graphql/mutations/ChangeMovieStatus'
import { Response } from 'src/api/graphql/types';
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes';
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

export function useChangeFilmStatusMutation(completeHandler: (data: Response) => void) {
    const {setNotification} = useNotification();
    const [changeMovieStatusCallback] = useMutation<ChangeMovieStatusMutationData, ChangeMovieStatusMutationArgs>(CHANGE_MOVIE_STATUS_MUTATION, {
        onCompleted: data => {
            if(data.ChangeMovieStatus.status === 'error') {
                setNotification({
                    message: data.ChangeMovieStatus.message,
                    variant: NotificationVariants.warning
                });
            } else {
                completeHandler(data.ChangeMovieStatus);
            }
        },
        onError: error => {
            setNotification({
                message: error.message,
                variant: NotificationVariants.error
            });
        },
    });

    return (movieID: number, status: ACTIVATION_STATUS) => changeMovieStatusCallback({
        variables:{
            movieID,
            status,
            typeID: CONTENT_TYPES.FILM
        }
    });
}