import { Region } from 'src/api/graphql/types'
import { gql } from '@apollo/client'

export interface GetAllRegionsArgs {}

export interface GetAllRegionsData {
    getAllRegions: Region[]
}

export const GET_ALL_REGIONS_QUERY = gql`
     query getAllRegionsQuery {
        getAllRegions {
            id
            name
        }
    }
`