import { gql } from '@apollo/client'
import { Language } from '../types'

export interface GetAllLanguagesArgs {} 

export interface GetAllLanguagesData {
    getAllLanguages: Language[]
}

export const GET_ALL_LANGUAGES_QUERY = gql`
    query GetAllLanguages {
        getAllLanguages {
            id
            name
            abbreviated
        }
    }
`