import React from 'react'
import { FileError } from 'react-dropzone'
import { BasicImageUploaderProps } from '../components/BasicImageUploader'
import { FileExtra } from '../helpers/ImageUploaderHelpers'

type ResultUploadElement = {
    onDrop: <T extends FileExtra>(files: T[], fileRejection: Array<{file: T, errors: FileError[]}>) => void
}

function withImplementation( maxSize: number, accept: string, validator:  <T extends FileExtra>(file: T) => FileError|FileError[]|null, wrapperClasses?: string) {
    // Second HOC for wrapping BasicUploader. That is its particular implementation.
    return (BasicUploader: React.ComponentType<BasicImageUploaderProps>): React.ComponentType<ResultUploadElement> => {
        // Particular implementation for BasicUploader with injected basicProps
        return ({onDrop}: ResultUploadElement): React.ReactElement => {

            return (
                <div className={wrapperClasses}>
                    <BasicUploader maxSize={maxSize} accept={accept} validator={validator} onDrop={onDrop}/>
                </div>
            );
        }
    }
}

export default withImplementation