import { withUploader } from 'src/app/components/ContentService/EditContent/hocs/withUploader'
import { VideoUploader } from 'src/app/components/ContentService/VideoUploader'
import TrailerName from './components/TrailerName'
import useTrailerDropHandler from './hooks/useTrailerDropHandler'

type TrailerElementProps = {
    data: {
        id: number,
        name: string,
        typeId: number,
        trailerUrl: string
    }
    isActive: boolean
}

function TrailerElement(props: TrailerElementProps) {
    const {name} = props.data;
    return (
        <div>
            <TrailerName name={name}/>
        </div>
    );
}

const UploaderWrappedTrailerElement = withUploader(
    TrailerElement,
    VideoUploader,
    useTrailerDropHandler, 
    'trailer'
);

export default UploaderWrappedTrailerElement