import { Redirect, Route, Switch,  } from 'react-router-dom'
import Login from '../components/Login';
import { ROUTES_LIST } from './RootRoute';


function AuthRoute() {
    const { LOGIN,  } = ROUTES_LIST;
    
    return (
        <>
            <Switch>
                <Route path={LOGIN}>
                    <Login/>
                </Route>

                <Redirect to={LOGIN}/>
            </Switch>
        </>
    );
}

export default AuthRoute