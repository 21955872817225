import React from 'react'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { CardHeaderToolbar } from 'src/metronic/components/Card/CartHeader/CartHeaderToolbar'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import EditImageCollection from '../EditImageCollection'
import { getActiveType } from '../../../helpers/EditContentHelpers'
import { useContentEditContext } from '../../../contexts/ContentEditContext'

function EditImagesCard() {
    const contentEditContextValue = useContentEditContext();
    const filmsUIProps = React.useMemo(() => {
        return {
            typeId: contentEditContextValue.typeId,
        }
    }, [contentEditContextValue]);

    return (
        <Card className='add-images-card'>
            <CardHeader title={'Картинки'}>
                <CardHeaderToolbar>
                    <CardHeaderToolbar>
                        <span>
                            { getActiveType(filmsUIProps.typeId) }
                        </span>
                    </CardHeaderToolbar>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ErrorBoundary> 
                    <EditImageCollection/>
                </ErrorBoundary>
            </CardBody>
        </Card>
    );
}

export default EditImagesCard