import { gql } from '@apollo/client'
import { Participant } from '../types'

export interface GetAllParticipantsArgs {
    limit: number
    page: number
} 

export interface GetAllParticipantsData {
    getAllParticipants: {
        Participants: Participant[]
        Pagination: {
            TotalCount: number
        }
    }
}

export const GET_ALL_PARTICIPANTS_QUERY = gql`
    query GetAllParticipants($limit: Int!, $page: Int!) {
        getAllParticipants( limit: $limit, page: $page) {
            Participants {
                name
                roleID
                id
                image
            }
            Pagination {
                TotalCount
            }
        }
    }
`