import { gql } from '@apollo/client'
import { Response, TapeType, TapeSort, TapeSize } from 'src/api/graphql/types'

export interface UpdateCategoryToHomePageArgs {
    id: number
    input: {
        TitleTk: string
        TitleRu: string
        categoryType: TapeType['Value']
        categoryID: number
        sort: TapeSort['Value']
        itemSize: TapeSize['Value']
        position: number
    }
} 
export interface UpdateCategoryToHomePageData {
    UpdateCategoryToHomePage: Response
}

export const UPDATE_CATEGORY_TO_HOME_PAGE_MUTATION = gql`
    mutation UpdateCategoryToHomePageMutation($id: Int!, $input: InputArgsToHomePage) {
        UpdateCategoryToHomePage(id: $id, input: $input) {
            status
            message
        }
    }
`