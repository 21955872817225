import React from 'react'
import { 
    QueryResult, 
    useQuery 
} from '@apollo/client'
import { 
    GetFilesByProcessStatusArgs, 
    GetFilesByProcessStatusData, 
    GET_FILES_BY_PROCESS_STATUS_QUERY 
} from 'src/api/graphql/queries/GetFilesByProcessStatus'
import { TranscodeRequestFile, TRANSCODE_STATUS } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { ProcessingFilesResult } from '../types'
import { PROCESS_POLL_INTERVAL } from '../helpers/pollingIntervals'

type RejectionResponse = QueryResult<GetFilesByProcessStatusData, GetFilesByProcessStatusArgs>

export function useGetRejectedFiles(typeID: CONTENT_TYPES) {
    const transcodeRejections = useQuery<GetFilesByProcessStatusData, GetFilesByProcessStatusArgs>(
        GET_FILES_BY_PROCESS_STATUS_QUERY,
        {
            variables: { 
                typeID, 
                status: TRANSCODE_STATUS.TRANSCODE_ERROR 
            },
            pollInterval: PROCESS_POLL_INTERVAL.REJECTED
        }
    );

    const fileStorageRejections = useQuery<GetFilesByProcessStatusData, GetFilesByProcessStatusArgs>(
        GET_FILES_BY_PROCESS_STATUS_QUERY,
        {
            variables: { 
                typeID, 
                status: TRANSCODE_STATUS.FILE_STORAGE_ERROR 
            },
            pollInterval: PROCESS_POLL_INTERVAL.REJECTED
        }
    );

    const rejectedFilesResult = React.useMemo<ProcessingFilesResult>(() => {
        const rejectionDeps = [transcodeRejections, fileStorageRejections];

        return {
            loading: makeLoading(rejectionDeps),
            data: makeData(rejectionDeps),
            error: makeError(rejectionDeps)
        }
    }, [transcodeRejections, fileStorageRejections]);

    return rejectedFilesResult;
}

/** Helper functions */
function makeLoading(deps: RejectionResponse[]): boolean {
    return deps.some(d => d.loading);
}
function makeData(deps: RejectionResponse[]): TranscodeRequestFile[] {
    return deps.reduce((prev, curr) => {
        if(curr.data) {
            prev.push(...curr.data.GetFilesByProcessStatus);
        }
        return prev;
    }, [] as TranscodeRequestFile[]);
}
function makeError(deps: RejectionResponse[]) {
    const firstError = deps.find(d => d.error);
    return firstError ? firstError.error : undefined
}