import { HostAndPagination } from 'src/api/graphql/types'
import { gql } from '@apollo/client'
import { ABONENT_SORT_TYPES } from 'src/app/assets/AbonentTypes'

export interface GetHostsByRegionsArgs {
    regionID: number,
    limit: number,
    page: number,
    sort: ABONENT_SORT_TYPES
}

export interface GetHostsByRegionsData {
    getHostsByRegions: HostAndPagination
}

export const GET_ALL_HOSTS_BY_REGION_QUERY = gql`
     query getHostsByRegionsQuery($limit: Int!, $page: Int!, $regionID: Int!, $sort: Int!) {
        getHostsByRegions(limit: $limit, page: $page, regionID: $regionID, sort: $sort) {
            hosts {
                ip
                phone
                code
                name
                active
                type
            }
            Pagination {
                TotalCount
            }
        }
    }
`