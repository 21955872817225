import React from 'react'
import { toAbsoluteUrl } from 'src/util/url'
import SVG from 'react-inlinesvg'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import clsx from 'clsx'

type SeasonHeaderProps = {
    editHandler: React.MouseEventHandler,
    statusChangeHandler: React.MouseEventHandler,
    isActive: boolean,
    status: ACTIVATION_STATUS
}

function SeasonHeader(props: SeasonHeaderProps) {
    const { editHandler, statusChangeHandler, status } = props;

    const statusDisplayClass = status === ACTIVATION_STATUS.ACTIVE 
        ? 'svg-icon-success' 
        : 'svg-icon-danger';

    return (
        <div className='season-header'>
           <button
                title='Сменить статус'
                className='btn btn-icon btn-light btn-sm m-0'
                onClick={statusChangeHandler}
            >   
                <span className={clsx('svg-icon', 'svg-icon-md', statusDisplayClass)}>
                    <SVG
                        description='Сменить статус'
                        title='Сменить статус'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>
            </button>

            <button
                title='Изменить'
                className='btn btn-icon btn-light btn-sm m-0'
                onClick={editHandler}
            >
                <span className='svg-icon svg-icon-md svg-icon-primary'>
                    <SVG
                        description='Изменить'
                        title='Изменить'
                        src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                    />
                </span>
            </button>
        </div>
    );
}


export default SeasonHeader