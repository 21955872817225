import React from 'react'
import { FormikErrors, useFormik } from 'formik'
import * as Yup from 'yup'
import { useSerialFilesContext } from '../../../../../contexts/SerialFilesContext'
import { Season, SeasonInput } from 'src/api/graphql/types'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import  DatePicker  from 'react-datepicker'
import { getFieldCSSClasses } from 'src/app/components/ContentService/AddContent/AddBasicInfo/helpers/AddBasicInfoHelpers'
import { useUpdateSeasonMutation } from '../hooks/useUpdateSeasonMutation'
import useEscapeCloseListener from '../../../../../hooks/useEscapeCloseListener'
import { isNull } from 'src/util/isNull'

type SeasonEditModalBodyProps = {
    seasonID: number, 
    closeCallback: () => void
}

type AddSeasonForm = {
    name: string,
    release: string|undefined,
}

const validationSchema = Yup.object({
    name: Yup
        .string()
        .min(3, 'Название сезона должно состоять минимум из 3-х символов')
        .required('Название сезона не должно быть пустым'),
    release: Yup
        .string()
});

const customValidator = (values: AddSeasonForm): FormikErrors<AddSeasonForm> => {
    const error:FormikErrors<AddSeasonForm> = {};
    
    if(values.release) {
        const today = new Date();
        const release = new Date(values.release);
        const todayPlusYear = new Date( today.setFullYear( today.getFullYear() + 1 ));
        const MoreThanTodayPlusYear = release > todayPlusYear;

        if(values.release === undefined) {
            return error;
        }

        if( MoreThanTodayPlusYear ) {
            error.release = 'Дата выхода не должна быть больше текущей даты более чем на год';
        } 

        const MIN_DATE = new Date('01.01.1900');
        if( release < MIN_DATE ) {
            error.release = 'Год выхода не может быть меньше чем 1990 год';
        }
    }
    
    return error;
}

function prepareToSend(values: AddSeasonForm, parentID: number, languageID: number): SeasonInput {
    const mutationArgs: SeasonInput = {
        name: values.name,
        parentID,
        languageID,
    };

    if(values.release !== undefined) {
        mutationArgs.release = values.release;
    }

    return mutationArgs;
}

function createInitialValue(season: Season|undefined): {name: string} {
    if(season !== undefined) {
        return {
            name: season.name
        }
    }

    throw new Error(`Season could not be undefined`)
}

function SeasonEditModalBody(props: SeasonEditModalBodyProps) {
    const {seasonID, closeCallback} = props;
    const serialFilesContext = useSerialFilesContext();

    useEscapeCloseListener(closeCallback);

    const targetSeason = React.useMemo(() => {
        if(isNull(serialFilesContext)) {
            throw new Error('serialFilesContext could not be null in SeasonEditModalBody');
        }

        if(serialFilesContext !== null) {
            const seasons = serialFilesContext.getAllSeasonsResult.data;
            return seasons.find( s => s.id === seasonID );
        }
        return undefined;
    }, [serialFilesContext, seasonID]);

    const triggerRefetching = React.useMemo(() => {
        if(serialFilesContext !== null) {
            return serialFilesContext.getAllSeasonsResult.refetch;
        }
        return undefined;
    }, [serialFilesContext]);

    const addSeasonCallback = useUpdateSeasonMutation(triggerRefetching);
    const contentEditContext = useContentEditContext();
    const { languageID, parentID } = React.useMemo(() => {
        return {
            languageID: contentEditContext.basicInfo.language,
            parentID: contentEditContext.id
        }
    }, [contentEditContext]);

    const formik = useFormik<AddSeasonForm>({
        initialValues: {
          ...createInitialValue(targetSeason),
          release: undefined
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            await addSeasonCallback({
                variables: {
                    seasonID,
                    input: prepareToSend(values, parentID, languageID, )
                }
            }).finally( closeCallback );
        },
        validate: customValidator
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='name'
                    name='name'
                    label='Наименование'
                    type='text'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </div>

            <div className='mb-5'>
                <DatePicker
                    disabled={formik.isSubmitting}
                    className={ `${getFieldCSSClasses(formik.touched['release'], formik.errors['release'])} p-4` }
                    name='release'
                    dateFormat='dd.MM.yyyy'
                    placeholderText='Выберите дату выхода'
                    selected={  formik.values.release ? new Date(formik.values.release) : undefined }
                    onChange={(date: Date) => {
                        formik.setFieldValue('release', date.toJSON());
                    }}
                    onBlur={formik.handleBlur}
                />
            </div>
            
            <div className='d-flex justify-content-end'>
                <Button color='primary' variant='primary' type='submit' disabled={formik.isSubmitting}>
                    Изменить
                </Button>
            </div>
      </form>
    );
}

export default SeasonEditModalBody