import React from 'react'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import EditFilesFilm from './../components/EditFilesFilm'
import EditFilesSerial from './../components/EditFilesSerial'
import EditFilesFallback from './../components/EditFilesFallback'

export function getEditFilesByType(type: CONTENT_TYPES, id: number): React.ComponentType{
    switch (type) {
        case CONTENT_TYPES.FILM: return EditFilesFilm
        case CONTENT_TYPES.SERIAL: return EditFilesSerial
        default: return () => <EditFilesFallback id={id}/>
    }
}