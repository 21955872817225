import React from "react";
import { PaginationOptions } from "react-bootstrap-table-next";
import {PaginationLinks} from "./PaginationLinks";
import {PaginationToolbar} from "./PaginationToolbar";

type PaginationProps = {
    children: React.ReactElement,
    isLoading: boolean,
    paginationProps: PaginationOptions
}

export function Pagination(props: PaginationProps) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks paginationProps={paginationProps} />
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
}
