import SelectedFile from './../../../SelectedFile'

function SelectedFileTrailer() {
    return (
        <div className='trailer-player'>
            <SelectedFile playerData={null}/>
        </div>
    );
}

export default SelectedFileTrailer