import { useMutation } from '@apollo/client'
import { SendToTranscodeArgs, SendToTranscodeData, SEND_TO_TRANSCODE_MUTATION } from 'src/api/graphql/mutations/SendToTranscode'
import { GET_MOVIE_FILE_INFO_QUERY } from 'src/api/graphql/queries/GetMovieFileInfo';
import { GET_SERIES_FILE_INFO_QUERY } from 'src/api/graphql/queries/GetSeriesFileInfo';
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'

export function useSendToTranscodeMutation(typeID: CONTENT_TYPES) {
    const [sendToTranscode] = useMutation<SendToTranscodeData, SendToTranscodeArgs>(SEND_TO_TRANSCODE_MUTATION, {
        refetchQueries: getRefectList(typeID)
    });

    return async (args: SendToTranscodeArgs) => {
        return await sendToTranscode({
            variables: {
                ...args
            }
        })
    }
}

const getRefectList = (typeID: CONTENT_TYPES) => {
    switch (typeID) {
        case CONTENT_TYPES.FILM: return [GET_MOVIE_FILE_INFO_QUERY]; 
        case CONTENT_TYPES.EPISODE: return [GET_SERIES_FILE_INFO_QUERY];    
        default: return undefined;
    }
}