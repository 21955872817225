import React from 'react'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import ErrorBoundary from '../../../ErrorBoundary'
import ComplaintsTable from './ComplaintsTable'

function SerialsCard(): React.ReactElement {
    return (
        <Card className='mb-0'>
            <CardHeader title={'Список сериалов'}/>
            <CardBody>
                {/* Unused, errors occur for reason of empty pagination */}
                <ErrorBoundary> 
                    <ComplaintsTable/>
                </ErrorBoundary>
            </CardBody>
        </Card>
    );
}

export default SerialsCard