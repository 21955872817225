import { useQuery } from '@apollo/client'
import { SearchMovieByNameArgs, SearchMovieByNameData, SEARCH_MOVIE_BY_NAME_QUERY } from 'src/api/graphql/queries/SearchMovieByName'

/**
 * Query for fetching Films and Serials without tag, genre. 
 * Has additional ability to skip call of query
 */
function useSearchMovieByNameQuery({ name, typeID, limit, page }: SearchMovieByNameArgs, skip: boolean = false) {
    const { loading, data, error } = useQuery<SearchMovieByNameData, SearchMovieByNameArgs>(SEARCH_MOVIE_BY_NAME_QUERY, {
        variables: {
            name,
            typeID,
            limit,
            page,
        },
        skip,
    });
    return { loading, data, error }
}

export default useSearchMovieByNameQuery