import { ApolloClient, createHttpLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from 'src/util/token'

const URI = process.env.NODE_ENV !== 'production' 
? process.env.REACT_APP_API_GRAPHQL_DEV
: process.env.REACT_APP_API_GRAPHQL;

const httpLink = createHttpLink({
    uri: URI
});

const authLink = setContext((_graphqlRequest, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ''
    }
  }
});

const link = authLink.concat(httpLink);

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Participant': return `Participant:${responseObject.id}-${responseObject.roleID}`;
      default: return defaultDataIdFromObject(responseObject);
    }
  }
});

const apolloClient = new ApolloClient({
  link,
  cache
});

export { apolloClient }
