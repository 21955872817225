import React from 'react'
import { Selector } from 'src/api/graphql/types'

type SelectorsListSelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    selectors: Selector[],
}

function SelectorsListSelect(props: SelectorsListSelectProps) {
    const { selectors } = props;

    return (
        <select
            {...props}
        >
            {
                selectors.map( selector => <option key={selector.id} value={selector.id}>{selector.name}</option> )
            }
        </select>
    )
} 

export default SelectorsListSelect