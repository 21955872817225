import React from 'react';
import { ImageRemoverCallback, RemovedImageData } from 'src/api/rest/types'
import { ContentEditContextValue } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'

type BindedImageRemoverCallback = () => Promise<RemovedImageData|null>

export function useBindedRemover(
    editContext: ContentEditContextValue, 
    imageRemover: ImageRemoverCallback
): BindedImageRemoverCallback {
    return React.useCallback<BindedImageRemoverCallback>(
        () => imageRemover(editContext.id), 
        [editContext, imageRemover]
    );
}