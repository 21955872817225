import React from 'react'
import { useAddGenre } from './hooks/useAddGenre'
import { useUpdateGenre } from './hooks/useUpdateGenre'
import { useGetAllGenres } from './hooks/useGetGenres'
import SelectorConfigCard from '../SelectorConfigCard'
import GenresConfigTable from './GenresConfigTable'
import { SelectorParams, useSelectorConfig } from '../../contexts/SelectorConfigContext'
import { Genre, GenreInput } from 'src/api/graphql/types'
import SelectorConfigSearchBar from '../SelectorConfigSearchBar'

const initialValue = {
    params: {
        searchText: ''
    },
    loading: false,
    selectors: [],
    pendingUpdates: new Set<number>(),
    addPermission: false
}

function GenresConfig() {
    const [SelectorConfigProvider, useSelectorConfigContext] = useSelectorConfig<SelectorParams, GenreInput, Genre>(initialValue);
    return (
        <SelectorConfigProvider 
            useGetQueryHook={useGetAllGenres} 
            useAddMutationHook={useAddGenre} 
            useUpdateMutationHook={useUpdateGenre}
        >
            <SelectorConfigCard title='Жанры' className='margin-bottom-0 genres-config-card'>
                <SelectorConfigSearchBar useSelectorConfigContext={useSelectorConfigContext}/>
                <GenresConfigTable useSelectorConfigContext={useSelectorConfigContext}/>
            </SelectorConfigCard>
        </SelectorConfigProvider>
    );
}

// Wrapped into memo for reason of performance and preventing error 
export default React.memo(GenresConfig)