import { gql } from '@apollo/client'
import { Response } from '../types'

export interface ChangeStatusTagMutationArgs {
    tagID: number
    status: number
} 
export interface ChangeStatusTagMutationData {
    ChangeStatusTag: Response
}

export const CHANGE_STATUS_TAG = gql`
    mutation ChangeStatusTag( $tagID: Int!, $status: Int! ) {
        ChangeStatusTag(tagID: $tagID, status: $status) {
            status
            message
        }
    }
`