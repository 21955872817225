import React from 'react'
import { Sources } from 'src/api/graphql/types'
import { ProcessData } from 'src/app/components/ContentService/EditContent/components/EditFiles/types'
import Player from '../Player'
import ProcessingSender from './components/ProcessingSender'

type SelectedFileProps = {
    playerData: Sources | null,
    processData?: ProcessData | undefined
}

function SelectedFile(props: SelectedFileProps) {
    const { playerData, processData } = props;

    const playerDataMemo = React.useMemo(() => {
        if(playerData !== null)
            return {
                Sources: playerData?.Sources
            }
        return {
            Sources: []
        }
    }, [playerData])

    return (
        <div className='player-content overflow-hidden'>
            <ProcessingSender processData={processData}/>
            <Player playerData={playerDataMemo}/>
        </div>
    );
}

export default SelectedFile
