import React from 'react'
import { Button } from '@material-ui/core'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { CardHeaderToolbar } from 'src/metronic/components/Card/CartHeader/CartHeaderToolbar'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import EditBasicInfoForm from '../EditBasicInfoForm'
import { CardFooter } from 'src/metronic/components/Card/CartFooter'
import { FormikProps } from 'formik'
import { BasicInfoEditForm, useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { Spinner } from 'react-bootstrap'
import ErrorStatusBar from 'src/app/components/ContentService/ErrorStatusBar'
import { getActiveType } from '../../../../helpers/EditContentHelpers'

type EditBasicInfoCardProps = FormikProps<BasicInfoEditForm>

// TODO: css classnames leave untouched, think about renaming
function EditBasicInfoCard(props: EditBasicInfoCardProps): React.ReactElement {
    const contentEditContextValue = useContentEditContext();
    const filmsUIProps = React.useMemo(() => {
        return {
            typeId: contentEditContextValue.typeId,
        }
    }, [contentEditContextValue]);

    const { handleSubmit } = props;
    const submitOnClick = () => handleSubmit();
    

    const { errors, isSubmitting } = props;
    return (
        <Card className='add-basic-info-card'>
            <CardHeader title={'Базовая информация'}>
                <CardHeaderToolbar>
                    <CardHeaderToolbar>
                        <span>
                            { getActiveType(filmsUIProps.typeId) }
                        </span>
                    </CardHeaderToolbar>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ErrorBoundary> 
                    <EditBasicInfoForm {...props}/>
                </ErrorBoundary>
            </CardBody>
            <CardFooter className='d-flex justify-content-end align-items-center' >
                <ErrorStatusBar errors={errors}/>
                <Button color='primary' variant='contained' onClick={ submitOnClick } className='add-form-submit-btn'>
                    {
                        isSubmitting 
                            && 
                        Object.values(errors).length === 0 
                            ?
                        <span className='d-flex align-center mr-1'>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </span>
                            :
                        <span>
                            Сохранить
                        </span>
                    }
                </Button>
            </CardFooter>
        </Card>
    );
}

export default EditBasicInfoCard