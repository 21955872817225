import React from 'react'

interface WaitingItemProps {
    value: string
}

function WaitingItem(props: WaitingItemProps) {
    const { value } = props;
    const itemRef = React.useRef<HTMLLIElement>(null);


    return (
        <li className='waiting-item' ref={itemRef}>
            { value }
        </li>
    );
}

export default WaitingItem