import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { 
    SearchParticipantByNameArgs, 
    SearchParticipantByNameData, 
    SEARCH_PARTICIPANTS_BY_NAME_QUERY
} from 'src/api/graphql/queries/SearchParticipantByName'

export function useIsUnique() {
    const [ searchCallbackBase ] = useLazyQuery<SearchParticipantByNameData, SearchParticipantByNameArgs>(SEARCH_PARTICIPANTS_BY_NAME_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });

    const searchCallback = React.useCallback(async (name: string) => {
        const {data} = await searchCallbackBase({
            variables: {
                name
            }
        });

        if(data !== undefined) {
            return !data.SearchParticipantByName.some(d => sanitazeSpace(d.name) === sanitazeSpace(name));
        }

        return data
    }, [searchCallbackBase]);

    return searchCallback;
}

const sanitazeSpace = (text: string) => {
    return text.replaceAll(/\s/g, '')
}