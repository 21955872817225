import { QUALITY_TYPES, QUALITY_TYPES_LABEL } from "src/api/graphql/types";

export function withQualityReadyToSendTable(
    ReadyToSendTable: React.ComponentType<unknown & {qualityID: QUALITY_TYPES}>, 
    qualityID: QUALITY_TYPES,
    name: QUALITY_TYPES_LABEL
) {
    const QualityCheckedReadyToSendTable: React.ComponentType<unknown> = props => {
        return (
            <ReadyToSendTable {...props} qualityID={qualityID}/>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        QualityCheckedReadyToSendTable.displayName = `ReadyToSendTable${name}`
    }

    return QualityCheckedReadyToSendTable;
}
