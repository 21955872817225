import React from 'react'
import { useLocation } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { defaultMovieQueryParams } from '../utils/defaultParams'

export function useSerialsQueryParams(): MoviesQueryParams {
    const { search } = useLocation();
    const { navigationLinks: {serialsLink} } = useNavigationLinksContext();

    const queryParams = React.useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const savedSearchParams = new URLSearchParams(serialsLink);

        return {
            tagID: getCorrectTagID(
                searchParams.get('tagID'), 
                savedSearchParams.get('tagID') 
            ),
            genreID: getCorrectGenerID(
                searchParams.get('genreID'), 
                savedSearchParams.get('genreID') 
            ),
            searchText: getCorrectSearchText(
                searchParams.get('searchText'), 
                savedSearchParams.get('searchText') 
            ),
            pageNumber: getCorrectPageNumber(
                searchParams.get('pageNumber'), 
                savedSearchParams.get('pageNumber')
            ),
            pageSize: getCorrectPageSize( 
                searchParams.get('pageSize'), 
                savedSearchParams.get('pageSize')
            ),
            sortField: getCorrectSortField(
                searchParams.get('sortField'), 
                savedSearchParams.get('sortField')
            ), 
            sortOrder: getCorrectSortOrder(
                searchParams.get('sortOrder'), 
                savedSearchParams.get('sortOrder')
            )
        }

    }, [search, serialsLink]);

    return queryParams; 
}

const getCorrectTagID = (
    tagID: string|null,
    savedtagID: string|null
): MoviesQueryParams['tagID'] => {
    if(tagID !== null) {
        return Number(tagID);
    }
    if(savedtagID !== null) return Number(savedtagID);

    return defaultMovieQueryParams.tagID;
}

const getCorrectGenerID = (
    genreID: string|null,
    savedGenreID: string|null
): MoviesQueryParams['genreID'] => {
    if(genreID !== null) {
        return Number(genreID);
    }
    if(savedGenreID !== null) return Number(savedGenreID);

    return defaultMovieQueryParams.tagID;
}

const getCorrectSearchText = (
    searchText: string|null, 
    savedSortSearchText: MoviesQueryParams['searchText']|null
): MoviesQueryParams['searchText'] => {
    if(searchText !== null) {
        return searchText;
    }

    if(savedSortSearchText !== null) return savedSortSearchText;

    return defaultMovieQueryParams.searchText;
}

const getCorrectPageSize = (
    pageSize: string|null,
    savedPageSize: string|null
): MoviesQueryParams['pageSize'] => {
    if(pageSize !== null) {
        return Number(pageSize);
    }
    if(savedPageSize !== null) return Number(savedPageSize);

    return defaultMovieQueryParams.pageSize;
}

const getCorrectPageNumber = (
    pageNumber: string|null,
    savedPageNumber: string|null    
): MoviesQueryParams['pageNumber'] => {
    if(pageNumber !== null) {
        return Number(pageNumber);
    }
    if(savedPageNumber !== null) return Number(savedPageNumber);

    return defaultMovieQueryParams.pageNumber;
}

const getCorrectSortField = (
    sortField: string|null, 
    savedSortField: MoviesQueryParams['sortField']|null
): MoviesQueryParams['sortField'] => {
    if(sortField !== null) {
        return sortField
    }

    if(savedSortField !== null) return savedSortField;

    return defaultMovieQueryParams.sortField;
}

const getCorrectSortOrder = (
    order: unknown, 
    savedOrder: unknown|null
): MoviesQueryParams['sortOrder'] => {
    if(order === 'asc' || order === 'desc') {
        return order
    };

    if(savedOrder === 'asc' || savedOrder === 'desc') return savedOrder;

    return defaultMovieQueryParams.sortOrder;
}