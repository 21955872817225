import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { CardHeader } from './CartHeader'
import { CardHeaderIcon } from './CartHeader/CartHeaderIcon'
import { CardHeaderTitle } from './CartHeader/CartHeaderTitle'
import { CardHeaderToolbar } from './CartHeader/CartHeaderToolbar'
import { CardBody } from './CartBody'
import { CardFooter } from './CartFooter'

type CardProps = {
  children: React.ReactElement|React.ReactElement[] ,
  fluidHeight?: number|string,
  className?: string,
  style?: React.CSSProperties,
  props?: any[]
}

export const Card = forwardRef<HTMLDivElement | null, CardProps>(({ fluidHeight, className, style, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      'card card-custom gutter-b',
      { 'card-height-fluid': fluidHeight },
      className
    )}
    style={style}
  />
));


// Set display names for debugging.
if (process.env.NODE_ENV !== 'production') {
  Card.displayName = 'Card';

  CardHeader.displayName = 'CardHeader';
  CardHeaderIcon.displayName = 'CardHeaderIcon';
  CardHeaderTitle.displayName = 'CardHeaderTitle';
  CardHeaderToolbar.displayName = 'CardHeaderToolbar';

  CardBody.displayName = 'CardBody';
  CardFooter.displayName = 'CardFooter';
}
