import { gql } from '@apollo/client'
import { Sources } from '../types'

export interface GetMovieFileSourceArgs {
    movieID: number
} 

export interface GetMovieFileSourceData {
    GetMovieFileSource: Sources | null
}

export const GET_MOVIE_FILE_SOURCE_QUERY = gql`
    query GetMovieFileSource($movieID: Int!) {
        GetMovieFileSource(movieID: $movieID) {
            Sources{
                Url
                Type
                Quality
            }
        }
    }
`