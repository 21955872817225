import { gql } from '@apollo/client'
import { SeriesFileInfo } from '../types'

export interface GetSeriesFileInfoArgs {
    movieID: number[]
} 

export interface GetSeriesFileInfoData {
    GetSeriesFileInfo: SeriesFileInfo[]
}

export const GET_SERIES_FILE_INFO_QUERY = gql`
    query GetSeriesFileInfo($movieID: [Int!]!) {
        GetSeriesFileInfo(movieID: $movieID) {
            UploadStatus
            ProcessStatusLQ
            ProcessStatusHQ
        }
    }
`