import { axiosImageApiInstance } from 'src/api/rest/axios'
import { IMAGE_API_ENDPOINT } from 'src/api/rest/urls/endpoints'
import IMAGE_REMOVE_API from 'src/api/rest/urls/imageRemove'
import { RemovedImageData } from 'src/api/rest/types'
import { getToken } from 'src/util/token'

/**
 * Api call with response data
 * { 
 *  status: 'ok'|'error', 
 *  message: ''|string', 
 *  data:    null (currently only null)
 * }
 * @param movieId movie id to which attemting to add image 
 */
async function removeImage( url: string, movieId: number ): Promise<RemovedImageData|null> {
    const Token = getToken();
    const payload = new FormData();
    payload.append('movieId', `${movieId}`);

    try {
        const response = await axiosImageApiInstance.delete<RemovedImageData>(url, {
            data: payload,
            headers: {
                Authorization: Token,
            }
        });
        
        return response.data
    } catch (error) {
        console.error('removeImage api call', error);
        return null
    }
}   

export const removeImageVertical = removeImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_REMOVE_API.vertical);
export const removeImageHorizontal = removeImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_REMOVE_API.horizontal);
export const removeImageHorizontalRaw = removeImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_REMOVE_API.horizontalRaw);
export const removeImageTitle = removeImage.bind(null, IMAGE_API_ENDPOINT + IMAGE_REMOVE_API.title);