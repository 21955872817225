import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type EpisodeNameProps = {
    name: string
}

function EpisodeName(props: EpisodeNameProps) {
    const { name } = props;
    const LIMIT = 20;

    return (
        <div className='episode-name unselectable'>
            <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                <SVG
                    description='Добавить'
                    title='Добавить'
                    src={toAbsoluteUrl('/media/svg/icons/Text/H1.svg')}
                />
            </span>

            <span className='name-title' >
                { formatNameLength(name, LIMIT) }
            </span>
        </div>
    );
}

const formatNameLength = (name: string, limit: number) => {
    if(name.length > limit) {
        return name.slice(0, limit-3).concat('...')
    }

    return name
}


export default EpisodeName