import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import {isFragment} from 'react-is';
import { CardHeaderTitle } from './CartHeaderTitle';
import { CardHeaderToolbar } from 'src/metronic/components/Card/CartHeader/CartHeaderToolbar';
import { CardHeaderIcon } from './CartHeaderIcon';

type CardHeaderProps = {
  children?: React.ReactElement,
  icon?: React.ReactElement<typeof CardHeaderIcon.propTypes>,
  title: React.ReactElement<typeof CardHeaderTitle.propTypes>|string,
  toolbar?: React.ReactElement<typeof CardHeaderToolbar.propTypes>,
  className?: string,
  sticky?: boolean,
  labelRef?: any,
  props?: any[]
}

export const CardHeader = forwardRef<HTMLDivElement | null, CardHeaderProps>(
    (
      {
        children,
        icon,
        title,
        toolbar,
        className,
        sticky = false,
        labelRef,
        ...props
      },
      ref
    ) => {
      const [top, setTop] = useState(0);
      const [windowHeight, setWindowHeight] = useState(0);
  
      useEffect(() => {
        handleResize();
  
        function handleResize() {
          setWindowHeight(window.innerWidth);
        }
  
        window.addEventListener('resize', handleResize);
  
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      });
  
      useEffect(() => {
        // Skip if sticky is disabled or on initial render when we don't know about window height.
        if (!sticky || windowHeight === 0) {
          return;
        }
  
        const headerElement = document.querySelector<HTMLElement>('.header');
        const subheaderElement = document.querySelector<HTMLElement>('.subheader');
        const headerMobileElement = document.querySelector<HTMLElement>('.header-mobile');
  
        let nextMarginTop = 0;
  
        // mobile header
        if (window.getComputedStyle(headerElement!).height === '0px') {
          nextMarginTop = headerMobileElement!.offsetHeight;
        } else {
          // desktop header
          if (document.body.classList.contains('header-minimize-topbar')) {
            // hardcoded minimized header height
            nextMarginTop = 60;
          } else {
            // normal fixed header
            if (document.body.classList.contains('header-fixed')) {
              nextMarginTop += headerElement!.offsetHeight;
            }
  
            if (document.body.classList.contains('subheader-fixed')) {
              nextMarginTop += subheaderElement!.offsetHeight;
            }
          }
        }
  
        setTop(nextMarginTop);
      }, [sticky, windowHeight]);
  
      return (
        <div
          ref={ref}
          className='card-header'
          style={
            !sticky
              ? undefined
              : { top, position: 'sticky', backgroundColor: '#fff' }
          }
        >
          {title != null && (
            <div ref={labelRef} className={clsx('card-title', className)}>
              {icon}
  
              {/* Wrap string and fragments in CardHeaderTitle */
              typeof title === 'string' || isFragment(title) ? (
                <CardHeaderTitle> 
                  <>{title}</> 
                </CardHeaderTitle>
              ) : (
                title
              )}
            </div>
          )}
  
          {toolbar}
          {children}
        </div>
      );
    }
  );