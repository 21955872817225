import React from 'react'

function useEscapeCloseListener(hadler: () => void) {
    React.useEffect(() => {
        const escapeHandler = (e: KeyboardEvent) => {
            if( e.code === 'Escape') hadler();
        }

        document.addEventListener('keydown', escapeHandler);

        return () => document.removeEventListener('keydown', escapeHandler);
    }, [hadler]);
}

export default useEscapeCloseListener