import { MovieAndPage } from '../types'
import { gql } from '@apollo/client'

export interface SearchMovieByNameArgs {
    name: string,
    typeID: number,
    limit: number,
    page: number
}

export interface SearchMovieByNameData {
    SearchMovieByName: MovieAndPage
}

export const SEARCH_MOVIE_BY_NAME_QUERY = gql`
    query SearchMovieByNameQuery($name: String!, $limit: Int!, $page: Int!, $typeID: Int!) {
        SearchMovieByName(name: $name, typeID: $typeID, limit: $limit, page: $page) {
            Movie {
                id
                name
                release
                ratingIMDB
                status
                addedDate
                language {
                    id
                    name
                    abbreviated
                }
                thumbnail {
                    standard {
                        url
                        width
                        height
                    }
                }
            }
        }
    }
`