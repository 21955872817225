import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { QUALITY_TYPES, QUALITY_TYPES_LABEL, TRANSCODE_STATUS } from 'src/api/graphql/types'

type FilmProcessStatusProps = {
    statusData: TRANSCODE_STATUS|undefined,
    qualityID: QUALITY_TYPES
}

function FilmProcessStatus(props: FilmProcessStatusProps) {
    const { statusData, qualityID } = props;

    if(statusData !== undefined) {
        return (
            <div className='film-status unselectable mb-2'>
                <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/Files/File-cloud.svg')}
                    />
                </span>

                <span className='mr-1'>
                    { getQualityText(qualityID) }: 
                </span>
                <span>
                    { getStatusText(statusData) }
                </span>
            </div>
        );
    }

    return null;
}

const getStatusText = (status: TRANSCODE_STATUS): string => {

    switch (status) {
        case TRANSCODE_STATUS.NON: return 'Не обработке';
        case TRANSCODE_STATUS.WAITING: return 'В ожидании';
        case TRANSCODE_STATUS.PENDING: return 'Обрабатывается';
        case TRANSCODE_STATUS.TRANSCODE_ERROR: return 'Ошибка при обработке';
        case TRANSCODE_STATUS.FILE_STORAGE_ERROR: return 'Ошибка на хранилище';
        case TRANSCODE_STATUS.FULLFILLED: return 'Обработан';
        default: throw new Error('Unknown status in FilmProcessStatus');
    }
}

const getQualityText = (qualityID: QUALITY_TYPES): string => {
    switch (qualityID) {
        case QUALITY_TYPES.QUALITY_480: return QUALITY_TYPES_LABEL.QUALITY_480;
        case QUALITY_TYPES.QUALITY_1080: return QUALITY_TYPES_LABEL.QUALITY_1080;
        default: throw new Error('Unknown qualityID in FilmProcessStatus');
    }
}

export default FilmProcessStatus