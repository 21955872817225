import { gql } from '@apollo/client'
import { Tag, TagInput } from '../types'

export interface UpdateTagMutationArgs {
    tagID: number,
    input: TagInput
} 
export interface UpdateTagMutationData {
    UpdateTag: Tag
}

export const UPDATE_TAG_MUTATION = gql`
    mutation UpdateTagMutation( $tagID: Int!, $input: TagInput! ) {
        UpdateTag(tagID: $tagID, input: $input) {
            id
            name
        }
    }
`