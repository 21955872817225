import ListUIProvider, { AbonentFilter, QueryParams } from 'src/app/contexts/ListUIContext'
import AbonentsCard from './components/AbonentsCard'

export interface AbonentsParams<AbonentParamType extends AbonentFilter = AbonentFilter> extends QueryParams<AbonentParamType> {
    actionType: HOST_ACTIONS
}

export enum HOST_ACTIONS {
    GET = 'get',
    FILTER = 'filter',
    SEARCH_BY_IP = 'searchIp',
    SEARCH_BY_PHONE = 'searchPhone'
}

const initialAbonentFilter: AbonentsParams = {
    filter: {
        searchText: '',
        searchPhone: '', 
        regionID: 0
    },
    sortField: 'ip',
    sortOrder: 'asc',
    pageNumber: 1,
    pageSize: 25,
    actionType: HOST_ACTIONS.GET
};

function AbonentList() {
    return (
        <ListUIProvider<AbonentsParams> initialFilter={initialAbonentFilter} >
            <AbonentsCard/>
        </ListUIProvider>
    );
}

export default AbonentList