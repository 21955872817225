import { TranscodeRequestFile } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { useGetFullfilledFiles } from '../hooks/useGetFullfilledFiles'
import { useGetPendingFiles } from '../hooks/useGetPendingFiles'
import { useGetRejectedFiles } from '../hooks/useGetRejectedFiles'
import { useGetWaitingFiles } from '../hooks/useGetWaitingFiles'
import { ProcessingFilesResult } from '../types'
import { createContext } from 'use-context-selector'

export interface ProcessingContextValue {
    typeID: CONTENT_TYPES,
    fullfilledFilesResult: ProcessingFilesResult,
    pendingFilesResult: ProcessingFilesResult,
    waitingFilesResult: ProcessingFilesResult,
    rejectedFilesResult: ProcessingFilesResult,
}

type ProccessingContextProviderProps = {
    typeID: CONTENT_TYPES
} & ReactMultipleContainer

const placeholder: ProcessingFilesResult = {
    loading: true, 
    data: [] as TranscodeRequestFile[], 
    error: undefined
};

const initialValue = {
    typeID: CONTENT_TYPES.FILM,
    readyToSendFilesResult: placeholder,
    fullfilledFilesResult:  placeholder,
    pendingFilesResult:     placeholder,
    waitingFilesResult:     placeholder,
    rejectedFilesResult:    placeholder,
}

const ProccessingContext = createContext<ProcessingContextValue>(initialValue);

function ProccessingContextProvider(props: ProccessingContextProviderProps) {
    const { children, typeID } = props;
    
    const fullfilledFilesResult = useGetFullfilledFiles(typeID);
    const pendingFilesResult = useGetPendingFiles(typeID);
    const waitingFilesResult = useGetWaitingFiles(typeID);
    const rejectedFilesResult = useGetRejectedFiles(typeID);

    const value = {
        typeID,
        fullfilledFilesResult,
        pendingFilesResult,
        waitingFilesResult,
        rejectedFilesResult,
    }
    
    return (
        <ProccessingContext.Provider value={value}>
            { children }
        </ProccessingContext.Provider>
    );
}

if(process.env.NODE_ENV !== 'production') {
    ProccessingContext.displayName = 'ProcessingContextProvider';
}

export default ProccessingContext
export { ProccessingContextProvider }


