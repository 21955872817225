import React from 'react';
import { ImageUploaderCallback, UploadedImageData, UploadImageProgressEvent } from 'src/api/rest/types'
import { ContentEditContextValue } from '../../../../contexts/ContentEditContext'

type BindedImageUploaderCallback = (
    file: File, 
    progressHandler: (event: UploadImageProgressEvent) => void
) => Promise<UploadedImageData|null>

export function useBindedUploader(
    editContext: ContentEditContextValue, 
    imageUploader: ImageUploaderCallback
): BindedImageUploaderCallback {
    return React.useCallback<BindedImageUploaderCallback>(
        (file, progressHandler) => imageUploader(editContext.id, file, progressHandler), 
        [editContext, imageUploader]
    );
}