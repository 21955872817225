import React from 'react'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import { MediaCardClasses, useAdditionalMediaCardStyles, useMediaCardStyles } from './MediaCardHelpers'
import './styles.css'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

type MediaCardProps = {
    title: string,
    description: string,
    placeholder: string,
    error?: string,
    src?: string,
    overridedClasses?: MediaCardClasses, 
    children?: React.ReactChild,
    extra?: React.ReactChild
}

/**
 * 
 * @param props {
 *  title: Title of the Card,
 *  description: Description of the Card,
 *  placeholder: Placeholder if src is not defined or not ready,
 *  error: Error message that be shown when error occured,
 *  src: Source of the image to display,
 *  overridedClasses: Overriding of the default css classes used in component,
 *  children: Plain React child, often used for Dropzone elements
 *  extra: Additional content for rendering in component
 * }
 * @returns Plain React element that is media card item
 */
function MediaCard(props: MediaCardProps): React.ReactElement {
    const {setNotification} = useNotification();
    const { title, description, placeholder, error, src, overridedClasses, children, extra } = props;

    // Component default styles
    const legacyClasses = useMediaCardStyles();
    // Extra styles for overriding existing
    const additionalClasses = useAdditionalMediaCardStyles();
    // Summary style entity for styleing in component
    const classes = React.useMemo(() => {
        if(overridedClasses === undefined) return legacyClasses;
    
        const result = {} as MediaCardClasses;
        Object.keys(legacyClasses).forEach( key => {
            result[key] = `${legacyClasses[key]} ${overridedClasses[key]}`
        });

        return result;
    }, [legacyClasses, overridedClasses]);

    React.useEffect(() => {
        if(error !== undefined) {
            setNotification( { 
                message: error,
                variant: NotificationVariants.error
            })
        }
    }, [error, setNotification]);
    
    return (
        <Card className={`${classes.card} ${error ? additionalClasses.redShaded : ''}`}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    title={title}
                >
                    <div className='card-media-wrapper'>
                        <div style={{ backgroundImage: `url(${src ?? placeholder})` }}  className='card-media'/>
                        { children }
                    </div>
                </CardMedia>
            
                <CardContent className={classes.content}>
                    <Typography gutterBottom variant='h5' component='h2' className={classes.title}>
                        {title}
                    </Typography>

                    <Typography variant='body2' color='textSecondary' component='p' className={classes.description}>
                    { description }
                    </Typography>

                    <Typography gutterBottom variant='h5' component='h2' className={additionalClasses.error}>
                        {error}
                    </Typography>
                </CardContent>
            </CardActionArea>

            {   
                // Extra content for rendering such as progress bar and etc
                extra 
            
            }
            
        </Card>
    );
}

export default MediaCard