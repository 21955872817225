import { useQuery } from '@apollo/client'
import { GetAllSeasonsArgs, GetAllSeasonsData, GET_ALL_SEASONS_QUERY } from 'src/api/graphql/queries/GetAllSeasons'
import { Season } from 'src/api/graphql/types'

export function useGetAllSeasonsQuery(serialId: number, completeHandler?: (data: Season[]) => void) {
    const { loading, data, error, refetch } = useQuery<GetAllSeasonsData, GetAllSeasonsArgs>(GET_ALL_SEASONS_QUERY, {
        variables: {
            parentID: serialId
        },
        onCompleted: data => {
            completeHandler && completeHandler(data.GetAllSeasons)
        }
    });

    return { 
        loading, 
        data: data ? data.GetAllSeasons : [], 
        error, 
        refetch };
}