import React from 'react'
import { ApolloError, ApolloQueryResult, LazyQueryResult, useLazyQuery } from '@apollo/client'
import { GetEpisodesListArgs, GetEpisodesListData, GET_EPISODES_LIST_QUERY } from 'src/api/graphql/queries/GetEpisodesList'
import { GetMoviesListArgs, GetMoviesListData, GET_MOVIES_LIST_QUERY } from 'src/api/graphql/queries/GetMoviesList'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { QUALITY_TYPES, TranscodeRequestFile } from 'src/api/graphql/types'
import { PROCESS_POLL_INTERVAL } from '../helpers/pollingIntervals'

interface ReadyToSendFilesResult {
    data: TranscodeRequestFile[],
    loading: boolean,
    error: ApolloError| undefined,
    refetch: (
        (variables?: Partial<GetMoviesListArgs|GetEpisodesListArgs> | undefined) => Promise<ApolloQueryResult<GetMoviesListData|GetEpisodesListData>>
    ) | undefined
}

export function useGetReadyToSendFilesByQuality(typeID: CONTENT_TYPES, qualityID: QUALITY_TYPES) {
    const [ getFilmList, getFilmListResult ] = useLazyQuery<GetMoviesListData, GetMoviesListArgs>(GET_MOVIES_LIST_QUERY, {
        pollInterval: PROCESS_POLL_INTERVAL.READY_TO_SEND,
        variables: {qualityID},
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });
    const [ getEpisodeList, getEpisodesListResult ] = useLazyQuery<GetEpisodesListData, GetEpisodesListArgs>(GET_EPISODES_LIST_QUERY, {
        pollInterval: PROCESS_POLL_INTERVAL.READY_TO_SEND,
        variables: {qualityID},
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    React.useEffect(() => {
        switch (typeID) {
            case CONTENT_TYPES.FILM:
                getFilmList();
                break;
            case CONTENT_TYPES.EPISODE: 
                getEpisodeList();
                break;
            default:
                console.error(`Unknown typeID: ${typeID} in useGetReadyToSendFiles hook`);
                break;
        }
    }, [typeID, getEpisodeList, getFilmList]);

    const readyToSendFilesResult = React.useMemo(() => {
        switch (typeID) {
            case CONTENT_TYPES.FILM:
                return createMovieListResult(getFilmListResult);
            case CONTENT_TYPES.EPISODE: 
                return createEpisodeListResult(getEpisodesListResult);
            default:
                console.error(`Unknown typeID: ${typeID} in useGetReadyToSendFiles hook`);
                return createErrorResult(typeID);
        }
    }, [typeID, getFilmListResult, getEpisodesListResult]);

    return readyToSendFilesResult;
}

function createMovieListResult(response: LazyQueryResult<GetMoviesListData, GetMoviesListArgs>): ReadyToSendFilesResult {
    return {
        loading: response.loading,
        data: response.data ? response.data.GetMoviesList : [],
        error: response.error,
        refetch: response.refetch
    }
}

function createEpisodeListResult(response: LazyQueryResult<GetEpisodesListData, GetEpisodesListArgs>): ReadyToSendFilesResult {
    return {
        loading: response.loading,
        data: response.data ? response.data.GetEpisodesList : [],
        error: response.error,
        refetch: response.refetch
    }
}

function createErrorResult(typeID: CONTENT_TYPES) {
    return {
        loading: false,
        data: [],
        error: new ApolloError({errorMessage: `Unknown typeID: ${typeID} in useGetReadyToSendFiles hook` })
    }
}