import React from 'react'
import {  Participant, Selector } from 'src/api/graphql/types'
import { useMutation } from '@apollo/client'
import { 
    UpdateParticipantMutationArgs, 
    UpdateParticipantMutationData, 
    UPDATE_PARTICIPANT_MUTATION 
} from 'src/api/graphql/mutations/UpdateParticipantMutation'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'
import Select, { ActionMeta, StylesConfig, ValueType } from 'react-select'
import { useAddRolesToParticipant } from '../hooks/useAddRolesToParticipant'
import ParticipantImageColumnRenderer from './ParticipantImageColumnRenderer'
import { GET_ALL_PARTICIPANTS_QUERY } from 'src/api/graphql/queries/getAllParticipants'
import { SEARCH_PARTICIPANTS_BY_NAME_QUERY } from 'src/api/graphql/queries/SearchParticipantByName'

  interface ParticipantRoleOptions {
    value: number
    label: string
    isFixed: boolean
  }

  export function OrderColumnFormatter(
    cellContent: any,
    row: Selector, 
    rowIndex: number,
    getOrder: (index: number) => number
  ) {
    return (
      <span className='unselectable'>
        { getOrder(rowIndex + 1) }
      </span>
    );
  }
  
  export function IdColumnFormatter(cellContent: number, row: Selector, rowIndex: number) {
    return (
      <span className='unselectable label label-lg label-light label-inline'>
        { cellContent }
      </span>
    );
  }

  export function ParticipantImageColumnFormatter(
    cellContent: Participant['image'], row: Participant, rowIndex: number, extraData: any
  ) {
    return (
      <ParticipantImageColumnRenderer jsonString={cellContent} id={row.id}/>
    );
  }

  export function ParticipantRolesColumnFormatter(
    cellContent: Participant['roleID'], 
    row: Participant, 
    rowIndex: number,
    participants: Participant[]
  ) {
    return (
      <EditableRolesColumnFormatter participants={participants} id={row.id} currentRole={cellContent}/>
    );
  }
  
  export function EditableRolesColumnFormatter(props: {participants: Participant[], id: number, currentRole: PARTICIPANT_TYPES}) {
    const { id: participantID, participants } = props;

    const roles = React.useMemo(() => {
      return participants.filter(participant => {
        return participant.id === participantID
      }).map( i => i.roleID );
    }, [participants, participantID]);

    const participantOptions = React.useMemo(() => 
      [
        {value: PARTICIPANT_TYPES.ACTOR, label: 'Актер', isFixed: false},
        {value: PARTICIPANT_TYPES.DIRECTOR, label: 'Режисер', isFixed: false},
        {value: PARTICIPANT_TYPES.PRODUCER, label: 'Продюсер', isFixed: false}
      ].map( i => {
        if(roles.includes(i.value)) {
          return {...i, isFixed: true}
        }
        return i;
      }), 
      [roles]
    );
    const initialValue = React.useMemo(() => participantOptions
      .filter( o => roles.includes(o.value) )
    , [participantOptions, roles]);
  
    const [value, setValue] = React.useState(initialValue);
    const [isEditing, setIsEditing] = React.useState(false);
  
    const inputRef = React.useRef<Select<ParticipantRoleOptions, true>>(null);
  
    const {setNotification} = useNotification();
    const addRoles = useAddRolesToParticipant();
  
    const editClickHandler = React.useCallback(() => {
      setIsEditing(true);
    }, []);
  
    const keyDownHandler: React.KeyboardEventHandler = React.useCallback(e => {
      e.stopPropagation();
      const filteredOptions = value.filter( v => !initialValue.some( init => init.value === v.value) );
      
      if(e.key === 'Enter' && filteredOptions.length !== 0) {
        addRoles(
          filteredOptions.map( v => v.value),
          participantID
        ).then(() => {
          setNotification({
            message: 'Роли для участника добавены успешно', 
            variant: NotificationVariants.success
          });
        }).catch(() => {
          setNotification({
            message: 'Ошибка при попытке добавить ролей участнику', 
            variant: NotificationVariants.error
          });
        });
      }
  
      if(e.key === 'Enter' && filteredOptions.length === 0) {
        setIsEditing(false);
        setValue(initialValue);
        setNotification({
          message: 'Выберите роли для добавления', 
          variant: NotificationVariants.warning
        });
      }
  
      if(e.key === 'Escape'){
        setIsEditing(false);
        setValue(initialValue);
      }
    }, [addRoles, setIsEditing, setValue, setNotification, initialValue, participantID, value]);
  
    const blurHandler: React.FocusEventHandler = React.useCallback( e => {
      e.persist();
      setIsEditing(false);
      setValue(initialValue);
    }, [setIsEditing, setValue, initialValue]);
  
    const changeHandler = React.useCallback( 
      (
        value: ValueType<ParticipantRoleOptions, true>, 
        actionMeta: ActionMeta<ParticipantRoleOptions>
      ) => {
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (actionMeta.removedValue.isFixed) {
              return;
            }
            break;
          case 'clear':
            value = participantOptions.filter(v => v.isFixed);
            break;
        }
        setValue([...value]);
      }, 
      [participantOptions]
    );
  
    React.useEffect(() => {
      if(isEditing) {
        const input = inputRef.current;
        if(input !== null) {
          input.focus();
        }
      }
    }, [isEditing]);
  
    if(isEditing) {
      return (
        <>
          <div style={{fontSize: 10}}>
            {/* нажмите  */}
            <span> Enter </span> 
            для добавления
          </div>
          <Select<ParticipantRoleOptions, true>
            value={value}
            ref={inputRef}
            name='participants'
            options={participantOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onBlur={blurHandler}
            onKeyDown={keyDownHandler}
            onChange={changeHandler}
            styles={styles}
            isMulti
            closeMenuOnSelect
            isSearchable={false}
            isClearable={false}
          />
          <div style={{fontSize: 10}}>
            {/* нажмите  */}
            <span> Esc </span> 
            для отмены
          </div>
        </>
      );
    }
    
    return (
      <div onClick={editClickHandler} style={{cursor: 'pointer'}}>
        { getParticipantByRoleId(props.currentRole) }
      </div>
    );
  }

  const getParticipantByRoleId = (roleID: PARTICIPANT_TYPES): string => {
    switch (roleID) {
      case PARTICIPANT_TYPES.ACTOR: return 'Актер';
      case PARTICIPANT_TYPES.DIRECTOR: return 'Режисер';
      case PARTICIPANT_TYPES.PRODUCER: return 'Продюсер';
      default: throw new Error('Passed not valid participant');
    }
  }

  const styles: StylesConfig<ParticipantRoleOptions, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  export function EditableNameColumnFormatter(
    cellContent: string, 
    row: Selector, 
    rowIndex: number
  ) {
    return <EditableNameColumnRenderer value={cellContent} id={row.id}/>
  }
  
  function EditableNameColumnRenderer(props: {value: string, id: number}) {
    const initialValue = props.value;
    const participantID = props.id;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [value, setValue] = React.useState(initialValue);
    const [isEditing, setIsEditing] = React.useState(false);
    const {setNotification} = useNotification();
    const [updateParticipant] = useMutation<UpdateParticipantMutationData, UpdateParticipantMutationArgs>(UPDATE_PARTICIPANT_MUTATION, {
      refetchQueries: [GET_ALL_PARTICIPANTS_QUERY, SEARCH_PARTICIPANTS_BY_NAME_QUERY]
    })
    const editClickHandler = React.useCallback(() => {
      setIsEditing(true);
    }, [setIsEditing]);
  
    React.useEffect(() => {
      if(isEditing) {
        const input = inputRef.current;
        if(input !== null) {
          input.focus();
        }
      }
    }, [isEditing]);
  
    const keyDownHandler: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback((e) => {
      e.stopPropagation();
      const value = e.currentTarget.value;
      
      if(e.key === 'Enter' && value !== '') {
        updateParticipant({
          variables: {
            participantID,
            input: {
              name: value
            }
          }
        }).then(() => {
          setIsEditing(false);
          setValue(value);
          setNotification({
            message: ' Участник успешно переименован',
            variant: NotificationVariants.success
          })
        }).catch((e) => {
          setNotification({
            message: 'Ошибка при попытке переименования' + e.message,
            variant: NotificationVariants.error
          })
        });
      }
  
      if(e.key === 'Escape'){
        setIsEditing(false);
        setValue(initialValue);
      }
    }, [updateParticipant, setIsEditing, setValue, setNotification, initialValue, participantID]);
  
    const blurHandler: React.FocusEventHandler<HTMLInputElement> = React.useCallback(e => {
      e.persist();
      setIsEditing(false);
      setValue(initialValue);
    }, [setIsEditing, setValue, initialValue]);
  
    if(isEditing) {
      return (
        <input 
          ref={inputRef}
          type='text' 
          className='form-control'
          value={value} 
          onChange={e => setValue(e.target.value)}
          onKeyDown={keyDownHandler}
          onBlur={blurHandler}
        />
      )
    }
    
    return (
      <div onClick={editClickHandler} style={{cursor: 'pointer'}}>
        { value }
      </div>
    );
  }