import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { GetAllGenresArgs, GetAllGenresData, GET_ALL_GENRES_QUERY } from 'src/api/graphql/queries/getAllGenres'
import { Genre } from 'src/api/graphql/types'

type useGetAllGenresQueryArgs = {
    completeHandler?: (data: Genre[]) => void,
    errorHandler?: (error: ApolloError) => void
}

type useGetAllGenresResult = {
    loading: boolean,
    data: Genre[] | undefined, 
    error: ApolloError | undefined,
    refetch: (variables?: Partial<GetAllGenresArgs> | undefined) => Promise<ApolloQueryResult<GetAllGenresData>>
}

function useGetAllGenresQuery(args: useGetAllGenresQueryArgs = {}): useGetAllGenresResult {
    const { completeHandler, errorHandler } = args;

    const { loading, data, error, refetch } = useQuery<GetAllGenresData, GetAllGenresArgs>(GET_ALL_GENRES_QUERY, {
        variables: {},
        onCompleted: (data: GetAllGenresData) => {
            completeHandler && completeHandler(data.getAllGenres);
        },
        onError: (error: ApolloError) => {
            errorHandler && errorHandler(error)
        }

    });
    return {
        loading, 
        data: data ? data.getAllGenres: undefined,
        error, 
        refetch
    }
}

export default useGetAllGenresQuery