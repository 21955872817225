import React from 'react'
import { Button } from 'react-bootstrap'

interface EnteredToolbarProps {
    addHandler: React.MouseEventHandler
    clearHandler: React.MouseEventHandler
    isDisabled: boolean
}

function EnteredToolbar(props: EnteredToolbarProps) {
    const { isDisabled, clearHandler, addHandler } = props;

    return <div className='mt-5 d-flex justify-content-between'>
        <Button 
            variant='secondary'
            onClick={clearHandler}
            disabled={isDisabled}
        >
            Очистить
        </Button>

        <Button
            onClick={addHandler}
            disabled={isDisabled}
        >
            Добавить
        </Button>
    </div>
}

export default EnteredToolbar