import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { AddedData, FileItem, InternalID, PendingFile } from 'src/uploader/types'

const useItemProgressEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<InternalID, AddedData>>, 
    pending: Map<InternalID, PendingFile>,
    setPending:  React.Dispatch<React.SetStateAction<Map<InternalID, PendingFile>>>
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.ITEM_PROGRESS, (item: FileItem) => {
            const filePayload = addedFiles.current.get(item.id);
            
            if(filePayload) {
                //Fixed behaviour of existing item with progress==100 in pending list 
                if(item.completed === 100) {
                    pending.delete(filePayload.externalID);
                    setPending(new Map(pending));
                } else {
                    pending.set(filePayload.externalID, {
                        progress: item.completed,
                        itemId: item.id,
                        title: filePayload.title
                    });
                    setPending(new Map(pending));
                }
            }
        });

        return () => {
            uploader.off(UPLOADER_EVENTS.ITEM_PROGRESS);
        }
    }, [uploader, addedFiles, pending, setPending]);
}

export default useItemProgressEffect