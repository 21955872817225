import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type EpisodeActiveStatusProps = {
    status: ACTIVATION_STATUS
}

function EpisodeActiveStatus(props: EpisodeActiveStatusProps) {
    const { status } = props;

    if(status === ACTIVATION_STATUS.ACTIVE) {
        return (
            <div className='season-status unselectable'>
                <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>

                <span>
                    Публич.
                </span>
            </div>
        )
    }

    if(status === ACTIVATION_STATUS.INACTIVE) {
        return (
            
            <div className='episode-status unselectable'>
                <span className='svg-icon svg-icon-md svg-icon-danger mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>

                <span>
                    Приват.
                </span>
            </div>
        );
    }

    return null;
}


export default EpisodeActiveStatus