import { gql } from '@apollo/client'
import { Genre } from '../types'

export interface GetAllGenresArgs {} 

export interface GetAllGenresData {
    getAllGenres: Genre[]
}

export const GET_ALL_GENRES_QUERY = gql`
    query GetAllGenres {
        getAllGenres {
            id
            name
        }
    }
`