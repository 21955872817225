import { getToken } from 'src/util/token'
import { FluidPlayerOptions } from '../types'

export const PLAYER_CONFIG: FluidPlayerOptions = {
    layoutControls: {
        doubleclickFullscreen: true,
        controlBar: {
            autoHideTimeout: 3,
            animated: false,
            autoHide: true,
        },
        htmlOnPauseBlock: {
            html: null,
            height: null,
            width: null
        },
        autoPlay: false,
        mute: false,
        allowTheatre: false,
        playPauseAnimation: false,
        playbackRateEnabled: false,
        allowDownload: false,
        playButtonShowing: true,
        fillToContainer: true,
        primaryColor: '#0085ea',
        posterImage: '',
    },
    vastOptions: {
        adList: [],
        adCTAText: false,
        adCTATextPosition: ''
    },
    // Send Auth-token in header when open xhr connection
    modules: {
        onBeforeInitHls: (hls: Record<string, Record<string, Function>>) => {
            hls.config.xhrSetup = function (xhr: Record<string, any>, url: string) {
                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                    // Append headers after opening
                    xhr.setRequestHeader('Authorization', getToken());
                }
            }
        },
        onAfterInitHls: (hls: Record<string, Record<string, Function>>) => {
            hls.config.xhrSetup = function (xhr: Record<string, any>, url: string) {
                xhr.withCredentials = false; // do send cookies
                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                    // Append headers after opening
                    xhr.setRequestHeader('Authorization', getToken());
                }
            }
        },
    }
}