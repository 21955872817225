const loader = document.getElementById('splash-screen');
const HIDE_CLASS = ['hidden', 'display-none']

function showLoader() {
    loader && loader.classList.remove(...HIDE_CLASS);
}

function hideLoader() {
    loader && loader.classList.add(...HIDE_CLASS);
}

export { showLoader, hideLoader } 