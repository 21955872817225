import { useMutation } from '@apollo/client'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import {
    UpdateBannerToHomePageMutationArgs,
    UpdateBannerToHomePageMutationData,
    UPDATE_BANNER_TO_HOME_PAGE_MUTATION
} from 'src/api/graphql/mutations/UpdateBannerToHomePage'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { TextField } from '@material-ui/core'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

type UpdateFormValue = {
    movieID: string;
}

export function UpdatePosterModal() {
    const history = useHistory();
    const { setNotification } = useNotification();

    const [updateBannerMutation, { loading }] = useMutation<
        UpdateBannerToHomePageMutationData,
        UpdateBannerToHomePageMutationArgs
    >(UPDATE_BANNER_TO_HOME_PAGE_MUTATION);

    const modalCloseHandler = () => {
        history.push(
            ROUTES_LIST.MAIN_PAGE
        );
    };

    const modalSubmitHandler = (
        values: UpdateFormValue,
        formikHelpers: FormikHelpers<UpdateFormValue>
    ) => {
        updateBannerMutation({
            variables: {
                movieID: Number(values.movieID)
            }
        })
            .then(({ data }) => {
                const { message, status } = data!.UpdateBannerToHomePage;

                if (status === 'ok') {
                    setNotification({
                        message: 'Успешно изменен',
                        variant: NotificationVariants.success
                    });
                } else {
                    setNotification({
                        message: `Ошибка при изменении: ${message}`,
                        variant: NotificationVariants.error
                    });
                }
            })
            .catch(error => {
                setNotification({
                    message: `Ошибка при изменении: ${error.message}`,
                    variant: NotificationVariants.error
                });
            })
            .finally(modalCloseHandler);
    }


    return (
        <Modal
            onHide={modalCloseHandler}
            size='sm'
            show
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Введите ID фильма
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='overlay overlay-block cursor-default'>
                <Formik<UpdateFormValue>
                    initialValues={{ movieID: '' }}
                    onSubmit={modalSubmitHandler}
                    validationSchema={
                        Yup.object().shape({
                            movieID: Yup.number()
                                .min(1, 'Должно быть больше нулья')
                                .required('Должно быть заполнено')
                        })
                    }
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} id='poster-update-form'>
                            <TextField
                                type='number'
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.movieID}
                                name='movieID'
                                variant='outlined'
                                fullWidth
                                error={props.touched.movieID && Boolean(props.errors.movieID)}
                                helperText={props.touched.movieID && props.errors.movieID}
                            />
                        </form>
                    )}
                </Formik>
            </Modal.Body>

            <Modal.Footer className='d-flex justify-content-between'>
                <Button variant='secondary' onClick={modalCloseHandler}>
                    Закрыть
                </Button>
                <Button variant='primary' type='submit' form='poster-update-form'>
                    <span>
                        Изменить
                        {
                            loading
                            && <span className='mr-3 ml-1 spinner spinner-white' />
                        }
                    </span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}