import React from 'react'
import { useLocation } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { defaultComplaintsQueryParams } from '../utils/defaultParams'

export function useComplaintsQueryParams(): ComplaintsQueryParams {
    const { search } = useLocation();
    const { navigationLinks: {complaintsLink} } = useNavigationLinksContext();

    const queryParams = React.useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const savedSearchParams = new URLSearchParams(complaintsLink);

        return {
            pageNumber: getCorrectPageNumber(
                searchParams.get('pageNumber'), 
                savedSearchParams.get('pageNumber')
            ),
            pageSize: getCorrectPageSize( 
                searchParams.get('pageSize'), 
                savedSearchParams.get('pageSize')
            ),
            sortField: getCorrectSortField(
                searchParams.get('sortField'), 
                savedSearchParams.get('sortField')
            ), 
            sortOrder: getCorrectSortOrder(
                searchParams.get('sortOrder'), 
                savedSearchParams.get('sortOrder')
            )
        }

    }, [search, complaintsLink]);

    return queryParams; 
}



const getCorrectPageSize = (
    pageSize: string|null,
    savedPageSize: string|null
): MoviesQueryParams['pageSize'] => {
    if(pageSize !== null) {
        return Number(pageSize);
    }
    if(savedPageSize !== null) return Number(savedPageSize);

    return defaultComplaintsQueryParams.pageSize;
}

const getCorrectPageNumber = (
    pageNumber: string|null,
    savedPageNumber: string|null    
): MoviesQueryParams['pageNumber'] => {
    if(pageNumber !== null) {
        return Number(pageNumber);
    }
    if(savedPageNumber !== null) return Number(savedPageNumber);

    return defaultComplaintsQueryParams.pageNumber;
}

const getCorrectSortField = (
    sortField: string|null, 
    savedSortField: MoviesQueryParams['sortField']|null
): MoviesQueryParams['sortField'] => {
    if(sortField !== null) {
        return sortField
    }

    if(savedSortField !== null) return savedSortField;

    return defaultComplaintsQueryParams.sortField;
}

const getCorrectSortOrder = (
    order: unknown, 
    savedOrder: unknown|null
): MoviesQueryParams['sortOrder'] => {
    if(order === 'asc' || order === 'desc') {
        return order
    };

    if(savedOrder === 'asc' || savedOrder === 'desc') return savedOrder;

    return defaultComplaintsQueryParams.sortOrder;
}