import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import SVG from "react-inlinesvg";
import objectPath from 'object-path';
import { toAbsoluteUrl } from 'src/util/url';
import { toggleSidebar } from 'src/metronic/core/LayoutHelpers';
import { useHtmlClassService } from 'src/metronic/core/MetronicLayout';
import { useAuthGateway } from 'src/app/contexts/AuthenticationContext';
import { getTitleFromUserRole } from '../SidebarUIHelpers';

function SidebarBrand() {
  const { authResult:{ user } } = useAuthGateway();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
      return {
          brandClasses: uiService.getClasses("brand", true),
          asideSelfMinimizeToggle: objectPath.get(
              uiService.config,
              "aside.self.minimize.toggle"
          ),
          headerLogo: uiService.getLogo(),
          headerStickyLogo: uiService.getStickyLogo()
      };
  }, [uiService]);

    return (
        <>
          {/* begin::Brand */}
          <div
              className={`brand flex-column-auto ${layoutProps.brandClasses}`}
              id="kt_brand"
          >
            {/* begin::Logo */}
            <Link to="" className="brand-logo">
              <img alt="logo" src={layoutProps.headerLogo}/>
              <span className="brand-logo-text">
                { user && getTitleFromUserRole(user.roleId) }
              </span>
            </Link>
            {/* end::Logo */}
    
            {layoutProps.asideSelfMinimizeToggle && (
              <>
                {/* begin::Toggle */}
                <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" onClick={toggleSidebar}>
                  <span className="svg-icon svg-icon-xl">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                  </span>
                </button>
                {/* end::Toolbar */}
                </>
            )}
          </div>
          {/* end::Brand */}
          </>
      );
}

export default SidebarBrand