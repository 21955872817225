import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { AddedData, ExternalID, FileItem, InternalID, PendingFile, WaitingFile } from 'src/uploader/types'

const useItemStartEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<InternalID, AddedData>>, 
    currentFile: React.MutableRefObject<AddedData|undefined>,
    waiting: Map<ExternalID, WaitingFile>,
    setWaiting: React.Dispatch<React.SetStateAction<Map<ExternalID, WaitingFile>>>,
    pending: Map<ExternalID, PendingFile>,
    setPending:  React.Dispatch<React.SetStateAction<Map<ExternalID, PendingFile>>>
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.ITEM_START, (item: FileItem) => {
            const filePayload = addedFiles.current.get(item.id);
            currentFile.current = filePayload;
            
            if(filePayload && currentFile.current) {
                pending.set(filePayload.externalID, {
                    progress: item.completed,
                    itemId: item.id,
                    title: filePayload.title
                });
                waiting.delete(filePayload.externalID);

                setPending(new Map(pending));
                setWaiting(new Map(waiting));
            }
        });

        return () => {
            uploader.off(UPLOADER_EVENTS.ITEM_START);
        }
    }, [uploader, addedFiles, currentFile, waiting, setWaiting, pending, setPending]);
}

export default useItemStartEffect