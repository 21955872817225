import React from 'react'
import createUploader from '@rpldy/uploader'
import { getXhrSend } from '@rpldy/sender'
import { DEFAULT_SIMULTANIOUS_COUNT } from 'src/uploader/assets/defaultValues'
import { 
    AddedData, 
    ErrorFile, 
    ExternalID, 
    FileStorageResponse, 
    FullfilledFile, 
    InternalID, 
    PendingFile, 
    ProxiedRequest, 
    UploaderContextProviderRawProps, 
    WaitingFile
} from 'src/uploader/types'
import useBatchAddEffect from 'src/uploader/hooks/useBatchAddEffect'
import useItemErrorEffect from 'src/uploader/hooks/useItemErrorEffect'
import useItemFinishEffect from 'src/uploader/hooks/useItemFinishEffect'
import useItemProgressEffect from 'src/uploader/hooks/useItemProgressEffect'
import useItemStartEffect from 'src/uploader/hooks/useItemStartEffect'
import { isFile } from 'src/uploader/helpers/isFile'
import { comment } from 'src/util/comment'
import { serializeFileName } from 'src/uploader/helpers/serializeFileName'
import { createExternalID } from 'src/uploader/helpers/createExternalID'
import { deSerializeFileName } from 'src/uploader/helpers/deSerializeFileName'
import { createMapLike } from '../helpers/createMapLike'
import useItemFinalizeEffect from './../hooks/useItemFinalizeEffect';

function UploaderContextProviderRaw(props: UploaderContextProviderRawProps) {
    const { UploaderContext, prefetchHandler, afterUploadHandler } = props;
    const { children, endpoint, simultaniousCount = DEFAULT_SIMULTANIOUS_COUNT } = props;

    const uploader = React.useMemo(() => createUploader({
        autoUpload: true,
        grouped: true,
        maxGroupSize: 2,
        destination: {
            url: endpoint,
            method: 'PUT',
            headers: {
                'Content-Type': 'video/mp4' 
            }
        },
        send: getXhrSend({
            getRequestData: (items, options) => {
                const [ item ] = items;

                if( !isFile(item.file) ) {
                    const fileId = currentFile.current ? currentFile.current.movieID : 0;
                    throw new TypeError(`Item with fileID: ${fileId} is not valid file`);
                }

                const deserializedFile = deSerializeFileName(item.file);

                return deserializedFile;
            },
            preRequestHandler: async( 
                doFileStorageRequest: (tempUrl: string) => Promise<XMLHttpRequest> 
            ) => {
                const currentItem = currentFile.current;
                const id = currentItem ? currentItem.movieID : 0;
                const name = currentItem ? currentItem.name : '';
                const uploadType = currentItem ? currentItem.uploadType : 0;
                
                try {
                    const prefetchResponse = await prefetchHandler(id, name, uploadType);
                    const tempUrl = prefetchResponse ? prefetchResponse.URL : '';
                    const filepath = prefetchResponse ? prefetchResponse.filepath : '';
                
                    const storageResponse = await doFileStorageRequest(tempUrl);

                    const proxiedStorageResponse: ProxiedRequest<FileStorageResponse> = new Proxy(storageResponse, {
                        get: function(target, prop) {
                            if(prop === 'response') {
                                return {
                                    filepath
                                };
                            }
                            return target[prop];
                        }
                    });

                    comment('Uploader send method proxied request', proxiedStorageResponse);

                    return proxiedStorageResponse;
                } catch (error) {
                    console.error('UploaderProviderRaw: ', error);
                    return new XMLHttpRequest();
                }
            }
        }),
        concurrent: true,
        maxConcurrent: simultaniousCount 
    }), [endpoint, simultaniousCount, prefetchHandler]);

    const addedFiles = React.useRef<Map<InternalID, AddedData>>(new Map());
    const currentFile = React.useRef<AddedData|undefined>(undefined);

    const [waiting, setWaiting]         = React.useState<Map<ExternalID, WaitingFile>>(new Map());
    const [pending, setPending]         = React.useState<Map<ExternalID, PendingFile>>(new Map());
    const [fullfilled, setFullfilled]   = React.useState<Map<ExternalID, FullfilledFile>>(new Map());
    const [rejected, setRejected]       = React.useState<Map<ExternalID, ErrorFile>>(new Map());

    /*
    * Uploader context event related effects list:
    *  BATCH_ADD = "BATCH-ADD",
    *  BATCH_START = "BATCH-START",
    *  BATCH_PROGRESS = "BATCH_PROGRESS",
    *  BATCH_FINISH = "BATCH-FINISH",
    *  BATCH_ABORT = "BATCH-ABORT",
    *  BATCH_CANCEL = "BATCH-CANCEL",
    *  ITEM_START = "FILE-START",
    *  ITEM_CANCEL = "FILE-CANCEL",
    *  ITEM_PROGRESS = "FILE-PROGRESS",
    *  ITEM_FINISH = "FILE-FINISH",
    *  ITEM_ABORT = "FILE-ABORT",
    *  ITEM_ERROR = "FILE-ERROR",
    *  ITEM_FINALIZE = "FILE-FINALIZE",
    *  REQUEST_PRE_SEND = "REQUEST_PRE_SEND",
    *  ALL_ABORT =  "ALL_ABORT",
    */
    useBatchAddEffect(
        uploader, 
        addedFiles, 
        waiting, 
        setWaiting, 
        pending, 
        setPending, 
        simultaniousCount
    );
    useItemStartEffect(
        uploader, 
        addedFiles, 
        currentFile, 
        waiting, 
        setWaiting, 
        pending, 
        setPending
    );
    useItemProgressEffect(
        uploader, 
        addedFiles, 
        pending, 
        setPending
    );
    useItemFinishEffect(
        uploader, 
        addedFiles, 
        pending, 
        setPending, 
        fullfilled, 
        setFullfilled, 
        rejected, 
        setRejected, 
        afterUploadHandler
    );
    useItemErrorEffect(
        uploader, 
        addedFiles, 
        pending, 
        setPending, 
        rejected, 
        setRejected
    );

    useItemFinalizeEffect(
        uploader,
        addedFiles
    )

    /*
    * Uploader context API callbacks
    */
    const addFileToQueue = React.useCallback((file: File, id: number, type: number, title: string) => {
        if(file) {
            // Inject ID to the name of file
            const serializedFile = serializeFileName(id, type, file, title);
            uploader.add(serializedFile);
        }
    }, [uploader]);

    const abortFile = React.useCallback((movieID: number|ExternalID, type: number) => {
        const externalID = typeof movieID === 'number' 
            ? createExternalID(movieID, type)
            : movieID as ExternalID;

        const waitingFile = waiting.get(externalID);
        const pendingItem = pending.get(externalID);

        if(pendingItem){
            uploader.abort(pendingItem.itemId);
            pending.delete(externalID);
            setPending(new Map(pending));
        }
        
        if(waitingFile) {
            uploader.abort(waitingFile.itemId);
            waiting.delete(externalID);
            setWaiting(new Map(waiting));
        } 

        addedFiles.current.delete(externalID);
    }, [uploader, pending, setPending, waiting, setWaiting]);

    const clearFullfilled = React.useCallback((movieID: number|ExternalID, type: number) => {
        if(typeof movieID === 'number') {
            const externalID = createExternalID(movieID, type);

            if(fullfilled.has(externalID)){
                fullfilled.delete(externalID);
                setFullfilled(new Map(fullfilled));
                addedFiles.current.delete(externalID);
            }
        }

        if(typeof movieID === 'string') {
            if(fullfilled.has(movieID)){
                fullfilled.delete(movieID);
                setFullfilled(new Map(fullfilled));
                addedFiles.current.delete(movieID);
            }
        }

    }, [fullfilled, setFullfilled]);

    const clearRejected = React.useCallback((movieID: number|ExternalID, type: number) => {
        if(typeof movieID === 'number') {
            const externalID = createExternalID(movieID, type);

            if(rejected.has(externalID)){
                rejected.delete(externalID);
                setRejected(new Map(rejected));
                addedFiles.current.delete(externalID);
            }
        }

        if(typeof movieID === 'string') {
            if(rejected.has(movieID)){
                rejected.delete(movieID);
                setRejected(new Map(rejected));
                addedFiles.current.delete(movieID);
            }
        }
    }, [rejected, setRejected]);

    const clearAllFullfilled = React.useCallback(() => {
        fullfilled.forEach((value, key) => {
            addedFiles.current.delete(key);
        })
        setFullfilled(new Map());
    }, [fullfilled, setFullfilled]);

    const clearAllRejected = React.useCallback(() => {
        rejected.forEach((value, key) => {
            addedFiles.current.delete(key);
        })
        setRejected(new Map());
    }, [rejected, setRejected]);


    const value = {
        addFileToQueue,
        abortFile,
        clearFullfilled,
        clearRejected,
        clearAllFullfilled,
        clearAllRejected,
        waiting: createMapLike(waiting),
        pending: createMapLike(pending),
        fullfilled: createMapLike(fullfilled),
        rejected: createMapLike(rejected)
    };

    return (
        <UploaderContext.Provider value={value} >
            { children }
        </UploaderContext.Provider>
    );
}

export default UploaderContextProviderRaw