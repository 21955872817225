import React from 'react'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'
import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'

function withCard<Props = any>(
    Wrappable: React.ComponentType<Props>, 
    title: React.ReactElement<typeof CardHeaderTitle.propTypes>|string,
    className: string
): React.ComponentType<Props> {
    const CardWrappedComponent: React.ComponentType<Props> = props => {
        return (
            <Card className={className}>
                <CardHeader title={title} className={'w-100'}/>
                <CardBody>
                    <ErrorBoundary> 
                        <Wrappable {...props}/>
                    </ErrorBoundary>
                </CardBody>
            </Card>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        CardWrappedComponent.displayName = 'CardWrappedComponent';
    }

    return CardWrappedComponent;
}

export default withCard