import React from 'react'
import { useAvatarImageStyles, useHorizontalImageStyles, useLogoImageStyles, useVerticalImageStyles } from './helpers/UploadImageCardHelpers'
import withMediaCard from './hocs/withMediaCard'
import vertical_image from 'src/app/assets/placeholders/vertical.jpg'
import horizontal_image from 'src/app/assets/placeholders/horizontal.jpg'
import logo_image from 'src/app/assets/placeholders/logo.png'
import avatar from 'src/app/assets/placeholders/participant.png'

const VerticalImageCard = withMediaCard({
    title: 'Вертикальное', 
    description: 'Нажми на изображение или перетащи его', 
    placeholder: vertical_image
}, useVerticalImageStyles);

const HorizontalImageCard = withMediaCard({
    title: 'Горизонтальное', 
    description: 'Нажми на изображение или перетащи его', 
    placeholder: horizontal_image
}, useHorizontalImageStyles);

const HorizontalRawImageCard = withMediaCard({
    title: 'Горизонтальное чистое', 
    description: 'Нажми на изображение или перетащи его', 
    placeholder: horizontal_image
}, useHorizontalImageStyles);

const LogoImageCard = withMediaCard({
    title: 'Лого', 
    description: 'Нажми на изображение или перетащи его', 
    placeholder: logo_image
}, useLogoImageStyles);

const AvatarImageUploader = React.memo( 
    withMediaCard({
        title:'',
        description: '',
        placeholder: avatar,
    }, useAvatarImageStyles)
);

// eslint-disable-next-line
export default {
    VerticalImageCard,
    HorizontalImageCard,
    HorizontalRawImageCard,
    LogoImageCard,
    AvatarImageUploader
}