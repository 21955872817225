import { Selector } from "src/api/graphql/types";
import { CONTENT_TYPES, CONTENT_TYPES_TEXT } from "src/app/assets/ContentTypes"
import { SelectorEditField } from "../contexts/ContentEditContext";

export const getActiveType = (type: number): string => {
    const activeType = CONTENT_TYPES_TEXT.find( contentType => contentType.typeID === CONTENT_TYPES.FILM);
    return activeType ? activeType.name : ''
}


export function formatToSelectorEditField (selector: Selector[]|[]): SelectorEditField[] {
    return selector.map( 
        selector => ({ 
            value: selector.id, 
            label: selector.name
        }) 
    );
}