import { isEqual, isFunction } from 'lodash'
import React, { createContext, useCallback, useContext, useState } from 'react'

export interface UIFilter {
    searchText: string,
}

export interface FilmSerialFilter extends UIFilter {
    genreID: number,
    tagID: number,
}

export interface AbonentFilter extends UIFilter {
    regionID: number,
    searchPhone: string
}

export interface QueryParams<FilterType extends UIFilter = UIFilter > {
    filter: FilterType,
    sortOrder: string,
    sortField: string,
    pageNumber: number,
    pageSize: number,
}

export interface ListUIContextValue<T extends QueryParams> {
    loading: boolean,
    queryParams: T,
    setQueryParams?: (nextQueryParams: any) => void
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

type ListUIProviderProps<T> = {
    initialFilter: T,
    children: React.ReactElement
}

function ListUIProvider<T extends QueryParams>(props: ListUIProviderProps<T>) {
    const { initialFilter, children } = props; 
    const [queryParams, setQueryParamsBase] = useState<T>(initialFilter);
    const [loading, setLoading] = useState(false);

    const setQueryParams = useCallback( nextQueryParams => {
        setQueryParamsBase( prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }
    
            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
    
            return nextQueryParams;
        })
    }, []);

    const value = {
        loading,
        queryParams,
        setQueryParams,
        setLoading
    };

    return (
        <ListUIContext.Provider value={value}>
            { children }
        </ListUIContext.Provider>
    );
}

const initialValue = {
    loading: false,
    queryParams: { 
        filter: {searchText: ''},
        sortOrder: 'asc',
        sortField: 'id',
        pageNumber: 1,
        pageSize: 25,
    }
}

const ListUIContext = createContext< ListUIContextValue<QueryParams> >(initialValue);
const useListUIContext = () => useContext(ListUIContext);

export { useListUIContext }
export default ListUIProvider