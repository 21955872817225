const vertical = '/upload/vertical';
const horizontal = '/upload/horizontalWithName';
const horizontalRaw = '/upload/horizontalWithOutName';
const title = '/upload/name';
const actor = '/upload/actorImage';

const IMAGE_UPLOAD_API = {
    vertical,
    horizontal,
    horizontalRaw,
    title,
    actor
}

export default IMAGE_UPLOAD_API