import { FormikHandlers, FormikHelpers, FormikState } from 'formik'
import DatePicker from 'react-datepicker'
import LanguageSelectInput from 'src/app/components/ContentService/LanguageSelectInput'
import CategorySelectInput from 'src/app/components/ContentService/CategorySelectInput'
import { getFieldCSSClasses } from '../../helpers/AddBasicInfoHelpers'
import ContentTypeSelector from 'src/app/components/ContentService/ContentTypeSelector'
import { MovieTVSeriesUnionInput } from '../../hocs/withAddBasicInfoFormik'


type AddBasicInfoFormProps = FormikState<MovieTVSeriesUnionInput> & FormikHelpers<MovieTVSeriesUnionInput> & FormikHandlers

function AddBasicInfoForm (props: AddBasicInfoFormProps) {
    const { values, touched, errors, handleSubmit, setFieldValue, handleBlur } = props;

    return (
        <form onSubmit={handleSubmit} className='form form-label-right' id='edit-basic-info-form'>
            <div className='form-group row'>
                <div className='col-2'>
                    <small className='form-text text-muted my-2'>
                        <b>Тип</b> 
                    </small>
                    <ContentTypeSelector
                        className={getFieldCSSClasses(touched['typeId'], errors['typeId'])}
                        name='typeId'
                        placeholder='Тип'
                        typeChangeHandler={(value: number) => {
                            setFieldValue('typeId', value);
                        }}
                        onBlur={handleBlur}
                        value={values.typeId}
                    />
                </div>

                <div className='col-10'>
                    <small className='form-text text-muted my-2'>
                        <b>Наименование</b> 
                    </small>
                    <input
                        className={getFieldCSSClasses(touched['name'], errors['name'])}
                        name='name'
                        placeholder='Наименование'
                        onChange={(e: any) => {
                            setFieldValue('name', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Язык</b> 
                    </small>

                    <LanguageSelectInput
                        className={ `${getFieldCSSClasses(touched['release'], errors['release'])} p-4` }
                        name='language'
                        placeholder='Язык'
                        optionChangeHandler={(language) => {
                            setFieldValue('language', language);
                        }}
                        onBlur={handleBlur}
                        value={values.language}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Категория</b> 
                    </small>

                    <CategorySelectInput
                        className={ `${getFieldCSSClasses(touched['category'], errors['category'])} p-4` }
                        name='category'
                        placeholder='Категория'
                        optionChangeHandler={(category) => {
                            setFieldValue('category', category);
                        }}
                        onBlur={handleBlur}
                        value={values.category}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Дата выхода</b> 
                    </small>
                    <DatePicker
                        className={ `${getFieldCSSClasses(touched['release'], errors['release'])} p-4` }
                        name='release'
                        dateFormat='dd.MM.yyyy'
                        placeholderText='Выберите дату выхода'
                        selected={  values.release ? new Date(values.release) : null }
                        onChange={(date: Date|null) => {
                            setFieldValue('release', date ? date.toJSON() : values.release);
                        }}
                        onBlur={handleBlur}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Возраст</b> 
                    </small>
                    <input
                        type='number'
                        className={getFieldCSSClasses(touched['age'], errors['age'])}
                        name='age'
                        placeholder='Возраст'
                        onChange={(e: any) => {
                            setFieldValue('age', e.target.value);
                            console.log(values);
                        }}
                        onBlur={handleBlur}
                        value={values.age}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Рейтинг KP</b> 
                    </small>
                    <input
                        type='number'
                        className={getFieldCSSClasses(touched['ratingKP'], errors['ratingKP'])}
                        name='ratingKP'
                        placeholder='Рейтинг KP'
                        onChange={(e: any) => {
                            setFieldValue('ratingKP', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.ratingKP}
                    />
                </div>

                <div className='col-4'>
                    <small className='form-text text-muted my-2'>
                        <b>Рейтинг IMDB</b> 
                    </small>
                    <input
                        type='number'
                        className={getFieldCSSClasses(touched['ratingIMDB'], errors['ratingIMDB'])}
                        name='ratingIMDB'
                        placeholder='Рейтинг IMDB'
                        onChange={(e: any) => {
                            setFieldValue('ratingIMDB', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.ratingIMDB}
                    />
                </div>

                <div className='col-12'>
                    <small className='form-text text-muted my-2'>
                        <b>Описание</b> 
                    </small>
                    <textarea
                        className={getFieldCSSClasses(touched['description'], errors['description'])}
                        name='description'
                        placeholder='Описание'
                        onChange={(e: any) => {
                            setFieldValue('description', e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.description}
                    />
                </div>

            </div>
        </form>
    );
}

export default AddBasicInfoForm