import { gql } from '@apollo/client'
import { Response, TapeType, TapeSort, TapeSize } from 'src/api/graphql/types'

export interface AddCategoryToHomePageArgs {
    input: {
        TitleTk: string
        TitleRu: string
        categoryType: TapeType['Value']
        categoryID: number
        sort: TapeSort['Value']
        itemSize: TapeSize['Value']
        position: number
    }
} 
export interface AddCategoryToHomePageData {
    AddCategoryToHomePage: Response
}

export const ADD_CATEGORY_TO_HOME_PAGE_MUTATION = gql`
    mutation AddCategoryToHomePageMutation($input: InputArgsToHomePage) {
        AddCategoryToHomePage(input: $input) {
            status
            message
        }
    }
`