import { Link, useHistory } from 'react-router-dom'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import useLoginMutation from '../../hooks/mutation/useLogin'
import { LoginInput } from 'src/api/graphql/types'
import { ROUTES_LIST } from 'src/app/routes/RootRoute';
import { comment } from 'src/util/comment'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

const loginInitialValue:LoginInput = {
    username: '',
    password: '',
};

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required(
            'AUTH.VALIDATION.REQUIRED_FIELD',
        ),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required(
            'AUTH.VALIDATION.REQUIRED_FIELD',
        ),
});

function Login() {
    const getInputClasses = (fieldname: string) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid';
        }

        return '';
    };

    const { setNotification } = useNotification();

    const loginSubmitHandler = async (values: LoginInput, formikHelpers: FormikHelpers<LoginInput>) => {
        formikHelpers.setTouched({username: true, password: true});

        await login(values);
        comment('Login component','form submit values', values);
    };

    const formik = useFormik({
        initialValues: loginInitialValue,
        validationSchema: loginSchema,
        onSubmit: loginSubmitHandler,
    });

    const login = useLoginMutation({
        completeHandler: data => {
            history.push(ROUTES_LIST.DASHBOARD);
            setNotification({message: 'Успешная авторизация', variant: NotificationVariants.success});
        },
        errorHandler: error => {
            formik.setStatus(
                error.message
            );
            setNotification({message: 'Авторизация прошла не успешно', variant: NotificationVariants.error});
            console.error(error)
        }
    });

    const history = useHistory();
    
    const loading = Boolean(formik.isSubmitting && formik.touched.password)

    return (
        <>
            <div className='d-flex flex-column flex-root'>
                {/*begin::Login*/}
                <div
                    className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
                    id='kt_login'
                >
                    {/*begin::Content*/}
                    <div className='d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden'>
                        {/* begin::Content body */}
                        <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                            
                            <div className='login-form login-signin' id='kt_login_signin_form'>
                                {/* begin::Head */}
                                <div className='text-center mb-10 mb-lg-20'>
                                    <h3 className='font-size-h1'>
                                    Войдите в аккаунт
                                    </h3>
                                    <p className='text-muted font-weight-bold'>
                                    Введите свой логин и пароль
                                    </p>
                                </div>
                                {/* end::Head */}

                                {/*begin::Form*/}
                                <form
                                    onSubmit={formik.handleSubmit}
                                    className='form fv-plugins-bootstrap fv-plugins-framework'
                                >
                                    {
                                        formik.status 
                                        ? (
                                            <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                                            </div>
                                        ) 
                                        : (
                                            <div className='mb-10 alert alert-custom alert-light-info alert-dismissible'>
                                                <div className='alert-text '>
                                                Введите свой <strong>Логин</strong> и {' '}
                                                <strong>Пароль</strong> правильно для входа.
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className='form-group fv-plugins-icon-container'>
                                        <input
                                            disabled={loading}
                                            placeholder='Email'
                                            type='text'
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            'username'
                                            )}`}
                                            {...formik.getFieldProps('username')}
                                        />
                                        {formik.touched.username && formik.errors.username ? (
                                            <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.username}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className='form-group fv-plugins-icon-container'>
                                        <input
                                            disabled={loading}
                                            placeholder='Password'
                                            type='password'
                                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            'password'
                                            )}`}
                                            {...formik.getFieldProps('password')}
                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.password}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                                        <Link
                                            to='/auth/forgot-password'
                                            className='text-dark-50 text-hover-primary my-3 mr-2'
                                            id='kt_login_forgot'
                                        >
                                            FORGOT password ?
                                        </Link>
                                        <button
                                            id='kt_login_signin_submit'
                                            type='submit'
                                            disabled={formik.isSubmitting}
                                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                        >
                                            <span>Sign In</span>
                                            {loading && <span className='ml-3 spinner spinner-white'></span>}
                                        </button>
                                    </div>
                                </form>
                                {/*end::Form*/}
                            </div>
                        </div>
                        {/*end::Content body*/}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
      );
}

export default Login