import { gql } from '@apollo/client'
import { QUALITY_TYPES, TranscodeRequestFile } from '../types'

export interface GetEpisodesListArgs {
    qualityID: QUALITY_TYPES
} 

export interface GetEpisodesListData {
    GetEpisodesList: TranscodeRequestFile[]
}

export const GET_EPISODES_LIST_QUERY = gql`
    query GetEpisodesListQuery($qualityID: Int!) {
        GetEpisodesList(qualityID: $qualityID) {
            MovieId
            Name
            RequestId
            FileId
            qualityID
        }
    }
`