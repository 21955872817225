import { PrefetchData } from 'src/uploader/types'
import { TRAILER_UPLOAD_PREFETCH_API } from 'src/api/rest/urls/videoUpload'
import { axiosVideoUploadInstance } from 'src/api/rest/axios'
import { comment } from 'src/util/comment'

async function prefetchTrailerUploadUrl(id: number, name: string): Promise<PrefetchData|null> {
    try {
        const payload = new FormData();
        payload.append('movieId', String(id));
        payload.append('fileName', name);

        comment('prefetchTrailerUploadUrl payload', payload);

        const response = await axiosVideoUploadInstance.post<PrefetchData>(TRAILER_UPLOAD_PREFETCH_API, payload);
        return response.data
    } catch (error) {
        console.error('prefetchTrailerUploadUrl api call', error);
        return null
    }
}

export default prefetchTrailerUploadUrl
