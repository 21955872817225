import React from 'react'
import { useQuery } from '@apollo/client'
import { 
    InitHomePageFormData,
    InitHomePageFormArgs,
    INIT_HOME_PAGE_FORM_QUERY
} from 'src/api/graphql/queries/InitHomePageFormQuery'

export function useTapeOptions() {
    const { data, loading } = useQuery<InitHomePageFormData, InitHomePageFormArgs>(INIT_HOME_PAGE_FORM_QUERY);
    const options = React.useMemo(() => {
        if(data !== undefined) {
            return {
                loading,
                ...data.InitHomePageForm
            };
        }

        return {
            loading,
            Sort: [],
            CategoryType: [],
            ItemSize: [],
        }
    }, [data, loading]);

    return options;
}