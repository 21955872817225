import { Box, LinearProgress, LinearProgressProps, Typography } from '@material-ui/core'
import { useMediaCardProgressStyles } from '../helpers/UploadImageCardHelpers'

type MediaCardProgressProps = LinearProgressProps & {
    value: number,
}

function MediaCardProgress(props: MediaCardProgressProps) {
    const classes = useMediaCardProgressStyles();
    const { value } = props;
    if(value === 0) {
        return (
            <div className={classes.root}>
                <Box width='100%' mr={1}>
                    
                </Box>
            </div>
        )
    }
    if(value === 100) {
        return (
            <div className={classes.root}>
                <Box width='100%' mr={1}>
                    <Typography variant='subtitle1' className={classes.successText}>
                        Изображение загружено
                    </Typography>
                </Box>
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <Box width='100%' mr={1}>
                <LinearProgress variant='determinate' {...props}/>
            </Box>

            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </div>
    )
}

export default MediaCardProgress