import { useMutation } from '@apollo/client'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { 
    AddCategoryToHomePageArgs, 
    AddCategoryToHomePageData, 
    ADD_CATEGORY_TO_HOME_PAGE_MUTATION
} from 'src/api/graphql/mutations/AddCategoryToHomePageMutation'
import { 
    UpdateCategoryToHomePageArgs, 
    UpdateCategoryToHomePageData, 
    UPDATE_CATEGORY_TO_HOME_PAGE_MUTATION 
} from 'src/api/graphql/mutations/UpdateCategoryToHomePageMutation'
import { GET_HOME_PAGE_CATEGORIES_QUERY } from 'src/api/graphql/queries/GetHomePageCategoriesQuery'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { useTapesList } from '../../TapesList/hooks/useTapesList'
import TapeForm from '../components/TapeForm'

const EMPTY_TAPE = {
    TitleTk: '',
    TitleRu: '',
    categoryType: '',
    sort: '',
    itemSize: '',
    categoryID: 0,
    position: 0
}

export function withTapeModal(): React.ComponentType {
    const TapeModal: React.ComponentType = () => {
        const formRef = React.useRef<HTMLFormElement>(null);
        const history = useHistory();
        const { search } = useLocation();
        const id = React.useMemo(() => {
            const id = new URLSearchParams(search).get('id');
            return Boolean(id) ? Number(id) : null;
        }, [search]);
        const { tapes } = useTapesList();

        const initialValues = React.useMemo(() => {
            if(id !== null) {
                const finded = tapes.find(t => t.id === id);
                if(finded !== undefined) return {
                    TitleTk: finded.TitleTk,
                    TitleRu: finded.TitleRu,
                    categoryType: finded.categoryType,
                    sort: finded.sortValue,
                    itemSize: finded.itemSizeValue,
                    categoryID: finded.genreID,
                    position: finded.position
                };

                return EMPTY_TAPE;
            }

            return EMPTY_TAPE;
        }, [id, tapes]);

        const [ addTrigger, {loading: addLoading}] = useMutation<AddCategoryToHomePageData, AddCategoryToHomePageArgs>(ADD_CATEGORY_TO_HOME_PAGE_MUTATION, {
            refetchQueries: [GET_HOME_PAGE_CATEGORIES_QUERY]
        });

        const [ editTrigger, {loading: editLoading} ] = useMutation<UpdateCategoryToHomePageData, UpdateCategoryToHomePageArgs>(UPDATE_CATEGORY_TO_HOME_PAGE_MUTATION, {
            refetchQueries: [GET_HOME_PAGE_CATEGORIES_QUERY]
        }); 

        const submitHandler = React.useCallback((id: number|null, input: AddCategoryToHomePageArgs['input']) => {
            if(id !== null) {
                return editTrigger({
                    variables: {
                        id,
                        input
                    }
                })
            } else {
                return addTrigger({
                    variables: {
                        input
                    }
                })
            }
        }, [addTrigger, editTrigger]);

        const modalCloseHandler = React.useCallback(() => {
            history.push(
                ROUTES_LIST.MAIN_PAGE
            );
        }, [history]);

        const modalSubmitHandler: React.MouseEventHandler = React.useCallback(e => {
            const form = formRef.current;
            if(form !== null) {
                form.requestSubmit();
            }
        }, []);

        return (
            <Modal 
                onHide={modalCloseHandler} 
                size='lg'
                show
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            id !== null
                            ? 'Изменить Ленту'
                            : 'Добавить Ленту'
                        }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='overlay overlay-block cursor-default'>
                    <TapeForm
                        id={id}
                        formRef={formRef}
                        initialValues={initialValues}
                        submitHandler={submitHandler}
                        closeHandler={modalCloseHandler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={modalCloseHandler}>
                        Закрыть
                    </Button>
                    <Button variant='primary' onClick={modalSubmitHandler}>
                        <span>
                            {
                                id !== null
                                ? 'Изменить'
                                : 'Добавить'
                            }
                            {
                                (addLoading||editLoading) 
                                && <span className='mr-3 ml-1 spinner spinner-white'/>
                            }
                        </span>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return TapeModal;
}