import { gql } from '@apollo/client'
import { Complaint } from '../types'

export interface GetAllComplaintsArgs {
  limit: number
  page: number
} 

export interface GetAllComplaintsData {
  GetAllComplaints: {
    Complaints: Complaint[]
    Pagination: {
      TotalCount: number
    }
  }
}

export const GET_ALL_COMPLAINTS_QUERY = gql`
  query GetAllComplaints($limit: Int!, $page: Int!) {
    GetAllComplaints(limit: $limit, page: $page) {
      Complaints{
        id
        userID
        typeID
        movieID
        movieName
        complaintsTitle
        complaintsDescription
        startDate
        endDate
        status
      }
      Pagination{
        TotalCount
      }
    }
  }
`