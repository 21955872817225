import { gql } from '@apollo/client'
import {User, AuthToken } from 'src/api/graphql/types'

export interface UserQueryArgs {
    input: AuthToken
} 

export interface UserQueryData {
    user: User
}

export const USER_QUERY = gql`
    query UserQuery( $input: AuthToken! ) {
        user(input: $input) {
           id
           name
           roleId
        }
    }
`