import { useMutation } from '@apollo/client'
import { AddCountryMutationArgs, AddCountryMutationData, ADD_COUNTRY_MUTATION, } from 'src/api/graphql/mutations/AddCountryMutation'
import { Country, CountryInput } from 'src/api/graphql/types'
import { AddCompleteHandler, AddErrorHandler, AddMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

export function useAddCountry(completeHandler?: AddCompleteHandler<Country>, errorHandler?: AddErrorHandler): AddMutationHookResult<CountryInput> {

    const [ AddInitialCallback ] = useMutation<AddCountryMutationData, AddCountryMutationArgs>(ADD_COUNTRY_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.AddCountry);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const addCallback = async (input : {name: string}) => {
        await AddInitialCallback({
            variables: { input }
        });
    }

    return [ addCallback ]
}