import { ApolloError, ApolloQueryResult, QueryLazyOptions, useLazyQuery } from '@apollo/client'
import { GetAllEpisodesArgs, GetAllEpisodesData, GET_ALL_EPISODES_QUERY } from 'src/api/graphql/queries/GetAllEpisodes'
import { Episode } from 'src/api/graphql/types'

type GetAllEpisodesLazyResult = [
    (options?: QueryLazyOptions<GetAllEpisodesArgs> | undefined) => void, 
    {
        loading: boolean, 
        data: Episode[], 
        error: ApolloError|undefined, 
        refetch?: ((variables?: Partial<GetAllEpisodesArgs> | undefined) => Promise<ApolloQueryResult<GetAllEpisodesData>>)
    }
]

export function useGetAllEpisodesLazyQuery(completeHandler?: (data: Episode[]) => void): GetAllEpisodesLazyResult {
    const [getAllEpisodes, queryResult ] = useLazyQuery<GetAllEpisodesData, GetAllEpisodesArgs>(GET_ALL_EPISODES_QUERY, {
        onCompleted: data => {
            completeHandler && completeHandler(data.GetAllEpisodes)
        },
        // fetchPolicy: 'network-only',
        // nextFetchPolicy: 'cache-and-network'
    });

    return [
        getAllEpisodes, 
        {
            loading: queryResult.loading,
            data: queryResult.data ? queryResult.data.GetAllEpisodes : [],
            error: queryResult.error,
            refetch: queryResult.refetch
        }
    ];
}