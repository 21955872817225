import { SELECTORS } from 'src/app/assets/Selectors'
import HeaderSelectorElement from './components/HeaderSelectorElement'

export const HeaderSelectors = () => {
    return <> 
        {
            SELECTORS.map( 
                selector => <HeaderSelectorElement selector={selector} key={selector.code}/>
            )
        }
    </>
}