import React from 'react'
import { useMutation } from '@apollo/client'
import { 
    AddPositionToParticipantArgs, 
    AddPositionToParticipantData, 
    ADD_POSITION_TO_PARTICIPANT_MUTATION 
} from 'src/api/graphql/mutations/AddPositionToParticipant'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'
import { GET_ALL_PARTICIPANTS_QUERY } from 'src/api/graphql/queries/getAllParticipants';
import { SEARCH_PARTICIPANTS_BY_NAME_QUERY } from 'src/api/graphql/queries/SearchParticipantByName';

export function useAddRolesToParticipant() {
    const [addRole] = useMutation<
        AddPositionToParticipantData, 
        AddPositionToParticipantArgs
    >(ADD_POSITION_TO_PARTICIPANT_MUTATION);

    const addMultipleRoles = React.useCallback((positions: PARTICIPANT_TYPES[], participantID: number) => {
        
        return Promise.all(
            positions.map( 
                (positionID, index) => addRole({
                    variables: {
                        participantID,
                        positionID
                    },
                    refetchQueries: index === positions.length-1 ? [GET_ALL_PARTICIPANTS_QUERY, SEARCH_PARTICIPANTS_BY_NAME_QUERY] : []
                })
            )
        );
    }, [addRole]);

    return addMultipleRoles;
}