import { HostAndPagination } from 'src/api/graphql/types'
import { ABONENT_SORT_TYPES } from 'src/app/assets/AbonentTypes'
import { gql } from '@apollo/client'

export interface GetAllHostsArgs {
    limit: number,
    page: number,
    sort: ABONENT_SORT_TYPES
}

export interface GetAllHostsData {
    getAllHosts: HostAndPagination
}

export const GET_ALL_HOSTS_QUERY = gql`
     query getAllHostsQuery($limit: Int!, $page: Int!, $sort: Int!) {
        getAllHosts(limit: $limit, page: $page, sort: $sort) {
            hosts {
                ip
                phone
                code
                name
                active
                type
            }
            Pagination {
                TotalCount
            }
        }
    }
`