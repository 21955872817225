import { gql } from '@apollo/client'
import { Season } from '../types'

export interface GetAllSeasonsArgs {
    parentID: number
} 

export interface GetAllSeasonsData {
    GetAllSeasons: Season[]
}

export const GET_ALL_SEASONS_QUERY = gql`
    query GetAllSeasonsQuery($parentID: Int!) {
        GetAllSeasons(parentID: $parentID) {
            id
            name
            status
            release
            parentID
        }
    }
`