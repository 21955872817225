import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type SeasonAddElementProps = {
    seasonAddCallback: React.MouseEventHandler
}

function SeasonAddElement(props: SeasonAddElementProps) {
    const { seasonAddCallback } = props;

    return (
        <button
            title='Добавить'
            className='btn btn-icon btn-dark season add-btn'
            onClick={seasonAddCallback}
        >
            <span className='svg-icon svg-icon-md svg-icon-primary'>
                <SVG
                    description='Добавить'
                    title='Добавить'
                    src={toAbsoluteUrl('/media/svg/icons/Files/Folder-plus.svg')}
                />
            </span>
        </button>
    )
}

export default SeasonAddElement