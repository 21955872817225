import { useQuery } from '@apollo/client'
import { 
    GetFilesByProcessStatusArgs, 
    GetFilesByProcessStatusData, 
    GET_FILES_BY_PROCESS_STATUS_QUERY 
} from 'src/api/graphql/queries/GetFilesByProcessStatus'
import { TRANSCODE_STATUS } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { PROCESS_POLL_INTERVAL } from '../helpers/pollingIntervals';

export function useGetFullfilledFiles(typeID: CONTENT_TYPES) {
    const { loading, data, error } = useQuery<GetFilesByProcessStatusData, GetFilesByProcessStatusArgs>(
        GET_FILES_BY_PROCESS_STATUS_QUERY,
        {
            variables: { 
                typeID, 
                status: TRANSCODE_STATUS.FULLFILLED 
            },
            pollInterval: PROCESS_POLL_INTERVAL.FULLFILLED
        }
    );

    return {
        loading,
        data: data ? data.GetFilesByProcessStatus : [],
        error
    }
}