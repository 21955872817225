import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client'
import { AddSeasonMutationArgs, AddSeasonMutationData, ADD_SEASON_MUTATION } from 'src/api/graphql/mutations/AddSeason'
import { Season } from 'src/api/graphql/types'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'

type AddSeasonMutationCallback = (
    options?: MutationFunctionOptions<AddSeasonMutationData, AddSeasonMutationArgs>
) => Promise<FetchResult<AddSeasonMutationData>>

export function useAddSeasonMutation(completeHandler?: (data: Season) => void): AddSeasonMutationCallback {
    const { setNotification } = useNotification();
    const [addSeasonCallback ] = useMutation<AddSeasonMutationData, AddSeasonMutationArgs>(ADD_SEASON_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data.AddSeason);
            setNotification({
                message: `Успешно добавлен: ${data.AddSeason.name}`,
                variant: NotificationVariants.success
            });
        },
        onError: error => {
            setNotification({
                message: `Ошибка при попытке добавления: ${error.message}`,
                variant: NotificationVariants.error
            });
            console.error('useAddSeasonMutation', error);
        }
    });

   return addSeasonCallback;
}