import React from 'react'
import { ValueType } from 'react-select'
import Select from 'react-select'
import { URGENT_TYPES } from 'src/api/graphql/types'

function UrgentSelect(
    props: {
        value: URGENT_TYPES,
        optionChangeHandler: (option: number) => void
    }
) {
    const options = React.useMemo(() => {
        return [
            {value: URGENT_TYPES.NON_URGENT, label: 'Обычный'},
            {value: URGENT_TYPES.URGENT, label: 'Срочный'},
        ]
    }, []);

    const handleChange = ( option: ValueType<{value: number, label: string}, boolean> ) => {
        const { value } = option as {value: number, label: string};
        props.optionChangeHandler(value);
    }
    const customStyles = React.useMemo(() => ({
        container: (provided: any, state: any) => ({
            ...provided,
            margin: 0
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          fontSize: 8
        }),
        control: (provided: any, state: any) => {
            if(state.selectProps.value.value === URGENT_TYPES.NON_URGENT) {
                return {
                    ...provided,
                    border: '1px solid var(--gray)',
                }
            }

            if(state.selectProps.value.value === URGENT_TYPES.URGENT) {
                return {
                    ...provided,
                    border: '1px solid var(--success)',
                }
            }
            return provided
        },
        singleValue: (provided: any, state: any) => {
            if(state.data.value === URGENT_TYPES.NON_URGENT) {
                return {
                    ...provided,
                    color: 'var(--gray)',
                    fontSize: 9,
                    padding: 0,
                    margin: 0,
                }
            }
            if(state.data.value === URGENT_TYPES.URGENT) {
                return {
                    ...provided,
                    color: 'var(--success)',
                    fontSize: 9,
                    padding: 0,
                    margin: 0,
                }
            }

            return { ...provided, fontSize: 8 };
        },
        valueContainer: (provided: any, state: any) => {
            return {
                ...provided,
                display: 'flex',
                flexWrap: 'flex-wrap',
                alignItems: 'center',
                justifyContent: 'center !important',
            }
        },
        IndicatorsContainer: (provided: any, state: any) => {
            return { display: 'none !important' };
        },
        indicatorSeparator: (provided: any, state: any) => {
            return { display: 'none' };
        },
        dropdownIndicator: (provided: any, state: any) => {
            return { display: 'none'}
        }
    }), []);

    return <Select 
        value={ options.find( o => o.value === props.value) }
        options={options} 
        onChange={handleChange}
        isMulti={false}
        isClearable={false}
        isSearchable={false}
        styles={customStyles}
    />
}

export default UrgentSelect