import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import { TranscodeRequestFile } from 'src/api/graphql/types'
import { useContextSelector } from 'use-context-selector'
import ProccessingContext from '../../contexts/ProccessingContext'
import { ProcessIDFormatter, ProcessNameFormatter,  ProcessResendActionFormatter, QualityIDFormatter } from '../../helpers/ProcessingFormatter'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { addUniqueID } from '../../helpers/addUniqueID'

function RejectedTable() {
    const rejected = useContextSelector(ProccessingContext, v => v.rejectedFilesResult);
    const typeID = useContextSelector(ProccessingContext, v => v.typeID);

    const entities = React.useMemo<Array<TranscodeRequestFile>>(() => { 
        return addUniqueID(rejected.data)
    }, [rejected]);
   
    const columns: ColumnDescription<TranscodeRequestFile>[] = [
        // {
        //     dataField: 'order',
        //     text: '#',
        //     isDummyField: true,
        //     formatter: ProcessOrderFormatter,
        // },
        {
            dataField: 'MovieId',
            text: 'ID',
            formatter: ProcessIDFormatter,
        },
        {
            dataField: 'qualityID',
            text: 'Качество',
            formatter: QualityIDFormatter,
            style: {
                minWidth: '30px',
            },        
        },
        {
            dataField: 'Name',
            text: 'Название',
            formatter: ProcessNameFormatter,
        },
        {
            dataField: 'action',
            text: 'Действия',
            formatter: ProcessResendActionFormatter,
            formatExtraData: {typeID},
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '50px',
            },
        },
    ];
      
    return (
        <PerfectScrollbar 
            className='processing-list-scrollbar' 
            options={{suppressScrollX: true}}
        >
            <BootstrapTable
                wrapperClasses='table-responsive overflow-x-none'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                data={entities}
                columns={columns}
                keyField={'id'}
            />
        </PerfectScrollbar>
    );
}

export default RejectedTable