import React from 'react'

export interface NavigationLinksState {
    filmsLink: string
    serialsLink: string
    abonentsLink: string
    participantsLink: string
    complaintsLink: string
}

export enum NavigationLinksActionTypes {
    setFilmsLink = '__setFilmsLink',
    setSerialsLink = '__setSerialsLinl',
    setAbonentsLink = '__setAbonentsLink',
    setParticipantsLink = '__setParticipantsLink',
    setComplaintsLink = '__setComplaintsLink'
}

export interface NavigationStateAction {
    type: NavigationLinksActionTypes
    payload?: string
}

export interface NavigationLinksContextValue {
    navigationLinks: NavigationLinksState
    dispatch: React.Dispatch<NavigationStateAction>
}

export interface NavigationLinksContextProviderProps extends ReactMultipleContainer {}

const NavigationLinksContext = React.createContext<NavigationLinksContextValue|undefined>(undefined);

const NavigationLinksReducer = (
    state: NavigationLinksState, 
    action: NavigationStateAction
): NavigationLinksState => {
    switch (action.type) {
        case NavigationLinksActionTypes.setFilmsLink: {
            if(action.payload) {
                return {
                    ...state,
                    filmsLink: action.payload
                }
            }
            return state;
        }
        case NavigationLinksActionTypes.setSerialsLink: {
            if(action.payload) {
                return {
                    ...state,
                    serialsLink: action.payload
                }
            }
            return state;
        }
        case NavigationLinksActionTypes.setAbonentsLink: {
            if(action.payload) {
                return {
                    ...state,
                    abonentsLink: action.payload
                }
            }
            return state;
        }
        case NavigationLinksActionTypes.setParticipantsLink: {
            if(action.payload) {
                return {
                    ...state,
                    participantsLink: action.payload
                }
            }
            return state;
        }
        case NavigationLinksActionTypes.setComplaintsLink: {
            if(action.payload) {
                return {
                    ...state,
                    complaintsLink: action.payload
                }
            }
            return state;
        }
        default: throw new Error('Unknown action type in NavigationLinksReducer');
    }
}

const initialNavigationLinks: NavigationLinksState = {
    filmsLink: '',
    serialsLink: '',
    abonentsLink: '',
    participantsLink: '',
    complaintsLink: ''
}

function NavigationLinksContextProvider(props: NavigationLinksContextProviderProps) {
    const { children } = props;

    const [navigationLinks, dispatch] = React.useReducer(NavigationLinksReducer, initialNavigationLinks);

    const value = {
        navigationLinks,
        dispatch
    }

    return (
        <NavigationLinksContext.Provider value={value}>
            {children}
        </NavigationLinksContext.Provider>
    );
}

const useNavigationLinksContext = () => {
    const navigationContext = React.useContext(NavigationLinksContext);

    if(navigationContext !== undefined) return navigationContext;
    else throw new Error('NavigationLinksContext could not be undefined in moment of using');
}

export default NavigationLinksContext
export {
    NavigationLinksContextProvider,
    useNavigationLinksContext
}