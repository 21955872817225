/**
 * Helps to choose what element is active currently
 * @param activeId ActiveId position could be 0 by default and id with type number
 * @param currentId currentId elements id
 * @param elementsSize all elements size
 * @param index index of currently seeking element
 * @returns is active or not
 */
export const isActiveId = (activeId: 0|number, currentId: number, elementsSize: number, index: number): boolean => {
    if(activeId === 0 && index === 0 ) {
        return true;
    }
    if(currentId === activeId) return true;
    else return false;
}