import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client'
import { UpdateEpisodeMutationArgs, UpdateEpisodeMutationData, UPDATE_EPISODE_MUTATION } from 'src/api/graphql/mutations/UpdateEpisode'
import { GET_ALL_EPISODES_QUERY } from 'src/api/graphql/queries/GetAllEpisodes'
import { GET_ALL_SEASONS_QUERY } from 'src/api/graphql/queries/GetAllSeasons'
import { Episode } from 'src/api/graphql/types'

type UpdateEpisodeMutationCallback = (
    options?: MutationFunctionOptions<UpdateEpisodeMutationData, UpdateEpisodeMutationArgs>
) => Promise<FetchResult<UpdateEpisodeMutationData>>

export function useUpdateEpisodeMutation(completeHandler?: (data: Episode) => void): UpdateEpisodeMutationCallback {
    const [UpdateEpisodeCallback ] = useMutation<UpdateEpisodeMutationData, UpdateEpisodeMutationArgs>(UPDATE_EPISODE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data.UpdateEpisode);
        },
        refetchQueries: [GET_ALL_SEASONS_QUERY, GET_ALL_EPISODES_QUERY]
    });

   return UpdateEpisodeCallback;
}