import { useQuery } from '@apollo/client'
import { Spinner } from 'react-bootstrap';
import { GetAllHostsArgs, GetAllHostsData, GET_ALL_HOSTS_QUERY } from 'src/api/graphql/queries/getAllHosts'

function AbonentsCardHeader() {
    const { loading, data } = useQuery<GetAllHostsData, GetAllHostsArgs>(GET_ALL_HOSTS_QUERY, {
        variables: {
            limit: 25,
            page: 1,
            sort: 0
        }
    });

    if(loading) return <div className='d-flex align-items-center'>
         <span className='d-flex align-item-center'>
            Список абонентов
        </span>

        <span className='label label-light d-flex align-items-center m-2' style={{width: 30, height: 30}}>
            <Spinner animation='grow' variant='primary' size='sm'/>
        </span>
    </div>;

    if(data) return <div className='d-flex align-items-center'>
        <span className='d-flex align-item-center'>
            Список абонентов
        </span>

        <span className='label label-primary d-flex align-items-center m-2' style={{width: 30, height: 30}}>
            {data.getAllHosts.Pagination.TotalCount}
        </span>
    </div>;

    return null;
}

export default AbonentsCardHeader