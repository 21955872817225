import React from 'react'
import { useIsUnique } from '../hooks/useIsUnique'

interface ProcessingItemProps {
    value: string
}

function ProcessingItem(props: ProcessingItemProps) {
    const { value } = props;
    const itemRef = React.useRef<HTMLLIElement>(null);
    const checkIsUnique = useIsUnique();

    React.useEffect(() => {
        const item = itemRef.current;
        
        if(item !== null) {
            (async function InitiateChecking() {
                const isUnique = await checkIsUnique(value);
                if(isUnique !== undefined) {
                    addCorrectClassName(item, isUnique);
                }
            })();
        }
    }, [value, checkIsUnique]);

    return (
        <li className='processing-item' ref={itemRef}>
            { value }
        </li>
    );
}

const addCorrectClassName = (element: HTMLElement, isValid: boolean) => {
    console.log('Class state', isValid);
    
    if(isValid) {
        if(!element.classList.contains('valid')) {
            element.classList.remove('invalid');
            element.classList.add('valid');
        }
    } else {
        if(!element.classList.contains('invalid')) {
            element.classList.remove('valid');
            element.classList.add('invalid');
        }
    }
}

export default ProcessingItem