import { isNull } from 'lodash';
import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { useSerialFilesContext } from '../../../../contexts/SerialFilesContext';

type EpisodeAddElementProps = {
    episodeAddHandler: React.MouseEventHandler
}

function EpisodeAddElement(props: EpisodeAddElementProps) {
    const { episodeAddHandler } = props;
    const serialFilesContext = useSerialFilesContext();
    const hasNotSeason = React.useMemo(() => {
        if( isNull(serialFilesContext) ) {
           throw new Error('Context value could not be null');
        } 

        return serialFilesContext.activeSeason === 0;
    }, [serialFilesContext]);

    return (
        <button
            title='Добавить'
            className='btn btn-icon btn-dark episode add-btn'
            onClick={episodeAddHandler}
            disabled={hasNotSeason}
        >
            <span className='svg-icon svg-icon-md svg-icon-primary'>
                <SVG
                    description='Добавить'
                    title='Добавить'
                    src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')}
                />
            </span>
        </button>
    )
}

export default EpisodeAddElement