import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type FilmActiveStatusProps = {
    status: ACTIVATION_STATUS
}

function FilmActiveStatus(props: FilmActiveStatusProps) {
    const { status } = props;

    if(status === ACTIVATION_STATUS.ACTIVE) {
        return (
            <div className='season-status unselectable mb-2'>
                <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>

                <span>
                    Публичный
                </span>
            </div>
        )
    }

    if(status === ACTIVATION_STATUS.INACTIVE) {
        return (
            
            <div className='episode-status unselectable mb-2'>
                <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                    <SVG
                        description='Добавить'
                        title='Добавить'
                        src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                    />
                </span>

                <span>
                    Приватный
                </span>
            </div>
        );
    }

    return null;
}


export default FilmActiveStatus