import { gql } from '@apollo/client'
import { SeasonInput, Season} from '../types'

export interface UpdateSeasonMutationArgs {
    seasonID: number,
    input: SeasonInput
} 
export interface UpdateSeasonMutationData {
    UpdateSeason: Season
}

export const UPDATE_SEASON_MUTATION = gql`
    mutation UpdateSeasonMutation( $seasonID: Int!, $input: SeasonInput! ) {
        UpdateSeason(seasonID: $seasonID, input: $input) {
            id
            name
            status
            release
        }
    }
`