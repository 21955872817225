import { ApolloError, useMutation } from '@apollo/client'
import { DeleteParticipantToMovieMutationData, DeleteParticipantToMovieMutationArgs, DELETE_PARTICIPANT_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/DeleteParticipantToMovie';
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes';
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext';

type useDeleteDirectorToMovieMutationArgs = {
    completeHandler?: (data: DeleteParticipantToMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

function useDeleteDirectorToMovieMutation(args: useDeleteDirectorToMovieMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;
    const editContentContext = useContentEditContext();

    const [ deleteDirector ] = useMutation<DeleteParticipantToMovieMutationData, DeleteParticipantToMovieMutationArgs>(DELETE_PARTICIPANT_TO_MOVIE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function( participantID: number ) {
        return await deleteDirector({
            variables: {
                movieID: editContentContext.id,
                participantID,
                positionID: PARTICIPANT_TYPES.DIRECTOR
            },
        });
    };
}

export default useDeleteDirectorToMovieMutation