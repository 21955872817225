import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import Classes from '../../styles.module.css'

function MainPageCardHeaderToolbar() {
    const history = useHistory();
    const addHandler: React.MouseEventHandler = e => {
        history.push(
            ROUTES_LIST.MAIN_PAGE
            .concat('/add')
        );
    }

    const changePosterHandler: React.MouseEventHandler = e => {
        history.push(
            ROUTES_LIST.MAIN_PAGE
            .concat('/change-poster')
        );
    }

    return(
        <div className={Classes['card-header-toolbar']}>
            <Button onClick={addHandler} className='mr-3'>
                Добавить Ленту
            </Button>

            <Button onClick={changePosterHandler}>
                Изменить постер
            </Button>
        </div>
    );
}

export default MainPageCardHeaderToolbar