import React from 'react'
import Select, { components, OptionTypeBase, ValueType } from 'react-select'
import { MultiValueGenericProps, MultiValueRemoveProps } from 'react-select/src/components/MultiValue'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { SelectorEditField } from '../../../../../../contexts/ContentEditContext'
import { comment } from '../../../../../../../../../../util/comment'
import { DeleteResult, SortableSelectInputBase } from '../../hocs/withSelector'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../../../../util/url'
import { FetchResult } from '@apollo/client'
import { NotificationVariants, useNotification } from '../../../../../../../../../contexts/NotificationContext'
import { useSortableSelectInputStyles } from './SortableSelectInputHelpers'

export interface SortableSelectInputProps extends SortableSelectInputBase {
  isLoading: boolean, 
  selectors: SelectorEditField[],
  deleteSelector: (selectorID: number) => Promise<FetchResult<DeleteResult>>
}

/**
 * Util function to use in sort handler
 * @param array Array which elements needed to be moved
 * @param from from which position
 * @param to to which position
 * @returns Moved array
 */
function arrayMove(array: SelectorEditField[], from: number, to: number) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

/**
 * Used to customize behaviour of standard MultiValue component
 * @param props MultiValue component props
 * @returns Custom delete MultiValueRemove component that could be sorted via drag & drop
 */
const SortableMultiValue = SortableElement((props: any)=> {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const classes = useSortableSelectInputStyles();
  const innerProps = { 
    ...props.innerProps, onMouseDown, 
  };

  comment('SortableSelectInput', 'SortableMultiValue sub component','sortableMultiValue', props);
  return <components.MultiValue {...props} innerProps={innerProps} className={classes.value}/>;
});

/**
 * Used to customize behaviour of standard MultiValueLabel component
 * @param props MultiValueLabel component props
 * @returns Custom delete MultiValueRemove component that could be sorted via drag & drop
 */
const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps<SelectorEditField>) => {
  comment('SortableSelectInput', 'SortableMultiValueLabel sub component','sortableMultiValueLabel', props);
  const classes = useSortableSelectInputStyles();
  // Appending styles to the existing
  const innerProps = { 
    ...props.innerProps, 
    className: props.innerProps.className ? `${props.innerProps.className} ${classes.label}` : '' 
  };
  return <components.MultiValueLabel {...props} innerProps={innerProps} />
});

/**
 * Used to customize behaviour of standard MultiValueRemove component
 * @param props MultiValueRemove component props and custom selector delete callback
 * @returns Custom MultiValueRemove component that send delete mutation
 */
const MultiValueRemove = (
  props: MultiValueRemoveProps<SelectorEditField> 
    & { 
        deleteSelector: (selectorID: number) => Promise<FetchResult<DeleteResult>>
      }
) => {
  const {data, deleteSelector} = props;
  const { setNotification } = useNotification();
  const classes = useSortableSelectInputStyles();

  const clickHandler = () => {
    deleteSelector(data.value)
      .then( result => setNotification({ message: `Удален селектор: ${data.label}`, variant: NotificationVariants.success }) )
      .catch( error => setNotification({ message: `Ошибка при удалении ${data.label}`, variant: NotificationVariants.error }) );
  }
  
  // Appending styles to the existing
  const innerProps = { 
    ...props.innerProps, 
    className: props.innerProps.className ? `${props.innerProps.className} ${classes.remove}` : '' 
  };

  return (
    <components.MultiValueRemove {...props} innerProps={innerProps}>
      <SVG 
        style={{width: '8px', height: '8px' }}
        src={toAbsoluteUrl('/media/svg/icons/General/Times-white.svg')} 
        onClick={clickHandler}
      />
    </components.MultiValueRemove>
  );
};

/**
 * Enhanced to be sorted react-select component
 */
const SortableSelect = SortableContainer(Select);

/**
 * Custom multi-select-dragable component propTypes described above
 */
const SortableSelectInput = (props: SortableSelectInputProps) => {
  const { changeHandler, value, deleteSelector, selectors } = props;

  const onChange = (selectedSelectors: ValueType<OptionTypeBase, boolean>) => {
    changeHandler(selectedSelectors ? (selectedSelectors as SelectorEditField[]) : []);
  }
  const onSortEnd = ({ oldIndex, newIndex }: {oldIndex: number, newIndex: number}) => {
    const newValue = arrayMove(Array.isArray(value) ? value : [], oldIndex, newIndex);
    changeHandler(newValue ?? []);
    comment(
      'SortableSelectInput onSortEnd handler',
      'Values sorted:',
      newValue.map(i => i.value)
    );
  };

  return (
    <SortableSelect
      // Disabled option to remove all for reason of unusability
      isClearable={false}
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }: {node: Element}) => node.getBoundingClientRect()}
      // react-select props:
      isMulti={true}
      options={selectors}
      noOptionsMessage={ ({ inputValue } : {inputValue:string}) => {
        return `Ничего не найдено по запросу "${inputValue.length < 4 ? inputValue: inputValue.slice(0, 4).concat('...')}"` 
      }}
      value={value}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
        MultiValueRemove: (props: MultiValueRemoveProps<SelectorEditField>) => <MultiValueRemove {...props} deleteSelector={deleteSelector}/>
      }}
      closeMenuOnSelect={false}
      // className = {props.className}
      name={props.name}
      placeholder={props.placeholder}
      className="basic-multi-select"
      classNamePrefix="select"
      // behaviour when loading list of selectors
      isLoading={props.isLoading}
      isDisabled={props.isLoading}
    />
  );
}

export default SortableSelectInput