import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'

type SelectorConfigCardProps = {
    className: string
    title: string
    style?: React.CSSProperties
} & ReactMultipleContainer

function SelectorConfigCard(props: SelectorConfigCardProps) {
    const { className, title, children, style } = props;

    return (
        <Card className={className} style={style}>
            <CardHeader title={title}/>
            <CardBody>
                <ErrorBoundary> 
                    { children }
                </ErrorBoundary>
            </CardBody>
        </Card>
    );
}

export default SelectorConfigCard