import React from 'react'
import ErrorBoundary from 'src/app/components/ErrorBoundary'
import { Card } from 'src/metronic/components/Card'
import { CardBody } from 'src/metronic/components/Card/CartBody'
import { CardHeader } from 'src/metronic/components/Card/CartHeader'

export function withUploadingTableCard(UploadingTable: React.ComponentType<unknown>, title: string, className: string, name: string) {
    const CardWrappedUploadingTable: React.ComponentType<{style?: React.CSSProperties}> = props => {
        return (
            <Card className={className} style={props.style}>
                <CardHeader title={title}/>
                <CardBody>
                    <ErrorBoundary> 
                        <UploadingTable {...props}/>
                    </ErrorBoundary>
                </CardBody>
            </Card>
        );
    }

    if(process.env.NODE_ENV !== 'production') {
        CardWrappedUploadingTable.displayName = name
    }

    return CardWrappedUploadingTable;
}
