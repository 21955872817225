import { formatDateString } from '../helpers/formatDateString';

type SeasonReleaseProps = {
    release: string|null|undefined,
}

function SeasonRelease(props: SeasonReleaseProps) {
    const { release } = props;

    if(release != null) {
        return (
            <div className='season-release'>
                { formatDateString(release) }
            </div>
        );
    }

    return null;
}


export default SeasonRelease