import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSerialFilesContext } from '../../../../../contexts/SerialFilesContext'
import { EpisodeInput } from 'src/api/graphql/types'
import { Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import { useAddEpisodeMutation } from '../hooks/useAddEpisodeMutation'
import { useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { isNull } from 'src/util/isNull'
import { NotificationVariants, useNotification } from 'src/app/contexts/NotificationContext'
import useEscapeCloseListener from '../../../../../hooks/useEscapeCloseListener'
import { isErrorArray } from './../helpers/isErrorArray';
import { isEpisodeArray } from '../helpers/isEpisodeArray'

type EpisodeAddModalBodyProps = {
    closeCallback: () => void
}

export interface AddEpisodeForm {
    count: number,
    name: string,
    duration: number|undefined,
    description: string|undefined,
}

const validationSchema = Yup.object({
    count: Yup
        .number()
        .min(1, 'Номер сезона должно быть не меньше 1-го')
        .max(20, 'Максимальное число одновременно добавляемых серий 20')
        .required('Номер сезона не должен быть пустым'),
    name: Yup
        .string()
        .min(3, 'Название сезона должно состоять минимум из 3-х символов')
        .required('Название сезона не должно быть пустым'),
    duration: Yup
        .number()
        .min(0)
        .optional(),
    description: Yup
        .string()
        .optional()
});

export function formatInput(values: AddEpisodeForm, parentID: number, languageID: number): EpisodeInput {
    const {name, duration, description} = values;

    return {
        name,
        parentID,
        languageID,
        duration,
        description
    }
}

function EpisodeAddModalBody(props: EpisodeAddModalBodyProps) {
    const { closeCallback } = props;

    useEscapeCloseListener(closeCallback);

    const serialFilesContext = useSerialFilesContext();

    const [episodesLength, activeSeason, refetchTrigger] = React.useMemo(() => {
        if( isNull(serialFilesContext) ) {
            throw new Error('EpisodeAddModalBody: serialFilesContext could not be null');
        }
        
        return [
            serialFilesContext.getAllEpisodesResult.data.length,
            serialFilesContext.activeSeason,
            () => {
                const refetchTrigger = serialFilesContext.getAllEpisodesResult.refetch;
                if(refetchTrigger !== undefined) {
                    refetchTrigger()
                } else {
                    console.error('EpisodeAddModalBody', 'refetch function')
                }
            }
        ]
    }, [serialFilesContext]);

    const addEpisodeCallback = useAddEpisodeMutation(
        () => {
            refetchTrigger();
            closeCallback();
        }
    );
    const {setNotification} = useNotification();

    const contentAddContext = useContentEditContext();
    const { languageID } = React.useMemo(() => {
        return {
            languageID: contentAddContext.basicInfo.language,
        }
    }, [contentAddContext]);

    const formik = useFormik<AddEpisodeForm>({
        initialValues: {
          count: 1,
          name: 'Серия',
          duration: 0, 
          description: ''
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            try {
                const result = await addEpisodeCallback(
                    formatInput(values, activeSeason, languageID),
                    values.count,
                    episodesLength
                );

                if( result !== null ) {
                    if(isErrorArray(result)) {
                        const names = result.map( i => i.message);
                        setNotification({
                            message: `Ошибка при добавлении: ${names.join(', ')}`,
                            variant: NotificationVariants.error
                        });
                    }

                    if( isEpisodeArray(result) ) {
                        const messages = result.map( i => i);
                        setNotification({
                            message: `Успешно добавлено: ${messages.join(', ')}`,
                            variant: NotificationVariants.success
                        });
                    }
                }
                
            } catch (error) {
                setNotification({
                    message: `Неизвестная ошибка: ${(error as Error).message}`,
                    variant: NotificationVariants.error
                });
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='count'
                    type='number'
                    name='count'
                    label='Количество'
                    value={formik.values.count}
                    onChange={formik.handleChange}
                    error={formik.touched.count && Boolean(formik.errors.count)}
                    helperText={formik.touched.count && formik.errors.count}
                    // className={ `${getFieldCSSClasses(formik.touched['duration'], formik.errors['duration'])}` }
                />
            </div>
            
            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='name'
                    name='name'
                    label='Наименование'
                    type='text'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </div>

            <div className='mb-5'>
                <TextField
                    disabled={formik.isSubmitting}
                    variant='outlined'
                    fullWidth
                    id='duration'
                    name='duration'
                    label='Продолжительность'
                    type='text'
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    error={formik.touched.duration && Boolean(formik.errors.duration)}
                    helperText={formik.touched.duration && formik.errors.duration}
                />
            </div>
            
            <div className='d-flex justify-content-end'>
                <Button color='primary' variant='primary' type='submit' disabled={formik.isSubmitting}>
                    Добавить
                </Button>
            </div>
        </form>
    );
}

export default EpisodeAddModalBody