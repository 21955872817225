import React from 'react'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import SelectorsListSelect from 'src/app/components/ContentService/SelectorsListSelect'
import useGetAllGenresQuery from 'src/app/hooks/query/useGetAllGenres'
import useGetAllTagsQuery from 'src/app/hooks/query/useGetAllTags'
import ListSearchInput from 'src/app/components/ContentService/ListSearchInput'
import { useFilmsQueryParams } from '../../hooks/useFilmsQueryParams'
import { useFilterChangehandler } from '../../hooks/useFilterChangehandler'
import { defaultMovieQueryParams } from '../../utils/defaultParams'

interface FilmsFilterProps {
  listLoading: boolean
}

export type FilmFilterFormValues = Pick<MoviesQueryParams, 'tagID'|'genreID'|'searchText'>

const prepareFilter = (queryParams: MoviesQueryParams, values: FilmFilterFormValues) => {
  const { tagID, genreID, searchText } = values;
  const newQueryParams = { 
    ...queryParams,
    tagID,
    genreID,
    searchText
  };
  return newQueryParams;
};

export function FilmsFilter(props: FilmsFilterProps) {
  const {listLoading} = props;
  /**
   * getAllGenres and getAllTags queries
   */
  const genresQueryResult = useGetAllGenresQuery();
  const tagsQueryResult = useGetAllTagsQuery();

  const queryParams = useFilmsQueryParams();
  const filterChangeHandler = useFilterChangehandler();

  const submitHandler = (values: FilmFilterFormValues) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!isEqual(newQueryParams, queryParams)) {
      filterChangeHandler(values);
    }
  };

  /**
   * Genres and tags array that a added into them {id: 0, name: 'All'} object for default value
   */
  const genres = genresQueryResult.data ? [ {id: 0, name: 'Все жанры'}, ...genresQueryResult.data ] : [{id: 0, name: 'Все жанры'}];
  const tags =  tagsQueryResult.data ? [ {id: 0, name: 'Все Тэги'}, ...tagsQueryResult.data ] : [{id: 0, name: 'Все Тэги'}];

  /**
   * Genres and Tags select element's loading indicators
   */
  const genreFieldLoading = listLoading || genresQueryResult.loading;
  const tagFieldLoading = listLoading || tagsQueryResult.loading;

  const initialValues = React.useMemo(() => {
    return {
      tagID: queryParams.tagID,
      genreID: queryParams.genreID,
      searchText: queryParams.searchText
    }
  }, [queryParams]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {({
        values,
        setValues,
        handleSubmit,
        handleBlur,
        setFieldValue,
        getFieldProps
      }) => (
        <form onSubmit={handleSubmit} className='form form-label-right'>
          <div className='form-group row'>
            <div className='col-lg-2'>
              <SelectorsListSelect
                selectors={ genres }
                disabled={genreFieldLoading}
                className='form-control'
                name='genreID'
                placeholder='Filter by Genre'
                // TODO: Change this code
                onChange={(e: any) => {
                  /* If search field has input, clear other selectors 
                  * without resubmissions of the form due in service layers
                  * there are different calls for searchText and other filters
                  */
                  setFieldValue('searchText', '');
                  setFieldValue('genreID', +e.target.value);
                  handleSubmit();
                }}
                onBlur={handleBlur}
                value={values.genreID}
              />
              <small className='form-text text-muted'>
                Отфильтровать по <b>Жанру</b> 
              </small>
            </div>
            <div className='col-lg-2'>
            <SelectorsListSelect
                selectors={ tags }
                disabled={tagFieldLoading}
                className='form-control'
                name='tagID'
                placeholder='Filter by Tag'
                onChange={(e: any) => {
                  /* If search field has input, clear other selectors 
                  * without resubmissions of the form due in service layers
                  * there are different calls for searchText and other filters
                  */
                  setFieldValue('searchText', '');
                  setFieldValue('tagID', +e.target.value);
                  handleSubmit();
                }}
                onBlur={handleBlur}
                value={values.tagID}
              />
              <small className='form-text text-muted'>
                Отфильтровать по  <b>Тэгу</b>
              </small>
            </div>
            <div className='col-lg-2'>
              <ListSearchInput
                field={ getFieldProps('searchText') }
                className='form-control'
                name='searchText'
                placeholder='Введите название'
                onBlur={handleBlur}
                value={values.searchText}
                changeHandler={(value: string) => {
                  /* If search field has input, clear other selectors 
                  * without resubmissions of the form due in service layers
                  * there are different calls for searchText and other filters
                  */
                  setValues({
                    tagID: defaultMovieQueryParams.tagID,
                    genreID: defaultMovieQueryParams.genreID,
                    searchText: value
                  });
                }}
                submitHandler={handleSubmit}
              />
              <small className='form-text text-muted'>
                <b>Поиск</b> по фильмам
              </small>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
