import React from 'react'
import { useHistory } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { comment } from 'src/util/comment'
import { createSerialsRouteLink } from '../utils/createSerialsRouteLink'
import { defaultSearchParams } from '../utils/defaultParams'

export function useSetInitialQueryParams() {
    const { 
        navigationLinks: {serialsLink} 
    } = useNavigationLinksContext();

    const history = useHistory();

    React.useEffect(() => {
        const constraition = serialsLink === '';

        if(constraition) {
            comment('useSetInitialParams on SerialsList','Setted initial params');
            history.push(
                createSerialsRouteLink(defaultSearchParams)
            )
        }
    }, [serialsLink, history]);    
}