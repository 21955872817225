import { gql } from '@apollo/client'
import { Response } from '../types'

export interface DeleteGenreToMovieMutationArgs {
    movieID: number,
    genreID: number,
} 
export interface DeleteGenreToMovieMutationData {
    DeleteGenreToMovie: Response
}

export const DELETE_GENRE_TO_MOVIE_MUTATION = gql`
    mutation DeleteGenreToMovieMutation( $movieID: Int!, $genreID: Int!) {
        DeleteGenreToMovie( movieID: $movieID, genreID: $genreID ) {
           status
           message
        }
    }
`