import { SelectorInput } from 'src/api/graphql/types'

/**
 * Sanitazes by removing spaces and formates it to LowerCase then return the result
 */
const sanitizeString = (str: string): string => {
    return str.replaceAll(/\s/g,'').toLowerCase();
}

/**
 * Checks if source contains in target, but before checking both arguments
 * were sanitazed by removing spaces and formated to LowerCase
 */
export const compareForInclude = (target:string, source: string): boolean => {
    return sanitizeString(target).includes(sanitizeString(source));
}

/**
 *  create new object that contains formatted name field
 */
export const normalizeSelector = (selector: SelectorInput): SelectorInput => {
    return Object.assign(selector, {
        name: toUpperCamelCase(selector.name)
    });
}
/**
 * Formats selector name to UpperCamelCase format then returns it
 */
 export const toUpperCamelCase = (str: string): string => {
    if(str.length === 0 ) return str;
    
    const first = str[0];
    const other = str.slice(1);

    return first.toUpperCase().concat(other);
}
