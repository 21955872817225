import { gql } from '@apollo/client'
import { EpisodeInput, Episode} from '../types'

export interface UpdateEpisodeMutationArgs {
    episodeID: number,
    input: EpisodeInput
} 
export interface UpdateEpisodeMutationData {
    UpdateEpisode: Episode
}

export const UPDATE_EPISODE_MUTATION = gql`
    mutation UpdateEpisodeMutation( $episodeID: Int!, $input: EpisodeInput! ) {
        UpdateEpisode(episodeID: $episodeID, input: $input) {
            id
            name
            duration
            status
        }
    }
`