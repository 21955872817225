import { gql } from '@apollo/client'
import { Country, CountryInput } from '../types'

export interface AddCountryMutationArgs {
    input: CountryInput
} 
export interface AddCountryMutationData {
    AddCountry: Country
}

export const ADD_COUNTRY_MUTATION = gql`
    mutation AddCountryMutation( $input: CountryInput! ) {
        AddCountry(input: $input) {
            id
            name
        }
    }
`