import { ApolloError } from '@apollo/client'
import { useFormik } from 'formik'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { SendToTranscodeArgs } from 'src/api/graphql/mutations/SendToTranscode'
import { QUALITY_TYPES, Response, TranscodeRequestFile } from 'src/api/graphql/types'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { SelectedReadyToSendtem } from '../../../contexts/ReadyToSendFilesContext'
import { useSendToTranscodeMutation } from '../../../hooks/useSendToTranscodeMutation'

type ProcessingSenderBarProps = {
    ids: Map<number, SelectedReadyToSendtem>,
    typeID: CONTENT_TYPES,
    qualityID: QUALITY_TYPES,
    entities: Array<TranscodeRequestFile>,
    setIds: React.Dispatch<React.SetStateAction<Map<number, SelectedReadyToSendtem>>>
}

type SendToProcessingResult = {
    loading: boolean;
    data: Response | undefined;
    error: ApolloError | undefined;
}

const prepareToSend = (
    id: number,
    selectedItem: SelectedReadyToSendtem, 
    typeID: CONTENT_TYPES, 
    qualityID: QUALITY_TYPES,
    entities: Array<TranscodeRequestFile> 
): SendToTranscodeArgs => {
    const { urgent } = selectedItem;
    const item = entities.find(i => i.MovieId === id)

    if(item) {
        return {
            fileID: item.FileId,
            movieID: item.MovieId,
            typeID,
            qualityID,
            urgent
        }
    } else throw new Error('MovieId could not be undefined in prepareToSend to proccessing')
}

const createQueue = (
    ids: Map<number, SelectedReadyToSendtem>, 
    entities: Array<TranscodeRequestFile>,
    typeID: CONTENT_TYPES,
    qualityID: QUALITY_TYPES,
    sendToProcessing: (args: SendToTranscodeArgs, refetch: boolean) => Promise<SendToProcessingResult>,
): Array<() => Promise<SendToProcessingResult>> => {
    const mutatationQueue = Array.from(ids.keys()).map((id, index, array) => async () => {
        /** Asserted cause of item in current id definitely exist*/
        const selectedItem = ids.get(id) as SelectedReadyToSendtem;
        const preparedValues = prepareToSend(id, selectedItem, typeID, qualityID, entities);
        const refetch = index + 1 === array.length;
        return await sendToProcessing(preparedValues, refetch);
    });

    return mutatationQueue;
}

function useMultipleSendToTranscodeMutation(
    ids: Map<number, SelectedReadyToSendtem>, 
    entities: Array<TranscodeRequestFile>, 
    typeID: CONTENT_TYPES,
    qualityID: QUALITY_TYPES,
) {
    const sendToProcessing = useSendToTranscodeMutation();
    
    return async () => {
        const executionQueue = createQueue(ids, entities, typeID, qualityID, sendToProcessing);
        for(const sendTo of executionQueue) {
            await sendTo();
        }
    }
}

function ProcessingSenderBar(props: ProcessingSenderBarProps) { 
    const { ids, typeID, qualityID, entities, setIds } = props;

    const sendMultipleMutations = useMultipleSendToTranscodeMutation(ids, entities, typeID, qualityID);

    const formik = useFormik({
        initialValues: {},
        onSubmit: async () => {
            await sendMultipleMutations().finally(() => {
                setIds(new Map<number, SelectedReadyToSendtem>());
            });
        },
      });

    if(ids.size > 0) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} className='mt-2 w-100'>
                    <span>Выбраных элементов: {ids.size}</span> 
                    <button
                        type="submit"
                        className="btn btn-light-primary font-weight-bolder font-size-sm p-2"
                    >
                        {
                            formik.isSubmitting 
                            ? <Spinner size='sm' animation={'border'} className=" mr-3"/>
                            : <i style={{width: 10}} className="fa fa-stream mr-3"></i>
                        }
                        <span style={{fontSize: 10, width: 30}}>Отправить</span> 
                    </button>
                </div>
            </form>
        )
    }

    return null;
}

export default ProcessingSenderBar