import { ApolloError, FetchResult, useMutation } from '@apollo/client'
import { UpdateMovieMutationArgs, UpdateMovieMutationData, UPDATE_MOVIE_MUTATION } from 'src/api/graphql/mutations/UpdateMovieMutation'

export interface useUpdateMovieMutationArgs {
    completeHandler?: (data: UpdateMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use UpdateMovie mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useUpdateMovieMutation(args: useUpdateMovieMutationArgs = {}): ( { movieID, input }: UpdateMovieMutationArgs ) => Promise<FetchResult<UpdateMovieMutationData>> {
    const [ UpdateMovie ] = useMutation<UpdateMovieMutationData, UpdateMovieMutationArgs>( UPDATE_MOVIE_MUTATION, {
        onCompleted: data => {
            args.completeHandler && args.completeHandler(data);
        },
        onError: error => {
            args.errorHandler && args.errorHandler(error);
        },
        errorPolicy: 'all'
    } );
    
    return async function({ movieID, input}: UpdateMovieMutationArgs) {
        return await UpdateMovie({
            variables: {
                movieID,
                input
            }
        });
    }
}

export default useUpdateMovieMutation