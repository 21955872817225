import { Route, Switch } from 'react-router-dom'
import NotificationBar from 'src/app/components/NotificationBar'
import AuthRoute from 'src/app/routes/AuthRoute'
import MainRoute from 'src/app/routes/MainRoute'
import { Layout } from 'src/metronic/components/Layout'
import Logout from 'src/app/components/Logout'
import { HeaderSelectors } from 'src/app/components/HeaderSelectors'
import SidebarMenuContent from 'src/app/components/SidebarMenuContent'

const ROUTES_LIST = {
    ROOT: '/',

    AUTH: '/auth',
    LOGIN: '/auth/login',

    LOGOUT: '/logout',
    ERROR: '/error',

    DASHBOARD: '/dashboard',

    CONTENT_SERVICE: '/content-service',
        MAIN_PAGE: '/content-service/main-page',
        ADD_CONTENT: '/content-service/add',
        EDIT_CONTENT: '/content-service/edit',
        FILM: '/content-service/films',
        SERIAL: '/content-service/serials',
        PARTICIPANT: '/content-service/participants',
        UPLOADING: '/content-service/uploading',
        PROCESSING_FILM: '/content-service/processing/film',
        PROCESSING_SERIAL: '/content-service/processing/serial',
        SELECTORS_CONFIG: '/content-service/selectors-config',

    CLIENT_SERVICE: '/client-service',
        ABONENTS: '/client-service/abonents',
        USERS: '/client-service/users',
        COMPLAINTS: '/client-service/complaints'
}

function RootRoute() {
    const { ROOT, AUTH, LOGOUT } = ROUTES_LIST;
   
    return (
        <>
            <NotificationBar/>

            <Switch>
                
                <Route path={AUTH}>
                    <AuthRoute />
                </Route>            

                <Route path={LOGOUT}>
                    <Logout/>
                </Route>
                
                <Route path={ROOT}>
                    <Layout 
                        selectors={<HeaderSelectors/>}
                        sidebarMenuContent={SidebarMenuContent}
                    >
                        <MainRoute/>
                    </Layout>
                </Route>
            </Switch>
        </>
    );
}

export default RootRoute
export { ROUTES_LIST }