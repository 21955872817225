import React from 'react'
import { ExternalID } from 'src/uploader/types'
import { QUALITY_TYPES, QUALITY_TYPES_LABEL, TranscodeRequestFile, TRANSCODE_STATUS, URGENT_TYPES } from 'src/api/graphql/types'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { Checkbox } from '@material-ui/core'
import { Context, useContextSelector } from 'use-context-selector'
import { ReadyToSendFilesContextValue, SelectedReadyToSendtem } from '../contexts/ReadyToSendFilesContext'
import UrgentSelect from '../../UrgentSelect'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { SendToTranscodeArgs } from 'src/api/graphql/mutations/SendToTranscode'
import { useSendToTranscodeMutation } from '../hooks/useSendToTranscodeMutation'
import clsx from 'clsx'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { useMutation } from '@apollo/client'
import { ChangeMovieStatusMutationArgs, ChangeMovieStatusMutationData, CHANGE_MOVIE_STATUS_MUTATION } from 'src/api/graphql/mutations/ChangeMovieStatus'
import { GET_FILES_BY_PROCESS_STATUS_QUERY } from 'src/api/graphql/queries/GetFilesByProcessStatus'

export interface MovieLike {
    id: ExternalID,
    title: string,
    cache?: Record<string, object & {name: string}>,
}

export function ProcessOrderFormatter(cellContent: unknown, row: TranscodeRequestFile, rowIndex: number) {
    return (
        <div>
            <span>{rowIndex + 1}</span>
        </div>
    );
}

export function ProcessCheckFormatter(
    cellContent: unknown, 
    row: TranscodeRequestFile, 
    rowIndex: number, 
    extra: [
        
        setSelectedIds: React.Dispatch<React.SetStateAction<Map<number, SelectedReadyToSendtem>>>, 
        limit: number
    ]
) {
    const id = row.MovieId;
    const [setSelectedIds, limit] = extra;
    return (
        <ProcessCheckRenderer id={id} setSelectedIds={setSelectedIds} limit={limit}/>
    );
}

function ProcessCheckRenderer(
    props: {
        id: number, 
        setSelectedIds: React.Dispatch<React.SetStateAction<Map<number, SelectedReadyToSendtem>>>, 
        limit: number
    }
) {
    const { id, setSelectedIds, limit } = props;
    const [checked, setChecked] = React.useState(false);

    const handleChange = React.useCallback<(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void>( (e, checked) => {
        setSelectedIds( prev => {
            if(checked) {
                if(prev.size < limit) {
                    setChecked(checked);
                    // Set Default values for initialization
                    prev.set(id, {urgent: URGENT_TYPES.NON_URGENT});
                }
            } else {
                prev.delete(id);
                setChecked(checked);
            }
            
            return new Map(prev);
        });
    }, [id, limit, setChecked, setSelectedIds]);

    return (
        <div >
            <Checkbox
                checked={checked}
                onChange={handleChange}
                color='primary'
                value={checked}
                style={{width: 10, height: 10}}
                inputProps={{
                    'aria-label': 'primary checkbox'
                }}
            />
        </div>
    )
}

export function ProcessIDFormatter(cellContent: number, row: TranscodeRequestFile, rowIndex: number) {
    return (
        <div>
            <span style={{fontSize: 12}} className='label label-lg label-light-primary label-inline'>{cellContent}</span>
        </div>
    );
}

export function QualityIDFormatter(cellContent: number, _row: TranscodeRequestFile, _rowIndex: number) {
    const getQualityText = (qualityID: QUALITY_TYPES) => {
        switch (qualityID) {
            case QUALITY_TYPES.QUALITY_480: return QUALITY_TYPES_LABEL.QUALITY_480
            case QUALITY_TYPES.QUALITY_1080: return QUALITY_TYPES_LABEL.QUALITY_1080
            default: throw new Error(`Unknown qualityID=${qualityID} in QualityIDFormatter`)
        }
    }

    return (
        <div>
            <span style={{fontSize: 8}} className='label label-lg label-inline'>
                { getQualityText(cellContent) }
            </span>
        </div>
    );
}

export function ProcessUrgentFormatter(
    cellContent: unknown, 
    row: TranscodeRequestFile, 
    rowIndex: number, 
    extra: {
        context: Context<ReadyToSendFilesContextValue>,
        qualityID: QUALITY_TYPES
    }
) {
    const id = row.MovieId;
    const {qualityID, context } = extra;

    return (
        <ProcessUrgentRendererMemo context={context} id={id}  qualityID={qualityID}/>
    )
}

function ProcessUrgentRenderer(props: {context: Context<ReadyToSendFilesContextValue>, id: number, qualityID: QUALITY_TYPES}) {
    const {id, context, qualityID} = props;
    const selectedIds = useContextSelector(context, v => v.selectedIds[qualityID]);
    const setSelectedIds = useContextSelector(context, v => v.setSelectedIds[qualityID]!);
    const active = selectedIds.has(id);

    const optionChangeHandler = React.useCallback<(option: URGENT_TYPES) => void>((option) => {
        setSelectedIds( prev => {
            const item = prev.get(id);
            if(item) {
                prev.set(id, {...item, urgent: option});
            }
            
            return new Map(prev);
        })
    }, [id, setSelectedIds]);

    if(active) {
        const selectedItem = selectedIds.get(id) as SelectedReadyToSendtem;

        return (
            <div >
                <UrgentSelect
                    value={selectedItem.urgent}
                    optionChangeHandler={optionChangeHandler}
                />
            </div>
        );
    } 

    return null;
}

const ProcessUrgentRendererMemo = React.memo(ProcessUrgentRenderer)

export function ProcessNameFormatter(cellContent: string, row: TranscodeRequestFile, rowIndex: number) {
    return <span>{cellContent}</span>;
}

export function ProcessResendActionFormatter(
    cellContent: unknown, 
    row: TranscodeRequestFile, 
    rowIndex: number,
    extraData: {typeID: CONTENT_TYPES}
) {
    const {FileId, MovieId, qualityID} = row;
    const {typeID} = extraData;

    return <ProcessResendActionRenderer 
        movieID={MovieId} 
        fileID={FileId} 
        urgent={URGENT_TYPES.NON_URGENT}
        typeID={typeID}
        qualityID={qualityID}
    />
}

function ProcessResendActionRenderer(props: SendToTranscodeArgs) {
    const sendToTranscode = useSendToTranscodeMutation();
    const [loading, setLoading] = React.useState(false);

    const resendHandler = React.useCallback(() => {
        setLoading(true);
        sendToTranscode(props, true).finally(() => setLoading(false));
    }, [sendToTranscode, props]);

    return (
        <button
            title='Переотправить'
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            onClick={resendHandler}
        >
            <span className={clsx('svg-icon svg-icon-md svg-icon-primary', loading && 'rotating-icon')}>
                <SVG
                    description='Переотправить'
                    title='Переотправить'
                    src={toAbsoluteUrl('/media/svg/icons/General/Update.svg')}
                />
            </span>
        </button>
    );
}

export function ProcessActivateActionColumnFormatter(
    _cellContent: unknown, 
    {MovieStatus, MovieId}: TranscodeRequestFile, 
    _rowIndex: number,
    typeID: CONTENT_TYPES
): JSX.Element {
    return (
        <ProcessActivateActionColumnRenderer 
            status={MovieStatus}
            movieID={MovieId}
            typeID={typeID}
        />
    );
}
function ProcessActivateActionColumnRenderer(
    props: {
        status?: ACTIVATION_STATUS;
        movieID: number;
        typeID: CONTENT_TYPES
    }
): JSX.Element {
    const {status, movieID, typeID} = props;

    const [changeStatus] = useMutation<
        ChangeMovieStatusMutationData, 
        ChangeMovieStatusMutationArgs
    >(CHANGE_MOVIE_STATUS_MUTATION, {
        refetchQueries: [{
            query: GET_FILES_BY_PROCESS_STATUS_QUERY,
            variables: {typeID, status: TRANSCODE_STATUS.FULLFILLED}
        }]
    });

    const statusChangeHandler = () => {
        if(status !== undefined) {
            changeStatus({
                variables: {
                    movieID,
                    status: status === ACTIVATION_STATUS.ACTIVE 
                        ? ACTIVATION_STATUS.INACTIVE 
                        : ACTIVATION_STATUS.ACTIVE,
                    typeID
                }
            });
        }
    };

    return (
        <button
            title='Сменить статус'
            className='btn btn-icon btn-light btn-sm m-0'
            onClick={statusChangeHandler}
        >   
            <span className={clsx('svg-icon', 'svg-icon-md', {
                'svg-icon-success': status === ACTIVATION_STATUS.ACTIVE,
                'svg-icon-danger': status === ACTIVATION_STATUS.INACTIVE
            })}>
                <SVG
                    description='Сменить статус'
                    title='Сменить статус'
                    src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')}
                />
            </span>
        </button>
    );
}