import { gql } from '@apollo/client'
import { Response } from '../types'

export interface AddGenresToMovieMutationArgs {
    movieID: number,
    genreID: number[]
} 
export interface AddGenresToMovieMutationData {
    AddGenresToMovie: Response
}

export const ADD_GENRES_TO_MOVIE_MUTATION = gql`
    mutation AddGenresToMovieMutation( $movieID: Int!, $genreID: [Int!]! ) {
        AddGenresToMovie(movieID: $movieID, genreID: $genreID) {
            status
            message
        }
    }
`