import React from 'react'
import { TableChangeType } from 'react-bootstrap-table-next'
import { useHistory, useLocation } from 'react-router'
import { GetHandlerTableChangeProps } from 'src/metronic/core/TablePaginationHelpers'
import { createComplaintsRouteLink } from '../utils/createComplaintsRouteLink'
import { defaultComplaintsQueryParams } from '../utils/defaultParams'

export function useTableChangeHandler() {
    const history = useHistory();
    const { search } = useLocation();

    const tableChangeHandler = React.useCallback( (
        type: TableChangeType,
        payload: GetHandlerTableChangeProps
    ) => {
        const searchParams = new URLSearchParams(search);

        const { page, sizePerPage, sortField, sortOrder } = payload;

        const pageNumber = String(page);
        const defaultPageNumber = String(defaultComplaintsQueryParams.pageNumber);
        const pageSize = String(sizePerPage);
        const defaultPageSize = String(defaultComplaintsQueryParams.pageNumber);
        const prevPageSize = searchParams.get('pageSize') || defaultPageSize;

        switch (type) {
            case 'sort': {
                searchParams.set('sortField', sortField);
                searchParams.set('sortOrder', sortOrder);
                history.push(
                    createComplaintsRouteLink(searchParams)
                );
                break;
            }
            case 'pagination': {
                if(pageSize !== prevPageSize) {
                    searchParams.set('pageNumber', defaultPageNumber);
                    searchParams.set('pageSize', pageSize);
                } else {
                    searchParams.set('pageNumber', pageNumber);
                    searchParams.set('pageSize', pageSize);
                }
                
                history.push(
                    createComplaintsRouteLink(searchParams)
                );
                break;
            }
            default:
                break;
        }
    }, [search, history] );

    return tableChangeHandler;
}
