import React from 'react'

/**
 * This component used for custom renderer for edit
 * for reason of react-bootstrap-table2-editor JS only lib
 * props type is 'any' 
 */
class TextEditRenderer extends React.Component<any, {active: boolean}> { 
    input: HTMLInputElement|null = null;
  
    constructor(props: any) {
        super(props);
        this.inputKeyDownHandler = this.inputKeyDownHandler.bind(this);
        this.inputBlurHandler = this.inputBlurHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.state = {active: true};
    }
  
    /**
     * On mount catch focus on input and fill it with value
     */
    componentDidMount(){
        const input = this.input;
        if(input) {
            input.value = this.props.value
            input.focus();
        }
    }
  
    /**
     * If updates catch focus again if state is still active
     */
    componentDidUpdate() {
        this.input && this.state.active && this.input.focus(); 
    }
  
    getValue() {
        return this.input ? this.input.value : this.props.value;
    }
  
    // On blur make state inactive
    inputBlurHandler(e: React.FocusEvent) {
        e.stopPropagation();
        this.setState({active: false});
    }
  
    // Handle 'Enter' and 'Escape' key downs
    inputKeyDownHandler(e: React.KeyboardEvent){
        e.stopPropagation();
    
        if(e.key === 'Enter' && this.input) {
            const { onUpdate } = this.props;
            onUpdate( this.getValue() );
    
            this.setState({ active: false });
            this.input.blur();
            // alert(`Enter. props.value: ${this.props.value}, props.initialVal: ${this.props.initialValue}, input.value: ${this.input.value}`);
        }
    
        if(e.key === 'Escape' && this.input){
            this.input.value = this.props.initialValue
            this.setState({ active: false });
            this.input.blur();
        }
    }
  
    clickHandler(e: React.MouseEvent){
        e.stopPropagation();
        this.setState({ active: true })
    }
  
    render() {
        const { editorProps, colName, value } = this.props;
        const { active } = this.state;
    
        return (
            <>
                <input
                    name={colName}
                    { ...editorProps }
                    ref={ node => this.input = node }
                    className='form-control'
                    style={{width: 150, height: 30, borderRadius: 3, display: active ? 'block' : 'none'}}
                    onKeyDown={this.inputKeyDownHandler}
                    onBlur={this.inputBlurHandler}
                />
        
                < span style={{display: active ? 'none' : 'block', cursor: 'pointer'}} onDoubleClick={this.clickHandler}>{value}</span>
            </>
        );
    }
}

export default TextEditRenderer