import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthGateway } from 'src/app/contexts/AuthenticationContext'
import { useSplashScreen } from 'src/app/contexts/SplashScreenContext'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { clearToken } from 'src/util/token'

function Logout(): React.ReactElement {
    const { authResult, setAuthResult } = useAuthGateway();
    const setSplashScreenVisible = useSplashScreen(); 
    setSplashScreenVisible(true);

    useEffect(() => {
        setAuthResult && setAuthResult({user: null});
        clearToken();
        return () => setSplashScreenVisible(false);
    }, [setAuthResult, setSplashScreenVisible]);

    return (
        authResult.user ? <></> : <Redirect to={ROUTES_LIST.LOGIN}/>
    )
}

export default Logout