import withCard from './../../../hocs/withCard'
import SelectedFileHeader from './components/SelectedFileHeader'
import SelectedFile from '../../../SelectedFile'
import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'
import { withSerialFileInfo } from './components/hocs/withSerialFileInfo'

const FileInfoInjectedSerial = withSerialFileInfo(SelectedFile);

const CardWrappedSelectedfileSerial = withCard(
    FileInfoInjectedSerial, 
    <CardHeaderTitle>
        <SelectedFileHeader/>
    </CardHeaderTitle>,
    'player-card'
);

export default CardWrappedSelectedfileSerial