import axios from 'axios'
import { getToken } from 'src/util/token'
import { IMAGE_API_ENDPOINT, VIDEO_API_ENDPOINT } from './urls/endpoints'

export const axiosImageApiInstance = axios.create({
    baseURL: IMAGE_API_ENDPOINT,
    headers: {
        Authorization: getToken(),
        'Content-Type': 'multipart/form-data'
    },
    validateStatus: status => status < 500
})

export const axiosVideoUploadInstance = axios.create({
    baseURL: VIDEO_API_ENDPOINT,
    headers: {
        // 'Authorization': TOKEN,
        'Content-Type': 'multipart/form-data'
    },
    validateStatus: status => status < 500
})