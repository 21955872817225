import {useApolloClient} from '@apollo/client'
import React from 'react'
import {
    SearchParticipantByNameArgs,
    SearchParticipantByNameData,
    SEARCH_PARTICIPANTS_BY_NAME_QUERY
} from 'src/api/graphql/queries/SearchParticipantByName'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'

interface UseGetDirectorsByName {
    (name: string): Promise<Array<{value: number, label: string}>>;
}

export const useGetDirectorsByName = (): UseGetDirectorsByName => {
    const client = useApolloClient();
    const getDirectorsByName = React.useCallback(async (name: string) => {
        const result = await client.query<SearchParticipantByNameData, SearchParticipantByNameArgs>({
            query: SEARCH_PARTICIPANTS_BY_NAME_QUERY,
            variables: {
                name
            }
        });

        if(result.data === undefined) throw new Error('Error on attempting to find actors by name');

        return result
            .data
            .SearchParticipantByName
            .filter( i => i.roleID === PARTICIPANT_TYPES.DIRECTOR )
            .map( i => ({label: i.name, value: i.id}) );
    }, [client]);

    return getDirectorsByName;
}