import { gql } from '@apollo/client'
import { MovieFileInput, Response } from '../types'

export interface AddTrailerFileMutationArgs {
    input: MovieFileInput
} 
export interface AddTrailerFileMutationData {
    AddTrailerFile: Response
}

export const ADD_TRAILER_FILE_MUTATION = gql`
    mutation AddTrailerFileMutation( $input: MovieFileInput! ) {
        AddTrailerFile(input: $input) {
            status
            message
        }
    }
`