import { ApolloError, useMutation } from '@apollo/client'
import { DeleteCountryToMovieMutationArgs, DeleteCountryToMovieMutationData, DELETE_COUNTRY_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/DeleteCountryToMovie';

type useDeleteCountryToMovieMutationArgs = {
    completeHandler?: (data: DeleteCountryToMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use DeleteCountryToMovie mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useDeleteCountryToMovieMutation(args: useDeleteCountryToMovieMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;

    const [ DeleteCountry ] = useMutation<DeleteCountryToMovieMutationData, DeleteCountryToMovieMutationArgs>(DELETE_COUNTRY_TO_MOVIE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function( movieID: number, countryID: number ) {
        return await DeleteCountry({
            variables: {
                movieID,
                countryID
            },
            // refetchQueries: [{query: GET_MOVIE_BY_ID_QUERY, variables: {id: movieID}}]
        });
    };
}

export default useDeleteCountryToMovieMutation