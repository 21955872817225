import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { ParticipantFilterFormValues } from '../types'
import { createParticipantsRouteLink } from '../utils/createParticipantsRouteLink'
import { defaultParticipantsQueryParams } from '../utils/ParticipantsConfigAssets'

export function useFilterChangehandler() {
    const history = useHistory();
    const { search } = useLocation();

    const filterChangeHandler = React.useCallback((payload: ParticipantFilterFormValues) => {
        const { searchText } = payload;

        const searchParams = new URLSearchParams(search);
        /** pre process */
        searchParams.set('pageNumber', String(defaultParticipantsQueryParams.pageNumber));

        /** process */
        searchParams.set('searchText', searchText);

        /** post process */
        if(searchText === '') {
            searchParams.delete('searchText');
        }

        history.push(
            createParticipantsRouteLink(searchParams)
        );
    }, [history, search]);

    return filterChangeHandler;
}