import { gql } from '@apollo/client'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'
import { Response } from '../types'

export interface AddPositionToParticipantArgs {
    participantID: number, 
    positionID: PARTICIPANT_TYPES,
} 
export interface AddPositionToParticipantData {
    AddPositionToParticipant: Response
}

export const ADD_POSITION_TO_PARTICIPANT_MUTATION = gql`
    mutation AddPositionToParticipantMutation( $participantID: Int!, $positionID: Int!) {
        AddPositionToParticipant(participantID: $participantID, positionID: $positionID) {
            status
            message
        }
    }
`