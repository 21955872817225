import { useMutation } from '@apollo/client'
import { AddTagMutationArgs, AddTagMutationData, ADD_TAG_MUTATION } from 'src/api/graphql/mutations/AddTagMutation'
import { Tag, TagInput } from 'src/api/graphql/types'
import { AddCompleteHandler, AddErrorHandler, AddMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

export function useAddTag(completeHandler?: AddCompleteHandler<Tag>, errorHandler?: AddErrorHandler): AddMutationHookResult<TagInput> {

    const [ AddInitialCallback ] = useMutation<AddTagMutationData, AddTagMutationArgs>(ADD_TAG_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.AddTag as Tag);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const addCallback = async (input : {name: string}) => {
        await AddInitialCallback({
            variables: { input }
        });
    }

    return [ addCallback ]
}