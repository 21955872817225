import { gql } from '@apollo/client'
import { PARTICIPANT_TYPES } from 'src/app/assets/ParticipantTypes'

export interface AddParticipantsToMovieMutationArgs {
    movieID: number,
    participantID: number[],
    positionID: PARTICIPANT_TYPES
} 
export interface AddParticipantsToMovieMutationData {
    AddParticipantsToMovie: Response,
}

export const ADD_PARTICIPANTS_TO_MOVIE_MUTATION = gql`
    mutation AddParticipantsToMovieMutation( $movieID: Int!, $participantID: [Int!]!, $positionID: Int!) {
        AddParticipantsToMovie(movieID: $movieID, participantID: $participantID, positionID: $positionID ) {
            status
            message
        }
    }
`