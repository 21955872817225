import { QUALITY_TYPES, QUALITY_TYPES_LABEL } from "src/api/graphql/types";
import { withQualityReadyToSendTable } from "../../hocs/withQualityReadyToSendTable"
import ReadyToSendTableBase from './components/ReadyToSendTableBase'

export const ReadyToSendTable480p = withQualityReadyToSendTable (
    ReadyToSendTableBase,
    QUALITY_TYPES.QUALITY_480,
    QUALITY_TYPES_LABEL.QUALITY_480
);
export const ReadyToSendTable1080p = withQualityReadyToSendTable (
    ReadyToSendTableBase,
    QUALITY_TYPES.QUALITY_1080,
    QUALITY_TYPES_LABEL.QUALITY_1080
);