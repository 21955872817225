import React, { useMemo } from 'react'
import objectPath from 'object-path';
import AnimateLoading from 'src/metronic/components/Header/components/AnimateLoading';
import { useHtmlClassService } from 'src/metronic/core/MetronicLayout';
import { HeaderMenuWrapper } from './components/HeaderMenuWrapper';
import { Topbar } from './components/Topbar';
import HeaderProvider from './contexts/HeaderContext';

type HeaderProps = {
  selectors: React.ReactElement
}

function Header({selectors}: HeaderProps ) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
          headerClasses: uiService.getClasses("header", true),
          headerAttributes: uiService.getAttributes("header"),
          headerContainerClasses: uiService.getClasses("header_container", true),
          menuHeaderDisplay: objectPath.get(
            uiService.config,
            "header.menu.self.display"
          )
        };
      }, [uiService]);

    return (
        <HeaderProvider selectors={selectors}>
          {/*begin::Header*/}
          <header
            className={`header ${layoutProps.headerClasses}`}
            id="kt_header"
            {...layoutProps.headerAttributes}
          >
            {/*begin::Container*/}
            <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
              <AnimateLoading />
              {/*begin::Header Menu Wrapper*/}
              {layoutProps.menuHeaderDisplay ? <HeaderMenuWrapper/> : <div/>}
              {/*end::Header Menu Wrapper*/}
    
              {/*begin::Topbar*/}
              <Topbar />
              {/*end::Topbar*/}
            </div>
            {/*end::Container*/}
          </header>
          {/*end::Header*/}
        </HeaderProvider>
      );
}

export default Header