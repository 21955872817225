import React from 'react'
import SelectedFileFilm from './components/SelectedFileFilm'
import FilmElement from './components/FilmElement'
import { ContentEditProviderState, useContentEditContext } from 'src/app/components/ContentService/EditContent/contexts/ContentEditContext'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { useChangeFilmStatusMutation } from './hooks/useChangeFilmStatusMutation'
import { useGetMovieFileInfo } from './hooks/useGetMovieFileInfo'
import { useGetFilmFileSource } from './hooks/useGetFilmFileSource'

function EditFilesFilm() {
    const contentEditContext = useContentEditContext();
    const setEditContextBasicInfo = React.useMemo(() => {
        return contentEditContext.setInfo;
    }, [contentEditContext]);

    /**Injecting processData to the film */
    const getMovieFilesResult = useGetMovieFileInfo(contentEditContext.id);
    const [processData, processStatus] = getMovieFilesResult.data;

    const data = React.useMemo(() => {
        return {
            id: contentEditContext.id,
            name: contentEditContext.basicInfo.name,
            status: contentEditContext.status,
            duration: contentEditContext.duration,
            uploaded: processData ? processData.uploaded : undefined,
            processStatus: processStatus ? processStatus : undefined
        }
    }, [contentEditContext, processData, processStatus]);

    /**Injecting playerData to the film */
    const getMovieFileSourceResult = useGetFilmFileSource(contentEditContext.id);
    const { data: playerData } = getMovieFileSourceResult;

    const completeHandler = React.useCallback(() => {
        setEditContextBasicInfo && setEditContextBasicInfo(
            (prev: ContentEditProviderState) => ({
                ...prev,
                status: prev.status === ACTIVATION_STATUS.ACTIVE 
                    ? ACTIVATION_STATUS.INACTIVE 
                    : ACTIVATION_STATUS.ACTIVE
            })
        )
    }, [setEditContextBasicInfo]);

    const changeMovieStatusCallback = useChangeFilmStatusMutation(completeHandler);

    const statusChangeHandler = React.useCallback<React.MouseEventHandler>( e => {
        e.stopPropagation();
        const status = data.status === ACTIVATION_STATUS.ACTIVE 
            ? ACTIVATION_STATUS.INACTIVE 
            : ACTIVATION_STATUS.ACTIVE;
        changeMovieStatusCallback(data.id, status);
    }, [changeMovieStatusCallback, data.status, data.id]);

    return (
        <>
            <FilmElement data={data} isActive statusChangeHandler={statusChangeHandler }/>
            <SelectedFileFilm processData={processData} playerData={playerData}/>
        </>
    );
}

export default EditFilesFilm