import { gql } from '@apollo/client'
import { Language, LanguageInput } from '../types'

export interface AddLanguageMutationArgs {
    input: LanguageInput
} 
export interface AddLanguageMutationData {
    AddLanguage: Language
}

export const ADD_LANGUAGE_MUTATION = gql`
    mutation AddLanguageMutation( $input: LanguageInput! ) {
        AddLanguage(input: $input) {
            id
            name
        }
    }
`