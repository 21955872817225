export const defaultMovieQueryParams: MoviesQueryParams = {
    tagID: 0,
    genreID: 0,
    pageSize: 25,
    pageNumber: 1,
    searchText: '',
    sortField: 'movieID', 
    sortOrder: 'asc'
}

export const defaultSearchParams = new URLSearchParams([
    ['tagID', '0'],
    ['genreID', '0'],
    ['pageSize', '25'],
    ['pageNumber', '1'],
    ['sortField', 'movieID'],
    ['sortOrder', 'asc']
])