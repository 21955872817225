import { useMutation } from '@apollo/client'
import { UpdateLanguageMutationArgs, UpdateLanguageMutationData, UPDATE_LANGUAGE_MUTATION } from 'src/api/graphql/mutations/UpdateLanguageMutation'
import { Language } from 'src/api/graphql/types';
import { UpdateCompleteHandler, UpdateErrorHandler, UpdateMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

type UpdateCallbackArgs = { 
    id: number,
    name: string,
    abbreviated: string
}

export function useUpdateLanguage(completeHandler?: UpdateCompleteHandler<Language>, errorHandler?: UpdateErrorHandler): UpdateMutationHookResult<Language> {
    const [ UpdateInitialCallback ] = useMutation<UpdateLanguageMutationData, UpdateLanguageMutationArgs>(UPDATE_LANGUAGE_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.UpdateLanguage);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const UpdateCallback = async (args: UpdateCallbackArgs ) => {
        const { id, name, abbreviated } = args;
        await UpdateInitialCallback({
            variables: { 
                languageID: id, 
                input: {
                    name,
                    abbreviated: abbreviated
                }
            }
        });
    }

    return [ UpdateCallback ]
}