import React from 'react'
import { VideoFileUploadContext } from 'src/app/contexts/VideoFileUploadContext'
import { useContextSelector } from 'use-context-selector'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import { useApolloClient } from '@apollo/client'
import { MovieLike, UploadIDFormatter, UploadNameFormatter, UploadOrderFormatter, UploadRemoveActionFormatter } from '../../helpers/UploadFormatter'
import { ExternalID } from 'src/uploader/types'

function FullfilledTable() {
    const fullfilled = useContextSelector(VideoFileUploadContext, v => v.fullfilled);
    const clearFullfilled = useContextSelector(VideoFileUploadContext, v => v.clearFullfilled);
    // const clearAllFullfilled = useContextSelector(VideoFileUploadContext, v => v.clearAllFullfilled);
    
    const client = useApolloClient();
    const normalizedCache  = client.cache.extract();

    const data = React.useMemo<MovieLike[]>(() => { 
        return Array.from(fullfilled.keys()).map( (id: ExternalID) => {
            const fullfilledItem = fullfilled.get(id, undefined);
            const title = fullfilledItem ? fullfilledItem.title : 'Неизвестный';
            return {
                id,
                cache: normalizedCache as Record<string, object & {name: string}>,
                title
            }
        });
    }, [fullfilled, normalizedCache]);

    const columns: ColumnDescription<MovieLike>[] = [
        {
            dataField: '#',
            text: '#',
            isDummyField: true,
            formatter: UploadOrderFormatter,
        },
        {
            dataField: 'id',
            text: 'ID',
            formatter: UploadIDFormatter,
        },
        {
            dataField: 'name',
            text: 'Название',
            formatter: UploadNameFormatter,
        },
        {
            dataField: 'action',
            text: 'Действия',
            formatter: UploadRemoveActionFormatter,
            formatExtraData: clearFullfilled,
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '50px',
            },
        },
    ];
      
    const entities = data ?? [];

    return (
        <>
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                data={entities}
                columns={columns}
                keyField={'id'}
            />
        </>
    );
}

export default FullfilledTable