import { gql } from '@apollo/client'
import { QUALITY_TYPES, Response, URGENT_TYPES } from '../types'

export interface SendToTranscodeArgs {
    movieID: number,
    fileID: number,
    typeID: number,
    qualityID: QUALITY_TYPES,
    urgent: URGENT_TYPES
} 
export interface SendToTranscodeData {
    SendToTranscode: Response
}

export const SEND_TO_TRANSCODE_MUTATION = gql`
    mutation SendToTranscodeMutation( $movieID: Int!, $fileID: Int!, $typeID: Int!, $qualityID: Int!, $urgent: Int!) {
        SendToTranscode(movieID: $movieID, fileID: $fileID, typeID: $typeID, qualityID: $qualityID, urgent: $urgent) {
            status
            message
        }
    }
`