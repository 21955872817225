import BasicModal from '../../../BasicModal'
import EpisodeAddModalBody from './components/EpisodeAddModalBody'

type EpisodeAddModalProps = {
    show: boolean,
    onHide: () => void
}

function EpisodeAddModal(props: EpisodeAddModalProps) {
    const { show, onHide } = props;

    return (
        <BasicModal show={show} onHide={onHide}>
            <EpisodeAddModalBody closeCallback={onHide}/>
        </BasicModal>
    );
}

export default EpisodeAddModal