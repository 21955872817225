import { CardHeaderTitle } from 'src/metronic/components/Card/CartHeader/CartHeaderTitle'
import withCard from '../../../hocs/withCard'
import SelectedFile from '../../../SelectedFile'
import SelectedFileHeader from './components/SelectedFileHeader'

const CardWrappedSelectedfileFilm = withCard(
    SelectedFile, 
    <CardHeaderTitle>
        <SelectedFileHeader/>
    </CardHeaderTitle>,
    'player-card player-film'
);

export default CardWrappedSelectedfileFilm