import { useMutation } from '@apollo/client'
import { UpdateGenreMutationArgs, UpdateGenreMutationData, UPDATE_GENRE_MUTATION } from 'src/api/graphql/mutations/UpdateGenreMutation'
import { Genre } from 'src/api/graphql/types';
import { UpdateCompleteHandler, UpdateErrorHandler, UpdateMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

type UpdateCallbackArgs = { 
    id: number,
    name: string,
}

export function useUpdateGenre(completeHandler?: UpdateCompleteHandler<Genre>, errorHandler?: UpdateErrorHandler): UpdateMutationHookResult<Genre> {
    const [ UpdateInitialCallback ] = useMutation<UpdateGenreMutationData, UpdateGenreMutationArgs>(UPDATE_GENRE_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.UpdateGenre);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const UpdateCallback = async (args: UpdateCallbackArgs ) => {
        const { id, name } = args;
        await UpdateInitialCallback({
            variables: { 
                genreID: id, 
                input: {
                    name
                }
            }
        });
    }

    return [ UpdateCallback ]
}