import React from 'react'
import SVG from 'react-inlinesvg'
import { SORT_TYPES } from 'src/app/assets/SortTypes'
import { ImageSize, Language, Movie } from 'src/api/graphql/types'
import { toAbsoluteUrl } from 'src/util/url'
import { CONTENT_TYPES } from 'src/app/assets/ContentTypes'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { Link } from 'react-router-dom'

export const FilmSerialStatusCssClasses = ['success', 'danger', 'info', ''];
export const FilmSerialStatusTitles = ['публичный', 'приватный'];
export const defaultSorted: any = [{ dataField: 'id', order: 'asc' }];
export const sizePerPageList = [
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];
export const initialFilter = {
  filter: {
    lastName: '',
    firstName: '',
    email: '',
    ipAddress: ''
  },
  sortOrder: 'asc', // asc||desc
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

/**
 * Used to reformate default behaviour of bootstrap-table-next
 * based on 'sortField' and 'sortOrder' params to single 'sort' param
 * for sending to the server
 *  * id - id of the element
 *  * name - name of the element
 *  * release - release data of the element
 *  * ratingIMDB - ratingIMDB of the element
 *  * status - status accessiblity (public or private) of the element
 * @param sortOrder ASC or DESC sorting 
 * @param sortField Field name from table 
 * @returns SORT_TYPES enum type
 */
export const getSortParamForFilmSerial = (sortField: string, sortOrder: string): number => {
  switch (sortField) {
    case 'id':
      return SORT_TYPES.BY_ID
    case 'name':
      return SORT_TYPES.BY_NAME
    case 'ratingIMDB': 
      return SORT_TYPES.BY_IMDB_DESC
    case 'status':
      return sortOrder === 'asc' ? SORT_TYPES.BY_STATUS_ASC : SORT_TYPES.BY_STATUS_DESC
    case 'release':
      return sortOrder === 'asc' ? SORT_TYPES.BY_RELEASE_ASC : SORT_TYPES.BY_RELEASE_DESC
    default:
      return 0;
  }
}

function StatusColumnFormatter(cellContent: number, row: Movie) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      FilmSerialStatusCssClasses[cellContent]
    } label-inline`;
  };

  return (
    <span className={getLabelCssClasses()}>
      { FilmSerialStatusTitles[cellContent] }
    </span>
  );
}

function ProcessingStatusColumnFormatter(cellContent: any, row: Movie) {
  return (
    <span>
      { cellContent }
    </span>
  );
}

function RatingColumnFormatter(cellContent: number, row: Movie) {
  const rating = cellContent.toPrecision(2);

  return (
    <span>
      { rating }
    </span>
  );
}

function OrderColumnFormatter(cellContent: number, row: Movie, rowIndex: number, {getOrder}: { getOrder: (index: number) => void} ) {
  const orderNumber = getOrder(rowIndex+1);

  return (
    <span>
      { orderNumber }.
    </span>
  );
}

function LanguageColumnFormatter(cellContent: Language, row: Movie) {
  return (
    <div className='d-flex'>
      <span
        className={`label label-${
          ''
        } mr-2`}
      >
        <span className={'label label-lg label-light'}>
          {cellContent.abbreviated}
        </span>
      </span>
      &nbsp;
      <span className={`font-bold font-${''}`}>
        {cellContent.name}
      </span>
    </div>
  );
}

function DateColumnFormatter(cellContent: string, row: Movie) {
  const classes = 'label label-lg label-light label-inline';
  const date = new Date(cellContent);

  return (
    <>
      <span className={classes}>
        { date.toLocaleDateString() }
      </span>
    </>
  )
}

function ImageColumnFormatter(cellContent: ImageSize, row: Movie, rowIndex: number, extra: CONTENT_TYPES) {
  const src = cellContent.standard.url;
  const link = getContentLink(extra, row.id);

  return (
    <Link to={link}>
        <div className='d-flex'>
          <img src={src} alt='film or serial avatar' className='list-element-avatar-image'/>
        </div>
    </Link>
  );
}

const getContentLink = (type: CONTENT_TYPES, id: number): string => {
  switch (type) {
    case CONTENT_TYPES.FILM: return `${ROUTES_LIST.EDIT_CONTENT}/${CONTENT_TYPES.FILM}/${id}`
    case CONTENT_TYPES.SERIAL: return `${ROUTES_LIST.EDIT_CONTENT}/${CONTENT_TYPES.SERIAL}/${id}`
    default: throw new Error('Unknown content type in Films/Serials list formatter')
  }
}

function ActionsColumnFormatter(
  cellContent: any,
  row: Movie,
  rowIndex: number,
  { moveToEditRoute }: { moveToEditRoute: (id: number) => void }
) {

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    moveToEditRoute(row.id)
  }

  return (
    <>
      <button
        title='Изменить'
        className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
        onClick={clickHandler}
      >
        <span className='svg-icon svg-icon-md svg-icon-primary'>
          <SVG
            description='Изменить'
            title='Изменить'
            src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
          />
        </span>
      </button>
      <> </>

    {/* Currently unused */}
      {/* <button
        title='Удалить'
        className='btn btn-icon btn-light btn-hover-danger btn-sm'
        onClick={() => alert(row.id)}
      >
        <span className='svg-icon svg-icon-md svg-icon-danger'>
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
        </span>
      </button> */}
    </>
  );
}

const filmsSerialsColumnFormatters = {
  StatusColumnFormatter,
  ImageColumnFormatter,
  ProcessingStatusColumnFormatter,
  LanguageColumnFormatter,
  RatingColumnFormatter,
  OrderColumnFormatter,
  DateColumnFormatter,
  ActionsColumnFormatter
}

export { filmsSerialsColumnFormatters }