import { gql } from '@apollo/client'
import { Participant, ParticipantInput } from '../types'

export interface AddParticipantMutationArgs {
    input: ParticipantInput, 
    positionID: number,
} 
export interface AddParticipantMutationData {
    AddParticipant: Participant
}

export const ADD_PARTICIPANT_MUTATION = gql`
    mutation AddParticipantMutation( $input: ParticipantInput!, $positionID: Int!) {
        AddParticipant(input: $input, positionID: $positionID) {
            id
            name
        }
    }
`