import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'

type EpisodeUploadStatusProps = {
    uploaded: boolean|undefined,
}

function EpisodeUploadStatus(props: EpisodeUploadStatusProps) {
    const { uploaded } = props;

    if(uploaded !== undefined) {
            return (
                <div className='film-status unselectable mb-2'>
                    <span className='svg-icon svg-icon-md svg-icon-primary mr-1'>
                        <SVG
                            description='Загруженные'
                            title='Загруженные'
                            src={toAbsoluteUrl('/media/svg/icons/Files/Upload.svg')}
                        />
                    </span>

                    <span>
                        { getTextFromStatus(uploaded) }
                    </span>
                </div>
            )
       
    }

    return null;
}

const getTextFromStatus = (status: boolean): string => {
    if(status === false) return 'Не загружено';
    else return 'Загружено';
}

export default EpisodeUploadStatus