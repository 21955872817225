import clsx from 'clsx'
import React from 'react'
import { VariableSizeList } from 'react-window'

export interface XTableCell<TData> {
    dataField: keyof TData
    title: string
    formatter: (cell: any, row: TData, index: number) => JSX.Element
}
export type XTableDescriptor<TData> = XTableCell<TData>[]


export interface XTableHeaderProps {
    titles: string[]
}
export interface XTableBodyProps<TData> {
    attributes: Pick<XTableCell<TData>, 'dataField'|'formatter'>[]
    payload: TData[]
}

function XTableHeader(props: XTableHeaderProps) {
    const {titles} = props;
    const headerContent = titles.map(
        (title, index) => (
            <th 
                key={`${title}#${index}`}
                tabIndex={0}
                aria-labelledby={title}
            >
                { title }
            </th>
        )
    );

    return (
        <thead>
            <tr>
                {
                   headerContent 
                }
            </tr>
        </thead>
    );
}


export interface XTableData<TData> {
    payload: TData[];
    attributes: Pick<XTableCell<TData>, 'dataField' | 'formatter'>[];
}

export interface XRowProps<TData> {
    data: XTableData<TData>
    index: number
    style: React.CSSProperties
}

function XRow<TData>(props: XRowProps<TData>) {
    const { index, style } = props;
    const { payload, attributes } = props.data;

    const rowContent = React.useMemo(() => {
        const row = payload[index];

        const mapDataToRow = (
            atr: Pick<XTableCell<TData>, "dataField" | "formatter">,
            i: number
        ) => {
            const cell = row[atr.dataField];
            return <td key={`${atr.dataField}-${i}`}>
                { atr.formatter(cell, row, index) }
            </td>
        }

        return attributes.map(mapDataToRow);
    }, [attributes, payload, index]);

    return (
        <tr style={style}>
            { rowContent }
        </tr>
    );
}

const XRowMemoized = (XRow);

function XTableBody<TData>(props: XTableBodyProps<TData>) {
    const { payload, attributes } = props;
    const outerWrap = React.useRef<HTMLElement>();
    const itemData = React.useMemo(() => {
        return {
            payload, 
            attributes
        }
    }, [payload, attributes]);

    return (
        <VariableSizeList<XTableData<TData>>
            itemCount={payload.length}
            itemData={itemData}
            itemSize={index => 30}
            height={400}
            width={'50%'}
            outerElementType={'table'}
            innerElementType={'tbody'}
            outerRef={outerWrap}
        >
            { XRowMemoized }
        </VariableSizeList>
    );
}

export interface XTableProps<TData> {
    payload: TData[]
    descriptors: XTableDescriptor<TData>
    tableClasses?: string
}

export default function XTable<TData>(props: XTableProps<TData>) {
    const { payload, descriptors, tableClasses } = props;

    const titles = React.useMemo(() => descriptors.map(desc => desc.title), [descriptors]);
    const attributes = React.useMemo(() => descriptors.map(
        desc => ({
            dataField: desc.dataField, 
            formatter: desc.formatter
        })
    ), [descriptors]);

    return <div className={clsx('x-table-wrap', tableClasses)}>
        <table className='x-table'>
            <XTableHeader titles={titles}/>
            <XTableBody attributes={attributes} payload={payload}/>
        </table>
    </div>
}