import { Image } from 'src/api/graphql/types'
import { ImageStandartSize } from '..'

export function createSrcFromImageSize(image: ImageStandartSize|null|undefined): string|undefined {
    if(image) {
        return extractSrcFromImage(image.standard);
    }
    return undefined;
}

export function extractSrcFromImage(image: Image|null|undefined): string|undefined {
    if(image) {
        return image.url
    }

    return undefined
}