import { ACTIVATION_STATUS } from "src/app/assets/ActivationStatus"
import { CONTENT_TYPES } from "src/app/assets/ContentTypes"

export type User = {
    id: number
    name: string
    roleId: number
}

export type AuthResponse = {
    authToken: string
    user: User
}

export type ErrorResponse = {
    message: string
    path: string[]
}

export type LoginInput = {
    username: string
    password: string
}

export type AuthToken = {
    token: string
}

export type Image = { 
    url: string
    width: number
    height: number
}

export type ImageSize = {
    default: Image
    high: Image
    medium: Image
    standard: Image
}

export type MovieImage = {
    Vertical: ImageSize|null
    HorizontalWithoutName: ImageSize|null
    HorizontalWithName: ImageSize|null
    Name: Image|null
}

export type Movie = {
    id: number
    name: string
    release: string
    age: number
    image: Partial<MovieImage>
    thumbnail: ImageSize
    description: string
    typeId: number
    parentId: number
    trailerUrl: string
    language: Language
    category: Category
    duration: number
    status: number
    addedDate?: string
    modifiedDate?: string
    ratingKP: number
    ratingIMDB: number
    tags: Tag[]
    genres: Genre[]
    countries: Country[]
    actors: Actor[]
    directors: Director[]
}

export type TVSeries = {
    id: number
    name: string
    release: string
    age: number
    thumbnail: ImageSize
    image: MovieImage
    description: string
    typeId: number
    category: Category
    parentId: number
    language: Language
    status: number
    addedDate: string
    modifiedDate: string
    ratingKP: number
    ratingIMDB: number
    tags: Tag[]
    genres: Genre[]
    countries: Country[]
}

export type Pagination = {
    TotalPage: number
    TotalCount: number
    CurrentPage: number
    Limit: number
}

export type MovieAndPage = {
    Movie: Movie
    Page: Pagination
}

export type Selector = {
    id: number
    name: string
}
export enum TagStatus {
    INACTIVE,
    ACTIVE
}
export type JSONImage = string
export type Genre = Selector
export type Category = Selector
export type Country = Selector
export type Tag = Selector & {
    status: TagStatus
}
export type Language = Selector & { 
    abbreviated: string 
}
export type Participant = Selector & { 
    image: JSONImage
    roleID: number
}
export type Actor = Participant
export type Director = Participant

export type SelectorInput =  {
    name: string
}

export type TagInput = SelectorInput
export type GenreInput = SelectorInput
export type CategoryInput = SelectorInput
export type CountryInput = SelectorInput
export type LanguageInput = SelectorInput & { abbreviated: string }
export type ParticipantInput = SelectorInput

export type Response = {
    status: string
    message: string
}

export type MovieInput = {
    name: string
    age: number
    language: number
    description: string
    category: number
    release: string
    ratingKP: number
    ratingIMDB: number
    parentId?: number
    trailerUrl?: string
}

export type TVSeriesInput = {
    name: string
    age: number
    language: number
    description: string
    category: number
    release: string
    ratingKP: number
    ratingIMDB: number
    parentId?: number
    trailerUrl?: string
}

export type SelectorsInfoInput = {
    countries: number[]
    genres: number[]
    tags: number[]
    categories: number[]
    actors: number[]
    languages: number[]
}

export type GetArgumentsInput = {
    typeID: number
    page: number
    limit: number
    sort: number|0
}

export type Season = {
    id: number
    name: string
    typeID: number
    parentID: number
    status: ACTIVATION_STATUS
    release?: string|null
    addedDate?: string
    modifiedDate?: string
}

export type SeasonInput = {
    name: string
    parentID: number
    languageID: number
    release?: string
}

export type Episode = {
    id: number
    name: string
    typeID: number
    parentID: number
    duration: number
    description: string
    status: ACTIVATION_STATUS
    addedDate?: string
    modifiedDate?: string
}

export type EpisodeInput = {
    name: string
    parentID: number
    languageID: number
    duration?: number
    description?: string
}

export enum TYPES {
    Genre = 'Genre',
    Episode = 'Episode',
    Category = 'Category',
    Season = 'Season',
    Movie = 'Movie',
}

export type MovieFileInput = {
    filePath: string
    movieID: number
    typeID: number
}

export type Host = {
    ip: string
    phone: string
    code: number
    active: number
    type: number
    name: string
}

export type HostAndPagination = {
    hosts: Host[]
    Pagination: Pagination
}

export type Region = {
    id: number
    name: string
}

export enum TRANSCODE_STATUS {
    NON,
    WAITING,
    PENDING,
    TRANSCODE_ERROR,
    FILE_STORAGE_ERROR,
    FULLFILLED,
}

export type TranscodeRequestFile = {
    RequestId: number
    MovieId: number
    Name: string
    FileId: number
    qualityID: QUALITY_TYPES
    MovieStatus?: ACTIVATION_STATUS
}

export enum QUALITY_TYPES {
    QUALITY_480 = 2,
    QUALITY_1080 = 4
}

export enum QUALITY_TYPES_LABEL {
    QUALITY_480 = '480p',
    QUALITY_1080 = '1080p'
}

export enum URGENT_TYPES {
    NON_URGENT,
    URGENT
}
export type ProcessedFiles = {
    Url: string
    Type: string
    Quality: string
}

export type Sources = {
    Sources: ProcessedFiles[]
}

export type FileInfo = {
    UploadStatus: boolean
    ProcessStatusLQ: TRANSCODE_STATUS
    ProcessStatusHQ: TRANSCODE_STATUS
    FileID: number
    MovieID: number
    Source: Sources
}

export type FileID = number

export type SeriesFileInfo = {
    UploadStatus: FileID
    ProcessStatusLQ: TRANSCODE_STATUS
    ProcessStatusHQ: TRANSCODE_STATUS
}

export type TapeSelector = {
    Value: string
    Title: string
}

export type TapeType = TapeSelector
export type TapeSort = TapeSelector
export type TapeSize = TapeSelector
export enum TapeStatus {
    INACTIVE,
    ACTIVE
}

export type Tape = {
    id: number
    TitleTk: string
    TitleRu: string
    genreID: number
    position: number
    categoryType: TapeType['Value']
    GenreName: string
    sortValue: TapeSort['Value']
    itemSizeValue: TapeSize['Value']
    active: TapeStatus
}

export enum ComplaintStatus {
    WAITING = 1,
    PROCESSING = 2,
    FINISHED = 3
}

export type Complaint = {
    id: number
    userID: number
    typeID: CONTENT_TYPES
    movieID: number
    movieName: string
    complaintsTitle: string
    complaintsDescription: string
    startDate: string
    endDate: string | null
    status: ComplaintStatus
}