import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { SendToTranscodeArgs } from 'src/api/graphql/mutations/SendToTranscode'
import { QUALITY_TYPES, QUALITY_TYPES_LABEL, URGENT_TYPES } from 'src/api/graphql/types'
import UrgentSelect from 'src/app/components/ContentService/UrgentSelect'
import { ProcessData } from 'src/app/components/ContentService/EditContent/components/EditFiles/types'
import { useSendToTranscodeMutation } from '../hooks/useSendToTranscodeMutation'

function ProcessingSendCard(props: Omit<ProcessData, 'qualities'> & Pick<SendToTranscodeArgs, 'qualityID'>) {
    const {typeID, movieID, fileID, qualityID, uploaded} = props;
    const [loading, setLoading] = React.useState(false);
    const [urgent, setUrgent] = React.useState<URGENT_TYPES>(URGENT_TYPES.NON_URGENT);
    const sendToTranscode = useSendToTranscodeMutation(typeID);

    const optionChangeHandler = React.useCallback((id: URGENT_TYPES) => {
        setUrgent(id);
    }, []);

    const submitHandler: React.FormEventHandler<HTMLFormElement> = event => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);

        sendToTranscode({typeID, movieID, fileID, qualityID, urgent}).finally(() => {
            setLoading(false);
        });
    };

    if(uploaded === false) return null;

    return (
        <form onSubmit={submitHandler} style={{border: '2px dotted', padding: 10}}>
            <span>
                {getTitleForQuality(qualityID)}
            </span>
            <UrgentSelect value={urgent} optionChangeHandler={optionChangeHandler} />
            <Button variant='primary' type='submit' className='mt-3' disabled={loading}>
                {loading && <Spinner animation='border' variant='light' size='sm'/>}
                <span>
                    обработать
                </span>
            </Button>
        </form>
    );
}

const getTitleForQuality = (quality: QUALITY_TYPES) => {
    switch (quality) {
        case QUALITY_TYPES.QUALITY_480: return <span>{QUALITY_TYPES_LABEL.QUALITY_480}</span>;
        case QUALITY_TYPES.QUALITY_1080: return <span>{QUALITY_TYPES_LABEL.QUALITY_1080}</span>;
    }
}

export default ProcessingSendCard