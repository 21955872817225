import React from 'react'
import { Context, createContext } from 'use-context-selector'
import { UpladerContextValue, UploaderContextProviderProps } from 'src/uploader/types'
import UploaderContextProviderRaw from 'src/uploader/components/UploaderContextProviderRaw'

type UploaderInstance = [ 
    React.FC<UploaderContextProviderProps>, 
    Context<UpladerContextValue>
]

const initialValue = {
    waiting: new Map(),
    pending: new Map(),
    fullfilled: new Map(),
    rejected: new Map(),
}

function createUploaderInstance(): UploaderInstance {
    const UploaderContext = createContext<UpladerContextValue>(initialValue);

    const UploaderContextProvider: React.FC<UploaderContextProviderProps> = props => (
        <UploaderContextProviderRaw UploaderContext={UploaderContext} {...props}/>
    );

    if(process.env.NODE_ENV !== 'production') {
        UploaderContextProvider.displayName = 'UploaderContextProvider';
        UploaderContext.displayName = 'UploaderContextEnhached'
    }

    return [UploaderContextProvider, UploaderContext];
}

export default createUploaderInstance