import { gql } from '@apollo/client'
import { AuthResponse } from '../types'
import { LoginInput } from 'src/api/graphql/types'

export interface LoginMutationArgs {
    input: LoginInput
} 
export interface LoginMutationData {
    login: AuthResponse
}

export const LOGIN_MUTATION = gql`
    mutation LoginMutation( $input: LoginInput! ) {
        login(input: $input) {
            authToken
            user {
                id
                name
                roleId
            }
        }
    }
`