import { useMutation } from '@apollo/client'
import { AddGenreMutationArgs, AddGenreMutationData, ADD_GENRE_MUTATION } from 'src/api/graphql/mutations/AddGenreMutation'
import { Genre, GenreInput } from 'src/api/graphql/types'
import { AddCompleteHandler, AddErrorHandler, AddMutationHookResult } from 'src/app/components/ContentService/SelectorConfig/contexts/SelectorConfigContext'

export function useAddGenre(completeHandler?: AddCompleteHandler<Genre>, errorHandler?: AddErrorHandler): AddMutationHookResult<GenreInput> {

    const [ AddInitialCallback ] = useMutation<AddGenreMutationData, AddGenreMutationArgs>(ADD_GENRE_MUTATION,{
        onCompleted: data => {
            completeHandler && completeHandler(data.AddGenre);
        },
        onError: error => {
            errorHandler && errorHandler(error)
        }
    });

    const addCallback = async (input : {name: string}) => {
        await AddInitialCallback({
            variables: { input }
        });
    }

    return [ addCallback ]
}