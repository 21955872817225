import { FileError } from 'react-dropzone'
import { useContextSelector } from 'use-context-selector'
import { useNotification, NotificationVariants } from 'src/app/contexts/NotificationContext'
import { UploadTypes, VideoFileUploadContext } from 'src/app/contexts/VideoFileUploadContext'
import { IMapLike } from 'src/uploader/types'
import { useIsHaveTrailerFileLazyQuery } from '../../../../../hooks/useIsHaveTrailerFileLazyQuery'

const useTrailerDropHandler = (id: number, name: string) => {
    const waiting = useContextSelector(VideoFileUploadContext, v => v.waiting);
    const pending = useContextSelector(VideoFileUploadContext, v => v.pending);
    const addFileToQueue = useContextSelector(VideoFileUploadContext, v => v.addFileToQueue);

    const { setNotification } = useNotification();

    const trailerExistCheckCallback = useIsHaveTrailerFileLazyQuery(id);
    
    const dropHandler = async (file: File|undefined, fileRejections: Array<{file: File, errors: FileError[]}>) => {
        if(file !== undefined) {
            if( hasNotInMaps(id, UploadTypes.TRAILER, waiting, pending) ) {
                try {
                    const movieExistInDB = await trailerExistCheckCallback();

                    if(!movieExistInDB && addFileToQueue){
                        addFileToQueue(file, id, UploadTypes.TRAILER, name);
                        setNotification({
                            message: name,
                            variant: NotificationVariants.success
                        });
                    } else {
                        setNotification({
                            message: `${name} уже есть на сервере`,
                            variant: NotificationVariants.warning
                        });
                    }
                } catch (error) {
                    setNotification({
                        message: 'Ошибка при попытке проверки файла' + name,
                        variant: NotificationVariants.error
                    });
                }
            } else {
                setNotification({
                    message: `${name} уже есть в списках загрузки файлов`,
                    variant: NotificationVariants.warning
                });
            }
        }

        if(fileRejections.length !== 0) {
            setNotification({
                message: 'Rejected files: ' + fileRejections.map(i => i.file.name).join(),
                variant: NotificationVariants.error
            });
        }
    }

    return dropHandler;
}

function hasNotInMaps(id: number, uploadType: UploadTypes, ...rest: IMapLike<unknown>[]) {
    return !rest.some( i => i.has(id, uploadType) );
}

export default useTrailerDropHandler