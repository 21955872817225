import { Chip, makeStyles, Theme } from '@material-ui/core'
import { FormikErrors } from 'formik'

type ErrorStatusBarProps<T> = {
    errors: FormikErrors<T>,
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(0.5),
    },
    chip: {
      margin: theme.spacing(0.2),
    },
  }));

function ErrorStatusBar<T>(props: ErrorStatusBarProps<T>){
    const classes = useStyles();
    const messages: string[] = Object.values(props.errors);
    return (
        <div className={`error-status-bar ${classes.root}`}>
            { 
                messages.map( message => {
                    const key = Math.random();
                    return <Chip className={`error-status-bar-message ${classes.root}`} key={key} label={message} />
                })  
            }
        </div>
    )
}

export default ErrorStatusBar