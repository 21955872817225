type SeasonNameProps = {
    name: string
}

function SeasonName(props: SeasonNameProps) {
    const { name } = props;

    return (
        <div className='season-name unselectable'>
            { name }
        </div>
    );
}


export default SeasonName