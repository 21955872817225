import SerialsCard from './components/ComplaintsCard'
import { useSaveComplaintsQueryParams } from './hooks/useSaveComplaintsQueryParams'
import { useSetInitialQueryParams } from './hooks/useSetInitialQueryParams'

function ComplaintsList() {
    useSetInitialQueryParams();
    useSaveComplaintsQueryParams();
    
    return (
        <SerialsCard/>
    );
}

export default ComplaintsList