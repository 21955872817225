import { gql } from '@apollo/client'
import { Country } from '../types'

export interface GetAllCountriesArgs {} 

export interface GetAllCountriesData {
    getAllCountries: Country[]
}

export const GET_ALL_COUNTRIES_QUERY = gql`
    query GetAllCountries {
        getAllCountries {
            id
            name
        }
    }
`