import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'src/util/url'
import { useContextSelector } from 'use-context-selector'
import { UploadTypes, VideoFileUploadContext } from 'src/app/contexts/VideoFileUploadContext'
import { ExternalID } from 'src/uploader/types'

export interface MovieLike {
    id: ExternalID,
    cache: Record<string, object & {name: string}>,
    title: string
}

export function UploadOrderFormatter(cellContent: unknown, row: MovieLike, rowIndex: number) {
    return (
        <div>
            <span>{rowIndex + 1}</span>
        </div>
    );
}

export function UploadIDFormatter(cellContent: ExternalID, row: MovieLike, rowIndex: number) {
    const basicInfo = JSON.parse(cellContent) as {movieID:number,uploadType:UploadTypes}
    return (
        <div>
            <span>{basicInfo.movieID}</span>
        </div>
    );
}

export function UploadNameFormatter(cellContent: unknown, row: MovieLike, rowIndex: number) {
    return <UploadNameRenderer data={row}/>
}


export function UploadProgressFormatter(cellContent: unknown, row: MovieLike, rowIndex: number) {
    return <UploadProgressRenderer id={row.id}/>
}

export function UploadCancelActionFormatter(
    cellContent: unknown, 
    row: MovieLike, 
    rowIndex: number, 
    cancelItem?: (id: number|ExternalID) => void
) {
    const cancelHandler: React.MouseEventHandler<HTMLButtonElement> = () => cancelItem && cancelItem(row.id);

    return <UploadCancelActionRenderer cancelHandler={cancelHandler}/>
}

export function UploadRemoveActionFormatter(
    cellContent: unknown, 
    row: MovieLike, 
    rowIndex: number, 
    removeItem?: (id: number|ExternalID) => void
) {
    const removeHandler: React.MouseEventHandler<HTMLButtonElement> = () => removeItem && removeItem(row.id);
    return <UploadRemoveActionRenderer removeHandler={removeHandler}/>
}

const UploadNameRenderer: React.ComponentType<{data: MovieLike}> = props => {
    /* Previously name was extracted from apollo cache, currently it's came from
      * uploader context. Previous one is apollo client dependant resolve: 
        const {id, cache } = props.data;
        const movieKey = getKeyFromParams(TYPES.Movie, id);
        const episodeKey = getKeyFromParams(TYPES.Episode, id);
        const parentIDKey = 'parentID';
        const movie = {
            name: '',
        }
        if(movieKey in cache) {
            movie.name = cache[movieKey].name
        }
        if(episodeKey in cache) {
            const episodeName = cache[episodeKey].name;

            if( parentIDKey in cache[episodeKey] ) {
                const seasonKey = getKeyFromParams( TYPES.Season, cache[episodeKey][parentIDKey] );
                const seasonName = cache[seasonKey].name;

                if(parentIDKey in cache[seasonKey]) {
                    const serialKey = getKeyFromParams( TYPES.Movie, cache[seasonKey][parentIDKey] );
                    const serialName = cache[serialKey].name;

                    movie.name = createNameForSeason(episodeName, seasonName, serialName);
                }
            } 
        }
    */
    
    return <div>
        <span>
            {props.data.title}
        </span>
    </div>;
}

const UploadProgressRenderer: React.ComponentType<{id: ExternalID}> = props => {
    const { id } = props;
    const pending = useContextSelector(VideoFileUploadContext, v => v.pending);
    const item = pending.get(id);

    return <div>
        <span>
            { item ? item.progress.toPrecision(3) : ''}%
        </span>
    </div>
}

const UploadCancelActionRenderer: React.ComponentType<{cancelHandler: React.MouseEventHandler<HTMLButtonElement>}> = props => {
    const { cancelHandler } = props;

    return (
        <button
            title='Изменить'
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            onClick={cancelHandler}
        >
            <span className='svg-icon svg-icon-md svg-icon-primary'>
                <SVG
                    description='Изменить'
                    title='Изменить'
                    src={toAbsoluteUrl('/media/svg/icons/General/Times.svg')}
                />
            </span>
        </button>
    )
}

const UploadRemoveActionRenderer: React.ComponentType<{removeHandler: React.MouseEventHandler<HTMLButtonElement>}> = props => {
   const {removeHandler} = props;

    return (
        <button
            title='Изменить'
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            onClick={removeHandler}
        >
            <span className='svg-icon svg-icon-md svg-icon-danger'>
                <SVG
                    description='Изменить'
                    title='Изменить'
                    src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')}
                />
            </span>
        </button>
    )
}