import { toAbsoluteUrl } from 'src/util/url'
import  SVG  from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive } from 'src/metronic/core/RouterHelpers'
import { ROUTES_LIST } from 'src/app/routes/RootRoute'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import PerfectScrollbar from 'react-perfect-scrollbar'

type SidebarMenuContentProps = {
  layoutProps: {
      ulClasses: string
  } & unknown
}

function SidebarMenuContent(props: SidebarMenuContentProps) {
  const { layoutProps } = props;
  const location = useLocation();
  const {navigationLinks} = useNavigationLinksContext()

  // const filmLink = `${ROUTES_LIST.FILM}?tagID=1&genreID=2&sortField=id&sortOrder=asc&pageNumber=1&pageSize=25&searchText=Something`
  const filmsLink     = ROUTES_LIST.FILM.concat('?').concat(navigationLinks.filmsLink);
  const serialsLink   = ROUTES_LIST.SERIAL.concat('?').concat(navigationLinks.serialsLink);
  const abonentsLink  = ROUTES_LIST.ABONENTS.concat('?').concat(navigationLinks.abonentsLink);
  const participantsLink = ROUTES_LIST.PARTICIPANT.concat('?').concat(navigationLinks.participantsLink)

  const getMenuItemActive = (url:string, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <>
        {/*begin::1 Level*/}
        <PerfectScrollbar
          component='ul'
          className={`menu-nav ${layoutProps.ulClasses}`}
          style={{height: '90vh'}}
        >
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.DASHBOARD, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.DASHBOARD}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Home.svg')} />
              </span>
              <span className='menu-text'>Главная</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}


          {/* Контент */}
          {/* begin::section */}
          <li className='menu-section '>
            <h4 className='menu-text'>Контент</h4>
            <i className='menu-icon flaticon-more-v2'></i>
          </li>
          {/* end:: section */}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.MAIN_PAGE, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.MAIN_PAGE}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Laptop-macbook.svg')} />
              </span>
              <span className='menu-text'>Главная приложений</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.ADD_CONTENT, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.ADD_CONTENT}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
              </span>
              <span className='menu-text'>Добавить контент</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.FILM, false)}`}
          >
            <NavLink className='menu-link' to={filmsLink}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/Media.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Фильмы</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.SERIAL, false)}`}
          >
            <NavLink className='menu-link' to={serialsLink}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/Media-folder.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Сериалы</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.UPLOADING, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.UPLOADING}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/Upload.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Загрузка на сервер</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.PROCESSING_FILM, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.PROCESSING_FILM}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/File-cloud.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Обработка Фильмов</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.PROCESSING_SERIAL, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.PROCESSING_SERIAL}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/Folder-cloud.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Обработка Сериалов</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.SELECTORS_CONFIG, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.SELECTORS_CONFIG}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/General/Settings-2.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Настройки селекторов</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/* Контент */}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.PARTICIPANT, false)}`}
          >
            <NavLink className='menu-link' to={participantsLink}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Files/User-folder.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Участники</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/* Контент */}


          {/* Клиенты */}
          {/* begin::section */}
          <li className='menu-section '>
            <h4 className='menu-text'>Клиенты</h4>
            <i className='menu-icon flaticon-more-v2'></i>
          </li>
          {/* end:: section */}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.COMPLAINTS, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.COMPLAINTS}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/General/Sad.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Жалобы</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.ABONENTS, false)}`}
          >
            <NavLink className='menu-link' to={abonentsLink}>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Earth.svg')} />
              </span>
              <span className='menu-text'>Абоненты</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive(ROUTES_LIST.USERS, false)}`}
          >
            <NavLink className='menu-link' to={ROUTES_LIST.USERS}>
              <span className='svg-icon menu-icon'>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Group.svg'
                  )}
                />
              </span>
              <span className='menu-text'>Пользователи</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/* Клиенты */}
          {/*end::1 Level*/}
        </PerfectScrollbar>
      </>
    </>
  );
}

export default SidebarMenuContent