import React from 'react'
import { comment } from 'src/util/comment'
import { PLAYER_CONFIG } from './helpers/PlayerConfig'
import { PlayerBase, PlayerProps } from './types'

// @ts-ignore // this lib is js only 
const fluidPlayer = window.fluidPlayer;

function Player({ playerData }: PlayerProps) {
    const self = React.useRef<HTMLVideoElement|null>(null);
    const player = React.useRef<PlayerBase|null>(null);
    const { Sources } = playerData;
    
    /** Initialization of player instance */
    // TODO: Check. May be uncorrected processing of the file when data is changing
    React.useEffect(() => {
        if(self.current !== null) {
            player.current = fluidPlayer( self.current, PLAYER_CONFIG );
        }
        return () => {
            if(player.current !== null) {
                player.current.destroy();
                comment('#Player Destroyed')
            }
        }
    }, []);

    return (
        <div className='player'>
            <video ref={self}>
                {
                    Sources
                    .filter(i => i.Url !== '')
                    .map( 
                        source => <source 
                            key={source.Url}
                            src={source.Url}
                            title={source.Quality}
                            type={source.Type} 
                        />
                    )
                }
            </video>
        </div>
    );
}

export default Player
