import React from 'react'
import { useHistory } from 'react-router'
import { useNavigationLinksContext } from 'src/app/contexts/NavigationLinkContext'
import { comment } from 'src/util/comment'
import { createComplaintsRouteLink } from '../utils/createComplaintsRouteLink'
import { defaultComplaintsSearchParams } from '../utils/defaultParams'

export function useSetInitialQueryParams() {
    const { 
        navigationLinks: { complaintsLink }
    } = useNavigationLinksContext();

    const history = useHistory();

    React.useEffect(() => {
        const constraition = complaintsLink === '';

        if(constraition) {
            comment('useSetInitialParams on ComplaintsList','Setted initial params');
            history.push(
                createComplaintsRouteLink(defaultComplaintsSearchParams)
            )
        }
    }, [complaintsLink, history]);    
}