import { gql } from '@apollo/client'
import { Tag } from '../types'

export interface GetAllTagsArgs {} 

export interface GetAllTagsData {
    getAllTags: Tag[]
}

export const GET_ALL_TAGS_QUERY = gql`
    query GetAllTags {
        getAllTags {
            id
            name
            status
        }
    }
`