import { ApolloError, useMutation } from '@apollo/client'
import { DeleteTagToMovieMutationArgs, DeleteTagToMovieMutationData, DELETE_TAG_TO_MOVIE_MUTATION } from 'src/api/graphql/mutations/DeleteTagToMovie';

type useDeleteTagToMovieMutationArgs = {
    completeHandler?: (data: DeleteTagToMovieMutationData) => void,
    errorHandler?: (error: ApolloError) => void
}

/**
 * use DeleteTagToMovie mutatation,
 * Additionally could execute completeHandler
 * and errorHandler
 */
function useDeleteTagToMovieMutation(args: useDeleteTagToMovieMutationArgs = {}) {
    const { completeHandler, errorHandler } = args;

    const [ DeleteTag ] = useMutation<DeleteTagToMovieMutationData, DeleteTagToMovieMutationArgs>(DELETE_TAG_TO_MOVIE_MUTATION, {
        onCompleted: data => {
            completeHandler && completeHandler(data);
        },
        onError: error => {
            errorHandler && errorHandler(error);
        }
    });

    return async function( movieID: number, tagID: number ) {
        return await DeleteTag({
            variables: {
                movieID,
                tagID 
            }
        });
    };
}

export default useDeleteTagToMovieMutation