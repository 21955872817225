import React from 'react'
import { Episode, QUALITY_TYPES, SeriesFileInfo } from 'src/api/graphql/types'
import EpisodeHeader from './components/EpisodeHeader'
import EpisodeName from './components/EpisodeName'
import EpisodeDuration from './components/EpisodeDuration'
import EpisodeActiveStatus from './components/EpisodeActiveStatus'
import { VideoUploader } from 'src/app/components/ContentService/VideoUploader'
import { withUploader } from 'src/app/components/ContentService/EditContent/hocs/withUploader'
import useEpisodeDropHandler from './hooks/useEpisodeDropHandler'
import { ACTIVATION_STATUS } from 'src/app/assets/ActivationStatus'
import { useChangeEpisodeStatusMutation } from './hooks/useChangeEpisodeStatusMutation'
import EpisodeUploadStatus from './components/EpisodeUploadStatus'
import EpisodeProcessStatus from './components/EpisodeProcessStatus'

type EpisodeBaseProps = {
    data: Episode,
    fileData: SeriesFileInfo|undefined,
    isActive: boolean,
    activateEpisodeCallback: (id: number) => void,
    episodeEditCallback: (e: React.MouseEvent, id: number) => void,
    innerRef?: React.RefObject<HTMLDivElement>
}

function EpisodeElement(props: EpisodeBaseProps) {
    const { data, fileData, isActive, activateEpisodeCallback, episodeEditCallback, innerRef } = props;
    const changeEpisodeStatusCallback = useChangeEpisodeStatusMutation(data.parentID);
    
    const selectHandler: React.MouseEventHandler = e => {
        activateEpisodeCallback(data.id);
    }

    const editHandler = React.useCallback<React.MouseEventHandler>( e => {
        episodeEditCallback(e, data.id);
    }, [episodeEditCallback, data.id]);

    const statusChangeHandler = React.useCallback<React.MouseEventHandler>( e => {
        e.stopPropagation();
        const status = data.status === ACTIVATION_STATUS.ACTIVE ? ACTIVATION_STATUS.INACTIVE : ACTIVATION_STATUS.ACTIVE;
        changeEpisodeStatusCallback(data.id, status);
    }, [changeEpisodeStatusCallback, data.status, data.id]);
    
    return (
        <div onClick={selectHandler} ref={innerRef}>
            <EpisodeHeader 
                editHandler={editHandler} 
                statusChangeHandler={statusChangeHandler}
                isActive={isActive}
                status={data.status}
            />
            <EpisodeName name={data.name}/>
            <EpisodeActiveStatus status={data.status} />
            <EpisodeUploadStatus statusData={fileData} />
            <EpisodeProcessStatus statusData={fileData} qualityID={QUALITY_TYPES.QUALITY_480}/>
            <EpisodeProcessStatus statusData={fileData} qualityID={QUALITY_TYPES.QUALITY_1080}/>
            <EpisodeDuration duration={data.duration} />
        </div>
    );
}

export default withUploader<EpisodeBaseProps>(
    EpisodeElement, 
    VideoUploader, 
    useEpisodeDropHandler, 
    'episode'
)