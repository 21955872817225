import { gql } from '@apollo/client'
import { Participant } from '../types'

export interface SearchParticipantByNameArgs {
    name: string
} 

export interface SearchParticipantByNameData {
    SearchParticipantByName: Participant[]
}

export const SEARCH_PARTICIPANTS_BY_NAME_QUERY = gql`
    query SearchParticipantByNameQuery($name: String!) {
        SearchParticipantByName( name: $name ) {
            name
            roleID
            id
            image
        }
    }
`