import { ApolloError, ApolloQueryResult, useLazyQuery } from '@apollo/client'
import { GetSeriesFileInfoArgs, GetSeriesFileInfoData, GET_SERIES_FILE_INFO_QUERY } from 'src/api/graphql/queries/GetSeriesFileInfo'
import { SeriesFileInfo } from 'src/api/graphql/types'

type GetSeriesFileInfoLazyResult = [
    (movieID: number[]) => void, 
    {
        loading: boolean, 
        data: SeriesFileInfo[], 
        error: ApolloError|undefined, 
        refetch?: ((variables?: Partial<GetSeriesFileInfoArgs> | undefined) => Promise<ApolloQueryResult<GetSeriesFileInfoData>>)
    }
]

export function useGetSeriesFileInfoLazyQuery(completeHandler?: (data: SeriesFileInfo[]) => void): GetSeriesFileInfoLazyResult {
    const [getSeriesFileInfo, queryResult ] = useLazyQuery<GetSeriesFileInfoData, GetSeriesFileInfoArgs>(GET_SERIES_FILE_INFO_QUERY, {
        onCompleted: data => {
            completeHandler && completeHandler(data.GetSeriesFileInfo)
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    });

    return [
        function(movieID: number[]) {
            return getSeriesFileInfo({
                variables: {movieID}
            });
        }, 
        {
            loading: queryResult.loading,
            data: queryResult.data ? queryResult.data.GetSeriesFileInfo : [],
            error: queryResult.error,
            refetch: queryResult.refetch
        }
    ];
}