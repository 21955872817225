import React from 'react'
import { UploaderType, UPLOADER_EVENTS } from '@rpldy/uploader'
import { extractPayload } from 'src/uploader/helpers/extractPayload'
import { AddedData, ExternalID, FileBatchItem, PendingFile, WaitingFile } from 'src/uploader/types'
import { createExternalID } from 'src/uploader/helpers/createExternalID'

const useBatchAddEffect = (
    uploader: UploaderType, 
    addedFiles: React.MutableRefObject<Map<ExternalID, AddedData>>, 
    waiting: Map<ExternalID, WaitingFile>,
    setWaiting: React.Dispatch<React.SetStateAction<Map<ExternalID, WaitingFile>>>,
    pending: Map<ExternalID, PendingFile>,
    setPending:  React.Dispatch<React.SetStateAction<Map<ExternalID, PendingFile>>>,
    simultaniousCount: number,
) => {
    React.useEffect(() => {
        uploader.on(UPLOADER_EVENTS.BATCH_ADD, (batch: FileBatchItem) => {
            const [item] = batch.items;
            const filePayload = extractPayload(item.file.name);
            
            if(filePayload) {
                const externalID = createExternalID(filePayload.movieID, filePayload.uploadType);
                const addedData = {
                    ...filePayload,
                    externalID
                }
                
                addedFiles.current.set(item.id, addedData);

                if(pending.size < simultaniousCount) {
                    pending.set(externalID, {progress: 0, itemId: item.id, title: filePayload.title});
                    setPending(new Map(pending));
                } else {
                    waiting.set(externalID, {itemId: item.id, title: filePayload.title});
                    setWaiting(new Map(waiting));
                }
            }
        });

        return () => {
            uploader.off(UPLOADER_EVENTS.BATCH_ADD);
        }
    }, [uploader, addedFiles, waiting, setWaiting, pending, setPending, simultaniousCount]);
}

export default useBatchAddEffect